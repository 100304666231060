<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="База контрагентов" subtitle="Test" description="Test 2">
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить контрагента</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="16" :lg="18">

        <a-spin indicator="activity" size="large" v-if="isLoading"/>

        <div v-else>
          <sdCards headless>
            <UserTableStyleWrapper>
              <div class="contact-table">
                <TableWrapper class="table-responsive">
                  <a-table
                      :dataSource="platformsTableData"
                      :columns="platformsTableColumns"
                  />
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </sdCards>
        </div>
      </a-col>
      <a-col :md="8" :lg="6">
        <h2>Что делать в этом разделе</h2>
        <p>В этом разделе вы можете просмотреть список всех контрагентов, которые добавлены в ОРД.</p>
        <p>Контрагенты автоматически появляются в этом разделе на этапе создания рекламы, добавлять их вручную не обязательно.</p>
        <p><b>Контрагентов можно редактировать</b>. Однако, будьте осторожны: редактирование затронет все рекламы, связанные с ним. Если где-то пролезла опечатка и нужно немного откорректировать, это возможно. Но вносить кардинальные изменения и менять одно юрлицо на другое закон не позволяет.</p>

<!--        <RoundVideo src="https://601002.selcdn.ru/static/video.mp4"/>-->
      </a-col>
      <sdModal
          :type="localState.modalType"
          title="Добавить нового контрагента"
          :visible="localState.visible"
          :footer="null"
          :onCancel="handleCancel"
      >
        <div class="project-modal" v-show="!showCreatedPlatform">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Тип" name="contragent_type_id">
                  <a-select v-model:value="addFormState.contragent_type_id">
                    <a-select-option v-for="t in typesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="getContragentNameLabel(addFormState.contragent_type_id)" name="name">
                  <a-input v-model:value="addFormState.name" :placeholder="getContragentNamePlaceholder(addFormState.contragent_type_id)"/>
                </a-form-item>

                <a-form-item label="ИНН" name="inn">
                  <a-input type="text" v-model:value="addFormState.inn" placeholder="123456789012" />
                </a-form-item>

                <a-form-item label="Номер телефона" name="phone">
                  <a-input type="text" v-model:value="addFormState.phone" placeholder="+79991234567" />
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Добавить контрагента'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
        <div v-show="showCreatedPlatform">
          <p><b>Контрагент добавлен.</b></p>

          <p style="color: red;">В ближайшие несколько минут он будет синхронизирован с ОРД и вы сразу сможете добавить к нему новую рекламу.</p>

          <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleCancel" style="margin-top: 20px;">
            Закрыть
          </sdButton>
        </div>
      </sdModal>
      <sdModal
          :type="localState.modalType"
          title="Редактирование контрагента"
          :visible="localState.editVisible"
          :footer="null"
          :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <sdAlerts
                  :outlined="false"
                  :closable="false"
                  :showIcon="true"
                  message="Обратите внимание"
                  description="Редактирование контрагента затронет все рекламы, связанные с ним. Если вы допустили опечатку в реквизитах и хотите ее исправить, это возможно. Но не стоит вносить кардинальные изменения и менять одно юрлицо на другое - могут быть проблемы с законом."
                  type="info"
              />
              <br>
              <a-form name="contactEdit" :rules="rules" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Тип" name="contragent_type_id">
                  <a-select v-model:value="editFormState.contragent_type_id">
                    <a-select-option v-for="t in typesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="getContragentNameLabel(editFormState.contragent_type_id)" name="name">
                  <a-input v-model:value="editFormState.name" :placeholder="getContragentNamePlaceholder(editFormState.contragent_type_id)"/>
                </a-form-item>

                <a-form-item label="ИНН" name="inn">
                  <a-input type="text" v-model:value="editFormState.inn" placeholder="123456789012" />
                </a-form-item>

                <a-form-item label="Номер телефона" name="phone">
                  <a-input type="text" v-model:value="editFormState.phone" placeholder="+79991234567" />
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Сохранить контрагента'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
.ant-table-column-title {
  font-size: 14px !important;
}

table tbody tr td {
  font-weight: normal !important;
}
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { contragentTypes, contragentTypesLabel, contragentTypesPlaceholder } from '@/config/enum';
import {useRouter} from "vue-router";
import moment from 'moment';
import RoundVideo from "../../../../components/vibes/RoundVideo.vue";

const Contragents = {
  name: 'Contragents',
  components: {
    RoundVideo,
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      contragent_type_id: 1,
      name: '',
      inn: '',
      phone: ''
    });

    const editFormState = reactive({
      contragent_type_id: 1,
      name: '',
      inn: '',
      phone: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(true);
    const isFormSending = ref(false);

    const router = useRouter();

    const typesList = ref([]);

    axios.get('/ord/data/contragent_types', {params: {}}).then(response => {
      typesList.value = response.data.types;
    });

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 191,
          message: 'Минимальная длина поля - 3 символа, максимальная - 191',
          trigger: 'blur',
        }
      ],
      contragent_type_id: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur'
        }
      ],
      inn: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 10,
          max: 12,
          message: 'ИНН физлица состоит из 12 цифр, ИНН юрлица - из 10. Проверьте правильность ввода.',
          trigger: 'blur',
        }
      ]
      // phone: [
      //   {
      //     required: true,
      //     message: 'Пожалуйста, заполните поле',
      //     trigger: 'blur',
      //   },
      //   {
      //     min: 10,
      //     max: 20,
      //     message: 'Пожалуйста, введите настоящий телефон',
      //     trigger: 'blur'
      //   }
      // ]
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.inn = data.inn;
      editFormState.contragent_type_id = data.contragent_type_id;
      editFormState.phone = data.phone;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const showCreatedPlatform = ref(false);

    const handleOk = values => {
      addFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/contragents/create', {
              project_id: activeProjectId(),
              ...addFormState
            }).then(response => {
              if(response.data.status == 'error') {
                message.error(response.data.message);
                return;
              }

              platformsList.value = response.data.contragents;

              showCreatedPlatform.value = true;

            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/contragents/edit', {
              project_id: activeProjectId(),
              ...editFormState
            }).then(response => {
              platformsList.value = response.data.contragents;

              handleCancel();
            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleCancel = () => {
      onCancel();

      showCreatedPlatform.value = false;
    };

    const platformsList = ref([]);
    const pkgs = computed(() => platformsList.value);

    const platformsTableData = computed(() => {
      return platformsList.value
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map(pkg => {
            const { id, name, inn, phone, contragent_type } = pkg;

            return {
              key: id,
              id,
              contragent_type,
              name,
              inn,
              phone,
              ord_sync: (
                  <div>
                    {(() => {
                      if (pkg.is_ord_synced){
                        return (
                            <a-tooltip placement="bottom" title={() => 'Синхронизировано с ОРД '+moment(pkg.ord_synced_at).format('DD.MM.YY в HH:mm')}>
                              <sdFeatherIcons type="check" size="16" color="green"/>
                            </a-tooltip>
                        )
                      }

                      return (<a-tooltip placement="bottom" title="Идет отправка данных в ОРД">
                        <a-spin indicator="activity" size="small"/>
                      </a-tooltip>);
                    })()}
                  </div>
              ),
              action: (
                  <Action class="table-actions">
                    <sdDropdown
                        class="wide-dropdwon"
                        overlay={
                          <>
                            <a onClick={() => showEditModal(pkg, id)} to="#">
                              <span>Редактировать</span>
                            </a>
                          </>
                        }
                        action={['click']}
                    >
                      <sdButton class="btn-icon" type="info" to="#" shape="circle">
                        <sdFeatherIcons type="more-vertical" size={16} />
                      </sdButton>
                    </sdDropdown>
                  </Action>
              ),
            };
          });
    });



    axios.get('/ord/contragents/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          platformsList.value = response.data.contragents;
          isLoading.value = false;
        });


    const platformsTableColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Тип',
        dataIndex: 'contragent_type.name',
        key: 'contragent_type.name'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'ИНН',
        dataIndex: 'inn',
        key: 'inn',
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'ОРД',
        dataIndex: 'ord_sync',
        key: 'ord_sync'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];

    const getContragentNameLabel = function(id) {
      return contragentTypesLabel[id];
    }
    const getContragentNamePlaceholder = function(id) {
      return contragentTypesPlaceholder[id];
    }

    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      platformsTableColumns,
      platformsTableData,
      platformsList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading,
      isFormSending,

      showCreatedPlatform,

      typesList,

      getContragentNameLabel,
      getContragentNamePlaceholder
    };
  },
};

export default Contragents;
</script>
