<template>
  <TopMenuStyle>
    <div class="strikingDash-top-menu">
      <ul>
        <li class="">
          <router-link to="/">
            Главная
          </router-link>
        </li>

        <li class="disabled">
          <router-link to="/ord/ads">
            Реклама
          </router-link>
        </li>

        <li class="has-subMenu">
          <a href="#" class="parent">
            Настройки
          </a>
          <ul class="subMenu">
            <li>
              <router-link to="/settings/users/manage">
                Добавить сотрудников
              </router-link>
            </li>
            <li class="">
              <router-link to="/ord/platforms">
                Добавить каналы
              </router-link>
            </li>
            <li class="">
              <router-link to="/ord/contragents">
                База контрагентов
              </router-link>
            </li>
            <li class="">
              <router-link to="/ord/telegram">
                Уведомления в Telegram
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </TopMenuStyle>
</template>
<script>
import { onMounted } from 'vue';
import { TopMenuStyle } from './style';

export default {
  name: 'TopMenu',
  components: {
    TopMenuStyle,
  },
  setup() {
    onMounted(() => {
      const active = document.querySelector('.strikingDash-top-menu a.active');
      const activeDefault = () => {
        const megaMenu = active.closest('.megaMenu-wrapper');
        const hasSubMenuLeft = active.closest('.has-subMenu-left');
        if (!megaMenu) {
         var s = active.closest('ul').previousSibling
             if(s !== null) s.classList.add('active');
         if (hasSubMenuLeft) {
           var ss = hasSubMenuLeft.closest('ul').previousSibling;
           if(ss !== null) ss.classList.add('active');
         }
        } else {
          var s =active.closest('.megaMenu-wrapper').previousSibling;
          if(s !== null)
            s.classList.add('active');
        }
      };
      window.addEventListener('load', active && activeDefault);
      // return () => window.removeEventListener('load', activeDefault);
    });

    const addParentActive = event => {
      document.querySelectorAll('.parent').forEach(element => {
        element.classList.remove('active');
      });

      const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
      const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
      if (!megaMenu) {
        event.currentTarget.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };

    return { addParentActive };
  },
};
</script>
