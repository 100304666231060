const periodUnits = {
  days: 'дней',
  months: 'месяцев',
  years: 'лет',
  forever: 'навсегда'
};

const contragentTypes = {
  1: 'Физическое лицо',
  2: 'ИП',
  3: 'Юридическое лицо'
};

const contragentTypesLabel = {
  1: 'ФИО',
  2: 'ФИО',
  3: 'Наименование юрлица'
};

const contragentTypesPlaceholder = {
  1: 'Иванов Иван Иванович',
  2: 'Иванов Иван Иванович',
  3: 'ООО «Божаю маркировку»'
};

const creativeTypes = {
  text_graphic_block: 'Текстово-графический блок',
  text_block: 'Текстовый блок',
  banner: 'Баннер',
  video: 'Видео',
  other: 'Иное'
};

const payTypes = {
  other: 'Иное (за размещение)',
  cpa: 'CPA (оплата за целевое действие)',
  cpc: 'CPC (оплата за клик)',
  cpm: 'CPM (оплата за 1000 показов)'
};

const contractStatusLabel = {
  0: 'Новая реклама',
  1: 'Пост вышел',
  2: 'Пора подавать отчет',
  4: 'Отчет просрочен',
  3: 'Успешно завершено',
};

const contractStatusLabelShort = {
  0: 'Новый',
  1: 'Вышел',
  2: 'Подать отчет',
  4: 'Просрочен',
  5: 'Завершено'
};

const contractStatusBadgeColor = {
  0: 'default',
  1: 'blue',
  2: 'orange',
  3: 'green',
  4: 'red'
};

const paymentMethods = {
  yoomoney: {
    name: 'YooMoney (для физ. лиц)',
    description: 'Самый быстрый способ запуска первых продаж. Нужен идентифицированный кошелек.',
    about_link: 'https://yoomoney.ru/',
    image: require('@/static/img/payment_methods/yoomoney.svg'),
    disabled: false
  },
  yookassa: {
    name: 'YooKassa (для ИП и юр. лиц)',
    description: '30+ методов оплаты. Обязательно заключение договора и установка онлайн кассы.',
    about_link: 'https://yookassa.ru/',
    image: require('@/static/img/payment_methods/yookassa.svg'),
    disabled: true
  },
  robokassa: {
    name: 'Robokassa (для ИП и юр. лиц)',
    description: '20+ методов оплаты. Обязательно заключение договора и установка онлайн кассы.',
    about_link: 'https://robokassa.ru/',
    image: require('@/static/img/payment_methods/robokassa.svg'),
    disabled: true
  },
  enot: {
    name: 'Enot.io (для физ. лиц)',
    description: 'Прием платежей для физических лиц без сложных договоров и документов.',
    about_link: 'https://enot.io/',
    image: require('@/static/img/payment_methods/enot.svg'),
    disabled: true
  },
  tome: {
    name: 'Tome.ru (для самозанятых)',
    description: 'Прием платежей для самозанятых. Автоматическая отправка чеков в ФНС.',
    about_link: 'https://tome.ru/',
    image: require('@/static/img/payment_methods/tome.svg'),
    disabled: true
  },

  qiwi: {
    name: 'Qiwi (для физ. лиц)',
    description: 'Оплата картами РФ и Qiwi. Нужен идентифицированный кошелек, можно онлайн через Госуслуги.',
    about_link: 'https://p2p.qiwi.com/',
    image: require('@/static/img/payment_methods/qiwi.svg'),
    disabled: false
  }
};

export {
  periodUnits,
  paymentMethods,
  contragentTypes,
  contragentTypesPlaceholder,
  contragentTypesLabel,
  creativeTypes,
  payTypes,
  contractStatusLabel,
  contractStatusBadgeColor,
  contractStatusLabelShort
};
