<template>
  <div>
    <sdPageHeader title="Добро пожаловать!">
      <template v-slot:buttons>
      </template>
    </sdPageHeader>
    <Main>

<!--      <a-spin indicator="activity" size="large" />-->

      <a-row :gutter="25">
        <a-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="16">
          <TaskListWrap class="mb-30">
            <div class="sDash_tasklist-wrap">
              <div class="sDash_tasklist-head">
                <h2 class="sDash_task-list-title">Пора подать отчеты</h2>
              </div>
                <div class="sDash_tasklist-body">
                  <ul v-if="reportAdsList.length > 0" class="sDash_tasks">
                    <li v-for="(item, i) in reportAdsList" class="sDash_tasks-item" :key="item.id" :class="{'report-expired': (item.status === 4)}">
                      <div class="sDash_tasks-item__content">
                        <div class="sDash_tasks-item__title">
                           №{{item.contract_no}}: {{ item.contragent.name }} / {{ item.creatives[0].brand_description }}
                        </div>
                        <div class="sDash_tasks-item__text">
                          <div style="display: flex; align-items: center; justify-content: flex-start; gap: 15px; color: grey;">
                            <div>
                              <sdFeatherIcons type="target" size="14" />
                              {{ item.platform.name }}
                            </div>
                            <div>
<!--                              <sdFeatherIcons type="activity" size="14" />-->
                              ₽ {{ item.contract_sum }}
                            </div>
                            <div :class="{'deadline-expired': (item.status === 4)}">
                              <sdFeatherIcons type="clock" size="14" />
                              <b v-if="item.status === 4">&nbsp;&nbsp;Дедлайн просрочен:</b>
                              {{ moment(item.report_deadline_till).format('DD.MM.YYYY') }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="sDash_tasks-item__action" v-if="!completedData[item.id]">
                        <router-link :to="'/ord/ads/item/'+item.id">
                          <a-button type="success" size="small">Подать отчет</a-button>
                        </router-link>
                      </div>
                    </li>
                  </ul>

                  <div v-else class="sDash_empty-task">
                    <a-empty description="Все отчеты поданы. Так держать!" />
                  </div>
                </div>
            </div>
          </TaskListWrap>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
          <CardStyleWrapper>
            <sdCards :border="false" title="Промаркировать рекламу">
              <p>Нажмите на кнопку, чтобы получить токен для новой рекламы.</p>
              <sdButton type="primary" @click="createNewAds" style="margin-top: 10px;">
                Новая реклама
              </sdButton>
            </sdCards>
          </CardStyleWrapper>

          <h2 class="sDash_task-list-title" style="font-size: 16px; font-weight: 500; padding: 15px 0px;">Возникли вопросы? {{ ios_token }}</h2>

          <p>Напишите нам в Telegram и мы поможем разобраться в настройках платформы.</p>

          <a href="https://t.me/vibes_manager" target="_blank">
            <a-button type="primary">Написать в Telegram</a-button>
          </a>
        </a-col>

      </a-row>
    </Main>
  </div>
</template>
<script>
import { Main } from '../../../styled';
import { Figure3, BannerWrapper, Figure4, Figure5 } from '../style';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { TaskListWrap } from './task_style';
import {firebaseInit} from "../../../../firebase";
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import moment from 'moment';

export default {
  name: 'OrdHome',
  components: {
    Main, Figure3, BannerWrapper, Figure4, Figure5, TaskListWrap
  },
  setup() {

    const reportAdsList = ref([]);
    const isReportAdsLoading = ref(true);

    const loadReportAds = function() {
      axios.get('/ord/dashboard/report_ads/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            reportAdsList.value = response.data.ads;
            isReportAdsLoading.value = false;
          });
    };

    loadReportAds();

    const task = ref([
      {completed: false, id: 1, title: 'Настроить тарифы', description: 'Указать стоимость и период приобретения доступа в ваш канал.', link: '/settings/packages'},
      {completed: false, id: 2, title: 'Настроить чат-бот', description: 'Указать тексты сообщений приветствия пользователя, настроить кнопки в чат-боте', link: '/settings/chatbot'},
      {completed: false, id: 5, title: 'Создать прогрев', description: 'Добавить сообщения, которые будут отправляться для дожима', link: '/settings/flows'},
      {completed: false, id: 4, title: 'Подключить платежную систему', description: 'Поддерживаем YooMoney, YooKassa, Qiwi, Enot и др. системы', link: '/settings/payments'},
      {completed: false, id: 6, title: 'Активировать магазин', description: 'Отправить заявку на модерацию и начать принимать деньги', link: '/settings'}
    ]);

    const completedData = ref((localStorage.getItem('onboardingChecklist')) ? JSON.parse((localStorage.getItem('onboardingChecklist'))) : {});

    const populateChecklist = function() {
      var data = {};

      task.value.forEach(function(item) {
        data[item.id] = false;
      });

      saveLocalStorage(data);
    }

    const saveLocalStorage = function(data) {
      localStorage.setItem('onboardingChecklist', JSON.stringify(data));

      completedData.value = (localStorage.getItem('onboardingChecklist')) ? JSON.parse((localStorage.getItem('onboardingChecklist'))) : {};
    }

    const ios_token = ref(localStorage.getItem('ios_token'));

    if(Object.keys(completedData.value).length == 0)
      populateChecklist();

    const setCompleted = function(id)  {
      completedData.value[id] = !completedData.value[id];

      saveLocalStorage(completedData.value);
    }

    const initFirebase = function() {
      firebaseInit();
    };

    return {
      task,

      completedData,
      setCompleted,

      initFirebase,
      ios_token,

      reportAdsList,
      isReportAdsLoading,

      moment
    }
  }
};
</script>
<style lang="css">
  .sDash_tasks .sDash_tasks-item__title .ant-checkbox + span {
    color: #272B41 !important;
  }

  .sDash_tasks .sDash_tasks-item.task-completed  {
    text-decoration: line-through !important;
  }

  .sDash_tasks .sDash_tasks-item.task-completed .sDash_tasks-item__title .ant-checkbox + span {
    text-decoration: line-through !important;
  }

  .report-expired {
    background: #ffe7e7 !important;
  }

  .deadline-expired {
    color: red !important;
  }
</style>
