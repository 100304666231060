<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Рекламные размещения" subtitle="Test" description="Test 2">
        <template #buttons>
          <router-link to="/ord/ads/create">
            <sdButton class="btn-add_new" size="default" type="primary" key="1">
              + Создать рекламу
            </sdButton>
          </router-link>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="16" :lg="18">

        <a-spin indicator="activity" size="large" v-if="isLoading"/>

        <div v-else>
          <AdsFilters>
            <a-popover trigger="click" placement="bottom">
              <template #content>
                <div class="ads-filter-pop-item" @click="setFiltersStatus('all')">
                  <sdFeatherIcons type="check" size="16" v-show="filters.status === 'all'"/> Все статусы
                </div>

                <div v-for="(item, key) in contractStatusLabel" :key="key" class="ads-filter-pop-item" @click="setFiltersStatus(key)">
                  <sdFeatherIcons type="check" size="16" v-show="Array.isArray(filters.status) && filters.status.includes(key)"/> {{ item }}
                </div>
              </template>
              <div class="ads-filter-item">
                {{ filtersStatusText }}&nbsp;<sdFeatherIcons type="chevron-down" size="16" />
              </div>
            </a-popover>

            <a-popover trigger="click" placement="bottom">
              <template #content>
                <div class="ads-filter-pop-item" @click="setFiltersChannel('all')">
                  <sdFeatherIcons type="check" size="16" v-show="filters.channel === 'all'"/> Все каналы
                </div>

                <div v-for="(item) in myPlatformsList" :key="item.id" class="ads-filter-pop-item" @click="setFiltersChannel(item.id)">
                  <sdFeatherIcons type="check" size="16" v-show="Array.isArray(filters.channel) && filters.channel.includes(item.id)"/> {{ item.name }}
                </div>
              </template>
              <div class="ads-filter-item">
                {{ filtersChannelText }}&nbsp;<sdFeatherIcons type="chevron-down" size="16" />
              </div>
            </a-popover>

            <a-popover trigger="click" placement="bottom">
              <template #content>
                <div class="ads-filter-pop-item" @click="setFiltersContragent('all')">
                  <sdFeatherIcons type="check" size="16" v-show="filters.contragent === 'all'"/> Все контрагенты
                </div>

                <div v-for="(item) in myContragentsList" :key="item.id" class="ads-filter-pop-item" @click="setFiltersContragent(item.id)">
                  <sdFeatherIcons type="check" size="16" v-show="Array.isArray(filters.contragent) && filters.contragent.includes(item.id)"/> {{ item.name }}
                </div>
              </template>
              <div class="ads-filter-item">
                {{ filtersContragentText }}&nbsp;<sdFeatherIcons type="chevron-down" size="16" />
              </div>
            </a-popover>
          </AdsFilters>

          <sdCards headless>
            <UserTableStyleWrapper>
              <div class="contact-table">
                <TableWrapper class="table-responsive">
                  <a-table
                      :dataSource="platformsTableData"
                      :columns="platformsTableColumns"
                      :row-class-name="platformsTableClassName"
                  />
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </sdCards>
        </div>
      </a-col>
      <a-col :md="8" :lg="6">
        <h2>Что делать в этом разделе</h2>
        <p>Это основной раздел, в котором вы будете работать 90% времени. В нем отображаются все рекламы, которые вы провели в ОРД с текущего юрлица.</p>
        <p><b>Чтобы создать новую рекламу</b>, нажмите на кнопку в правом верхнем углу и пройдите 5 простых шагов. Это займет не более 3-4 минут.</p>
        <p>Рекламы, по которым пора подавать отчетность, подсвечиваются красным. Постарайтесь не пропускать сроки подачи отчетности: за это может быть начислен штраф.</p>
        <p></p>

        <!--        <RoundVideo src="https://601002.selcdn.ru/static/video.mp4"/>-->
      </a-col>


    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
.ant-table-column-title {
  font-size: 14px !important;
}

table tbody tr td {
  font-weight: normal !important;
}

.custom-badge {
  border-radius: 10px;
  display: inline-block;
  background: #d9d9d9;
  padding: 5px 12px;
  font-size: 12px;
}

.custom-badge-blue { background: #1890ff; }
.custom-badge-lime { background: #e9ffb3; }
.custom-badge-red { background: #f5222d; color: #ffffff; font-weight: bold; }
.custom-badge-green { background: #52c41a; }
.custom-badge-magenta { background: #eb2f96; color: #ffffff; font-weight: bold; }

.ads-filter-pop-item {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #ffffff;
  cursor: pointer;
  transition: background 0.1s ease;
}

.ads-filter-pop-item:hover {
  background: #f8f9fb;
}

</style>
<script>
import { useStore } from 'vuex';
import {computed, onMounted, ref, watchEffect, reactive, watch} from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { contragentTypes, contragentTypesLabel, contragentTypesPlaceholder } from '@/config/enum';
import {useRouter} from "vue-router";
import moment from 'moment';
import RoundVideo from "../../../../components/vibes/RoundVideo.vue";
import {formatSum} from "@/config/helper";
import {contractStatusBadgeColor, contractStatusLabel, contractStatusLabelShort} from "@/config/enum";
import {ProjectSorting} from "../../../apps/project/style";
import {AdsFilters} from "./Style";
import { plural } from "@/utility/utility.js";

const Ads = {
  name: 'Ads',
  components: {
    RoundVideo,
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
    ProjectSorting,
    AdsFilters
  },
  setup() {
    const addFormState = reactive({
      contragent_type_id: 1,
      name: '',
      inn: '',
      phone: ''
    });

    const editFormState = reactive({
      contragent_type_id: 1,
      name: '',
      inn: '',
      phone: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(true);
    const isFormSending = ref(false);

    const router = useRouter();

    const typesList = ref([]);

    axios.get('/ord/data/contragent_types', {params: {}}).then(response => {
      typesList.value = response.data.types;
    });

    const filters = reactive({
      status: 'all',
      channel: 'all',
      contragent: 'all'
    });

    const setFiltersStatus = function(status) {
      if(status === 'all')
        filters.status = 'all';
      else {
        if(Array.isArray(filters.status)) {
          if(filters.status.includes(status)) {
            filters.status = filters.status.filter(item => item !== status);

            if(filters.status.length == 0)
              filters.status = 'all';
          }
          else filters.status.push(status);
        }
        else filters.status = [status];
      }
    };

    const filtersStatusText = computed(() => {
      if(Array.isArray(filters.status)) {
        if(filters.status.length == 1) {
          return contractStatusLabel[filters.status[0]];
        } else return filters.status.length+" "+plural(['статус', 'статуса', 'статусов'], filters.status.length);
      }

        return 'Все статусы';
    });

    const setFiltersChannel = function(status)
    {
      if(status === 'all')
        filters.channel = 'all';
      else {
        if(Array.isArray(filters.channel)) {
          if(filters.channel.includes(status)) {
            filters.channel = filters.channel.filter(item => item !== status);

            if(filters.channel.length == 0)
              filters.channel = 'all';
          }
          else filters.channel.push(status);
        }
        else filters.channel = [status];
      }
    }

    const setFiltersContragent = function(status)
    {
      if(status === 'all')
        filters.contragent = 'all';
      else {
        if(Array.isArray(filters.contragent)) {
          if(filters.contragent.includes(status)) {
            filters.contragent = filters.contragent.filter(item => item !== status);

            if(filters.contragent.length == 0)
              filters.contragent = 'all';
          }
          else filters.contragent.push(status);
        }
        else filters.contragent = [status];
      }
    }

    const filtersChannelText = computed(() => {
      if(Array.isArray(filters.channel)) {
        if(filters.channel.length == 1) {
          return (myPlatformsList.value.find(item => item.id === filters.channel[0])).name;
        } else return filters.channel.length+" "+plural(['канал', 'канала', 'каналов'], filters.channel.length);
      }

      return 'Все каналы';
    });

    const filtersContragentText = computed(() => {
      if(Array.isArray(filters.contragent)) {
        if(filters.contragent.length == 1) {
          return (myContragentsList.value.find(item => item.id === filters.contragent[0])).name;
        } else return filters.contragent.length+" "+plural(['контрагент', 'контрагента', 'контрагентов'], filters.contragent.length);
      }

      return 'Все контрагенты';
    });


    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 191,
          message: 'Минимальная длина поля - 3 символа, максимальная - 191',
          trigger: 'blur',
        }
      ],
      contragent_type_id: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur'
        }
      ],
      inn: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 10,
          max: 12,
          message: 'ИНН физлица состоит из 12 цифр, ИНН юрлица - из 10. Проверьте правильность ввода.',
          trigger: 'blur',
        }
      ]
      // phone: [
      //   {
      //     required: true,
      //     message: 'Пожалуйста, заполните поле',
      //     trigger: 'blur',
      //   },
      //   {
      //     min: 10,
      //     max: 20,
      //     message: 'Пожалуйста, введите настоящий телефон',
      //     trigger: 'blur'
      //   }
      // ]
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.inn = data.inn;
      editFormState.contragent_type_id = data.contragent_type_id;
      editFormState.phone = data.phone;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const showCreatedPlatform = ref(false);

    const handleOk = values => {
      addFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/contragents/create', {
              project_id: activeProjectId(),
              ...addFormState
            }).then(response => {
              if(response.data.status == 'error') {
                message.error(response.data.message);
                return;
              }

              platformsList.value = response.data.contragents;

              showCreatedPlatform.value = true;

            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/contragents/edit', {
              project_id: activeProjectId(),
              ...editFormState
            }).then(response => {
              platformsList.value = response.data.contragents;

              handleCancel();
            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleCancel = () => {
      onCancel();

      showCreatedPlatform.value = false;
    };

    const platformsList = ref([]);
    const pkgs = computed(() => platformsList.value);

    const platformsTableData = computed(() => {
      return platformsList.value
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map(pkg => {
            const { id, contract_no, platform, contragent, post_date, contract_sum, status } = pkg;

            return {
              key: id,
              contract_no,
              platform,
              contragent,
              post_date: (
                  <span>{moment(post_date).format('DD.MM.YYYY')}</span>
              ),
              contract_sum: (
                  <span>{formatSum(contract_sum)} руб.</span>
              ),
              status: (
                  <a-tag color={contractStatusBadgeColor[status]}>{contractStatusLabel[status]}</a-tag>
              ),
              // status: (
              //     <span className={'custom-badge custom-badge-'+contractStatusBadgeColor[status]}>{contractStatusLabel[status]}</span>
              // ),
              // ord_sync: (
              //     <div>
              //       {(() => {
              //         if (pkg.is_ord_synced){
              //           return (
              //               <a-tooltip placement="bottom" title={() => 'Синхронизировано с ОРД '+moment(pkg.ord_synced_at).format('DD.MM.YY в HH:mm')}>
              //                 <sdFeatherIcons type="check" size="16" color="green"/>
              //               </a-tooltip>
              //           )
              //         }
              //
              //         return (<a-tooltip placement="bottom" title="Идет отправка данных в ОРД">
              //           <a-spin indicator="activity" size="small"/>
              //         </a-tooltip>);
              //       })()}
              //     </div>
              // ),
              action: (
                  <router-link to={'/ord/ads/item/'+id}>
                    <sdButton class="btn-icon" type="default" size="small">
                      Открыть
                    </sdButton>
                  </router-link>
                  // <Action class="table-actions">
                  //   <sdDropdown
                  //       class="wide-dropdwon"
                  //       overlay={
                  //         <>
                  //           <a onClick={() => showEditModal(pkg, id)} to="#">
                  //             <span>Редактировать</span>
                  //           </a>
                  //         </>
                  //       }
                  //       action={['click']}
                  //   >
                  //     <sdButton class="btn-icon" type="info" to="#" shape="circle">
                  //       <sdFeatherIcons type="more-vertical" size={16} />
                  //     </sdButton>
                  //   </sdDropdown>
                  // </Action>
              ),
            };
          });
    });

    const loadAdsData = function() {
      axios.get('/ord/ads/list', {params: {project_id: activeProjectId(), ...filters}})
          .then(response => {
            platformsList.value = response.data.ads;
            isLoading.value = false;
          });
    };

    loadAdsData();

    watch(filters, () => {
      loadAdsData();
    })

    const platformsTableColumns = [
      {
        title: '№ договора',
        dataIndex: 'contract_no',
        key: 'contract_no'
      },
      {
        title: 'Канал',
        dataIndex: 'platform.name',
        key: 'platform.name'
      },
      {
        title: 'Рекламодатель',
        dataIndex: 'contragent.name',
        key: 'contragent.name'
      },
      {
        title: 'Дата рекламы',
        dataIndex: 'post_date'
      },
      {
        title: 'Сумма',
        dataIndex: 'contract_sum'
      },
      {
        title: 'Статус',
        dataIndex: 'status'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];

    const getContragentNameLabel = function(id) {
      return contragentTypesLabel[id];
    }
    const getContragentNamePlaceholder = function(id) {
      return contragentTypesPlaceholder[id];
    }

    const platformsTableClassName = function(record, index)
    {
      console.log(record);
      if(record.status.props.color === 'orange')
        return 'row-warning';

      if(record.status.props.color === 'red')
        return 'row-danger';

      return '';
    }

    const myPlatformsList = ref([]);

    axios.get('/ord/platforms/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          myPlatformsList.value = response.data.platforms;
          isLoading.value = false;
        });

    const myContragentsList = ref([]);

    axios.get('/ord/contragents/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          myContragentsList.value = response.data.contragents;
          isLoading.value = false;
        });




    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      platformsTableColumns,
      platformsTableData,
      platformsList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading,
      isFormSending,

      showCreatedPlatform,

      typesList,

      getContragentNameLabel,
      getContragentNamePlaceholder,

      platformsTableClassName,
      contractStatusLabelShort,
      contractStatusLabel,

      filters,
      setFiltersStatus,
      setFiltersChannel,
      setFiltersContragent,
      filtersStatusText,
      filtersChannelText,
      filtersContragentText,

      myPlatformsList,
      myContragentsList
    };
  },
};

export default Ads;
</script>
