<template>
  <Main style="height: 90vh; height: calc(100vh - 66px);">
    <a-row :gutter="15" style="height: 100%; padding-top: 20px;">
      <a-col :xs="24" style="height: 100%;">
        <KanvanBoardWrap style="height: 100%;">
          <sdCards headless style="height: 100%;">

            <vue-horizontal-es class="horizontal" scroll snap="none" :button-between="false" style="width: 100%; height: 100%;">
<!--              <template v-slot:btn-prev>-->
<!--                <div class="btn-left"></div>-->
<!--              </template>-->

<!--              <template v-slot:btn-next>-->
<!--                <div class="btn-right"></div>-->
<!--              </template>-->

              <div class="kanban-col" v-for="(item, index) in dealStatus" style="height: 100%;">
                <h3 class="kanban-col-name">{{ item.name }}</h3>
                <p class="kanban-deal-count">{{ dealList.filter(i => i.deal_status_id === item.id).length }} обращений</p>
                <div class="kanban-deal-color" :style="{'background-color': item.color}"></div>
                <draggable class="list-group" :data-id="item.id" :list="dealList.filter(i => i.deal_status_id === item.id)" group="deals" itemKey="id" @change="log" @end="onMove">
                  <template #item="{ element, index }">
                    <div class="kanban-deal-wrap" :data-id="element.id">
                      <router-link :to="'/chat/all/'+element.chat.id">
                        <div class="list-group-item kanban-deal-card" :data-id="element.id">
                          <div class="kanban-dialog-preview">
                            <img :src="getAvatarUrl(element.chat)" class="kanban-dialog-avatar"/>
                            <div class="kanban-dialog-info">
                              <div class="kanban-dialog-info-head">
                                <div class="kanban-dialog-info-name">
                                  {{ element.chat.first_name }} {{ element.chat.last_name }} {{ element.chat.title }}
                                </div>
                                <div class="kanban-dialog-info-time" v-if="element.chat.last_message !== null && element.chat.last_message !== undefined">
                                  {{
                                    moment(element.chat.last_message.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')
                                        ? moment(element.chat.last_message.created_at).fromNow()
                                        : moment(element.chat.last_message.created_at).format('DD.MM')
                                  }}
                                </div>
                              </div>
                              <div class="kanban-dialog-msg-preview">
                                <div class="kanban-last-msg">
                                  <LastMessageSnippet :lastMessage="element.chat.last_message"/>
                                </div>
                                <div class="kanban-msg-count" v-if="element.chat.unread_messages.length > 0">
                                  <a-badge  class="badge-success" :count="element.chat.unread_messages.length" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="element.responsible_user !== null && element.responsible_user !== undefined" class="kanban-responsible-user">
                            <sdFeatherIcons type="user" size="16" color="#ADB4D2"/> {{ element.responsible_user.name }}
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </template>
                </draggable>
              </div>

            </vue-horizontal-es>
          </sdCards>
        </KanvanBoardWrap>
      </a-col>
    </a-row>
  </Main>
  <UpdateTask :handleCancel="handleCancel" :modalVisible="modalVisible" :data="checklistData" />
  <BackShadow v-if="backShadow" @click="onBackShadowHide" />
</template>
<style scoped>
/*.btn-left, .btn-right {*/
/*  padding: 8px;*/
/*  height: 100%;*/
/*}*/

/*.btn-left {*/
/*  background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);*/
/*  padding-right: 20px;*/
/*}*/

/*.btn-right {*/
/*  background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);*/
/*  padding-left: 20px;*/
/*}*/

@media screen and (max-width: 768px) {
  /*.btn-right {*/
  /*  background: linear-gradient(to right, #F4F5F700 0, #F4F5F7 50%, #F4F5F7);*/
  /*}*/

  /*.btn-left {*/
  /*  background: linear-gradient(to left, #F4F5F700 0, #F4F5F7 50%, #F4F5F7);*/
  /*}*/

  .btn-right, .btn-left {
    display: none;
  }
}


</style>
<script>
import { KanvanBoardWrap, BackShadow } from './style';
import KanbanBoardItem from './overview/KanbanBoardItem';
import UpdateTask from './overview/UpdateTask';
import { Main } from '../../styled';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { toRefs, ref, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import draggable from 'vuedraggable';
import BoardTitleUpdate from './overview/BoardTitleUpdate.vue';
import TaskItem from './overview/TaskItem';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { VueDraggableNext } from 'vue-draggable-next';
import VueHorizontalEs from "vue-horizontal";
import LastMessageSnippet from "../chat/components/LastMessageSnippet.vue";
import moment from 'moment';
import {getAvatarUrl} from "../../../utility/utility";
import {message} from "ant-design-vue";
import _ from "lodash";

const Kanban = {
  name: 'Kanban',
  components: {
    KanvanBoardWrap,
    BackShadow,
    Main,
    BoardTitleUpdate,
    KanbanBoardItem,
    UpdateTask,
    draggable,
    TaskItem,
    PerfectScrollbar,
    VueDraggableNext,
    VueHorizontalEs,
    LastMessageSnippet
  },
  setup() {
    const { state, dispatch } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    // const boardData = computed(() => state.KanbanBoard.boardData);

    const tasks = computed(() => state.KanbanBoard.taskData);
    // const tasks = ref(tasksData.value);
    const addColumn = ref(false);

    const formState = reactive({});
    const states = reactive({
      formState,
      columnTitle: '',
      boardTitle: '',
      boardId: '',
      titleBoardId: '',
      checklistData: {
        id: 1,
        boardId: 1,
        checklist: [],
      },
      modalVisible: false,
      boardEditable: false,
      backShadow: false,
      taskId: '',
    });

    moment.updateLocale('en', {
      relativeTime : {
        future: "через %s",
        past:   "%s",
        s  : 'сейчас',
        ss : '%d с.',
        m:  "1 мин.",
        mm: "%d м.",
        h:  "1 ч.",
        hh: "%d ч.",
        d:  "1 д.",
        dd: "%d д.",
        w:  "1 н.",
        ww: "%d н.",
        M:  "1 м.",
        MM: "%d м.",
        y:  "1 г",
        yy: "%d г."
      }
    });


    const activeAddOption = e => {
      e.preventDefault();
      addColumn.value = true;
    };

    const diActiveAddOption = e => {
      e.preventDefault();
      addColumn.value = false;
    };
    const onColumnTitleChange = e => {
      state.columnTitle = e.target.value;
    };
    const handleOnAddTask = (e, id) => {
      e.preventDefault();
      states.boardId = id;
    };
    const handleOffAddTask = e => {
      e.preventDefault();
      states.boardId = '';
    };
    const onBackShadow = id => {
      states.backShadow = true;
      states.taskId = id;
    };
    const onBackShadowHide = () => {
      states.backShadow = false;
      states.taskId = '';
    };
    const onTaskTitleUpdate = (value, id) => {
      tasks.value.map(task => {
        if (task.id === id) {
          task.title = value;
          states.backShadow = false;
          states.taskId = '';
        }
        return task;
      });
      dispatch('ToAddTask', tasks.value);
    };
    const onTaskTitleDelete = (e, id) => {
      e.preventDefault();
      const afterDeleteTask = tasks.value.filter(task => task.id !== id);
      states.backShadow = false;
      states.taskId = '';
      dispatch('ToDeleteTask', afterDeleteTask);
    };

    const addTaskHandler = id => {
      const arrayData = [];
      const taskItem = document.querySelector(`input[name="taskInput-${id}"]`).value;
      tasks.value.map(data => {
        return arrayData.push(data.id);
      });
      const max = Math.max(...arrayData);
      console.log(max);
      if (taskItem !== '') {
        dispatch('ToAddTask', [
          ...tasks.value,
          {
            id: `${max + 1}`,
            boardId: states.boardId,
            title: taskItem,
            checklist: [],
          },
        ]);
        states.boardId = '';
      } else {
        alert('Please Enter a Task Ttile');
      }
    };

    const addColumnHandler = () => {
      const arrayData = [];
      boardData.value.map(data => {
        return arrayData.push(data.boardId);
      });
      const max = Math.max(...arrayData);
      if (states.columnTitle !== '') {
        dispatch('ToAddBoard', [
          ...boardData.value,
          {
            boardId: max + 1,
            title: states.columnTitle,
          },
        ]);
        states.columnTitle = '';
        addColumn.value = false;
      } else {
        alert('Please Enter a Column Ttile');
      }
    };

    const showModal = dataList => {
      states.modalVisible = !states.modalVisible;
      states.checklistData = dataList;
    };

    const onBoardEditable = (e, id, title) => {
      e.preventDefault();
      states.boardEditable = true;
      states.boardTitle = title;
      states.titleBoardId = id;
    };

    const onBoardEditableHide = id => {
      boardData.value.map(board => {
        if (board.boardId === id) {
          console.log(document.querySelector(`input[name="titile-edit${id}"]`));
          board.title = document.querySelector(`input[name="titile-edit${id}"]`).value;
        }
        return board;
      });
      dispatch('ToAddBoard', boardData.value);
      states.boardEditable = false;

      states.titleBoardId = '';
    };
    const deleteColumnHandler = id => {
      const afterDeleteData = boardData.value.filter(board => board.boardId !== id);
      const confirm = window.confirm('Are You sure to delete this?');
      if (confirm) {
        dispatch('ToAddBoard', afterDeleteData);
      }
      return false;
    };
    const handleCancel = () => {
      states.modalVisible = false;
    };
    function getComponentData() {
      return {
        class: 'sDash_kanban-board-list',
      };
    }
    const log = evt => {
      console.log(evt);
    };

    const dealList = ref([]);
    const dealStatus = ref([]);
    const boardData = ref([]);

    axios.get('/chat/deals/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          response.data.deal_status.forEach(function(item) {
            boardData.value.push({
              boardId: item.id,
              title: item.name
            });
          });

          dealList.value = response.data.deals;
          dealStatus.value = response.data.deal_status;
        });

  const onMove = function(evt, originalEvent)
  {
    console.log('end: ', evt, originalEvent);

    console.log('moved deal id', evt.item.dataset.id, 'from', evt.from.dataset.id, 'to', evt.to.dataset.id);

    dealList.value = dealList.value.map(item => {
      if(item.id == evt.item.dataset.id)
        item.deal_status_id = parseInt(evt.to.dataset.id);

      return item;
    });

    axios.post('/chat/deals/setStatus', {
      project_id: activeProjectId(),
      deal_id: parseInt(evt.item.dataset.id),
      status_id: parseInt(evt.to.dataset.id)
    }).then(response => {
      console.log(response);

      if(response.data.status == 'error') {
        message.error(response.data.message);
        return;
      }

      dealList.value = response.data.deals;
      dealStatus.value = response.data.deal_status;
    });

    //.dataset('id'), 'from', evt.from.dataset('id'), 'to', evt.to.dataset('id')
  }


    return {
      log,
      getComponentData,
      handleCancel,
      deleteColumnHandler,
      onBoardEditableHide,
      onBoardEditable,
      showModal,
      addColumnHandler,
      addTaskHandler,
      onTaskTitleDelete,
      onBackShadow,
      onTaskTitleUpdate,
      onBackShadowHide,
      handleOffAddTask,
      handleOnAddTask,
      activeAddOption,
      rtl,
      boardData,
      addColumn,
      states,
      ...toRefs(states),
      onColumnTitleChange,
      diActiveAddOption,

      dealList,
      dealStatus,

      onMove,

      moment,
      getAvatarUrl
    };
  },
  computed: {
    dragAbleBoardData: {
      get() {
        return this.boardData;
      },
      set(value) {
        this.$store.dispatch('ToAddBoard', value);
      },
    },
  },
};
export default Kanban;
</script>
