<template>
  <div class="round-video-container" @click="toggleVideo">
    <div v-if="videoPaused" class="round-video-play-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="64" fill="#ffffff" viewBox="0 0 384 512">
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
      </svg>
    </div>
    <video loop ref="videoElement">
      <source :src="src"/>
    </video>
  </div>
</template>
<script>
import { computed, onMounted, ref, watchEffect, reactive, watch } from 'vue';
import draggable from 'vuedraggable'
import { message } from 'ant-design-vue';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import activeProjectId from '@/config/helpers'

export default {
  name: 'RoundVideo',
  components: {
    draggable,
    PlusOutlined,
    LoadingOutlined
  },
  props: {
    src: String
  },
  setup(props) {

    //const videoSrc = ref(props.src);
    const videoElement = ref();

    const videoPaused = ref(true);

    const toggleVideo = function() {
      const v = videoElement.value;

      if (v.paused) {
        videoPaused.value = false;
        v.play();
      }
      else {
        videoPaused.value = true;
        v.pause();
      }
    };

    return {
      videoElement,
      toggleVideo,
      videoPaused
    }
  }
};
</script>

<style lang="scss">
video {
  border-radius: 50%;
  width: 100%;
  border: 2px solid #1890ff;
}

.round-video-container {
  position: relative;
  width: 100%;
  max-width: 280px;
}

.round-video-play-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
