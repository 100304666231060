<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Управление каналами" subtitle="Test" description="Test 2">
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить канал</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="16" :lg="18">

        <a-spin indicator="activity" size="large" v-if="isLoading"/>

        <div v-else>
          <sdCards headless>
            <UserTableStyleWrapper>
              <div class="contact-table">
                <TableWrapper class="table-responsive">
                  <a-table
                      :dataSource="platformsTableData"
                      :columns="platformsTableColumns"
                  />
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </sdCards>
        </div>
      </a-col>
      <a-col :md="8" :lg="6">
        <h2>Что делать в этом разделе</h2>
        <p>В этом разделе вы можете добавить каналы, в которых вы продаете рекламу.</p>
        <p>Сейчас мы поддерживаем маркировку только со стороны <b>владельца канала</b>, поэтому если вы не владеете каналом, а работаете с ним по агентскому договору или сами покупаете рекламу, добавлять такой канал через этот сервис не нужно.</p>
        <p>Перед добавлением канала убедитесь, что выбрали корректное юрлицо (в правом верхнем углу).</p>
        <p><b>Не добавляйте тестовые или некорректные данные:</b> после добавления канала информация сразу уйдет в ОРД, а удалять данные из ОРД не позволяет закон о маркировке.</p>

<!--        <RoundVideo src="https://601002.selcdn.ru/static/video.mp4"/>-->
      </a-col>
      <sdModal
          :type="localState.modalType"
          title="Добавить новую площадку"
          :visible="localState.visible"
          :footer="null"
          :onCancel="handleCancel"
      >
        <div class="project-modal" v-show="!showCreatedPlatform">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Тип площадки" name="platform_type_id">
                  <a-select v-model:value="addFormState.platform_type_id">
                    <a-select-option v-for="t in typesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="Название площадки" name="name" extra="Для Telegram/VK каналов укажите название, для аккаунтов Instagram логин через @">
                  <a-input v-model:value="addFormState.name" placeholder="@vibes_band" />
                </a-form-item>

                <a-form-item label="Ссылка на площадку" name="url">
                  <a-input type="text" v-model:value="addFormState.url" placeholder="https://t.me/vibes_band" />
                </a-form-item>

                <a-form-item label="Владение каналом" extra="Поставьте галочку, если вы являетесь владельцем данного канала. Если вы продаете рекламу по агентскому договору, не являясь владельцем площадки, такую цепочку договоров придется подавать отдельно, без нашего сервиса." name="is_owner">
                  <a-checkbox v-model:checked="addFormState.is_owner"><span class="checkbox-label">Я подтверждаю, что являюсь владельцем канала {{ addFormState.name }}</span></a-checkbox>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Добавить площадку'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
        <div v-show="showCreatedPlatform">
          <p><b>Площадка добавлена.</b></p>

          <p style="color: red;">В ближайшие несколько минут она будет синхронизирована с ОРД и вы сразу сможете добавить к ней новую рекламу.</p>

          <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleCancel" style="margin-top: 20px;">
            Закрыть
          </sdButton>
        </div>
      </sdModal>
      <sdModal
          :type="localState.modalType"
          title="Редактирование площадки"
          :visible="localState.editVisible"
          :footer="null"
          :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contactEdit" :rules="rules" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Тип площадки" name="platform_type_id">
                  <a-select v-model:value="editFormState.platform_type_id">
                    <a-select-option v-for="t in typesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="Название площадки" name="name" extra="Для Telegram/VK каналов укажите название, для аккаунтов Instagram логин через @">
                  <a-input v-model:value="editFormState.name" placeholder="@vibes_band" />
                </a-form-item>

                <a-form-item label="Ссылка на площадку" name="url">
                  <a-input type="text" v-model:value="editFormState.url" placeholder="https://t.me/vibes_band" />
                </a-form-item>

                <a-form-item label="Владение каналом" extra="Поставьте галочку, если вы являетесь владельцем данного канала. Если вы продаете рекламу по агентскому договору, не являясь владельцем площадки, такую цепочку договоров придется подавать отдельно, без нашего сервиса." name="is_owner">
                  <a-checkbox v-model:checked="editFormState.is_owner"><span class="checkbox-label">Я подтверждаю, что являюсь владельцем канала {{ editFormState.name }}</span></a-checkbox>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Сохранить площадку'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
.ant-table-column-title {
  font-size: 14px !important;
}

table tbody tr td {
  font-weight: normal !important;
}
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { periodUnits } from '@/config/enum';
import {useRouter} from "vue-router";
import moment from 'moment';
import RoundVideo from "../../../../components/vibes/RoundVideo.vue";

const Platforms = {
  name: 'Platforms',
  components: {
    RoundVideo,
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      platform_type_id: 1,
      name: '',
      url: '',
      is_owner: true
    });

    const editFormState = reactive({
      platform_type_id: 1,
      name: '',
      url: '',
      is_owner: true
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(true);
    const isFormSending = ref(false);

    const router = useRouter();

    const typesList = ref([]);

    axios.get('/ord/data/platform_types', {params: {}}).then(response => {
      typesList.value = response.data.types;
    });

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 64,
          message: 'Минимальная длина поля - 3 символа, максимальная - 64',
          trigger: 'blur',
        }
      ],
      platform_type_id: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur'
        }
      ],
      url: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          type: 'url',
          message: 'Пожалуйста, введите полную ссылку, начиная с https://',
          trigger: 'blur',
        },
      ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.url = data.url;
      editFormState.platform_type_id = data.platform_type_id;
      editFormState.is_owner = data.is_owner;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const showCreatedPlatform = ref(false);

    const handleOk = values => {
      addFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/platforms/create', {
              project_id: activeProjectId(),
              ...addFormState
            }).then(response => {
              if(response.data.status == 'error') {
                message.error(response.data.message);
                return;
              }

              platformsList.value = response.data.platforms;

              showCreatedPlatform.value = true;

            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
          .validate()
          .then(() => {
            isFormSending.value = true;

            axios.post('/ord/platforms/edit', {
              project_id: activeProjectId(),
              ...editFormState
            }).then(response => {
              platformsList.value = response.data.platforms;

              handleCancel();
            }).finally(() => {
              isFormSending.value = false;
            });
          });
    };

    const handleCancel = () => {
      onCancel();

      showCreatedPlatform.value = false;
    };

    const platformsList = ref([]);
    const pkgs = computed(() => platformsList.value);

    const platformsTableData = computed(() => {
      return platformsList.value
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map(pkg => {
            const { id, name, url, platform_type } = pkg;

            return {
              key: id,
              id,
              name,
              url,
              platform_type,
              ord_sync: (
                  <div>
                    {(() => {
                      if (pkg.is_ord_synced){
                        return (
                            <a-tooltip placement="bottom" title={() => 'Синхронизировано с ОРД '+moment(pkg.ord_synced_at).format('DD.MM.YY в HH:mm')}>
                              <sdFeatherIcons type="check" size="16" color="green"/>
                            </a-tooltip>
                        )
                      }

                      return (<a-tooltip placement="bottom" title="Идет отправка данных в ОРД">
                        <a-spin indicator="activity" size="small"/>
                      </a-tooltip>);
                    })()}
                  </div>
              ),
              action: (
                  <Action class="table-actions">
                    <sdDropdown
                        class="wide-dropdwon"
                        overlay={
                          <>
                            <a onClick={() => showEditModal(pkg, id)} to="#">
                              <span>Редактировать</span>
                            </a>
                          </>
                        }
                        action={['click']}
                    >
                      <sdButton class="btn-icon" type="info" to="#" shape="circle">
                        <sdFeatherIcons type="more-vertical" size={16} />
                      </sdButton>
                    </sdDropdown>
                  </Action>
              ),
            };
          });
    });



    axios.get('/ord/platforms/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          platformsList.value = response.data.platforms;
          isLoading.value = false;
        });


    const platformsTableColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ссылка',
        dataIndex: 'url',
        key: 'url',
      },
      {
        title: 'Тип площадки',
        dataIndex: 'platform_type.name',
        key: 'platform_type.name'
      },
      {
        title: 'ОРД',
        dataIndex: 'ord_sync',
        key: 'ord_sync'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];

    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      platformsTableColumns,
      platformsTableData,
      platformsList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading,
      isFormSending,

      showCreatedPlatform,

      typesList
    };
  },
};

export default Platforms;
</script>
