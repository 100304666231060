<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader :title=contractTitle subtitle="Test" description="Test 2">
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="16" :lg="18">
        <AdsItemCard>
          <a-card>
            <a-tabs v-model:activeKey="activeTab">
              <a-tab-pane key="contract" tab="Договор">
                <h2>Информация о договоре</h2>

                <SpinerWraperStyle v-if="isLoading">
                  <a-spin indicator="activity" size="large"/>
                </SpinerWraperStyle>

                <div v-else>
                  <BasicFormWrapper class="basic-form-inner">
                    <div class="atbd-form-checkout" style="margin-top: 20px;">
                      <a-form name="contract">

                        <a-row :gutter="30">
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Дата договора" name="contract_date">
                              <a-date-picker v-model:value="adsData.contract_date" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Номер договора" name="contract_no">
                              <a-input type="text" v-model:value="adsData.contract_no" placeholder="01/07/2023-1" />
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Сумма договора" name="contract_sum" extra="Укажите общую стоимость по договору в рублях">
                              <a-input-number  :prefix="'￥ ₽'" v-model:value="adsData.contract_sum" :min="1" :precision="2"
                                               :max="999999999" :formatter="value => `₽ ${value}`" :parser="value => value.replace('₽ ', '')"
                                               placeholder="10000"/>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="НДС" name="contract_vat" extra="Укажите, включен ли НДС в стоимость договора.">
                              <a-select v-model:value="adsData.contract_vat">
                                <a-select-option :value="0">Без НДС</a-select-option>
                                <a-select-option :value="1">НДС включен</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Тип договора" name="contract_type">
                              <a-select v-model:value="adsData.contract_type">
                                <a-select-option :value="'service'">Оказание услуг</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Предмет договора" name="contract_vat">
                              <a-select v-model:value="adsData.contract_subject_type">
                                <a-select-option :value="'distribution'">Распространение рекламы</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Дата выхода поста" name="post_date" extra="Автоматически поставили ту же дату, что и в договоре. Поменяйте, если нужно.">
                                <a-date-picker v-model:value="adsData.post_date" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="На сколько дней пост" name="post_duration" extra="Укажите, сколько полных дней пост будет в вашем канале. Посты более 30 дней или без удаления пока что не поддерживаются.">
                                <a-input-number  v-model:value="adsData.post_duration" :min="1" :max="30" placeholder="1"/>
                              </a-form-item>
                            </a-col>
                        </a-row>

                        <sdButton htmlType="submit" size="default" type="primary" key="submit">
                          {{ true === false ? 'Загрузка...' : 'Сохранить договор'}}
                        </sdButton>
                      </a-form>
                    </div>
                  </BasicFormWrapper>
                </div>
              </a-tab-pane>
              <a-tab-pane key="contragent" tab="Контрагент" force-render>
                <h2>Информация о контрагенте</h2>

                <SpinerWraperStyle v-if="isLoading">
                  <a-spin indicator="activity" size="large"/>
                </SpinerWraperStyle>

                <div v-else>
                  <sdAlerts
                      :outlined="false"
                      :closable="false"
                      :showIcon="true"
                      message="Обратите внимание"
                      description="Редактирование контрагента затронет все рекламы, связанные с ним. Если вы допустили опечатку в реквизитах и хотите ее исправить, это возможно. Но не стоит вносить кардинальные изменения и менять одно юрлицо на другое - оно заменится во всех контрактах, которые привязаны, могут быть проблемы с законом."
                      type="info"
                  />
                  <BasicFormWrapper class="basic-form-inner">
                    <div class="atbd-form-checkout" style="margin-top: 20px;">
                      <a-form name="contactEdit" ref="editFormRef" >
                        <a-row :gutter="30">
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Тип" name="contragent_type_id">
                              <a-select v-model:value="adsData.contragent.contragent_type_id">
                                <a-select-option v-for="t in contragentTypesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>

                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item :label="getContragentNameLabel(adsData.contragent.contragent_type_id)" name="name">
                              <a-input v-model:value="adsData.contragent.name" :placeholder="getContragentNamePlaceholder(adsData.contragent.contragent_type_id)"/>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="ИНН" name="inn">
                              <a-input type="text" v-model:value="adsData.contragent.inn" placeholder="123456789012" />
                            </a-form-item>
                          </a-col>

                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Номер телефона" name="phone">
                              <a-input type="text" v-model:value="adsData.contragent.phone" placeholder="+79991234567" />
                            </a-form-item>
                          </a-col>

                        </a-row>

                        <sdButton htmlType="submit" size="default" type="primary" key="submit">
                          {{ true === false ? 'Загрузка...' : 'Сохранить контрагента'}}
                        </sdButton>
                      </a-form>
                    </div>
                  </BasicFormWrapper>
                </div>
              </a-tab-pane>
              <a-tab-pane key="creative" tab="Креатив">
                <h2>Информация о креативе</h2>

                <SpinerWraperStyle v-if="isLoading">
                  <a-spin indicator="activity" size="large"/>
                </SpinerWraperStyle>

                <div v-else>
                  <BasicFormWrapper class="basic-form-inner">
                    <div class="atbd-form-checkout" style="margin-top: 20px;">
                      <a-form name="creativeEdit" ref="editFormRef" >
                        <a-row :gutter="30">
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Тип креатива" name="post_type" extra="Для большинства случаев подходит Текстово-графический блок.">
                              <a-select v-model:value="adsData.creatives[0].type">
                                <a-select-option v-for="(value, key) in creativeTypesList" :key="key" :value="key">{{ value }}</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Модель оплаты" name="post_type" extra="При оплате за факт размещения указывайте Иное.">
                              <a-select v-model:value="adsData.creatives[0].pay_type">
                                <a-select-option v-for="(value, key) in payTypesList" :key="key" :value="key">{{ value }}</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Название бренда" name="brand_name" extra="Если есть понятное название бренда - например Сбер, Apple, VK, поставьте его. Если нет, оставьте название юрлица.">
                              <a-input type="text" v-model:value="adsData.creatives[0].brand_name" placeholder="Digital-агентство Vibes" />
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Краткое описание рекламы" name="brand_description" extra="Например: Реклама услуг по запуску рекламы в интернете под ключ.">
                              <a-input type="text" v-model:value="adsData.creatives[0].brand_description"  placeholder="Реклама услуг по запуску рекламы в интернете под ключ"/>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="24" :xs="24" class="mb-25">
                            <a-form-item label="Код ОКВЭД" name="brand_okved" extra="Автоматически подгрузили ОКВЭД заказчика из налоговой. Если не подгрузился, укажите наиболее подходящий.">
                              <a-select v-model:value="adsData.creatives[0].brand_okved"
                                        show-search
                                        placeholder="Поиск по кодам ОКВЭД..." :options="okvedList" :filterOption="okvedFilterOption">
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="24" :xs="24" class="mb-25">
                            <a-form-item label="Текст креатива" name="post_text" extra="Вставьте в это поле текст рекламного поста">
                              <a-textarea v-model:value="adsData.creatives[0].post_text" placeholder="Вставьте сюда текст вашего поста" rows="6"/>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="24" :xs="24" class="mb-25">
                            <a-form-item label="Целевая ссылка" name="post_target_url" extra="Если в вашем посте есть внешняя ссылка, вставьте ее в это поле. Если ссылки нет, оставьте пустым.">
                              <a-input type="text" v-model:value="adsData.creatives[0].post_target_url" placeholder="https://vibes.band/"/>
                            </a-form-item>
                          </a-col>

                        </a-row>

                        <sdButton htmlType="submit" size="default" type="primary" key="submit">
                          {{ true === false ? 'Загрузка...' : 'Сохранить креатив'}}
                        </sdButton>
                      </a-form>
                    </div>
                  </BasicFormWrapper>


                  <h2 class="mt-25">Файлы креатива</h2>

                  <BasicFormWrapper class="basic-form-inner">
                    <div class="atbd-form-checkout" style="margin-top: 20px;">
                      <a-form name="creativeMediaEdit" ref="editFormRef" >
                        <AttachedFiles>
                          <div class="message-attachments">
                            <figure v-for="(item) in adsData.creatives[0].media" :key="item.id">
                              <div class="attachment-image">
                                <img :src="item.file.public_path" alt="" width="180"/>
                              </div>
                              <div class="attachment-hover">
                                <a class="btn-link" :href="item.file.public_path" target="_blank">
                                  <sdFeatherIcons type="download" />
                                </a>
                              </div>
                              <figcaption>
                                <sdHeading as="h4">ID: {{ item.file.id }}</sdHeading>
                              </figcaption>
                            </figure>
                          </div>
                        </AttachedFiles>


                        <a-col :lg="24" :xs="24" class="mb-25">
                            <a-form-item label="Добавить новые файлы">
                              <a-upload-dragger
                                  name="post_media"
                                  :multiple="true"
                              >
                                <p class="ant-upload-drag-icon">
                                  <plus-outlined></plus-outlined>
                                </p>
                                <p class="ant-upload-text">Нажмите или перетащите файлы для загрузки</p>
                                <p class="ant-upload-hint">
                                  Поддерживаются JPG, JPEG, PNG, GIF, MP4, MOV, до 50 мб
                                </p>
                              </a-upload-dragger>
                            </a-form-item>
                        </a-col>

                        <sdButton htmlType="submit" size="default" type="primary" key="submit">
                          {{ true === false ? 'Загрузка...' : 'Сохранить новые файлы'}}
                        </sdButton>

                      </a-form>
                    </div>
                  </BasicFormWrapper>


                </div>
              </a-tab-pane>
              <a-tab-pane key="token" tab="Готовый пост с токеном">
                <h2>Скопируйте готовый пост с токеном</h2>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="contactEdit3" ref="editFormRef3" >
                      <a-row :gutter="30">
                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="Готовый текст поста" name="post_text_token">
                            <div style="width: 100%; background-color: #F8F9FB; border: 1px solid #e4e6ef; border-radius: 10px; padding: 20px 20px; line-height: normal; white-space: pre-wrap;" ref="fieldPostRef">{{ postWithToken }}</div>
                          </a-form-item>
                          <sdButton type="light" @click="copyToClipboard('post')">
                            <sdFeatherIcons type="copy" size="24" />&nbsp;&nbsp;Скопировать
                          </sdButton>
                        </a-col>

                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="Токен отдельно" name="token_only">
                            <div style="width: 100%; background-color: #F8F9FB; border: 1px solid #e4e6ef; border-radius: 10px; padding: 20px 20px; line-height: normal; white-space: pre-wrap;" ref="fieldTokenRef">{{ humanizedToken }}</div>
                          </a-form-item>
                          <sdButton type="light" @click="copyToClipboard('token')">
                            <sdFeatherIcons type="copy" size="24" />&nbsp;&nbsp;Скопировать
                          </sdButton>
                        </a-col>
                      </a-row>
                    </a-form>
                  </div>
                </BasicFormWrapper>
              </a-tab-pane>
              <a-tab-pane key="acts" tab="Отчет (акты)">
                <h2>Подать отчет</h2>

                <SpinerWraperStyle v-if="isLoading">
                  <a-spin indicator="activity" size="large"/>
                </SpinerWraperStyle>

                <div v-else>
                  <div v-if="adsData.act === null">
                    <p v-if="adsData.status == 2 || adsData.status == 4">Пора подать отчет по рекламе. Это займет не более 2-3 минут: большинство полей мы уже заполнили самостоятельно, от вас только добавить количество показов, проверить фактические даты размещений и подтвердить отправку в ОРД.</p>
                    <p v-else>Кажется, еще рано подавать отчет по этой рекламе. Мы напомним вам, когда пора будет подавать отчет. Однако, если вы уверены что хотите подавать его прямо сейчас, укажите фактическое количество показов, проверьте остальные поля и подтвердите передачу в ОРД.</p>
                    <BasicFormWrapper class="basic-form-inner">
                      <div class="atbd-form-checkout" style="margin-top: 20px;">
                        <a-form :model="formStateCreateAct" name="createNewActForm" ref="createNewActFormRef" >
                          <a-row :gutter="30">
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Дата формирования акта" name="act_date">
                                <a-date-picker @change="suggestActNo" v-model:value="formStateCreateAct.act_date" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Номер акта" name="act_no">
                                <a-input type="text" v-model:value="formStateCreateAct.act_no" placeholder="01/07/2023-1" />
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Сумма по акту" name="act_sum" extra="Обычно совпадает с суммой по договору - подставили ее автоматически">
                                <a-input-number  :prefix="'￥ ₽'" v-model:value="formStateCreateAct.act_sum" :min="1" :precision="2"
                                                 :max="999999999" :formatter="value => `₽ ${value}`" :parser="value => value.replace('₽ ', '')"
                                                 placeholder="10000"/>
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="НДС" name="act_vat" extra="Укажите, включен ли НДС в сумму по акту. Обычно совпадает с договором - подставили автоматически">
                                <a-select v-model:value="formStateCreateAct.act_vat">
                                  <a-select-option :value="0">Без НДС</a-select-option>
                                  <a-select-option :value="1">НДС включен</a-select-option>
                                </a-select>
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Дата начала периода по акту" extra="Автоматически подставили дату выхода поста" name="date_start">
                                <a-date-picker v-model:value="formStateCreateAct.date_start" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                              </a-form-item>
                            </a-col>
                            <a-col :lg="12" :xs="24" class="mb-25">
                              <a-form-item label="Дата окончания периода по акту" name="date_end" extra="Автоматически подставили дату удаления поста">
                                <a-date-picker v-model:value="formStateCreateAct.date_end" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                              </a-form-item>
                            </a-col>

                          </a-row>
                        </a-form>
                        </div>
                    </BasicFormWrapper>
                  </div>
                  <div v-else>
                    .....
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="docs" tab="Закрывающие документы">
                <h2>Скачайте закрывающие документы по сделке</h2>
                <p>Этот раздел еще в разработке, но совсем скоро тут можно будет скачать закрывающие документы по сделке для бухгалтерии.</p>

                <p><b>Как это работает?</b></p>
                <p>При создании рекламы, мы автоматически генерируем договор и акт выполненных работ. Если для какой-то из сделок вам понадобится предоставить подтверждение в виде договора или акта, вы в любой момент сможете выгрузить их из нашего сервиса. Останется только распечатать и подписать.</p>
                <p>Мы уже продумали идеальный шаблон типового договора и акта на продажу рекламы в Telegram, но если они по каким-то причинам вам не подойдут, вы сможете загрузить в систему свои шаблоны через техническую поддержку.</p>
                <AttachedFiles>
                <div class="message-attachments">
                  <figure>
                    <div class="attachment-image">
                      <img :src="require('@/static/img/preview/preview_contract.png')" :srcset="require('@/static/img/preview/preview_contract@2x.png')+' 2x'" alt="" />
                    </div>
                    <div class="attachment-hover">
                      <router-link class="btn-link" to="#">
                        <sdFeatherIcons type="download" />
                      </router-link>
                    </div>
                    <figcaption>
                      <sdHeading as="h4"><img :src="require('@/static/img/pdf-icon.svg')" width="20"/>&nbsp;&nbsp;Договор {{ adsData.contract_no }}.pdf</sdHeading>
                      <p>256.5 KB</p>
                    </figcaption>
                  </figure>
                  <figure>
                    <div class="attachment-image">
                      <img :src="require('@/static/img/preview/preview_act.png')" :srcset="require('@/static/img/preview/preview_act@2x.png')+' 2x'" alt="" />
                    </div>
                    <div class="attachment-hover">
                      <router-link class="btn-link" to="#">
                        <sdFeatherIcons type="download" />
                      </router-link>
                    </div>
                    <figcaption>
                      <sdHeading as="h4"><img :src="require('@/static/img/pdf-icon.svg')" width="20"/>&nbsp;&nbsp;Акт {{ adsData.contract_no }}.pdf</sdHeading>
                      <p>128.4 KB</p>
                    </figcaption>
                  </figure>
                </div>
                </AttachedFiles>
              </a-tab-pane>
            </a-tabs>
          </a-card>
        </AdsItemCard>
      </a-col>
      <a-col :md="8" :lg="6">
        <div v-if="!isLoading">
          <AdsItemRightItem>
            <label>Текущий статус</label>
            <a-tag :color=contractStatusBadgeColor[adsData.status]>{{ contractStatusLabel[adsData.status] }}</a-tag>

            <div class="status-comment" v-if="adsData.status == 0">Реклама сохранена и передана в ОРД. Пока что ничего делать не нужно.</div>
            <div class="status-comment" v-if="adsData.status == 1">По нашим данным, пост успешно вышел. Пока что ничего делать не нужно - когда подойдет дата удаления поста, можно будет подать отчет.</div>
            <div class="status-comment" v-if="adsData.status == 2">Дата удаления поста прошла, значит реклама завершена. Можете подать отчет прямо сейчас или в течение следующих 30 дней с момента удаления поста.</div>
            <div class="status-comment" v-if="adsData.status == 3">Реклама успешно завершена, отчет подан в ОРД. Больше ничего делать не нужно.</div>
            <div class="status-comment" v-if="adsData.status == 4" style="color: red;">Отчет просрочен. Подайте информацию о прошедшей рекламе как можно скорее, чтобы не получить штраф.</div>
          </AdsItemRightItem>

          <AdsItemRightItem v-if="adsData.status == 2 || adsData.status == 4">
            <label>Дедлайн по отчету</label>

            <div>
              <sdFeatherIcons type="clock" size="14" />
              {{ moment(adsData.report_deadline_till).format('DD.MM.YYYY') }}
            </div>
          </AdsItemRightItem>

          <AdsItemRightItem>
            <label>Частые вопросы</label>

            <a-collapse v-model:activeKey="faqActiveKey" accordion :bordered="false">
              <template #expandIcon="props">
                <sdFeatherIcons type="plus" v-if="!props.isActive" size="14" />
                <sdFeatherIcons type="minus" v-else size="14" />
              </template>
              <a-collapse-panel header="Как исправить ошибку в рекламе?" key="1">
                <p>Просто отредактируйте данные на нужной вкладке (Договор, Контрагент, Креатив) и нажмите на кнопку Сохранить.</p>
                <p style="margin-top: 10px;">Обратите внимание: мы не рекомендуем вносить кардинальные изменения (менять полностью реквизиты контрагента или ставить совершенно другую рекламу), вся история изменений хранится в ОРД и это можно проверить в будущем.</p>
                <p style="margin-top: 10px;">Если вы допустили опечатку в процессе или условия сделки изменились, никаких проблем нет, просто поменяйте информацию и сохраните.</p>
              </a-collapse-panel>
              <a-collapse-panel header="Как удалить рекламу полностью?" key="2">
                <p>К сожалению, в законе о маркировке и в ОРД не предусмотрена возможность удаления уже созданных договоров. Даже если сделка сорвалась и рекламы в итоге не было.</p>
                <p style="margin-top: 10px;">Официальных комментариев на эту тему пока что нет, как только они появятся, мы дополним эту статью.</p>
                <p style="margin-top: 10px;">Теоретически можно попробовать изменить договор на другой (не меняя контрагента) или подать нулевой акт - реклама не показывалась, деньги не уплачивались.</p>
              </a-collapse-panel>
              <a-collapse-panel header='Написано "Пора подать отчет", а пост еще не вышел' key="3">
                <p>
                  Наш сервис учитывает данные, которые вы указали при создании рекламы, а именно - дату выхода поста и на сколько дней пост.
                </p>
                <p style="margin-top: 10px;">
                  Если пост вышел позже намеченной даты, или в итоге вы договорились на большую длительность поста, сервис не знает об этом и все равно предложит подать отчет.
                </p>
                <p style="margin-top: 10px;">
                  Не переживайте, ничего страшного не произошло. Вы можете подать отчет с момента удаления поста в любой момент в течение 30 дней. Если вы забудете подать отчет, мы напомним вам об этом в Telegram.
                </p>
              </a-collapse-panel>
            </a-collapse>
          </AdsItemRightItem>
        </div>
<!--        <h2>Что делать в этом разделе</h2>-->
<!--        <p>Это основной раздел, в котором вы будете работать 90% времени. В нем отображаются все рекламы, которые вы провели в ОРД с текущего юрлица.</p>-->
<!--        <p><b>Чтобы создать новую рекламу</b>, нажмите на кнопку в правом верхнем углу и пройдите 5 простых шагов. Это займет не более 3-4 минут.</p>-->
<!--        <p>Рекламы, по которым пора подавать отчетность, подсвечиваются красным. Постарайтесь не пропускать сроки подачи отчетности: за это может быть начислен штраф.</p>-->
<!--        <p></p>-->

        <!--        <RoundVideo src="https://601002.selcdn.ru/static/video.mp4"/>-->
      </a-col>


    </a-row>
  </Main>
</template>
<script>
import { useStore } from 'vuex';
import {computed, onMounted, ref, watchEffect, reactive, watch} from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';
import { okved } from "@/config/okved";

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { contragentTypes, contragentTypesLabel, contragentTypesPlaceholder, creativeTypes, payTypes } from '@/config/enum';
import {useRoute, useRouter} from "vue-router";
import moment from 'moment';
import RoundVideo from "../../../../components/vibes/RoundVideo.vue";
import {formatSum} from "@/config/helper";
import {contractStatusBadgeColor, contractStatusLabel, contractStatusLabelShort} from "@/config/enum";
import {ProjectSorting} from "../../../apps/project/style";
import {AdsFilters, AdsItemCard, AdsItemRightItem, AttachedFiles} from "./Style";
import { plural } from "@/utility/utility.js";

import { TabBasic, Child } from "../../../../components/tabs/Style";
import useClipboard from "vue-clipboard3";
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";



const AdsItem = {
  name: 'AdsItem',
  components: {
    RoundVideo,
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
    ProjectSorting,
    AdsFilters,
    TabBasic,
    Child,
    AdsItemCard,
    SpinerWraperStyle,
    AdsItemRightItem,
    AttachedFiles,
    PlusOutlined
  },
  setup() {

    const isLoading = ref(true);
    const adsData = ref(null);

    const router = useRouter();
    const { params } = useRoute();

    const activeTab = ref('contract');

    const faqActiveKey = ref(null);

    const contractTitle = computed(() => {
      if(isLoading.value)
        return "Реклама №"+params.id;

      return 'Реклама №'+adsData.value.contract_no;
    });

    const contragentTypesList = ref([]);
    const creativeTypesList = ref(creativeTypes);
    const payTypesList = ref(payTypes);
    const okvedList = ref([]);

    const formStateCreateAct = reactive({
      act_no: null,
      act_date: moment().format('YYYY-MM-DD'),
      act_sum: null,
      act_vat: 0,
      date_start: null,
      date_end: null
    });

    const suggestActNo = function() {
      var date = formStateCreateAct.act_date;

      axios.get('/ord/form/suggest/act_no', {params: {project_id: activeProjectId(), date: date}}).then(response => {
        formStateCreateAct.act_no = response.data.no;
      });
    };

    const loadAllDictionary = function() {
      axios.get('/ord/data/contragent_types', {params: {}}).then(response => {
        contragentTypesList.value = response.data.types;
      });

      okved.forEach(function(item) {
        if(item.code.includes('.'))
          okvedList.value.push({value: item.code, label: `${item.code} - ${item.name}`});
      });

      suggestActNo();
    };

    const loadAdsData = function() {
      axios.get('/ord/ads/item', {params: {project_id: activeProjectId(), id: params.id}})
        .then(response => {
          adsData.value = response.data.contract;
          isLoading.value = false;

          formStateCreateAct.act_sum = response.data.contract.contract_sum;
          formStateCreateAct.act_vat = response.data.contract.contract_vat;
          formStateCreateAct.date_start = response.data.contract.post_date;
          formStateCreateAct.date_end = moment(response.data.contract.post_date_till).format('YYYY-MM-DD');
        });
    };

    loadAllDictionary();
    loadAdsData();

    const getContragentNameLabel = function(id) {
      return contragentTypesLabel[id];
    }
    const getContragentNamePlaceholder = function(id) {
      return contragentTypesPlaceholder[id];
    }

    const humanizedToken = computed(() => {
      if(adsData.value === null || adsData.value === undefined)
        return '';

      var name = (adsData.value.contragent.contragent_type_id == 2) ? 'ИП '+adsData.value.contragent.name : adsData.value.contragent.name;

      return `Реклама. ${name}, ИНН ${adsData.value.contragent.inn}, erid: ${adsData.value.creatives[0].erid_token}`;
    });

    const postWithToken = computed(() => {
      if(adsData.value === null || adsData.value === undefined)
        return '';

      return `${adsData.value.creatives[0].post_text}

${humanizedToken.value}`;
    });

    const fieldTokenRef = ref();
    const fieldPostRef = ref();
    const { toClipboard } = useClipboard()

    const copyToClipboard = async function(type) {
        var r = (type === 'token') ? fieldTokenRef : fieldPostRef;
        try {
          await toClipboard(r.value.innerText);
          message.info('Скопировано в буфер обмена');
        } catch(e) {
          message.error('Ошибка копирования. Пожалуйста, скопируйте вручную');
        }
    };

    const okvedFilterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const pathBasename = function( path )
    {
      var parts = path.split( '/' );
      return parts[parts.length-1];
    }




    return {
      isLoading,
      adsData,
      activeTab,
      contractTitle,

      contragentTypesList,
      creativeTypesList,
      payTypesList,
      okvedList,

      getContragentNameLabel,
      getContragentNamePlaceholder,

      contractStatusLabelShort,
      contractStatusLabel,
      contractStatusBadgeColor,

      moment,

      faqActiveKey,

      postWithToken,
      humanizedToken,

      copyToClipboard,
      fieldTokenRef,
      fieldPostRef,

      okvedFilterOption,

      pathBasename,

      formStateCreateAct,
      suggestActNo,

      datePickerLocale: locale,
    };
  },
};

export default AdsItem;
</script>
