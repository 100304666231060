<template>
  <div v-if="isLoaded">
    <div v-if="activeProjectId === null || activeProjectId === undefined">
      <OnboardingHome/>
    </div>
    <div v-else>
        <OrdHome/>
    </div>
  </div>
  <div v-else>
    <div style="min-height: 85vh; padding-top: 20%;">
      <center>
        <a-spin indicator="activity" size="large" />
      </center>
    </div>
  </div>
</template>

<script>
import OnboardingHome from './Home/OnboardingHome';
import OrdHome from './Home/OrdHome.vue';

import axios from '@/config/axios'
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import {useRouter} from "vue-router";

export default {
  name: 'Home',
  components: {
    OnboardingHome,
    OrdHome
  },
  setup() {
    const activeProjectId = ref(localStorage.getItem('activeProjectId') || null);

    const isLoaded = ref(false);

    const project = ref({});

    const router = useRouter();

    if(activeProjectId.value === null || activeProjectId.value === undefined) {
      isLoaded.value = true;
    } else {
      axios.get('/auth/project', {
        params: { id: activeProjectId.value }
      }).then(response => {
        if(response.data.status == 'ok') {
          project.value = response.data.project;
        }

        isLoaded.value = true;

        //router.push('/chat/all/');
      });
    }

    return {
      activeProjectId,
      isLoaded,
      project,


    }
  }
};
</script>
