<template>
  <sdPageHeader title="Создать рекламу"> </sdPageHeader>
  <Main>
    <a-row :gutter="25">
      <a-col :sm="24" :xs="24">
        <sdCards headless>
          <a-row>
            <a-col :xl="7" :lg="8" :md="11" :sm="24" :xs="24" style="border-right: 1px solid #F1F2F6;">
              <ul class="custom-step-numbers">
                <li class="" :class="{'step-current': currentStep == 1, 'step-done': currentStep > 1}">1</li>
                <li class="" :class="{'step-current': currentStep == 2, 'step-done': currentStep > 2}">2</li>
                <li class="" :class="{'step-current': currentStep == 3, 'step-done': currentStep > 3}">3</li>
                <li class="" :class="{'step-current': currentStep == 4, 'step-done': currentStep > 4}">4</li>
                <li class="" :class="{'step-current': currentStep == 5, 'step-done': currentStep > 5}">5</li>
                <li class="" :class="{'step-current': currentStep == 6, 'step-done': currentStep > 6}">✅</li>
              </ul>

              <div class="wizard-item" v-if="currentStep == 1">
                <h2>Мастер создания рекламы</h2>

                <p>На этом этапе вы можете занести планируемое размещение в ОРД и получить токен для рекламы.</p>

                <p>Процесс займет 3-4 минуты. Понадобятся данные юрлица заказчика, стоимость размещения и дата, а также креатив.</p>
                <img :src="require('@/static/img/wizards/1.svg')" alt="">
              </div>

              <div class="wizard-item" v-if="currentStep == 2">
                <h2>Укажите заказчика рекламы</h2>

                <p>Если вы уже работали с ним, вы можете выбрать его из базы.</p>

                <p>Если вы продаете рекламу этому заказчику впервые, укажите его название, ИНН и телефон.</p>

<!--                <p style="font-weight: 500; text-align: center; margin-top: 40px;">Если вы не понимаете как создать бота, посмотрите нашу короткую видео-инструкцию (81 сек.):</p>-->

<!--                <sdButton size="default" shape="circle" :transparented="true" type="primary">-->
<!--                  <sdFeatherIcons type="play-circle" size="14" /> <span>Смотреть видео-инструкцию</span>-->
<!--                </sdButton>-->
              </div>

              <div class="wizard-item" v-if="currentStep == 3">
                <h2>Укажите договор</h2>

                <p>На этом шаге нужно заполнить информацию о договоре между вами и Заказчиком.</p>

                <p>Мы уже сгенерировали их автоматически. Укажите сумму договора и проверьте остальные поля.</p>

                <p>Pro tip: чаще всего вам не нужно иметь на руках подписанный договор с клиентом, он понадобится только в случае проверки. Главное &ndash; отразить корректную сумму и реквизиты заказчика.</p>

<!--                <p style="font-weight: 500; text-align: center; margin-top: 40px;">Если вы не понимаете как создать закрытый канал, посмотрите нашу короткую видео-инструкцию (113 сек.):</p>-->

<!--                <sdButton size="default" shape="circle" :transparented="true" type="primary">-->
<!--                  <sdFeatherIcons type="play-circle" size="14" /> <span>Смотреть видео-инструкцию</span>-->
<!--                </sdButton>-->
              </div>

              <div class="wizard-item" v-if="currentStep == 4">
                <h2>Информация о рекламодателе</h2>

                <p>На этом шаге нужно заполнить информацию о рекламе.</p>

                <p>Большинство полей сгенерировано автоматически. Напишите краткое описание рекламы и проверьте остальные поля.</p>

                <p>ОКВЭД подгружается из базы ФНС автоматически. Если ОКВЭД не подгрузился, выберите максимально близкий к тематике рекламы.</p>
              </div>

              <div class="wizard-item" v-if="currentStep == 5">
                <h2>Загрузите креатив</h2>

                <p>На этом шаге нужно загрузить рекламный креатив.</p>

                <p>Сначала выберите тип креатива: текстово-графический подходит для большинства случаев.</p>

                <p>Далее загрузите текст и всю графику (картинки, видео) из планируемого поста.</p>

                <p>Если в посте есть внешние ссылки, укажите их в отдельном поле.</p>
              </div>

              <div class="wizard-item" v-if="currentStep == 6">
                <h2>Пора отправить данные в ОРД 🎉</h2>

                <p>Ура, вы закончили заполнение всех полей. Теперь нужно отправить данные в ОРД и получить токен.</p>

                <p>Просто проверьте все данные и нажмите Подтвердить.</p>

                <p>Если допустили ошибку, можно вернуться назад и исправить.</p>
              </div>

              <div class="wizard-item" v-if="currentStep == 7">
                <h2>Отправлено в ОРД</h2>

                <p>Мы сохранили вашу рекламу и отправили данные о ней в ОРД.</p>

                <p>Вам осталось сохранить токен и добавить его к рекламному посту.</p>
              </div>

            </a-col>
            <a-col :xl="17" :lg="16" :md="13" :sm="24" :xs="24">
<!--                <Vue3Lottie :animationData="PaperplaneJSON" :height="200" :width="200" />-->

              <div class="current-step-content" v-if="currentStep == 1">
                <h2>В каком канале будет реклама?</h2>

                <p>Выберите канал, в котором вы продаете рекламу. </p>

                <p>Если канал не отображается, проверьте, что вы выбрали корректное юрлицо (справа вверху).<br> Если вы хотите добавить новый канал, нажмите <router-link to="/ord/platforms">сюда</router-link>.</p>

                <p>Пока что за 1 раз можно добавить только один канал. В будущем добавим поддержку одного договора на несколько размещений.</p>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 40px;">
                    <a-form name="account" :model="formStateStep1" ref="formRefStep1" :rules="rulesStep1">
                      <a-form-item label="Выберите канал" name="project_platform_id">
                        <a-select v-model:value="formStateStep1.project_platform_id">
                          <a-select-option v-for="t in myPlatformsList" :value="t.id" :key="t.id">{{ t.platform_type.name }} - {{ t.name }}</a-select-option>
                        </a-select>
                      </a-form-item>

                      <sdButton class="btn-next" type="primary" @click="nextStep"  :disabled="isLoading">
                        Далее&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                      </sdButton>
                    </a-form>
                  </div>
                </BasicFormWrapper>
              </div>

              <div class="current-step-content" v-if="currentStep == 2">
                <h2>Кто заказчик рекламы?</h2>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="customer" :model="formStateStep2" ref="formRefStep2" :rules="rulesStep2">
                      <div>
                        <a-radio-group v-model:value="formStateStep2.select_type">
                          <a-radio value="existing">Уже работали с заказчиком (найти из базы)</a-radio>
                        </a-radio-group>
                      </div>

                      <div v-show="formStateStep2.select_type === 'existing'">
                        <a-form-item label="Выберите из списка" name="project_contragent_id" style="margin-top: 10px;">
                          <a-select v-model:value="formStateStep2.project_contragent_id">
                            <a-select-option v-for="t in myContragentsList" :value="t.id" :key="t.id">{{ t.name }} ({{ t.contragent_type.name}}), ИНН {{ t.inn }}</a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>

                      <div style="margin-top: 10px;">
                        <a-radio-group v-model:value="formStateStep2.select_type">
                          <a-radio value="new">Новый заказчик</a-radio>
                        </a-radio-group>
                      </div>

                      <div v-show="formStateStep2.select_type === 'new'">
                        <a-form-item label="Тип" name="contragent_type_id" style="margin-top: 10px;">
                          <a-select v-model:value="formStateStep2.contragent_type_id">
                            <a-select-option v-for="t in myContragentTypesList" :value="t.id" :key="t.id">{{ t.name }}</a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item :label="getContragentNameLabel(formStateStep2.contragent_type_id)" name="name">
                          <a-input v-model:value="formStateStep2.name" :placeholder="getContragentNamePlaceholder(formStateStep2.contragent_type_id)"/>
                        </a-form-item>

                        <a-form-item label="ИНН" name="inn">
                          <a-input type="text" v-model:value="formStateStep2.inn" placeholder="123456789012" />
                        </a-form-item>

                        <a-form-item label="Номер телефона" name="phone">
                          <a-input type="text" v-model:value="formStateStep2.phone" placeholder="+79991234567" />
                        </a-form-item>

                      </div>

                      <div style="display: flex; gap: 20px; margin-top: 30px;">
                        <sdButton class="btn-prev" type="light" @click="prevStep" :disabled="isLoading">
                          <sdFeatherIcons type="arrow-left" size="16" />&nbsp;Назад
                        </sdButton>

                        <sdButton class="btn-next" type="primary" @click="nextStep" :disabled="isLoading">
                          Далее&nbsp;&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                        </sdButton>
                      </div>
                    </a-form>
                  </div>
                </BasicFormWrapper>

              </div>

              <div class="current-step-content" v-if="currentStep == 3">
                <h2>Информация о договоре</h2>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="contract" :model="formStateStep3" ref="formRefStep3" :rules="rulesStep3">

                    <a-row :gutter="30">
                      <a-col :lg="12" :xs="24" class="mb-25">
                        <a-form-item label="Дата договора" name="contract_date" extra="Автоматически поставили сегодняшнюю дату. Поменяйте, если нужно.">
                          <a-date-picker @change="suggestContractNo" v-model:value="formStateStep3.contract_date" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                        </a-form-item>
                      </a-col>
                      <a-col :lg="12" :xs="24" class="mb-25">
                        <a-form-item label="Номер договора" name="contract_no" extra="Сгенерировали автоматически. Если у вас другая нумерация в бухгалтерии, поменяйте вручную.">
                          <a-input type="text" v-model:value="formStateStep3.contract_no" placeholder="01/07/2023-1" />
                        </a-form-item>
                      </a-col>
                      <a-col :lg="12" :xs="24" class="mb-25">
                        <a-form-item label="Сумма договора" name="contract_sum" extra="Укажите общую стоимость по договору в рублях">
                          <a-input-number  :prefix="'￥ ₽'" v-model:value="formStateStep3.contract_sum" :min="1" :precision="2"
                                           :max="999999999" :formatter="value => `₽ ${value}`" :parser="value => value.replace('₽ ', '')"
                                           placeholder="10000"/>
                        </a-form-item>
                      </a-col>
                      <a-col :lg="12" :xs="24" class="mb-25">
                        <a-form-item label="НДС" name="contract_vat" extra="Укажите, включен ли НДС в стоимость договора.">
                          <a-select v-model:value="formStateStep3.contract_vat">
                            <a-select-option :value="0">Без НДС</a-select-option>
                            <a-select-option :value="1">НДС включен</a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                    </a-row>

                    <a-collapse accordion>
                      <a-collapse-panel :showArrow="true" header="Дополнительные поля">
                        <a-row :gutter="30">
                          <a-col :lg="24" :xs="24" class="mb-25">
                            <a-form-item label="Краткое описание договора" name="contract_description" extra="У этого поля лимит в ОРД - 32 символа, поэтому мы советуем оставить стандартную формулировку.">
                              <a-input type="text" v-model:value="formStateStep3.contract_description" placeholder="Размещение рекламы" />
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Тип договора" name="contract_type">
                              <a-select v-model:value="formStateStep3.contract_type">
                                <a-select-option :value="'service'">Оказание услуг</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :lg="12" :xs="24" class="mb-25">
                            <a-form-item label="Предмет договора" name="contract_vat">
                              <a-select v-model:value="formStateStep3.contract_subject_type">
                                <a-select-option :value="'distribution'">Распространение рекламы</a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </a-collapse-panel>
                    </a-collapse>

                    <div style="display: flex; gap: 20px; margin-top: 30px;">
                      <sdButton class="btn-prev" type="light" @click="prevStep" :disabled="isLoading">
                        <sdFeatherIcons type="arrow-left" size="16" />&nbsp;Назад
                      </sdButton>

                      <sdButton class="btn-next" type="primary" @click="nextStep" :disabled="isLoading">
                        Далее&nbsp;&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                      </sdButton>
                    </div>
                    </a-form>
                  </div>
                </BasicFormWrapper>


              </div>

              <div class="current-step-content" v-if="currentStep == 4">
                <h2>Когда выйдет пост?</h2>

                <p>Для вашего удобства, укажите дату выхода поста и сколько дней он будет в канале. Эта информация не передается в ОРД, но поможет нам напомнить о том, что пора подавать отчет.</p>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="contract" :model="formStateStep4" ref="formRefStep4" :rules="rulesStep4">
                      <a-row :gutter="30">
                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="Дата выхода поста" name="post_date" extra="Автоматически поставили ту же дату, что и в договоре. Поменяйте, если нужно.">
                            <a-date-picker v-model:value="formStateStep4.post_date" style="width: 100%;" :format="'DD.MM.YYYY'" :valueFormat="'YYYY-MM-DD'" :locale="datePickerLocale"/>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="На сколько дней пост" name="post_duration" extra="Укажите, сколько полных дней пост будет в вашем канале. Посты более 30 дней или без удаления пока что не поддерживаются.">
                            <a-input-number  v-model:value="formStateStep4.post_duration" :min="1" :max="30" placeholder="1"/>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <h2>Что будем рекламировать?</h2>

                      <p>Тут нужно заполнить информацию о бренде, который будем рекламировать. Насколько подробно заполнять эту информацию решать вам, четких разъяснений от ФАС все еще нет.</p>

                      <a-row :gutter="30">
                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="Название бренда" name="post_brand_name" extra="Если есть понятное название бренда - например Сбер, Apple, VK, поставьте его. Если нет, оставьте название юрлица.">
                            <a-input type="text" v-model:value="formStateStep4.post_brand_name" placeholder="Digital-агентство Vibes" />
                          </a-form-item>
                        </a-col>
<!--                        <a-col :lg="12" :xs="24" class="mb-25">-->
<!--                          <a-form-item label="Продукт в рекламе" name="post_brand_category" extra="Например: Маркетинг под ключ, Наушники, Женская одежда.">-->
<!--                            <a-input type="text" v-model:value="formStateStep4.post_brand_category" placeholder="Маркетинг под ключ"/>-->
<!--                          </a-form-item>-->
<!--                        </a-col>-->
                        <a-col :lg="12" :xs="24" class="mb-25">
                          <a-form-item label="Краткое описание рекламы" name="post_brand_description" extra="Например: Реклама услуг по запуску рекламы в интернете под ключ.">
                            <a-input type="text" v-model:value="formStateStep4.post_brand_description"  placeholder="Реклама услуг по запуску рекламы в интернете под ключ"/>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="24" :xs="24" class="mb-25">
                          <a-form-item label="Код ОКВЭД" name="post_brand_okved" extra="Автоматически подгрузили ОКВЭД заказчика из налоговой. Если не подгрузился, укажите наиболее подходящий.">
                            <a-select v-model:value="formStateStep4.post_brand_okved"
                                      show-search
                                      placeholder="Поиск по кодам ОКВЭД..." :options="myOkvedList" :filterOption="filterOption">
<!--                              <a-select-option v-for="t in myOkvedList" :value="t.code" :key="t.code">{{ t.code }} - {{ t.name }}</a-select-option>-->
                            </a-select>
                          </a-form-item>
                        </a-col>

                      </a-row>

                    </a-form>
                  </div>
                </BasicFormWrapper>

                <div style="display: flex; gap: 20px; margin-top: 30px;">
                  <sdButton class="btn-prev" type="light" @click="prevStep" :disabled="isLoading">
                    <sdFeatherIcons type="arrow-left" size="16" />&nbsp;Назад
                  </sdButton>

                  <sdButton class="btn-next" type="primary" @click="nextStep" :disabled="isLoading">
                    Далее&nbsp;&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                  </sdButton>
                </div>
              </div>

              <div class="current-step-content" v-if="currentStep == 5">
                <h2>Пора загрузить креатив</h2>

                <p>К счастью, это последний шаг. Осталось только загрузить рекламный креатив и отправить информацию в ОРД.</p>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="contract" :model="formStateStep5" ref="formRefStep5" :rules="rulesStep5">
                      <a-row :gutter="30">
                        <a-col :lg="24" :xs="24" class="mb-25">
                          <a-form-item label="Тип креатива" name="post_type" extra="Для большинства случаев подходит Текстово-графический блок.">
                            <a-select v-model:value="formStateStep5.post_type">
                              <a-select-option v-for="(value, key) in myCreativeTypes" :key="key" :value="key">{{ value }}</a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="24" :xs="24" class="mb-25">
                          <a-form-item label="Текст креатива" name="post_text" extra="Вставьте в это поле текст рекламного поста">
                            <a-textarea v-model:value="formStateStep5.post_text" placeholder="Вставьте сюда текст вашего поста" rows="6"/>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="24" :xs="24" class="mb-25">
                          <a-form-item label="Файлы креатива" extra="Загрузите изображения и видео вашего креатива">
                            <a-upload-dragger
                                v-model:file-list="formStateStep5.post_media"
                                name="post_media"
                                :action="uploadActionUrl"
                                :before-upload="uploadBeforeEvent"
                                :data="uploadData"
                                :headers="uploadHeaders"
                                @change="uploadHandleChange"
                                :multiple="true"
                            >
                              <p class="ant-upload-drag-icon">
                                <plus-outlined></plus-outlined>
                              </p>
                              <p class="ant-upload-text">Нажмите или перетащите файлы для загрузки</p>
                              <p class="ant-upload-hint">
                                Поддерживаются JPG, JPEG, PNG, GIF, MP4, MOV, до 50 мб
                              </p>
                            </a-upload-dragger>
                          </a-form-item>
                        </a-col>
                        <a-col :lg="24" :xs="24" class="mb-25">
                          <a-form-item label="Целевая ссылка" name="post_target_url" extra="Если в вашем посте есть внешняя ссылка, вставьте ее в это поле. Если ссылки нет, оставьте пустым.">
                            <a-input type="text" v-model:value="formStateStep5.post_target_url" placeholder="https://vibes.band/"/>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  </div>
                </BasicFormWrapper>

                <div style="display: flex; gap: 20px; margin-top: 30px;">
                  <sdButton class="btn-prev" type="light" @click="prevStep" :disabled="isLoading || uploadLoading">
                    <sdFeatherIcons type="arrow-left" size="16" />&nbsp;Назад
                  </sdButton>

                  <sdButton class="btn-next" type="primary" @click="nextStep" :disabled="isLoading || uploadLoading">
                    Далее&nbsp;&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                  </sdButton>
                </div>
              </div>

              <div class="current-step-content" v-if="currentStep == 6">
                <div v-if="!isLoading">
                  <h2>Внимательно проверьте данные</h2>

                  <p>Если допустили ошибки, воспользуйтесь кнопкой Назад чтобы исправить их. Если все хорошо, смело жмите Подтвердить - информация сразу уйдет в ОРД.</p>

                  <a-row :gutter="30">
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Канал для рекламы</label>
                      <div>{{ confirmationData.channel_name() }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Реквизиты заказчика</label>
                      <div>{{ confirmationData.contragent_name() }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Договор</label>
                      <div>{{ confirmationData.contract }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Сумма по договору</label>
                      <div>{{ confirmationData.contract_sum }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Дата выхода поста</label>
                      <div>{{ moment(confirmationData.post_date).format('DD.MM.YYYY') }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Длительность поста</label>
                      <div>{{ confirmationData.post_duration }} дней</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Бренд</label>
                      <div>{{ confirmationData.post_brand }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Краткое описание рекламы</label>
                      <div>{{ confirmationData.post_description }}</div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">ОКВЭД</label>
                      <div>{{ confirmationData.post_okved() }}</div>

                      <div style="margin-top: 15px;">
                        <label class="" style="display: block; font-weight: bold;">Файлы креатива</label>
                        <div>
                          <div v-for="m in confirmationData.creative_media" :key="m.uid">
                            <file-outlined/> {{ m.name }}
                          </div>
                        </div>
                      </div>

                      <div style="margin-top: 15px;">
                        <label class="" style="display: block; font-weight: bold;">Целевая ссылка</label>
                        <div v-if="confirmationData.creative_url === null || confirmationData.creative_url === ''">
                          Не указана
                        </div>
                        <div v-else>
                          <a :href="confirmationData.creative_url" target="_blank" style="word-break: break-all;">{{ confirmationData.creative_url }}</a>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="12" :xs="24" class="mb-25">
                      <label class="" style="display: block; font-weight: bold;">Текст креатива</label>
                      <div style="white-space: pre-wrap; text-wrap: wrap; word-break: break-word;">{{ confirmationData.creative_text }}</div>
                    </a-col>
                  </a-row>

                  <div style="display: flex; gap: 20px; margin-top: 30px;">
                    <sdButton class="btn-prev" type="light" @click="prevStep" :disabled="isLoading">
                      <sdFeatherIcons type="arrow-left" size="16" />&nbsp;Назад
                    </sdButton>

                    <sdButton class="btn-next" type="danger" @click="saveProject" :disabled="isLoading">
                      {{ isLoading ? 'Загрузка...' : 'Подтверждаю правильность данных, отправить в ОРД' }}&nbsp;&nbsp;<sdFeatherIcons type="check" size="16" />
                    </sdButton>
                  </div>
                </div>
                <div v-else>
                  <div style="width: 100%; display: flex; align-items: center; justify-content: center; padding-top: 100px; padding-bottom: 20px;">
                    <SpinerWraperStyle style="background: transparent !important; padding: 10px !important; margin: 0 auto !important;">
                      <a-spin indicator="activity" />
                    </SpinerWraperStyle>
<!--                    <Vue3Lottie :animationData="PaperplaneJSON" :height="200" :width="200" />-->
                  </div>
                  <center><h2>Отправка данных в ОРД</h2></center>
                  <div style="text-align: center;">Пожалуйста, не закрывайте страницу.</div>
                </div>

              </div>

              <div v-if="currentStep == 7" class="current-step-content">
                <h2>Все готово!</h2>

                <p>Ваш креатив загружен в ОРД!</p>
                <p>Теперь можно ставить рекламный пост в отложку, не забудьте подгрузить токен, который указан ниже.</p>

                <h2 style="margin-top: 20px;">Ваш токен: {{ eridToken }}</h2>

                <p>Для рекламного поста используйте этот текст:</p>

                <div style="border: 1px solid #d1d1d1;
                border-radius: 15px;
                padding: 20px;
                max-width: 500px;
                display: flex;
                align-items: flex-start;
                gap: 20px;
                justify-content: flex-start;">
                  <div style="flex-grow: 1;" ref="eridTokenRef">
                    Реклама. {{ confirmationData.contragent_name_short() }}, erid: {{ eridToken }}
                  </div>
                  <div>
                    <sdButton type="light" @click="copyEridToClipboard">
                      <sdFeatherIcons type="copy" size="24" />
                    </sdButton>
                  </div>
                </div>

                <h2 style="margin-top: 20px;">Что делать дальше?</h2>

                <a-row :gutter="30">
                  <a-col :xs="24" :sm="12" :md="12" :lg="8">
                    <CardStyleWrapper>
                      <sdCards :border="false" title="Подать акты по рекламе">
                        <p>Акты подаются в следующем месяце после размещения, но можно и раньше.</p>
                        <p>Проверьте, может какие-то уже пора подавать.</p>
                        <sdButton type="primary" @click="openMyAds" style="margin-top: 10px;">
                          Открыть акты
                        </sdButton>
                      </sdCards>
                    </CardStyleWrapper>
                  </a-col>
                  <a-col :xs="24" :sm="12" :md="12" :lg="8">
                    <CardStyleWrapper>
                      <sdCards :border="false" title="Промаркировать еще рекламу">
                        <p>Если у вас планируется несколько размещений, промаркируйте их все сразу.</p>
                        <sdButton type="primary" @click="createNewAds" style="margin-top: 10px;">
                          Новая реклама
                        </sdButton>
                      </sdCards>
                    </CardStyleWrapper>
                  </a-col>
                </a-row>

<!--                <p>Когда придет время подавать отчет о рекламе, нужно будет передать в ОРД информацию о количестве показов рекламы. Мы заранее пришлем вам напоминание. </p>-->

<!--                <p>Созданное объявление будет отображаться у вас в разделе <router-link to="/ord/ads">Реклама</router-link>.</p>-->

<!--                <p>Если вам нужно промаркировать несколько реклам, можно <router-link :to="'/ord/ads/create?t='+moment().unix()">создать еще рекламу</router-link>.</p>-->

              </div>


            </a-col>
          </a-row>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>
.bot-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.bot-preview img {
  border-radius: 50%;
  width: 90px;
}

.bot-preview p {
  display: block;
}

.bot-preview p span.title {
  color: #272B41;
  font-weight: bold;
  font-size: 18px;
  display: block;
}

.bot-preview p span.username {
  color: #616268;
  font-size: 16px;
  display: block;
  margin-top: 0px;
}


.custom-step-numbers {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  list-style-type: none;
  gap: 20px;
}

.custom-step-numbers li {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 0 none;
  box-shadow: 10px 0 20px #9299b815;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  font-family: Inter,sans-serif;
}

.custom-step-numbers li.step-current {
  background: #355DFF;
  color: #ffffff !important;
}

.custom-step-numbers li.step-done {
  background: #20C997;
  color: #ffffff !important;
}


.wizard-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.wizard-item h2 {
  margin-bottom: 20px;
}

.wizard-item p {
  color: #5A5F7D;
  text-align: left;
  margin-top: 10px;
}

.wizard-item img {
  max-width: 200px;
  margin-top: 30px;
}

.current-step-content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
}
</style>
<script>
import { BasicFormWrapper, Main } from '../../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';
import activeProjectId from '@/config/helpers'
import { contragentTypes, contragentTypesLabel, contragentTypesPlaceholder, creativeTypes } from '@/config/enum';
import moment from "moment";
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
import 'moment/dist/locale/ru';
import { okved } from "@/config/okved";
import { message } from 'ant-design-vue';
import PaperplaneJSON from '@/lottie/paperplane.json';
import useClipboard from 'vue-clipboard3'
import {CardStyleWrapper} from "../../../uiElements/ui-elements-styled";
import {useRouter} from "vue-router";

const AdsCreate = {
  name: 'AdsCreate',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    FileOutlined,
    Main,
    SpinerWraperStyle,
    CardStyleWrapper
  },
  setup() {
    const { state, dispatch } = useStore();

    const router = useRouter();

    const isLoading = ref(false);

    const currentStep = ref(1);

    const { toClipboard } = useClipboard()

    const formRefStep1 = ref();
    const formStateStep1 = reactive({
      project_platform_id: null
    });

    const myPlatformsList = ref([]);

    axios.get('/ord/platforms/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          myPlatformsList.value = response.data.platforms;
          isLoading.value = false;
    });

    const myContragentsList = ref([]);

    axios.get('/ord/contragents/list', {params: {project_id: activeProjectId()}})
        .then(response => {
          myContragentsList.value = response.data.contragents;
          isLoading.value = false;
        });

    const myContragentTypesList = ref([]);

    axios.get('/ord/data/contragent_types', {params: {}}).then(response => {
      myContragentTypesList.value = response.data.types;
    });

    const myOkvedList = ref([]);

    okved.forEach(function(item) {
      if(item.code.includes('.'))
        myOkvedList.value.push({value: item.code, label: `${item.code} - ${item.name}`});
    });

    const myCreativeTypes = ref(creativeTypes);

    const rulesStep1 = {
      project_platform_id: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, выберите канал для рекламы',
          trigger: 'blur'
        }
      ]
    };

    const formRefStep2 = ref();
    const formStateStep2 = reactive({
      select_type: 'existing',
      project_contragent_id: null,
      name: null,
      inn: null,
      phone: null,
      contragent_type_id: 1
    });

    const rulesStep2 = computed(() => {
      if(formStateStep2.select_type === 'existing') {
        return {
          project_contragent_id: [
            {
              validator: (rule, value, callback) => {
                if(value === '' || value === undefined || value === null)
                  return Promise.reject('Field is empty');

                return Promise.resolve();
              },
              message: 'Пожалуйста, выберите контрагента или создайте нового',
              trigger: 'blur'
            }
          ]
        };
      }

      return {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 191,
            message: 'Минимальная длина поля - 3 символа, максимальная - 191',
            trigger: 'blur',
          }
        ],
        contragent_type_id: [
          {
            validator: (rule, value, callback) => {
              if(value === '' || value === undefined || value === null)
                return Promise.reject('Field is empty');

              return Promise.resolve();
            },
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        inn: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            min: 10,
            max: 12,
            message: 'ИНН физлица состоит из 12 цифр, ИНН юрлица - из 10. Проверьте правильность ввода.',
            trigger: 'blur',
          }
        ]
      }
    });

    const formRefStep3 = ref();
    const formStateStep3 = reactive({
      contract_date: moment().format('YYYY-MM-DD'),
      contract_no: null,
      contract_sum: null,
      contract_vat: 0,
      contract_description: 'Размещение рекламы',
      contract_type: 'service',
      contract_subject_type: 'distribution'
    });

    const rulesStep3 = {
      contract_date: [
        {
          required: true,
          message: 'Пожалуйста, выберите дату',
          trigger: 'blur'
        }
      ],
      contract_no: [
        {
          required: true,
          message: 'Пожалуйста, укажите номер договора',
          trigger: 'blur'
        }
      ],
      contract_sum: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            if(value < 1)
              return Promise.reject('Field is empty');
            return Promise.resolve();
          },
          message: 'Сумма договора не может быть меньше 1 руб.',
          trigger: 'blur'
        }
      ],
    };

    const formRefStep4 = ref();
    const formStateStep4 = reactive({
      post_date: moment().format('YYYY-MM-DD'),
      post_duration: 1,
      post_brand_name: null,
      post_brand_category: null,
      post_brand_description: null,
      post_brand_okved: null
    });

    const rulesStep4 = {
      post_date: [
        {
          required: true,
          message: 'Пожалуйста, выберите дату',
          trigger: 'blur'
        }
      ],
      post_duration: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            if(value < 1 || value > 30)
              return Promise.reject('Field is empty');
            return Promise.resolve();
          },
          message: 'Пожалуйста, укажите длительность поста (от 1 до 30 дней)',
          trigger: 'blur'
        }
      ],
      post_brand_name: [
        {
          required: true,
          message: 'Пожалуйста, укажите название бренда',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 225,
          trigger: 'blur',
          message: 'Максимальная длина этого поля - 225 символов'
        }
      ],
      post_brand_description: [
        {
          required: true,
          message: 'Пожалуйста, укажите описание рекламы',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 225,
          trigger: 'blur',
          message: 'Максимальная длина этого поля - 225 символов'
        }
      ],
      post_brand_okved: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, выберите код ОКВЭД рекламодателя',
          trigger: 'blur'
        }
      ]
    };

    const formRefStep5 = ref();
    const formStateStep5 = reactive({
      post_type: 'text_graphic_block',
      post_pay_type: 'other',
      post_text: null,
      post_target_url: null,
      post_media: []
    });

    const rulesStep5 = computed(() => {
      var baseRules = {
        post_type: [
          {
            validator: (rule, value, callback) => {
              if (value === '' || value === undefined || value === null)
                return Promise.reject('Field is empty');

              return Promise.resolve();
            },
            message: 'Пожалуйста, выберите код ОКВЭД рекламодателя',
            trigger: 'blur'
          }
        ],
        post_target_url: [
          {
            type: 'url',
            trigger: 'blur',
            message: 'Пожалуйста, введите действительный адрес в формате https://'
          }
        ]
      };

      if(formStateStep5.post_type === 'text_graphic_block' || formStateStep5.post_type === 'text_block') {
        baseRules.post_text = [
          {
            required: true,
            message: 'Укажите текст рекламного поста',
            trigger: 'blur'
          }
        ];
      }

      return baseRules;
    });


    const prevStep = () => {
      currentStep.value--;
    }

    const nextStep = () => {

      if(currentStep.value == 1) {
        formRefStep1.value
            .validate()
            .then(() => {
              currentStep.value = 2;
            }).catch(error => {
          // alert('Validation not passed!');
          console.log('error', error);
        });
      }


      if(currentStep.value == 2) {
        formRefStep2.value
            .validate()
            .then(() => {
              currentStep.value = 3;
              suggestContractNo();
              suggestBrandName();
              suggestOkved();
            }).catch(error => {
          console.log('error', error);
        });
      }

      if(currentStep.value == 3) {
        formRefStep3.value
            .validate()
            .then(() => {
              currentStep.value = 4;
            }).catch(error => {
          console.log('error', error);
        });
      }

      if(currentStep.value == 4) {
        formRefStep4.value
            .validate()
            .then(() => {
              currentStep.value = 5;
            }).catch(error => {
          console.log('error', error);
        });
      }

      if(currentStep.value == 5) {
        formRefStep5.value
            .validate()
            .then(() => {
              currentStep.value = 6;
            }).catch(error => {
          console.log('error', error);
        });
      }


    };



    const saveProject = () => {
      isLoading.value = true;

      axios.post('/ord/ads/create', {
        project_id: activeProjectId(),
        project_platform_id: formStateStep1.project_platform_id,
        contragent: formStateStep2,
        contract: formStateStep3,
        post: {...formStateStep4, ...formStateStep5}
      }).then(response => {
        console.log(response);

        if(response.data.status === 'error') {
          alert(response.data.message);
          return;
        }

        eridToken.value = response.data.erid;

        console.log(response);
      }).finally(() => {
        isLoading.value = false;
        currentStep.value = 7;
      });
    };

    const getContragentNameLabel = function(id) {
      return contragentTypesLabel[id];
    }
    const getContragentNamePlaceholder = function(id) {
      return contragentTypesPlaceholder[id];
    }

    const suggestContractNo = function() {
      var date = formStateStep3.contract_date;

      formStateStep4.post_date = formStateStep3.contract_date;

      axios.get('/ord/form/suggest/contract_no', {params: {project_id: activeProjectId(), date: date}}).then(response => {
        formStateStep3.contract_no = response.data.no;
      });
    };

    const suggestBrandName = function() {
      try {
        if (formStateStep2.select_type === 'existing')
          formStateStep4.post_brand_name = myContragentsList.value.find(item => item.id === formStateStep2.project_contragent_id).name;
        else formStateStep4.post_brand_name = formStateStep2.name;
      } catch(e) {

      }
    }

    const suggestOkved = function() {
      var inn = null;

      try {
        if (formStateStep2.select_type === 'existing')
          inn = myContragentsList.value.find(item => item.id === formStateStep2.project_contragent_id).inn;
        else inn = formStateStep2.inn;
      } catch(e) {

      }

      axios.get('/ord/form/suggest/okved', {params: {inn: inn}}).then(response => {
        console.log(response);

        formStateStep4.post_brand_okved = response.data.okved;
      });

    }

    const filterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const uploadActionUrl = ref(process.env.VUE_APP_API_BASE_URL + 'ord/ads/creative/uploadMedia');
    const uploadData = ref({
      project_id: activeProjectId()
    });
    const uploadHeaders = ref({
      'Authorization': 'Bearer '+localStorage.getItem('accessToken')
    });

    const uploadLoading = ref(false);

    const uploadHandleChange = (info) => {
      console.log('Handle change: ', info);

      if(!uploadValidateFile(info.file,false)) {
        formStateStep5.post_media = formStateStep5.post_media.filter(item => item.uid !== info.file.uid);
      }

      if (info.file.status === 'uploading') {
        uploadLoading.value = true;
        return;
      }
      if (info.file.status === 'done') {
        uploadLoading.value = false;
        // elementData.value.public_url = info.file.response.url;
        // elementData.value.inner_file_id = info.file.response.inner_file_id;
        // elementData.value.name = info.file.response.name;
      }
      if (info.file.status === 'error') {
        uploadLoading.value = false;
        // @todo: обработка разных типов ошибок
        message.error('Ошибка загрузки');
      }
    };

    const uploadBeforeEvent = (file) => {
      return uploadValidateFile(file, true);
    };

    const uploadValidateFile = (file, echo) => {
      var supportedTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'video/mp4',
        'video/quicktime',
        'image/gif'
      ];

      const isJpgOrPng = supportedTypes.includes(file.type);

      if (!isJpgOrPng && echo) {
        message.error('Поддерживаются файлы форматов jpeg, jpg, png, gif, mp4, mov.');
      }

      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M && echo) {
        message.error('Вес файла должен быть до 50 мб.');
      }

      return isJpgOrPng && isLt2M;
    }

    const confirmationData = computed(() => {
      var formattedContractDate = moment(formStateStep3.contract_date).format('DD.MM.YYYY');

      return {
        channel_name: () => {
          var c = myPlatformsList.value.find(item => item.id === formStateStep1.project_platform_id);

          if(c !== undefined)
            return `${c.platform_type.name} ${c.name}`;

          return '';
        },
        contragent_name: () => {
          if(formStateStep2.select_type === 'existing') {
            var ca = myContragentsList.value.find(item => item.id === formStateStep2.project_contragent_id);
            if(ca !== undefined)
              return `${ca.name}, ИНН ${ca.inn}, тел. ${ca.phone}`;
            return '';
          } else return `${formStateStep2.name}, ИНН ${formStateStep2.inn}, тел. ${formStateStep2.phone}`
        },
        contragent_name_short: () => {
          if(formStateStep2.select_type === 'existing') {
            var ca = myContragentsList.value.find(item => item.id === formStateStep2.project_contragent_id);
            if(ca !== undefined)
              return `${ca.name}, ИНН ${ca.inn}`;
            return '';
          } else return `${formStateStep2.name}, ИНН ${formStateStep2.inn}`
        },
        contract: `№${formStateStep3.contract_no} от ${formattedContractDate}`,
        contract_sum: `${formStateStep3.contract_sum} руб., ${(formStateStep3.contract_vat == 0) ? 'Без НДС' : 'НДС включен'}`,
        post_date: formStateStep4.post_date,
        post_duration: formStateStep4.post_duration,
        post_brand: formStateStep4.post_brand_name,
        post_description: formStateStep4.post_brand_description,
        post_okved: () => {
          var okved = myOkvedList.value.find(item => item.value === formStateStep4.post_brand_okved);

          if(okved !== undefined)
            return `${okved.value} - ${okved.label}`;

          return formStateStep4.post_brand_okved;
        },
        creative_text: formStateStep5.post_text,
        creative_media: formStateStep5.post_media,
        creative_url: formStateStep5.post_target_url
      }
    });

    const eridToken = ref(null);


    // eridToken.value = 'test12e12dwds';
    // currentStep.value = 7;

    const eridTokenRef = ref();

    const copyEridToClipboard = async function() {
      try {
        await toClipboard(eridTokenRef.value.innerText);
        message.info('Токен скопирован в буфер обмена');
      } catch(e) {
        message.error('Ошибка копирования токена. Пожалуйста, скопируйте его вручную');
      }
    }

    const openMyAds = function() {
      router.push('/ord/ads');
    };

    const createNewAds = function() {
      location.reload();
    };


    return {
      isLoading,
      currentStep,
      nextStep,
      prevStep,
      saveProject,

      formStateStep1,
      formRefStep1,
      rulesStep1,

      formStateStep2,
      formRefStep2,
      rulesStep2,

      formStateStep3,
      formRefStep3,
      rulesStep3,

      formStateStep4,
      formRefStep4,
      rulesStep4,

      formStateStep5,
      formRefStep5,
      rulesStep5,

      myOkvedList,
      myPlatformsList,
      myContragentsList,
      myContragentTypesList,
      myCreativeTypes,
      getContragentNameLabel,
      getContragentNamePlaceholder,

      suggestContractNo,

      filterOption,
      datePickerLocale: locale,

      uploadActionUrl,
      uploadHeaders,
      uploadData,
      uploadHandleChange,
      uploadBeforeEvent,
      uploadLoading,
      confirmationData,

      moment,

      eridToken,
      eridTokenRef,
      copyEridToClipboard,

      openMyAds,
      createNewAds
    };
  },
};

export default AdsCreate;
</script>
