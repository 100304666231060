<template>
  <sdModal
      :type="'primary'"
      title="Управление папками"
      :visible="isFoldersListModalVisible"
      :onCancel="hideFoldersListModal"
      :width="400"
  >
    <div class="project-modal">
      <BasicFormWrapper v-if="!isFolderCreateVisible">
        <small style="display: block; font-size: 14px; line-height: 1.5;">По папкам вы можете разложить командные чаты или важных клиентов, за которых отвечаете непосредственно вы. Другие пользователи не увидят ваши папки.</small>

        <br>
        <b>Ваши папки:</b>
        <FolderList>
          <ul>
            <li v-for="(item, index) in foldersList"
                :key="item.id">
              <span class="folder-name">{{ item.name }}</span>
              <a-tooltip>
                <template #title>Редактировать</template>
                <sdFeatherIcons type="edit" size="16" color="#ADB4D2" style="cursor: pointer;"/>
              </a-tooltip>
              <a-tooltip>
                <template #title>Удалить</template>
                <sdFeatherIcons type="trash" size="16" color="red" style="cursor: pointer;" @click="deleteFolder(item)"/>
              </a-tooltip>
            </li>
          </ul>
        </FolderList>

        <a-button @click="toggleCreateFolder">Создать папку</a-button>
      </BasicFormWrapper>
      <BasicFormWrapper v-else>
        <a-form name="contact">
          <a-input type="text" v-model:value="createFolderFormState.name" placeholder="Название папки..." style="width: 100%;" :disabled="isFolderCreateLoading" />

          <a-button @click="createFolder" type="primary" :disabled="isFolderCreateLoading">{{ (isFolderCreateLoading) ? 'Загрузка...' : 'Создать папку' }}</a-button>
          <a-button @click="toggleCreateFolder" style="margin-left: 10px;" :disabled="isFolderCreateLoading">Отмена</a-button>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>

  <sdCards headless style="height: 100%; margin-bottom: 0px !important;">
    <div class="chatbox-search">
      <div class="chatbox-search-flex">
        <input type="text" placeholder="Поиск..." v-model="searchInput.text" @keyup="onChangeSearchInput" @change="onChangeSearchInput"/>
        <sdFeatherIcons type="search" size="16" color="#ADB4D2" v-if="searchInput.text.length === 0"/>
        <sdFeatherIcons type="x" size="16" color="#ADB4D2" v-else @click="resetSearch" style="cursor: pointer;"/>
      </div>
    </div>

    <ReadFilterSwitch>
      <div class="switch-group">
        <button :class="{'active': readSwitch === 'all'}" @click="setReadSwitch('all')">
          Все
        </button>
        <button :class="{'active': readSwitch === 'unread'}" @click="setReadSwitch('unread')">
          Непрочитанные
        </button>
      </div>
    </ReadFilterSwitch>

    <nav>
      <div class="nav-ul">
      <vue-horizontal-es class="horizontal" snap="none" :button-between="false" style="width: 100%;">
        <template v-slot:btn-prev>
          <div class="btn-left"></div>
        </template>

        <template v-slot:btn-next>
          <div class="btn-right"></div>
        </template>

<!--        <UL>-->
          <li class="nav-li">
            <a :class="{'active': currentFolder == 0}" @click="setActiveFolder(0)">
              Все
            </a>
          </li>
          <li v-for="folder in foldersList" class="nav-li" :key="folder.id">
            <a :class="{'active': folder.id == currentFolder}" @click="setActiveFolder(folder.id)">
              {{ folder.name }}
            </a>
          </li>
          <li class="nav-li" style="padding-top: 3px;">
            <a-tooltip>
              <template #title>Настроить папки</template>
              <sdFeatherIcons type="folder" size="16" color="#ADB4D2" @click="openFoldersDialog"/>
            </a-tooltip>
          </li>
<!--        </UL>-->
      </vue-horizontal-es>
      </div>
    </nav>
    <Content style="height: 90%; height: calc(100% - 165px);">
      <sdCards v-if="dialogsList.length == 0">
        <a-empty>
          <template v-slot:description>
            <span>В этой папке пока нет чатов</span>
          </template>
        </a-empty>
      </sdCards>


      <perfect-scrollbar
          :options="{
                  wheelSpeed: 1,
                  swipeEasing: true,
                  suppressScrollX: true,
                }"
          style="height: 100%;"
      >
        <ChatWrapper style="margin-top: 15px;">
          <!--    <div class="create-action">-->
          <!--      <sdButton class="btn-add" size="default" type="default" shape="circle" block>-->
          <!--        <sdFeatherIcons type="user-plus" size="14" />-->
          <!--        Add New Contact-->
          <!--      </sdButton>-->
          <!--    </div>-->
          <ul v-if="dialogsFiltered.length > 0">
            <li
                @click="dataFiltering"
                v-for="(item, key) in dialogsFiltered"
                :key="key + 1"
                :class="{'chat-link-signle': true, 'chat-link-active': (item.id == activeDialogId)}"
            >
              <router-link :data-id="item.id" :to="{path: `${item.id}`, query: {d: Date.now()}}" @click="setActiveDialogId(item.id)">
<!--              <router-link :data-id="item.id" :to="`${item.id}`" @click="setActiveDialogId(item.id)">-->
                <div class="author-figure">
                  <img :src="getAvatarUrl(item)" alt="" v-img-fallback="require('@/static/img/fallback_avatar.jpg')"/>
                  <span :class="item.unread_messages_count > 0 ? 'active' : 'inactive'" />
                  <div class="dialog-pinned-icon" v-if="item.is_pinned">
                    <sdFeatherIcons type="star" size="12" color="#ffffff"/>
                  </div>
                </div>
                <div class="author-info">
                  <BlockSpan class="author-name">
                    <b v-if="item.type == 'private'">{{ item.first_name }} {{ item.last_name }}</b>
                    <b v-else>{{ item.title }}</b>
                  </BlockSpan>
                  <LastMessageSnippet :lastMessage="item.last_message"/>
                </div>
                <div class="author-chatMeta">
                  <BlockSpan v-if="item.last_message !== null && item.last_message !== undefined">{{
                      moment(item.last_message.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')
                          ? moment(item.last_message.created_at).fromNow()
                          : moment(item.last_message.created_at).format('DD.MM')
                    }}</BlockSpan>
                  <a-badge v-if="item.unread_messages_count > 0" class="badge-success" :count="item.unread_messages_count" />
                </div>
              </router-link>
            </li>
            <li v-observe-visibility="handleScrolled" >&nbsp;&nbsp;</li>
          </ul>
          <SpinerWraperStyle style="background: transparent !important; padding: 10px !important; margin: 0 auto !important;" v-if="isLazyLoading">
            <a-spin indicator="activity" />
          </SpinerWraperStyle>

        </ChatWrapper>
      </perfect-scrollbar>
    </Content>
  </sdCards>
</template>
<style>
.ant-badge span {
    float: left !important;
}
</style>
<style scoped>
.nav-li {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.horizontal-more-right {
  height: 100%;
  width: 94px;
  background: linear-gradient(270deg, rgb(255 255 255) 0%, rgba(242, 243, 246, 0) 62.55%);
}

.horizontal-more-left {
  height: 100%;
  /*width: 94px;*/
  width: 20px;
  background: rgba(0, 0, 0, 0.5);
  /*background: linear-gradient(270deg, rgba(242, 243, 246, 0) 62.55%, rgb(255 255 255) 0%);*/
}

.btn-left, .btn-right {
  padding: 8px;
  height: 100%;
}

.btn-left {
  background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
  padding-right: 20px;
}

.btn-right {
  background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
  padding-left: 20px;
}

</style>
<script>
import moment from 'moment';
import { BlockSpan, ChatWrapper, Content, ReadFilterSwitch, FolderList } from '../style';
import { textRefactor } from '../../../../components/utilities/utilities';
import {useRoute, useRouter} from 'vue-router';
import {computed, createVNode, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import LastMessageSnippet from "../components/LastMessageSnippet";
import { BadgeStandAloneStyle, BadgeWraperStyle } from "../../../uiElements/ui-elements-styled";
import {SpinerWraperStyle} from "../../../uiElements/ui-elements-styled";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueHorizontalEs from "vue-horizontal";
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import _ from "lodash";
import { BasicFormWrapper } from '@/view/styled';
import {getAvatarUrl} from "../../../../utility/utility";

const AllContact = {
  name: 'AllContact',
  components: {
    BlockSpan,
    ChatWrapper,
    LastMessageSnippet,
    BadgeStandAloneStyle,
    BadgeWraperStyle,
    SpinerWraperStyle,
    Content,
    ReadFilterSwitch,
    PerfectScrollbar,
    VueHorizontalEs,
    FolderList,
    BasicFormWrapper
  },
  setup() {
    const { state, dispatch } = useStore();
    const match = useRoute();

    const readSwitch = ref('all');
    const router = useRouter();

    const dialogsFiltered = computed(function() {
      var val = dialogsList.value;

      if(readSwitch.value == 'unread')
        val = dialogsList.value.filter(function(i) {
          if(i.unread_messages_count > 0) return i;
        });

      var pinned = val.filter(function(i) {
        if(i.is_pinned) return i;
      })

      return pinned.concat(val.filter(function(i) {
        if(!i.is_pinned) return i;
      }));
    });

    const searchInput = reactive({text: ''});

    moment.updateLocale('en', {
          relativeTime : {
              future: "через %s",
              past:   "%s",
              s  : 'сейчас',
              ss : '%d с.',
              m:  "1 мин.",
              mm: "%d м.",
              h:  "1 ч.",
              hh: "%d ч.",
              d:  "1 д.",
              dd: "%d д.",
              w:  "1 н.",
              ww: "%d н.",
              M:  "1 м.",
              MM: "%d м.",
              y:  "1 г",
              yy: "%d г."
          }
    });

    const activeDialogId = ref(null);

    activeDialogId.value = match.params.id;

    const setActiveDialogId = function(id)
    {
        activeDialogId.value = id;
    }

    const dataFiltering = e => {
      e.preventDefault();
      dispatch('filterSinglePage', match.params.id);
    };

    const isLoading = ref(false);
    const isLazyLoading = ref(true);

    const dialogsList = ref([]);

    const currentPageOffset = ref(0);
    const hasNext = ref(true);
    const itemsPerPage = 3;

    const foldersList = ref([]);
    const currentFolder = ref(0);


    const loadDialogsList = function() {
      axios.get('/chat/dialogs/list', {params: {project_id: activeProjectId(), offset: currentPageOffset.value, search: searchInput.text, folder: currentFolder.value}})
          .then(response => {
            dialogsList.value = response.data.dialogs;
            hasNext.value = response.data.has_next;

            isLazyLoading.value = false;
          });
    }

    loadDialogsList();

    setInterval(function() {
        axios.get('/chat/dialogs/list', {params: {project_id: activeProjectId(), offset: currentPageOffset.value, search: searchInput.text, folder: currentFolder.value}})
            .then(response => {
                dialogsList.value = response.data.dialogs;
                hasNext.value = response.data.has_next;
            });
    }, 2000);

    const handleScrolled = function(isVisible) {
        if(!isVisible) return;

        if(isLazyLoading.value === true) return;

        if(!hasNext.value) return;

        isLazyLoading.value = true;

        currentPageOffset.value = currentPageOffset.value + 1;

        axios.get('/chat/dialogs/list', {params: {project_id: activeProjectId(), offset: currentPageOffset.value}})
            .then(response => {
                dialogsList.value = response.data.dialogs;
                isLazyLoading.value = false;
                hasNext.value = response.data.has_next;
            });
    }

    const setReadSwitch = function(value) {
      readSwitch.value = value;
    }

    const resetSearch = function() {
      searchInput.text = '';
      loadDialogsList();
    }

    const onChangeSearchInput = function(event) {
     // alert('search changed!');
      loadDialogsList();
    }


    const loadFoldersList = function() {
      axios.get('/chat/folders/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            foldersList.value = response.data.folders;
          });
    }

    loadFoldersList();

    const setActiveFolder = function(id)
    {
      currentFolder.value = id;

      loadDialogsList();
    }

    const openFoldersDialog = function()
    {
      isFoldersListModalVisible.value = true;
    }

    const isFoldersListModalVisible = ref(false);
    const isFolderCreateVisible = ref(false);

    const hideFoldersListModal = function() {
      isFoldersListModalVisible.value = false;
    }

    const createFolderFormState = reactive({
      name: ''
    });

    const isFolderCreateLoading = ref(false);

    const deleteFolder = function(folder) {
      Modal.confirm({
        title:  'Вы точно хотите удалить папку '+folder.name+'?',
        icon: createVNode(ExclamationCircleOutlined),
        content: (<div>После удаления она исчезнет, а все диалоги из нее будут отвязаны.<br/><br/><b>Хотите продолжить?</b></div>),
        okText: 'Да, удалить',
        okType: 'danger',
        cancelText: 'Нет',
        onOk() {
          axios.post('/chat/folders/delete', {
            project_id: activeProjectId(),
            id: folder.id
          }).then(response => {
            console.log(response);

            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }

            foldersList.value = response.data.folders;
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    const toggleCreateFolder = function() {
      isFolderCreateVisible.value = !isFolderCreateVisible.value;
    }

    const createFolder = function() {
      if(isFolderCreateLoading.value)
        return;

      if(createFolderFormState.name.length == 0) {
        alert('Придумайте название папки');
        return;
      }

      isFolderCreateLoading.value = true;

      axios.post('/chat/folders/create', {
        project_id: activeProjectId(),
        name: createFolderFormState.name
      }).then(response => {
        console.log(response);

        if(response.data.status == 'error') {
          message.error(response.data.message);
          return;
        }

        foldersList.value = response.data.folders;

        createFolderFormState.name = '';
        isFolderCreateVisible.value = false;
      }).finally(function() {
        isFolderCreateLoading.value = false;
      });
    }

    document.addEventListener('click', function(e) {
      console.log('clickE: ', e);
    })

    return {
      match,
      dataFiltering,
      moment,
      textRefactor,
      isLoading,
      dialogsList,
      handleScrolled,
      isLazyLoading,
      activeDialogId,
      setActiveDialogId,

      readSwitch,
      setReadSwitch,
      dialogsFiltered,

      searchInput,
      resetSearch,
      onChangeSearchInput,

      foldersList,
      currentFolder,
      setActiveFolder,

      openFoldersDialog,
      isFoldersListModalVisible,
      isFolderCreateVisible,
      hideFoldersListModal,
      deleteFolder,
      toggleCreateFolder,
      createFolderFormState,
      isFolderCreateLoading,
      createFolder,
      getAvatarUrl
    };
  },
};

export default AllContact;
</script>
