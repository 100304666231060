<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Подключите уведомления в Telegram" subtitle="Test" description="Test 2">
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="16" :lg="18">

        <a-spin indicator="activity" size="large" v-if="isLoading"/>

        <div v-else>
          <div v-if="telegramStatus.is_enabled === undefined || telegramStatus.is_enabled === 0 || telegramStatus.is_enabled === false">
            <p>Будем присылать вам напоминания, когда пора подавать отчет и дублировать токены прямо в Telegram, чтобы было проще ставить отложку.</p>
            <p>Просто нажмите на кнопку ниже, вы перейдете в Telegram на нашего бота. В боте нажмите кнопку Start и уведомления подключатся автоматически.</p>

            <a :href="'https://t.me/VibesORD_bot?start='+telegramStatus.external_id">
              <sdButton size="default" type="primary" key="1">
                Подключить уведомления в TG
              </sdButton>
            </a>
          </div>
          <div v-else>
            <p>Вы успешно подключили уведомления на аккаунт {{ telegramStatus.telegram_first_name }} {{ telegramStatus.telegram_last_name }} {{ telegramStatus.telegram_username }}</p>
          </div>
        </div>
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
.ant-table-column-title {
  font-size: 14px !important;
}

table tbody tr td {
  font-weight: normal !important;
}
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { periodUnits } from '@/config/enum';
import {useRouter} from "vue-router";
import moment from 'moment';
import RoundVideo from "../../../../components/vibes/RoundVideo.vue";

const Telegram = {
  name: 'Telegram',
  components: {
    RoundVideo,
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {

    const isLoading = ref(true);

    const telegramStatus = ref(null);

    const router = useRouter();

    const loadTelegramStatus = function() {
      axios.get('/ord/telegram/status', {params: {project_id: activeProjectId()}})
          .then(response => {
            telegramStatus.value = response.data.telegram;
            isLoading.value = false;
          });
    };

    loadTelegramStatus();

    return {
      isLoading,
      telegramStatus
    };
  },
};

export default Telegram;
</script>
