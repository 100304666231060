const okved = [
    {
        "code": "01",
        "name": "Растениеводство и животноводство, охота и предоставление соответствующих услуг в этих областях"
    },
    {
        "code": "01.1",
        "name": "Выращивание однолетних культур"
    },
    {
        "code": "01.11",
        "name": "Выращивание зерновых (кроме риса), зернобобовых культур и семян масличных культур"
    },
    {
        "code": "01.11.1",
        "name": "Выращивание зерновых культур"
    },
    {
        "code": "01.11.11",
        "name": "Выращивание пшеницы"
    },
    {
        "code": "01.11.12",
        "name": "Выращивание ячменя"
    },
    {
        "code": "01.11.13",
        "name": "Выращивание ржи"
    },
    {
        "code": "01.11.14",
        "name": "Выращивание кукурузы"
    },
    {
        "code": "01.11.15",
        "name": "Выращивание овса"
    },
    {
        "code": "01.11.16",
        "name": "Выращивание гречихи"
    },
    {
        "code": "01.11.19",
        "name": "Выращивание прочих зерновых культур"
    },
    {
        "code": "01.11.2",
        "name": "Выращивание зернобобовых культур"
    },
    {
        "code": "01.11.3",
        "name": "Выращивание семян масличных культур"
    },
    {
        "code": "01.11.31",
        "name": "Выращивание семян подсолнечника"
    },
    {
        "code": "01.11.32",
        "name": "Выращивание семян рапса"
    },
    {
        "code": "01.11.33",
        "name": "Выращивание семян соевых бобов"
    },
    {
        "code": "01.11.39",
        "name": "Выращивание семян прочих масличных культур"
    },
    {
        "code": "01.12",
        "name": "Выращивание риса"
    },
    {
        "code": "01.13",
        "name": "Выращивание овощей, бахчевых, корнеплодных и клубнеплодных культур, грибов и трюфелей"
    },
    {
        "code": "01.13.1",
        "name": "Выращивание овощей"
    },
    {
        "code": "01.13.11",
        "name": "Выращивание овощей открытого грунта"
    },
    {
        "code": "01.13.12",
        "name": "Выращивание овощей защищенного грунта"
    },
    {
        "code": "01.13.2",
        "name": "Выращивание бахчевых культур"
    },
    {
        "code": "01.13.3",
        "name": "Выращивание столовых корнеплодных и клубнеплодных культур с высоким содержанием крахмала или инулина"
    },
    {
        "code": "01.13.31",
        "name": "Выращивание картофеля"
    },
    {
        "code": "01.13.39",
        "name": "Выращивание прочих столовых корнеплодных и клубнеплодных культур с высоким содержанием крахмала или инулина"
    },
    {
        "code": "01.13.4",
        "name": "Выращивание семян овощных культур, за исключением семян сахарной свеклы"
    },
    {
        "code": "01.13.5",
        "name": "Выращивание сахарной свеклы и семян сахарной свеклы"
    },
    {
        "code": "01.13.51",
        "name": "Выращивание сахарной свеклы"
    },
    {
        "code": "01.13.52",
        "name": "Выращивание семян сахарной свеклы"
    },
    {
        "code": "01.13.6",
        "name": "Выращивание грибов и трюфелей"
    },
    {
        "code": "01.13.9",
        "name": "Выращивание овощей, не включенных в другие группировки"
    },
    {
        "code": "01.14",
        "name": "Выращивание сахарного тростника"
    },
    {
        "code": "01.15",
        "name": "Выращивание табака и махорки"
    },
    {
        "code": "01.16",
        "name": "Выращивание волокнистых прядильных культур"
    },
    {
        "code": "01.16.1",
        "name": "Выращивание хлопчатника"
    },
    {
        "code": "01.16.2",
        "name": "Выращивание льна"
    },
    {
        "code": "01.16.3",
        "name": "Выращивание обыкновенной конопли"
    },
    {
        "code": "01.16.9",
        "name": "Выращивание прочих текстильных культур"
    },
    {
        "code": "01.19",
        "name": "Выращивание прочих однолетних культур"
    },
    {
        "code": "01.19.1",
        "name": "Выращивание однолетних кормовых культур"
    },
    {
        "code": "01.19.2",
        "name": "Цветоводство"
    },
    {
        "code": "01.19.21",
        "name": "Выращивание цветов в открытом и защищенном грунте"
    },
    {
        "code": "01.19.22",
        "name": "Выращивание семян цветов"
    },
    {
        "code": "01.19.3",
        "name": "Выращивание семян свеклы (кроме семян сахарной свеклы) и семян кормовых культур"
    },
    {
        "code": "01.19.9",
        "name": "Выращивание прочих однолетних культур, не включенных в другие группировки"
    },
    {
        "code": "01.2",
        "name": "Выращивание многолетних культур"
    },
    {
        "code": "01.21",
        "name": "Выращивание винограда"
    },
    {
        "code": "01.22",
        "name": "Выращивание тропических и субтропических культур"
    },
    {
        "code": "01.23",
        "name": "Выращивание цитрусовых культур"
    },
    {
        "code": "01.24",
        "name": "Выращивание семечковых и косточковых культур"
    },
    {
        "code": "01.25",
        "name": "Выращивание прочих плодовых деревьев, кустарников и орехов"
    },
    {
        "code": "01.25.1",
        "name": "Выращивание прочих плодовых и ягодных культур"
    },
    {
        "code": "01.25.2",
        "name": "Выращивание семян плодовых и ягодных культур"
    },
    {
        "code": "01.25.3",
        "name": "Выращивание орехоплодных культур"
    },
    {
        "code": "01.26",
        "name": "Выращивание плодов масличных культур"
    },
    {
        "code": "01.27",
        "name": "Выращивание культур для производства напитков"
    },
    {
        "code": "01.27.1",
        "name": "Выращивание чая"
    },
    {
        "code": "01.27.9",
        "name": "Выращивание прочих культур для производства напитков"
    },
    {
        "code": "01.28",
        "name": "Выращивание специй, пряно-ароматических, эфиромасличных и лекарственных культур"
    },
    {
        "code": "01.28.1",
        "name": "Выращивание пряностей"
    },
    {
        "code": "01.28.2",
        "name": "Выращивание хмеля"
    },
    {
        "code": "01.28.3",
        "name": "Выращивание растений, используемых в основном в парфюмерии, фармации или в качестве инсектицидов, фунгицидов и для аналогичных целей"
    },
    {
        "code": "01.29",
        "name": "Выращивание прочих многолетних культур"
    },
    {
        "code": "01.3",
        "name": "Выращивание рассады"
    },
    {
        "code": "01.30",
        "name": "Выращивание рассады"
    },
    {
        "code": "01.4",
        "name": "Животноводство"
    },
    {
        "code": "01.41",
        "name": "Разведение молочного крупного рогатого скота, производство сырого молока"
    },
    {
        "code": "01.41.1",
        "name": "Разведение молочного крупного рогатого скота"
    },
    {
        "code": "01.41.11",
        "name": "Разведение молочного крупного рогатого скота, кроме племенного"
    },
    {
        "code": "01.41.12",
        "name": "Разведение племенного молочного крупного рогатого скота"
    },
    {
        "code": "01.41.2",
        "name": "Производство сырого коровьего молока и сырого молока прочего крупного рогатого скота (буйволов, яков и др.)"
    },
    {
        "code": "01.41.21",
        "name": "Производство сырого коровьего молока"
    },
    {
        "code": "01.41.29",
        "name": "Производство сырого молока прочего крупного рогатого скота (буйволов, яков и др.)"
    },
    {
        "code": "01.42",
        "name": "Разведение прочих пород крупного рогатого скота и буйволов, производство спермы"
    },
    {
        "code": "01.42.1",
        "name": "Разведение мясного и прочего крупного рогатого скота, включая буйволов, яков и др."
    },
    {
        "code": "01.42.11",
        "name": "Разведение мясного и прочего крупного рогатого скота, включая буйволов, яков и др., на мясо"
    },
    {
        "code": "01.42.12",
        "name": "Разведение племенного мясного и прочего крупного рогатого скота, включая буйволов, яков и др."
    },
    {
        "code": "01.42.2",
        "name": "Производство бычьей спермы, а также спермы буйволов, яков и др."
    },
    {
        "code": "01.43",
        "name": "Разведение лошадей и прочих животных семейства лошадиных отряда непарнокопытных"
    },
    {
        "code": "01.43.1",
        "name": "Разведение лошадей, ослов, мулов, лошаков"
    },
    {
        "code": "01.43.2",
        "name": "Производство сырого кобыльего молока"
    },
    {
        "code": "01.43.3",
        "name": "Производство спермы жеребцов и ослов"
    },
    {
        "code": "01.44",
        "name": "Разведение верблюдов и прочих животных семейства верблюжьих"
    },
    {
        "code": "01.45",
        "name": "Разведение овец и коз"
    },
    {
        "code": "01.45.1",
        "name": "Разведение овец и коз"
    },
    {
        "code": "01.45.2",
        "name": "Производство сырого овечьего и козьего молока"
    },
    {
        "code": "01.45.3",
        "name": "Производство сырой (немытой) шерсти и волоса козы"
    },
    {
        "code": "01.45.4",
        "name": "Разведение племенных овец и коз"
    },
    {
        "code": "01.46",
        "name": "Разведение свиней"
    },
    {
        "code": "01.46.1",
        "name": "Выращивание и разведение свиней"
    },
    {
        "code": "01.46.11",
        "name": "Выращивание свиней на мясо"
    },
    {
        "code": "01.46.12",
        "name": "Разведение племенного поголовья свиней"
    },
    {
        "code": "01.46.2",
        "name": "Производство спермы хряков"
    },
    {
        "code": "01.47",
        "name": "Разведение сельскохозяйственной птицы"
    },
    {
        "code": "01.47.1",
        "name": "Выращивание и разведение сельскохозяйственной птицы: кур, индеек, уток, гусей и цесарок"
    },
    {
        "code": "01.47.11",
        "name": "Выращивание сельскохозяйственной птицы на мясо"
    },
    {
        "code": "01.47.12",
        "name": "Разведение племенной сельскохозяйственной птицы"
    },
    {
        "code": "01.47.2",
        "name": "Производство яиц сельскохозяйственной птицы"
    },
    {
        "code": "01.47.3",
        "name": "Деятельность инкубаторов для птицеводства"
    },
    {
        "code": "01.49",
        "name": "Разведение прочих животных"
    },
    {
        "code": "01.49.1",
        "name": "Пчеловодство"
    },
    {
        "code": "01.49.11",
        "name": "Пчеловодство медового направления"
    },
    {
        "code": "01.49.12",
        "name": "Пчеловодство опылительного направления"
    },
    {
        "code": "01.49.13",
        "name": "Пчеловодство разведенческого направления"
    },
    {
        "code": "01.49.2",
        "name": "Разведение кроликов и прочих пушных зверей на фермах"
    },
    {
        "code": "01.49.21",
        "name": "Разведение кроликов, производство тонкого волоса кроликов на фермах"
    },
    {
        "code": "01.49.22",
        "name": "Разведение прочих пушных зверей на фермах"
    },
    {
        "code": "01.49.3",
        "name": "Разведение шелкопряда"
    },
    {
        "code": "01.49.31",
        "name": "Производство грен шелкопряда"
    },
    {
        "code": "01.49.32",
        "name": "Производство коконов шелкопряда"
    },
    {
        "code": "01.49.4",
        "name": "Разведение оленей"
    },
    {
        "code": "01.49.41",
        "name": "Разведение домашних северных оленей"
    },
    {
        "code": "01.49.42",
        "name": "Разведение пятнистых оленей, ланей"
    },
    {
        "code": "01.49.43",
        "name": "Разведение благородных оленей (европейских, кавказских, маралов, изюбрей)"
    },
    {
        "code": "01.49.44",
        "name": "Производство пантов северных оленей, пятнистых оленей, благородных оленей (европейских, кавказских, маралов, изюбрей), ланей"
    },
    {
        "code": "01.49.5",
        "name": "Разведение домашних животных"
    },
    {
        "code": "01.49.6",
        "name": "Разведение лабораторных животных"
    },
    {
        "code": "01.49.7",
        "name": "Разведение дождевых червей"
    },
    {
        "code": "01.49.9",
        "name": "Разведение прочих животных, не включенных в другие группировки"
    },
    {
        "code": "01.5",
        "name": "Смешанное сельское хозяйство"
    },
    {
        "code": "01.50",
        "name": "Смешанное сельское хозяйство"
    },
    {
        "code": "01.6",
        "name": "Деятельность вспомогательная в области производства сельскохозяйственных культур и послеуборочной обработки сельхозпродукции"
    },
    {
        "code": "01.61",
        "name": "Предоставление услуг в области растениеводства"
    },
    {
        "code": "01.62",
        "name": "Предоставление услуг в области животноводства"
    },
    {
        "code": "01.63",
        "name": "Деятельность сельскохозяйственная после сбора урожая"
    },
    {
        "code": "01.64",
        "name": "Обработка семян для посадки"
    },
    {
        "code": "01.7",
        "name": "Охота, отлов и отстрел диких животных, включая предоставление услуг в этих областях"
    },
    {
        "code": "01.70",
        "name": "Охота, отлов и отстрел диких животных, включая предоставление услуг в этих областях"
    },
    {
        "code": "02",
        "name": "Лесоводство и лесозаготовки"
    },
    {
        "code": "02.1",
        "name": "Лесоводство и прочая лесохозяйственная деятельность"
    },
    {
        "code": "02.10",
        "name": "Лесоводство и прочая лесохозяйственная деятельность"
    },
    {
        "code": "02.10.1",
        "name": "Деятельность лесопитомников"
    },
    {
        "code": "02.10.11",
        "name": "Выращивание посадочного материала лесных растений (саженцев, сеянцев)"
    },
    {
        "code": "02.10.19",
        "name": "Выращивание прочей продукции лесопитомниками"
    },
    {
        "code": "02.10.2",
        "name": "Деятельность лесохозяйственная прочая"
    },
    {
        "code": "02.2",
        "name": "Лесозаготовки"
    },
    {
        "code": "02.20",
        "name": "Лесозаготовки"
    },
    {
        "code": "02.3",
        "name": "Сбор и заготовка пищевых лесных ресурсов, недревесных лесных ресурсов и лекарственных растений"
    },
    {
        "code": "02.30",
        "name": "Сбор и заготовка пищевых лесных ресурсов, недревесных лесных ресурсов и лекарственных растений"
    },
    {
        "code": "02.30.1",
        "name": "Сбор и заготовка пищевых лесных ресурсов"
    },
    {
        "code": "02.30.11",
        "name": "Сбор и заготовка дикорастущих грибов"
    },
    {
        "code": "02.30.12",
        "name": "Сбор и заготовка дикорастущих плодов, ягод"
    },
    {
        "code": "02.30.13",
        "name": "Сбор и заготовка дикорастущих орехов"
    },
    {
        "code": "02.30.14",
        "name": "Сбор лекарственных растений"
    },
    {
        "code": "02.30.2",
        "name": "Сбор и заготовка недревесных лесных ресурсов"
    },
    {
        "code": "02.4",
        "name": "Предоставление услуг в области лесоводства и лесозаготовок"
    },
    {
        "code": "02.40",
        "name": "Предоставление услуг в области лесоводства и лесозаготовок"
    },
    {
        "code": "02.40.1",
        "name": "Предоставление услуг в области лесоводства"
    },
    {
        "code": "02.40.2",
        "name": "Предоставление услуг в области лесозаготовок"
    },
    {
        "code": "03",
        "name": "Рыболовство и рыбоводство"
    },
    {
        "code": "03.1",
        "name": "Рыболовство"
    },
    {
        "code": "03.11",
        "name": "Рыболовство морское"
    },
    {
        "code": "03.11.1",
        "name": "Рыболовство морское промышленное"
    },
    {
        "code": "03.11.2",
        "name": "Рыболовство морское прибрежное"
    },
    {
        "code": "03.11.3",
        "name": "Рыболовство в научно-исследовательских и контрольных целях"
    },
    {
        "code": "03.11.4",
        "name": "Рыболовство в учебных и культурно-просветительских целях"
    },
    {
        "code": "03.11.5",
        "name": "Рыболовство морское в целях аквакультуры (рыбоводства)"
    },
    {
        "code": "03.12",
        "name": "Рыболовство пресноводное"
    },
    {
        "code": "03.12.1",
        "name": "Рыболовство пресноводное промышленное"
    },
    {
        "code": "03.12.2",
        "name": "Рыболовство пресноводное в целях аквакультуры (рыбоводства)"
    },
    {
        "code": "03.12.3",
        "name": "Рыболовство любительское и спортивное"
    },
    {
        "code": "03.12.4",
        "name": "Рыболовство в целях обеспечения ведения традиционного образа жизни и осуществления традиционной хозяйственной деятельности коренных малочисленных народов Севера, Сибири и Дальнего Востока Российской Федерации"
    },
    {
        "code": "03.2",
        "name": "Рыбоводство"
    },
    {
        "code": "03.21",
        "name": "Рыбоводство морское"
    },
    {
        "code": "03.21.1",
        "name": "Рыбоводство морское индустриальное"
    },
    {
        "code": "03.21.2",
        "name": "Рыбоводство морское пастбищное"
    },
    {
        "code": "03.21.3",
        "name": "Мелиорация рыбохозяйственная морских и минерализированных водных объектов"
    },
    {
        "code": "03.21.4",
        "name": "Воспроизводство морских биоресурсов искусственное"
    },
    {
        "code": "03.21.5",
        "name": "Акклиматизация морских биоресурсов"
    },
    {
        "code": "03.21.9",
        "name": "Деятельность по морскому рыбоводству прочая"
    },
    {
        "code": "03.22",
        "name": "Рыбоводство пресноводное"
    },
    {
        "code": "03.22.1",
        "name": "Рыбоводство пресноводное индустриальное"
    },
    {
        "code": "03.22.2",
        "name": "Рыбоводство пресноводное пастбищное"
    },
    {
        "code": "03.22.3",
        "name": "Рыбоводство прудовое"
    },
    {
        "code": "03.22.4",
        "name": "Мелиорация рыбохозяйственная пресноводных объектов"
    },
    {
        "code": "03.22.5",
        "name": "Воспроизводство пресноводных биоресурсов искусственное"
    },
    {
        "code": "03.22.6",
        "name": "Акклиматизация пресноводных биоресурсов"
    },
    {
        "code": "03.22.9",
        "name": "Деятельность по пресноводному рыбоводству прочая"
    },
    {
        "code": "05",
        "name": "Добыча угля"
    },
    {
        "code": "05.1",
        "name": "Добыча и обогащение угля и антрацита"
    },
    {
        "code": "05.10",
        "name": "Добыча и обогащение угля и антрацита"
    },
    {
        "code": "05.10.1",
        "name": "Добыча угля и антрацита"
    },
    {
        "code": "05.10.11",
        "name": "Добыча антрацита открытым способом"
    },
    {
        "code": "05.10.12",
        "name": "Добыча коксующегося угля открытым способом"
    },
    {
        "code": "05.10.13",
        "name": "Добыча угля, за исключением антрацита, угля коксующегося и угля бурого, открытым способом"
    },
    {
        "code": "05.10.14",
        "name": "Добыча антрацита подземным способом"
    },
    {
        "code": "05.10.15",
        "name": "Добыча коксующегося угля подземным способом"
    },
    {
        "code": "05.10.16",
        "name": "Добыча угля, за исключением антрацита, угля коксующегося и угля бурого, подземным способом"
    },
    {
        "code": "05.10.2",
        "name": "Обогащение угля"
    },
    {
        "code": "05.10.21",
        "name": "Обогащение антрацита"
    },
    {
        "code": "05.10.22",
        "name": "Обогащение коксующегося угля"
    },
    {
        "code": "05.10.23",
        "name": "Обогащение угля, кроме антрацита, угля коксующегося и угля бурого"
    },
    {
        "code": "05.2",
        "name": "Добыча и обогащение бурого угля (лигнита)"
    },
    {
        "code": "05.20",
        "name": "Добыча и обогащение бурого угля (лигнита)"
    },
    {
        "code": "05.20.1",
        "name": "Добыча бурого угля (лигнита)"
    },
    {
        "code": "05.20.11",
        "name": "Добыча бурого угля (лигнита) открытым способом"
    },
    {
        "code": "05.20.12",
        "name": "Добыча бурого угля (лигнита) подземным способом"
    },
    {
        "code": "05.20.2",
        "name": "Обогащение бурого угля (лигнита)"
    },
    {
        "code": "06",
        "name": "Добыча нефти и природного газа"
    },
    {
        "code": "06.1",
        "name": "Добыча нефти и нефтяного (попутного) газа"
    },
    {
        "code": "06.10",
        "name": "Добыча нефти и нефтяного (попутного) газа"
    },
    {
        "code": "06.10.1",
        "name": "Добыча нефти"
    },
    {
        "code": "06.10.2",
        "name": "Добыча горючих (битуминозных) сланцев, песка и озокерита"
    },
    {
        "code": "06.10.3",
        "name": "Добыча нефтяного (попутного) газа"
    },
    {
        "code": "06.2",
        "name": "Добыча природного газа и газового конденсата"
    },
    {
        "code": "06.20",
        "name": "Добыча природного газа и газового конденсата"
    },
    {
        "code": "06.20.1",
        "name": "Добыча природного газа"
    },
    {
        "code": "06.20.2",
        "name": "Добыча газового конденсата"
    },
    {
        "code": "07",
        "name": "Добыча металлических руд"
    },
    {
        "code": "07.1",
        "name": "Добыча и обогащение железных руд"
    },
    {
        "code": "07.10",
        "name": "Добыча и обогащение железных руд"
    },
    {
        "code": "07.10.1",
        "name": "Добыча железных руд подземным способом"
    },
    {
        "code": "07.10.2",
        "name": "Добыча железных руд открытым способом"
    },
    {
        "code": "07.10.3",
        "name": "Обогащение и агломерация железных руд"
    },
    {
        "code": "07.2",
        "name": "Добыча руд цветных металлов"
    },
    {
        "code": "07.21",
        "name": "Добыча урановой и ториевой руд"
    },
    {
        "code": "07.21.1",
        "name": "Добыча и первичное обогащение урановых руд"
    },
    {
        "code": "07.21.11",
        "name": "Добыча урановых руд подземным способом, включая способы подземного и кучного выщелачивания"
    },
    {
        "code": "07.21.12",
        "name": "Добыча урановых руд открытым способом, включая способ кучного выщелачивания"
    },
    {
        "code": "07.21.2",
        "name": "Добыча и первичное обогащение ториевых руд"
    },
    {
        "code": "07.29",
        "name": "Добыча руд прочих цветных металлов"
    },
    {
        "code": "07.29.1",
        "name": "Добыча и обогащение медной руды"
    },
    {
        "code": "07.29.2",
        "name": "Добыча и обогащение никелевой и кобальтовой руд"
    },
    {
        "code": "07.29.21",
        "name": "Добыча и обогащение никелевой руды"
    },
    {
        "code": "07.29.22",
        "name": "Добыча и обогащение кобальтовой руды"
    },
    {
        "code": "07.29.3",
        "name": "Добыча и обогащение алюминийсодержащего сырья (бокситов и нефелин-апатитовых руд)"
    },
    {
        "code": "07.29.31",
        "name": "Добыча алюминийсодержащего сырья подземным способом"
    },
    {
        "code": "07.29.32",
        "name": "Добыча алюминийсодержащего сырья открытым способом"
    },
    {
        "code": "07.29.33",
        "name": "Обогащение нефелин-апатитовых руд"
    },
    {
        "code": "07.29.4",
        "name": "Добыча руд и песков драгоценных металлов и руд редких металлов"
    },
    {
        "code": "07.29.41",
        "name": "Добыча руд и песков драгоценных металлов (золота, серебра и металлов платиновой группы)"
    },
    {
        "code": "07.29.42",
        "name": "Добыча и обогащение руд редких металлов (циркония, тантала, ниобия и т. п.)"
    },
    {
        "code": "07.29.5",
        "name": "Добыча и обогащение свинцово-цинковой руды"
    },
    {
        "code": "07.29.6",
        "name": "Добыча и обогащение оловянной руды"
    },
    {
        "code": "07.29.7",
        "name": "Добыча и обогащение титаномагниевого сырья"
    },
    {
        "code": "07.29.8",
        "name": "Добыча и обогащение вольфраммолибденовой руды"
    },
    {
        "code": "07.29.9",
        "name": "Добыча и обогащение руд прочих цветных металлов"
    },
    {
        "code": "07.29.91",
        "name": "Добыча и обогащение сурьмяно-ртутных руд"
    },
    {
        "code": "07.29.92",
        "name": "Добыча и обогащение марганцевых руд"
    },
    {
        "code": "07.29.93",
        "name": "Добыча и обогащение хромовых (хромитовых) руд"
    },
    {
        "code": "07.29.99",
        "name": "Добыча и обогащение руд прочих цветных металлов, не включенных в другие группировки"
    },
    {
        "code": "08",
        "name": "Добыча прочих полезных ископаемых"
    },
    {
        "code": "08.1",
        "name": "Добыча камня, песка и глины"
    },
    {
        "code": "08.11",
        "name": "Добыча декоративного и строительного камня, известняка, гипса, мела и сланцев"
    },
    {
        "code": "08.11.1",
        "name": "Добыча и первичная обработка камня для памятников и строительства"
    },
    {
        "code": "08.11.2",
        "name": "Добыча и первичная обработка известняка и гипсового камня"
    },
    {
        "code": "08.11.3",
        "name": "Добыча мела и некальцинированного доломита"
    },
    {
        "code": "08.11.4",
        "name": "Добыча и первичная обработка сланцев"
    },
    {
        "code": "08.12",
        "name": "Разработка гравийных и песчаных карьеров, добыча глины и каолина"
    },
    {
        "code": "08.12.1",
        "name": "Разработка гравийных и песчаных карьеров"
    },
    {
        "code": "08.12.2",
        "name": "Добыча глины и каолина"
    },
    {
        "code": "08.9",
        "name": "Добыча полезных ископаемых, не включенных в другие группировки"
    },
    {
        "code": "08.91",
        "name": "Добыча минерального сырья для химической промышленности и производства минеральных удобрений"
    },
    {
        "code": "08.92",
        "name": "Добыча и агломерация торфа"
    },
    {
        "code": "08.92.1",
        "name": "Добыча торфа"
    },
    {
        "code": "08.92.2",
        "name": "Агломерация торфа"
    },
    {
        "code": "08.93",
        "name": "Добыча соли"
    },
    {
        "code": "08.99",
        "name": "Добыча прочих полезных ископаемых, не включенных в другие группировки"
    },
    {
        "code": "08.99.1",
        "name": "Добыча природного асфальта, асфальтитов и битумных пород"
    },
    {
        "code": "08.99.2",
        "name": "Добыча абразивных материалов, асбеста, кремнеземистой каменной муки, природных графитов, мыльного камня (талька), полевого шпата и т. д."
    },
    {
        "code": "08.99.21",
        "name": "Добыча природных абразивов, кроме алмазов"
    },
    {
        "code": "08.99.22",
        "name": "Добыча вермикулита"
    },
    {
        "code": "08.99.23",
        "name": "Добыча асбеста хризотилового"
    },
    {
        "code": "08.99.3",
        "name": "Добыча драгоценных камней, кварца, слюды, мусковита и т. д."
    },
    {
        "code": "08.99.31",
        "name": "Добыча драгоценных и полудрагоценных камней, кроме алмазов"
    },
    {
        "code": "08.99.32",
        "name": "Добыча алмазов"
    },
    {
        "code": "08.99.33",
        "name": "Добыча мусковита"
    },
    {
        "code": "08.99.34",
        "name": "Добыча пьезокварца"
    },
    {
        "code": "08.99.35",
        "name": "Добыча гранулированного кварца"
    },
    {
        "code": "08.99.36",
        "name": "Добыча слюды"
    },
    {
        "code": "09",
        "name": "Предоставление услуг в области добычи полезных ископаемых"
    },
    {
        "code": "09.1",
        "name": "Предоставление услуг в области добычи нефти и природного газа"
    },
    {
        "code": "09.10",
        "name": "Предоставление услуг в области добычи нефти и природного газа"
    },
    {
        "code": "09.10.1",
        "name": "Предоставление услуг по бурению, связанному с добычей нефти, газа и газового конденсата"
    },
    {
        "code": "09.10.2",
        "name": "Предоставление услуг по монтажу, ремонту и демонтажу буровых вышек"
    },
    {
        "code": "09.10.3",
        "name": "Предоставление услуг по доразведке месторождений нефти и газа на особых экономических условиях (по соглашению о разделе продукции - СРП)"
    },
    {
        "code": "09.10.4",
        "name": "Сжижение и обогащение природного газа на месте добычи для последующей транспортировки"
    },
    {
        "code": "09.10.9",
        "name": "Предоставление прочих услуг в области добычи нефти и природного газа"
    },
    {
        "code": "09.9",
        "name": "Предоставление услуг в других областях добычи полезных ископаемых"
    },
    {
        "code": "09.90",
        "name": "Предоставление услуг в других областях добычи полезных ископаемых"
    },
    {
        "code": "10",
        "name": "Производство пищевых продуктов"
    },
    {
        "code": "10.1",
        "name": "Переработка и консервирование мяса и мясной пищевой продукции"
    },
    {
        "code": "10.11",
        "name": "Переработка и консервирование мяса"
    },
    {
        "code": "10.11.1",
        "name": "Производство мяса в охлажденном виде"
    },
    {
        "code": "10.11.2",
        "name": "Производство пищевых субпродуктов в охлажденном виде"
    },
    {
        "code": "10.11.3",
        "name": "Производство мяса и пищевых субпродуктов в замороженном виде"
    },
    {
        "code": "10.11.4",
        "name": "Производство щипаной шерсти, сырых шкур и кож крупного рогатого скота, животных семейств лошадиных и оленевых, овец и коз"
    },
    {
        "code": "10.11.5",
        "name": "Производство животных жиров"
    },
    {
        "code": "10.11.6",
        "name": "Производство субпродуктов, непригодных для употребления в пищу"
    },
    {
        "code": "10.12",
        "name": "Производство и консервирование мяса птицы"
    },
    {
        "code": "10.12.1",
        "name": "Производство мяса птицы в охлажденном виде"
    },
    {
        "code": "10.12.2",
        "name": "Производство мяса птицы в замороженном виде"
    },
    {
        "code": "10.12.3",
        "name": "Производство жиров домашней птицы"
    },
    {
        "code": "10.12.4",
        "name": "Производство субпродуктов домашней птицы, пригодных для употребления в пищу"
    },
    {
        "code": "10.12.5",
        "name": "Производство пера и пуха"
    },
    {
        "code": "10.13",
        "name": "Производство продукции из мяса убойных животных и мяса птицы"
    },
    {
        "code": "10.13.1",
        "name": "Производство соленого, вареного, запеченого, копченого, вяленого и прочего мяса"
    },
    {
        "code": "10.13.2",
        "name": "Производство колбасных изделий"
    },
    {
        "code": "10.13.3",
        "name": "Производство мясных (мясосодержащих) консервов"
    },
    {
        "code": "10.13.4",
        "name": "Производство мясных (мясосодержащих) полуфабрикатов"
    },
    {
        "code": "10.13.5",
        "name": "Производство кулинарных мясных (мясосодержащих) изделий"
    },
    {
        "code": "10.13.6",
        "name": "Производство прочей пищевой продукции из мяса или мясных пищевых субпродуктов"
    },
    {
        "code": "10.13.7",
        "name": "Производство муки и гранул из мяса и мясных субпродуктов, непригодных для употребления в пищу"
    },
    {
        "code": "10.13.9",
        "name": "Предоставление услуг по тепловой обработке и прочим способам переработки мясных продуктов"
    },
    {
        "code": "10.2",
        "name": "Переработка и консервирование рыбы, ракообразных и моллюсков"
    },
    {
        "code": "10.20",
        "name": "Переработка и консервирование рыбы, ракообразных и моллюсков"
    },
    {
        "code": "10.20.1",
        "name": "Переработка и консервирование рыбы"
    },
    {
        "code": "10.20.2",
        "name": "Переработка и консервирование ракообразных и моллюсков"
    },
    {
        "code": "10.20.3",
        "name": "Производство пищевой рыбной муки или муки для корма животных"
    },
    {
        "code": "10.20.4",
        "name": "Производство муки грубого помола и растворимых компонентов из рыбы и прочих водных животных, непригодных для потребления человеком"
    },
    {
        "code": "10.20.5",
        "name": "Деятельность по обработке морских водорослей, в том числе морской капусты"
    },
    {
        "code": "10.20.9",
        "name": "Производство прочих продуктов из рыбы, ракообразных, моллюсков и прочих водных беспозвоночных, непригодных для употребления в пищу"
    },
    {
        "code": "10.3",
        "name": "Переработка и консервирование фруктов и овощей"
    },
    {
        "code": "10.31",
        "name": "Переработка и консервирование картофеля"
    },
    {
        "code": "10.32",
        "name": "Производство соковой продукции из фруктов и овощей"
    },
    {
        "code": "10.39",
        "name": "Прочие виды переработки и консервирования фруктов и овощей"
    },
    {
        "code": "10.39.1",
        "name": "Переработка и консервирование овощей (кроме картофеля) и грибов"
    },
    {
        "code": "10.39.2",
        "name": "Переработка и консервирование фруктов и орехов"
    },
    {
        "code": "10.39.9",
        "name": "Предоставление услуг по тепловой обработке и прочим способам подготовки овощей и фруктов для консервирования"
    },
    {
        "code": "10.4",
        "name": "Производство растительных и животных масел и жиров"
    },
    {
        "code": "10.41",
        "name": "Производство масел и жиров"
    },
    {
        "code": "10.41.1",
        "name": "Производство нерафинированных животных масел и жиров, их фракций"
    },
    {
        "code": "10.41.2",
        "name": "Производство нерафинированных растительных масел и их фракций"
    },
    {
        "code": "10.41.21",
        "name": "Производство нерафинированного соевого масла и его фракций"
    },
    {
        "code": "10.41.22",
        "name": "Производство нерафинированного арахисового масла и его фракций"
    },
    {
        "code": "10.41.23",
        "name": "Производство нерафинированного оливкового масла и его фракций"
    },
    {
        "code": "10.41.24",
        "name": "Производство нерафинированного подсолнечного масла и его фракций"
    },
    {
        "code": "10.41.25",
        "name": "Производство нерафинированного хлопкового масла и его фракций"
    },
    {
        "code": "10.41.26",
        "name": "Производство нерафинированного рапсового сурепного и горчичного масла и их фракций"
    },
    {
        "code": "10.41.27",
        "name": "Производство нерафинированного пальмового масла и его фракций"
    },
    {
        "code": "10.41.28",
        "name": "Производство нерафинированного кокосового масла и его фракций"
    },
    {
        "code": "10.41.29",
        "name": "Производство прочих нерафинированных растительных масел и их фракций"
    },
    {
        "code": "10.41.3",
        "name": "Производство хлопкового линта"
    },
    {
        "code": "10.41.4",
        "name": "Производство жмыха и муки тонкого и грубого помола из семян или плодов масличных культур"
    },
    {
        "code": "10.41.5",
        "name": "Производство рафинированных растительных масел и их фракций"
    },
    {
        "code": "10.41.51",
        "name": "Производство рафинированного соевого масла и его фракций"
    },
    {
        "code": "10.41.52",
        "name": "Производство рафинированного арахисового масла и его фракций"
    },
    {
        "code": "10.41.53",
        "name": "Производство рафинированного оливкового масла и его фракций"
    },
    {
        "code": "10.41.54",
        "name": "Производство рафинированного подсолнечного масла и его фракций"
    },
    {
        "code": "10.41.55",
        "name": "Производство рафинированного хлопкового масла и его фракций"
    },
    {
        "code": "10.41.56",
        "name": "Производство рафинированного рапсового, сурепного, горчичного масел и их фракций"
    },
    {
        "code": "10.41.57",
        "name": "Производство рафинированного пальмового масла и его фракций"
    },
    {
        "code": "10.41.58",
        "name": "Производство рафинированного кокосового масла и его фракций"
    },
    {
        "code": "10.41.59",
        "name": "Производство прочих рафинированных растительных масел и их фракций"
    },
    {
        "code": "10.41.6",
        "name": "Производство гидрогенизированных и переэтерифицированных животных и растительных жиров и масел и их фракций"
    },
    {
        "code": "10.41.7",
        "name": "Производство растительных восков и дегры"
    },
    {
        "code": "10.42",
        "name": "Производство маргариновой продукции"
    },
    {
        "code": "10.5",
        "name": "Производство молочной продукции"
    },
    {
        "code": "10.51",
        "name": "Производство молока (кроме сырого) и молочной продукции"
    },
    {
        "code": "10.51.1",
        "name": "Производство питьевого молока и питьевых сливок"
    },
    {
        "code": "10.51.2",
        "name": "Производство сливочного масла, топленого масла, масляной пасты, молочного жира, спредов и топленых сливочно-растительных смесей"
    },
    {
        "code": "10.51.3",
        "name": "Производство сыра и сырных продуктов"
    },
    {
        "code": "10.51.4",
        "name": "Производство молока и сливок в твердой форме"
    },
    {
        "code": "10.51.9",
        "name": "Производство прочей молочной продукции"
    },
    {
        "code": "10.52",
        "name": "Производство мороженого"
    },
    {
        "code": "10.6",
        "name": "Производство продуктов мукомольной и крупяной промышленности, крахмала и крахмалосодержащих продуктов"
    },
    {
        "code": "10.61",
        "name": "Производство продуктов мукомольной и крупяной промышленности"
    },
    {
        "code": "10.61.1",
        "name": "Производство обработанного риса"
    },
    {
        "code": "10.61.2",
        "name": "Производство муки из зерновых культур"
    },
    {
        "code": "10.61.3",
        "name": "Производство крупы и гранул из зерновых культур"
    },
    {
        "code": "10.61.4",
        "name": "Производство мучных смесей и приготовление мучных смесей или теста для хлеба, тортов, бисквитов и блинов"
    },
    {
        "code": "10.62",
        "name": "Производство крахмала и крахмалосодержащих продуктов"
    },
    {
        "code": "10.62.1",
        "name": "Производство крахмала"
    },
    {
        "code": "10.62.2",
        "name": "Производство нерафинированного кукурузного масла и его фракций"
    },
    {
        "code": "10.62.3",
        "name": "Производство рафинированного кукурузного масла и его фракций"
    },
    {
        "code": "10.62.9",
        "name": "Производство прочих крахмалосодержащих продуктов"
    },
    {
        "code": "10.7",
        "name": "Производство хлебобулочных и мучных кондитерских изделий"
    },
    {
        "code": "10.71",
        "name": "Производство хлеба и мучных кондитерских изделий, тортов и пирожных недлительного хранения"
    },
    {
        "code": "10.71.1",
        "name": "Производство хлеба и хлебобулочных изделий недлительного хранения"
    },
    {
        "code": "10.71.2",
        "name": "Производство мучных кондитерских изделий, тортов и пирожных недлительного хранения"
    },
    {
        "code": "10.71.3",
        "name": "Производство охлажденных хлебобулочных полуфабрикатов"
    },
    {
        "code": "10.72",
        "name": "Производство сухарей, печенья и прочих сухарных хлебобулочных изделий, производство мучных кондитерских изделий, тортов, пирожных, пирогов и бисквитов, предназначенных для длительного хранения"
    },
    {
        "code": "10.72.1",
        "name": "Производство хрустящих хлебцев, сухарей и прочих сухарных хлебобулочных изделий"
    },
    {
        "code": "10.72.2",
        "name": "Производство тортов и пирожных длительного хранения"
    },
    {
        "code": "10.72.3",
        "name": "Производство прочих мучных кондитерских изделий длительного хранения"
    },
    {
        "code": "10.72.31",
        "name": "Производство печенья"
    },
    {
        "code": "10.72.32",
        "name": "Производство пряников и коврижек"
    },
    {
        "code": "10.72.33",
        "name": "Производство вафель"
    },
    {
        "code": "10.72.34",
        "name": "Производство галет и крекеров"
    },
    {
        "code": "10.72.35",
        "name": "Производство кексов, рулетов и аналогичных изделий длительного хранения"
    },
    {
        "code": "10.72.39",
        "name": "Производство восточных сладостей и прочих мучных кондитерских изделий"
    },
    {
        "code": "10.72.4",
        "name": "Производство замороженных хлебобулочных полуфабрикатов"
    },
    {
        "code": "10.73",
        "name": "Производство макаронных изделий кускуса и аналогичных мучных изделий"
    },
    {
        "code": "10.73.1",
        "name": "Производство макаронных изделий"
    },
    {
        "code": "10.73.2",
        "name": "Производство кускуса"
    },
    {
        "code": "10.73.3",
        "name": "Производство консервированных или замороженных макаронных изделий"
    },
    {
        "code": "10.8",
        "name": "Производство прочих пищевых продуктов"
    },
    {
        "code": "10.81",
        "name": "Производство сахара"
    },
    {
        "code": "10.81.1",
        "name": "Производство сахара из сахарной свеклы и тростникового сырца"
    },
    {
        "code": "10.81.11",
        "name": "Производство сахара из сахарной свеклы"
    },
    {
        "code": "10.81.12",
        "name": "Производство сахара из тростникового сырца"
    },
    {
        "code": "10.81.2",
        "name": "Производство сахарного сиропа"
    },
    {
        "code": "10.81.3",
        "name": "Производство мелассы"
    },
    {
        "code": "10.82",
        "name": "Производство какао, шоколада и сахаристых кондитерских изделий"
    },
    {
        "code": "10.82.1",
        "name": "Производство какао, масла какао, жира какао, растительного масла какао, порошка какао"
    },
    {
        "code": "10.82.2",
        "name": "Производство шоколада и сахаристых кондитерских изделий"
    },
    {
        "code": "10.82.3",
        "name": "Производство кондитерских изделий из сахара"
    },
    {
        "code": "10.82.4",
        "name": "Производство жевательной резинки"
    },
    {
        "code": "10.82.5",
        "name": "Производство засахаренных фруктов, орехов, цукатов из кожуры и прочих частей растений"
    },
    {
        "code": "10.82.6",
        "name": "Производство кондитерских леденцов и пастилок"
    },
    {
        "code": "10.83",
        "name": "Производство чая и кофе"
    },
    {
        "code": "10.84",
        "name": "Производство приправ и пряностей"
    },
    {
        "code": "10.85",
        "name": "Производство готовых пищевых продуктов и блюд"
    },
    {
        "code": "10.86",
        "name": "Производство детского питания и диетических пищевых продуктов"
    },
    {
        "code": "10.86.1",
        "name": "Производство молока и молочных продуктов для детского питания"
    },
    {
        "code": "10.86.11",
        "name": "Производство молока и молочных продуктов для детей раннего возраста"
    },
    {
        "code": "10.86.12",
        "name": "Производство молока и молочных продуктов для детей дошкольного и школьного возраста"
    },
    {
        "code": "10.86.2",
        "name": "Производство соковой продукции из фруктов и овощей для детского питания"
    },
    {
        "code": "10.86.3",
        "name": "Производство мясной продукции для детского питания, в том числе из мяса птицы"
    },
    {
        "code": "10.86.4",
        "name": "Производство продуктов на злаковой основе для детского питания"
    },
    {
        "code": "10.86.5",
        "name": "Производство продуктов детского питания профилактического и лечебного назначения"
    },
    {
        "code": "10.86.6",
        "name": "Производство специализированной пищевой продукции, в том числе диетических пищевых продуктов"
    },
    {
        "code": "10.86.61",
        "name": "Производство пищевой продукции диетического и диабетического питания"
    },
    {
        "code": "10.86.62",
        "name": "Производство пищевой продукции для питания спортсменов"
    },
    {
        "code": "10.86.63",
        "name": "Производство пищевой продукции для питания беременных и кормящих женщин"
    },
    {
        "code": "10.86.64",
        "name": "Производство пищевой продукции энтерального питания"
    },
    {
        "code": "10.86.69",
        "name": "Производство прочих диетических пищевых продуктов"
    },
    {
        "code": "10.86.7",
        "name": "Производство воды питьевой, напитков безалкогольных для детского питания"
    },
    {
        "code": "10.86.8",
        "name": "Производство рыбной продукции для детского питания"
    },
    {
        "code": "10.86.9",
        "name": "Производство хлебобулочных и кондитерских изделий для детского питания"
    },
    {
        "code": "10.89",
        "name": "Производство прочих пищевых продуктов, не включенных в другие группировки"
    },
    {
        "code": "10.89.1",
        "name": "Производство супов и бульонов"
    },
    {
        "code": "10.89.2",
        "name": "Производство скоропортящихся продуктов, таких как: сэндвичи и свежая пицца (полуфабрикат)"
    },
    {
        "code": "10.89.3",
        "name": "Производство растительных соков и экстрактов, пептических веществ, растительных клеев и загустителей"
    },
    {
        "code": "10.89.4",
        "name": "Производство пищевых ферментов"
    },
    {
        "code": "10.89.5",
        "name": "Производство искусственного меда и карамели"
    },
    {
        "code": "10.89.6",
        "name": "Переработка меда (темперирование, фильтрация, декристаллизация и смешивание меда)"
    },
    {
        "code": "10.89.7",
        "name": "Производство рационов питания и пайков"
    },
    {
        "code": "10.89.8",
        "name": "Производство биологически активных добавок к пище"
    },
    {
        "code": "10.89.9",
        "name": "Производство прочих продуктов питания, не включенных в другие группировки"
    },
    {
        "code": "10.9",
        "name": "Производство готовых кормов для животных"
    },
    {
        "code": "10.91",
        "name": "Производство готовых кормов для животных, содержащихся на фермах"
    },
    {
        "code": "10.91.1",
        "name": "Производство готовых кормов (смешанных и несмешанных), кроме муки и гранул из люцерны, для животных, содержащихся на фермах"
    },
    {
        "code": "10.91.2",
        "name": "Производство муки грубого помола и гранул из люцерны"
    },
    {
        "code": "10.91.3",
        "name": "Производство кормового микробиологического белка, премиксов, кормовых витаминов, антибиотиков, аминокислот и ферментов"
    },
    {
        "code": "10.92",
        "name": "Производство готовых кормов для непродуктивных животных"
    },
    {
        "code": "11",
        "name": "Производство напитков"
    },
    {
        "code": "11.0",
        "name": "Производство напитков"
    },
    {
        "code": "11.01",
        "name": "Перегонка, очистка и смешивание спиртов"
    },
    {
        "code": "11.01.1",
        "name": "Производство дистиллированных питьевых алкогольных напитков: водки, виски, бренди, джина, ликеров и т. п."
    },
    {
        "code": "11.01.2",
        "name": "Производство напитков, смешанных с дистиллированными алкогольными напитками"
    },
    {
        "code": "11.01.3",
        "name": "Смешивание дистиллированных спиртов"
    },
    {
        "code": "11.01.4",
        "name": "Производство пищевого спирта"
    },
    {
        "code": "11.02",
        "name": "Производство вина из винограда"
    },
    {
        "code": "11.03",
        "name": "Производство сидра и прочих плодовых вин"
    },
    {
        "code": "11.04",
        "name": "Производство прочих недистиллированных напитков из сброженных материалов"
    },
    {
        "code": "11.05",
        "name": "Производство пива"
    },
    {
        "code": "11.06",
        "name": "Производство солода"
    },
    {
        "code": "11.07",
        "name": "Производство безалкогольных напитков; производство упакованных питьевых вод, включая минеральные воды. Эта группировка не включает: - производство воды питьевой, напитков безалкогольных для детского питания, см. 10.86.7"
    },
    {
        "code": "11.07.1",
        "name": "Производство упакованных питьевых вод, включая минеральные воды"
    },
    {
        "code": "11.07.11",
        "name": "Производство упакованных природных минеральных вод"
    },
    {
        "code": "11.07.12",
        "name": "Производство упакованных природных питьевых вод"
    },
    {
        "code": "11.07.13",
        "name": "Производство упакованных обработанных питьевых вод"
    },
    {
        "code": "11.07.14",
        "name": "Производство упакованных купажированных питьевых вод"
    },
    {
        "code": "11.07.15",
        "name": "Производство упакованных искусственно минерализованных питьевых вод"
    },
    {
        "code": "11.07.2",
        "name": "Производство безалкогольных напитков ароматизированных и/или с добавлением сахара, кроме минеральных вод"
    },
    {
        "code": "12",
        "name": "Производство табачных изделий"
    },
    {
        "code": "12.0",
        "name": "Производство табачных изделий"
    },
    {
        "code": "12.00",
        "name": "Производство табачных изделий"
    },
    {
        "code": "12.00.1",
        "name": "Производство изделий из табака и махорки: сигарет, папирос, сигар, сигарилл, курительного тонкорезаного табака, трубочного табака, жевательного табака, сосательного табака, нюхательного табака, табака для кальяна, курительной и нюхательной махорки"
    },
    {
        "code": "12.00.2",
        "name": "Производство гомогенизированного или восстановленного табака"
    },
    {
        "code": "12.00.3",
        "name": "Стрипсование (удаление главной жилки) и редраинг-обработка табака"
    },
    {
        "code": "13",
        "name": "Производство текстильных изделий"
    },
    {
        "code": "13.1",
        "name": "Подготовка и прядение текстильных волокон"
    },
    {
        "code": "13.10",
        "name": "Подготовка и прядение текстильных волокон"
    },
    {
        "code": "13.10.1",
        "name": "Прядение хлопчатобумажных волокон"
    },
    {
        "code": "13.10.2",
        "name": "Прядение кардное шерстяных волокон"
    },
    {
        "code": "13.10.3",
        "name": "Прядение гребенное шерстяных волокон"
    },
    {
        "code": "13.10.4",
        "name": "Прядение льняных волокон"
    },
    {
        "code": "13.10.5",
        "name": "Изготовление натуральных шелковых, искусственных и синтетических волокон"
    },
    {
        "code": "13.10.6",
        "name": "Производство швейных ниток"
    },
    {
        "code": "13.10.9",
        "name": "Подготовка и прядение прочих текстильных волокон"
    },
    {
        "code": "13.2",
        "name": "Производство текстильных тканей"
    },
    {
        "code": "13.20",
        "name": "Производство текстильных тканей"
    },
    {
        "code": "13.20.1",
        "name": "Производство тканей (без специальных тканей) из натуральных волокон, кроме хлопка"
    },
    {
        "code": "13.20.11",
        "name": "Производство шелковых тканей"
    },
    {
        "code": "13.20.12",
        "name": "Производство шерстяных тканей"
    },
    {
        "code": "13.20.13",
        "name": "Производство льняных тканей"
    },
    {
        "code": "13.20.14",
        "name": "Производство тканей из джутовых и прочих лубяных текстильных волокон"
    },
    {
        "code": "13.20.19",
        "name": "Производство ткани из прочих растительных текстильных волокон; ткани из бумажной пряжи"
    },
    {
        "code": "13.20.2",
        "name": "Производство хлопчатобумажных тканей"
    },
    {
        "code": "13.20.3",
        "name": "Производство тканей, за исключением специальных тканей, из химических комплексных нитей и штапельных волокон"
    },
    {
        "code": "13.20.4",
        "name": "Производство ворсовых, махровых полотенечных тканей и прочих специальных тканей"
    },
    {
        "code": "13.20.41",
        "name": "Производство ворсовых тканей и ткани из синели"
    },
    {
        "code": "13.20.42",
        "name": "Производство хлопчатобумажных махровых полотенечных тканей"
    },
    {
        "code": "13.20.43",
        "name": "Производство прочих махровых полотенечных и аналогичных махровых тканей"
    },
    {
        "code": "13.20.44",
        "name": "Производство марли"
    },
    {
        "code": "13.20.45",
        "name": "Производство ворсовых тканей"
    },
    {
        "code": "13.20.46",
        "name": "Производство тканей из стекловолокна"
    },
    {
        "code": "13.20.5",
        "name": "Производство искусственного меха ткацким способом"
    },
    {
        "code": "13.20.6",
        "name": "Производство арамидных нитей и волокна"
    },
    {
        "code": "13.3",
        "name": "Отделка тканей и текстильных изделий"
    },
    {
        "code": "13.30",
        "name": "Отделка тканей и текстильных изделий"
    },
    {
        "code": "13.30.1",
        "name": "Отбеливание и окрашивание текстиля, волокон, тканей и текстильных изделий, включая готовую одежду"
    },
    {
        "code": "13.30.2",
        "name": "Аппретирование, сушка, обработка паром, декатировка, противоусадочная отделка, смягчение тканей и текстильных изделий, включая готовую одежду"
    },
    {
        "code": "13.30.3",
        "name": "Плиссировка и подобные работы на текстильных материалах"
    },
    {
        "code": "13.30.4",
        "name": "Нанесение водозащитного слоя, специальных покрытий, прорезинивание, пропитка приобретенной одежды"
    },
    {
        "code": "13.30.5",
        "name": "Нанесение рисунка на текстильные изделия и готовую одежду"
    },
    {
        "code": "13.9",
        "name": "Производство прочих текстильных изделий"
    },
    {
        "code": "13.91",
        "name": "Производство трикотажного и вязаного полотна"
    },
    {
        "code": "13.91.1",
        "name": "Производство и обработка трикотажного или вязаного полотна"
    },
    {
        "code": "13.91.2",
        "name": "Производство искусственного меха методом вязания"
    },
    {
        "code": "13.92",
        "name": "Производство готовых текстильных изделий, кроме одежды"
    },
    {
        "code": "13.92.1",
        "name": "Производство готовых текстильных изделий, кроме одежды"
    },
    {
        "code": "13.92.2",
        "name": "Пошив готовых текстильных изделий по индивидуальному заказу населения, кроме одежды"
    },
    {
        "code": "13.93",
        "name": "Производство ковров и ковровых изделий"
    },
    {
        "code": "13.94",
        "name": "Производство канатов, веревок, шпагата и сетей"
    },
    {
        "code": "13.94.1",
        "name": "Производство шпагата, канатов, веревок и тросов из текстильных волокон и лент, с пропиткой и без пропитки, с покрытием, защищенных или не защищенных оболочкой из резины или пластмассы"
    },
    {
        "code": "13.94.2",
        "name": "Производство изделий из веревки и сетного полотна: рыболовных сетей, предохранительных сеток на судах, защитных средств, используемых при погрузочно-разгрузочных работах, стропов, веревок или тросов с металлическими кольцами"
    },
    {
        "code": "13.95",
        "name": "Производство нетканых текстильных материалов и изделий из них, кроме одежды"
    },
    {
        "code": "13.96",
        "name": "Производство прочих технических и промышленных текстильных изделий"
    },
    {
        "code": "13.96.1",
        "name": "Производство металлизированной пряжи или металлизированной позументной тесьмы"
    },
    {
        "code": "13.96.2",
        "name": "Производство ткани из металлической нити и ткани из металлизированной пряжи"
    },
    {
        "code": "13.96.3",
        "name": "Производство резиновых нитей и шнуров с текстильным покрытием; производство текстильных нитей и лент, пропитанных или с пластмассовым или резиновым покрытием"
    },
    {
        "code": "13.96.4",
        "name": "Производство текстильных материалов, пропитанных или с покрытием"
    },
    {
        "code": "13.96.5",
        "name": "Производство кордных тканей"
    },
    {
        "code": "13.96.6",
        "name": "Производство текстильных материалов и изделий технического назначения (включая фитили, калильные сетки газовых фонарей, текстильные шланги, конвейерные ленты и приводные ремни, ситовые ткани и фильтровальные ткани)"
    },
    {
        "code": "13.96.7",
        "name": "Производство узких текстильных тканей, в том числе состоящих из основы безуточного переплетения и соединяемых клеящим веществом"
    },
    {
        "code": "13.99",
        "name": "Производство прочих текстильных изделий, не включенных в другие группировки"
    },
    {
        "code": "13.99.1",
        "name": "Производство кружевного сетчатого и гардинно-тюлевого полотна, а также кружев и вышитых изделий, в кусках, в форме полос или отдельных вышивок"
    },
    {
        "code": "13.99.2",
        "name": "Производство фетра и войлока"
    },
    {
        "code": "13.99.3",
        "name": "Производство ваты из текстильных материалов"
    },
    {
        "code": "13.99.4",
        "name": "Изготовление прочих текстильных изделий по индивидуальному заказу населения, не включенных в другие группировки"
    },
    {
        "code": "13.99.9",
        "name": "Производство текстильных изделий различного назначения, не включенных в другие группировки"
    },
    {
        "code": "14",
        "name": "Производство одежды"
    },
    {
        "code": "14.1",
        "name": "Производство одежды, кроме одежды из меха"
    },
    {
        "code": "14.11",
        "name": "Производство одежды из кожи"
    },
    {
        "code": "14.11.1",
        "name": "Производство одежды из кожи, кроме изготовленных по индивидуальному заказу"
    },
    {
        "code": "14.11.2",
        "name": "Пошив одежды из кожи по индивидуальному заказу населения"
    },
    {
        "code": "14.12",
        "name": "Производство спецодежды"
    },
    {
        "code": "14.12.1",
        "name": "Производство спецодежды, кроме изготовленных по индивидуальному заказу"
    },
    {
        "code": "14.12.2",
        "name": "Пошив производственной одежды по индивидуальному заказу населения"
    },
    {
        "code": "14.13",
        "name": "Производство прочей верхней одежды"
    },
    {
        "code": "14.13.1",
        "name": "Производство верхней трикотажной или вязаной одежды"
    },
    {
        "code": "14.13.11",
        "name": "Производство верхней трикотажной или вязаной одежды для мужчин или мальчиков"
    },
    {
        "code": "14.13.12",
        "name": "Производство верхней трикотажной или вязаной одежды для женщин или девочек"
    },
    {
        "code": "14.13.2",
        "name": "Производство верхней одежды из текстильных материалов, кроме трикотажных или вязаных"
    },
    {
        "code": "14.13.21",
        "name": "Производство верхней одежды из текстильных материалов, кроме трикотажных или вязаных, для мужчин или мальчиков"
    },
    {
        "code": "14.13.22",
        "name": "Производство верхней одежды из текстильных материалов, кроме трикотажных или вязаных, для женщин или девочек"
    },
    {
        "code": "14.13.3",
        "name": "Пошив и вязание прочей верхней одежды по индивидуальному заказу населения"
    },
    {
        "code": "14.14",
        "name": "Производство нательного белья"
    },
    {
        "code": "14.14.1",
        "name": "Производство трикотажного и вязаного нательного белья"
    },
    {
        "code": "14.14.11",
        "name": "Производство трикотажных или вязаных рубашек для мужчин или для мальчиков"
    },
    {
        "code": "14.14.12",
        "name": "Производство маек и прочего трикотажного или вязаного нательного белья для мужчин или мальчиков"
    },
    {
        "code": "14.14.13",
        "name": "Производство трикотажных или вязаных блузок, рубашек и батников для женщин или для девочек"
    },
    {
        "code": "14.14.14",
        "name": "Производство маек и прочего трикотажного или вязаного нательного белья для женщин или для девочек"
    },
    {
        "code": "14.14.2",
        "name": "Производство нательного белья из тканей"
    },
    {
        "code": "14.14.21",
        "name": "Производство рубашек из текстильных материалов, кроме трикотажных или вязаных, для мужчин или для мальчиков"
    },
    {
        "code": "14.14.22",
        "name": "Производство маек и прочего нательного белья из текстильных материалов, кроме трикотажных или вязаных, для мужчин или для мальчиков"
    },
    {
        "code": "14.14.23",
        "name": "Производство блузок, рубашек и батников из текстильных материалов, кроме трикотажных или вязаных, для женщин или для девочек"
    },
    {
        "code": "14.14.24",
        "name": "Производство маек и прочего нательного белья из текстильных материалов, кроме трикотажных или вязаных, для женщин или для девочек"
    },
    {
        "code": "14.14.25",
        "name": "Производство бюстгальтеров, поясов, корсетов и аналогичных изделий, и их частей из любого текстильного материала, включая трикотажные или вязаные"
    },
    {
        "code": "14.14.3",
        "name": "Производство трикотажных или вязаных футболок, маек и прочих нижних рубашек"
    },
    {
        "code": "14.14.4",
        "name": "Пошив нательного белья по индивидуальному заказу населения"
    },
    {
        "code": "14.19",
        "name": "Производство прочей одежды и аксессуаров одежды"
    },
    {
        "code": "14.19.1",
        "name": "Производство трикотажной или вязаной одежды для детей младшего возраста, спортивной или прочей одежды, аксессуаров и деталей одежды"
    },
    {
        "code": "14.19.11",
        "name": "Производство трикотажных или вязаных одежды и аксессуаров одежды для детей младшего возраста"
    },
    {
        "code": "14.19.12",
        "name": "Производство спортивных костюмов, лыжных костюмов, купальных костюмов и прочей трикотажной или вязаной одежды"
    },
    {
        "code": "14.19.13",
        "name": "Производство трикотажных или вязаных перчаток, рукавиц (варежек) и митенок"
    },
    {
        "code": "14.19.19",
        "name": "Производство прочих трикотажных или вязаных аксессуаров одежды, в том числе платков, шарфов, галстуков и прочих аналогичных изделий"
    },
    {
        "code": "14.19.2",
        "name": "Производство одежды для детей младшего возраста, спортивной или прочей одежды и аксессуаров одежды из текстильных материалов, кроме трикотажных или вязаных"
    },
    {
        "code": "14.19.21",
        "name": "Производство одежды и аксессуаров одежды для детей младшего возраста из текстильных материалов, кроме трикотажных или вязаных"
    },
    {
        "code": "14.19.22",
        "name": "Производство спортивных костюмов, лыжных костюмов, купальных костюмов и прочей одежды из текстильных материалов, кроме трикотажных или вязаных"
    },
    {
        "code": "14.19.23",
        "name": "Производство аксессуаров одежды, в том числе платков, шарфов, галстуков, перчаток и прочих аналогичных изделий из текстильных материалов, кроме трикотажных или вязаных"
    },
    {
        "code": "14.19.3",
        "name": "Производство аксессуаров одежды из кожи; производство одежды из фетра или нетканых материалов; производство одежды из текстильных материалов с покрытием"
    },
    {
        "code": "14.19.31",
        "name": "Производство аксессуаров одежды из натуральной или композиционной кожи"
    },
    {
        "code": "14.19.32",
        "name": "Производство одежды из фетра, нетканых материалов, из текстильных материалов с пропиткой или покрытием"
    },
    {
        "code": "14.19.4",
        "name": "Производство головных уборов"
    },
    {
        "code": "14.19.5",
        "name": "Пошив и вязание прочей одежды и аксессуаров одежды, головных уборов по индивидуальному заказу населения"
    },
    {
        "code": "14.2",
        "name": "Производство меховых изделий"
    },
    {
        "code": "14.20",
        "name": "Производство меховых изделий"
    },
    {
        "code": "14.20.1",
        "name": "Производство меховых изделий, кроме изготовленных по индивидуальному заказу"
    },
    {
        "code": "14.20.2",
        "name": "Пошив меховых изделий по индивидуальному заказу населения"
    },
    {
        "code": "14.3",
        "name": "Производство вязаных и трикотажных изделий одежды"
    },
    {
        "code": "14.31",
        "name": "Производство вязаных и трикотажных чулочно-носочных изделий"
    },
    {
        "code": "14.31.1",
        "name": "Производство вязаных и трикотажных чулочно- носочных изделий, кроме изготовленных по индивидуальному заказу"
    },
    {
        "code": "14.31.2",
        "name": "Изготовление вязаных и трикотажных чулочно- носочных изделий по индивидуальному заказу населения"
    },
    {
        "code": "14.39",
        "name": "Производство прочих вязаных и трикотажных изделий"
    },
    {
        "code": "14.39.1",
        "name": "Производство прочих вязаных и трикотажных изделий, не включенных в другие группировки"
    },
    {
        "code": "14.39.2",
        "name": "Изготовление прочих вязаных и трикотажных изделий не включенных в другие группировки по индивидуальному заказу населения"
    },
    {
        "code": "15",
        "name": "Производство кожи и изделий из кожи"
    },
    {
        "code": "15.1",
        "name": "Дубление и отделка кожи, производство чемоданов, сумок, шорно-седельных изделий из кожи; выделка и крашение меха"
    },
    {
        "code": "15.11",
        "name": "Дубление и выделка кожи, выделка и крашение меха"
    },
    {
        "code": "15.11.1",
        "name": "Выделка и крашение меха"
    },
    {
        "code": "15.11.2",
        "name": "Производство замши, пергаментной кожи, лакированной и металлизированной кожи"
    },
    {
        "code": "15.11.3",
        "name": "Дубление, выделка и крашение кожи из шкур крупного рогатого скота или животных семейства лошадиных"
    },
    {
        "code": "15.11.4",
        "name": "Дубление, выделка и крашение кожи из шкур овец, коз и свиней"
    },
    {
        "code": "15.11.5",
        "name": "Дубление, выделка и крашение кожи из шкур прочих животных, производство композиционной кожи"
    },
    {
        "code": "15.11.51",
        "name": "Дубление, выделка и крашение кожи из шкур прочих животных"
    },
    {
        "code": "15.11.52",
        "name": "Производство композиционной кожи"
    },
    {
        "code": "15.12",
        "name": "Производство чемоданов, дамских сумок и аналогичных изделий из кожи и других материалов; производство шорно-седельных и других изделий из кожи"
    },
    {
        "code": "15.2",
        "name": "Производство обуви"
    },
    {
        "code": "15.20",
        "name": "Производство обуви"
    },
    {
        "code": "15.20.1",
        "name": "Производство обуви, кроме спортивной, защитной и ортопедической"
    },
    {
        "code": "15.20.11",
        "name": "Производство водонепроницаемой обуви с верхом из резины или пластмассы"
    },
    {
        "code": "15.20.12",
        "name": "Производство обуви с верхом из резины и пластмассы, кроме водопроницаемой"
    },
    {
        "code": "15.20.13",
        "name": "Производство обуви с верхом из кожи, кроме спортивной обуви, обуви с защитным металлическим подноском и различной специальной обуви"
    },
    {
        "code": "15.20.14",
        "name": "Производство обуви с верхом из текстильных материалов, кроме спортивной обуви"
    },
    {
        "code": "15.20.2",
        "name": "Производство спортивной обуви"
    },
    {
        "code": "15.20.3",
        "name": "Производство защитной обуви"
    },
    {
        "code": "15.20.31",
        "name": "Производство обуви с защитным металлическим подноском"
    },
    {
        "code": "15.20.32",
        "name": "Производство деревянной и различной специальной обуви"
    },
    {
        "code": "15.20.4",
        "name": "Производство деталей обуви из кожи; вкладных стелек, подпяточников и аналогичных изделий; производство гетр, гамашей и аналогичных изделий"
    },
    {
        "code": "15.20.41",
        "name": "Производство деталей обуви из кожи; вкладных стелек, подпяточников и аналогичных изделий"
    },
    {
        "code": "15.20.42",
        "name": "Производство гетр, гамашей и аналогичных изделий"
    },
    {
        "code": "15.20.5",
        "name": "Пошив обуви и различных дополнений к обуви по индивидуальному заказу населения"
    },
    {
        "code": "16",
        "name": "Обработка древесины и производство изделий из дерева и пробки, кроме мебели, производство изделий из соломки и материалов для плетения"
    },
    {
        "code": "16.1",
        "name": "Распиловка и строгание древесины"
    },
    {
        "code": "16.10",
        "name": "Распиловка и строгание древесины"
    },
    {
        "code": "16.10.1",
        "name": "Производство пиломатериалов, кроме профилированных, толщиной более 6 мм; производство непропитанных железнодорожных и трамвайных шпал из древесины"
    },
    {
        "code": "16.10.2",
        "name": "Производство пиломатериалов, профилированных по кромке; производство древесного полотна, древесной муки; производство технологической щепы или стружки"
    },
    {
        "code": "16.10.3",
        "name": "Производство древесины, пропитанной или обработанной защитными или другими веществами"
    },
    {
        "code": "16.10.9",
        "name": "Предоставление услуг по пропитке древесины"
    },
    {
        "code": "16.2",
        "name": "Производство изделий из дерева, пробки, соломки и материалов для плетения"
    },
    {
        "code": "16.21",
        "name": "Производство шпона, фанеры, деревянных плит и панелей"
    },
    {
        "code": "16.21.1",
        "name": "Производство фанеры, деревянных фанерованных панелей и аналогичных слоистых материалов, древесных плит из древесины и других одревесневших материалов"
    },
    {
        "code": "16.21.11",
        "name": "Производство фанеры, деревянных фанерованных панелей и аналогичных слоистых материалов"
    },
    {
        "code": "16.21.12",
        "name": "Производство древесно-стружечных плит из древесины или других одревесневших материалов"
    },
    {
        "code": "16.21.13",
        "name": "Производство древесно-волокнистых плит из древесины или других одревесневших материалов"
    },
    {
        "code": "16.21.2",
        "name": "Производство листов для облицовки, шпона для фанеры, производство прессованной древесины"
    },
    {
        "code": "16.21.21",
        "name": "Производство листов для облицовки, шпона для фанеры"
    },
    {
        "code": "16.21.22",
        "name": "Производство прессованной древесины"
    },
    {
        "code": "16.22",
        "name": "Производство сборных паркетных покрытий"
    },
    {
        "code": "16.23",
        "name": "Производство прочих деревянных строительных конструкций и столярных изделий"
    },
    {
        "code": "16.23.1",
        "name": "Производство деревянных строительных конструкций и столярных изделий"
    },
    {
        "code": "16.23.2",
        "name": "Производство сборных деревянных строений"
    },
    {
        "code": "16.24",
        "name": "Производство деревянной тары"
    },
    {
        "code": "16.29",
        "name": "Производство прочих деревянных изделий; производство изделий из пробки, соломки и материалов для плетения"
    },
    {
        "code": "16.29.1",
        "name": "Производство прочих деревянных изделий"
    },
    {
        "code": "16.29.11",
        "name": "Производство деревянных инструментов, корпусов и рукояток инструментов, рукояток щеток и метелок, обувных колодок и растяжек для обуви"
    },
    {
        "code": "16.29.12",
        "name": "Производство деревянных столовых и кухонных принадлежностей"
    },
    {
        "code": "16.29.13",
        "name": "Производство деревянных статуэток и украшений из дерева, мозаики и инкрустированного дерева, шкатулок, футляров для ювелирных изделий или ножей"
    },
    {
        "code": "16.29.14",
        "name": "Производство деревянных рам для картин, фотографий, зеркал или аналогичных предметов и прочих изделий из дерева"
    },
    {
        "code": "16.29.15",
        "name": "Производство топливных гранул и брикетов из отходов деревопереработки"
    },
    {
        "code": "16.29.2",
        "name": "Производство изделий из пробки, соломки и материалов для плетения; производство корзиночных и плетеных изделий"
    },
    {
        "code": "16.29.21",
        "name": "Производство изделий из пробки"
    },
    {
        "code": "16.29.22",
        "name": "Производство изделий из соломки, эспарто (альфы) и прочих материалов для плетения"
    },
    {
        "code": "16.29.23",
        "name": "Производство корзиночных и плетеных изделий"
    },
    {
        "code": "16.29.3",
        "name": "Изготовление изделий из дерева, пробки, соломки и материалов для плетения, корзиночных и плетеных изделий по индивидуальному заказу населения"
    },
    {
        "code": "17",
        "name": "Производство бумаги и бумажных изделий"
    },
    {
        "code": "17.1",
        "name": "Производство целлюлозы, древесной массы, бумаги и картона"
    },
    {
        "code": "17.11",
        "name": "Производство целлюлозы и древесной массы"
    },
    {
        "code": "17.11.1",
        "name": "Производство целлюлозы"
    },
    {
        "code": "17.11.2",
        "name": "Производство древесной массы"
    },
    {
        "code": "17.11.9",
        "name": "Производство прочих волокнистых полуфабрикатов"
    },
    {
        "code": "17.12",
        "name": "Производство бумаги и картона"
    },
    {
        "code": "17.12.1",
        "name": "Производство бумаги"
    },
    {
        "code": "17.12.2",
        "name": "Производство картона"
    },
    {
        "code": "17.2",
        "name": "Производство изделий из бумаги и картона"
    },
    {
        "code": "17.21",
        "name": "Производство гофрированной бумаги и картона, бумажной и картонной тары"
    },
    {
        "code": "17.22",
        "name": "Производство бумажных изделий хозяйственно-бытового и санитарно-гигиенического назначения"
    },
    {
        "code": "17.23",
        "name": "Производство бумажных канцелярских принадлежностей"
    },
    {
        "code": "17.24",
        "name": "Производство обоев"
    },
    {
        "code": "17.29",
        "name": "Производство прочих изделий из бумаги и картона"
    },
    {
        "code": "18",
        "name": "Деятельность полиграфическая и копирование носителей информации"
    },
    {
        "code": "18.1",
        "name": "Деятельность полиграфическая и предоставление услуг в этой области"
    },
    {
        "code": "18.11",
        "name": "Печатание газет"
    },
    {
        "code": "18.12",
        "name": "Прочие виды полиграфической деятельности"
    },
    {
        "code": "18.13",
        "name": "Изготовление печатных форм и подготовительная деятельность"
    },
    {
        "code": "18.14",
        "name": "Деятельность брошюровочно- переплетная и отделочная и сопутствующие услуги"
    },
    {
        "code": "18.2",
        "name": "Копирование записанных носителей информации"
    },
    {
        "code": "18.20",
        "name": "Копирование записанных носителей информации"
    },
    {
        "code": "19",
        "name": "Производство кокса и нефтепродуктов"
    },
    {
        "code": "19.1",
        "name": "Производство кокса"
    },
    {
        "code": "19.10",
        "name": "Производство кокса"
    },
    {
        "code": "19.2",
        "name": "Производство нефтепродуктов"
    },
    {
        "code": "19.20",
        "name": "Производство нефтепродуктов"
    },
    {
        "code": "19.20.1",
        "name": "Производство жидкого топлива"
    },
    {
        "code": "19.20.2",
        "name": "Разделение и извлечение фракций из нефтяного (попутного) газа"
    },
    {
        "code": "19.20.9",
        "name": "Производство прочих нефтепродуктов"
    },
    {
        "code": "19.3",
        "name": "Агломерация угля, антрацита и бурого угля (лигнита) и производство термоуглей"
    },
    {
        "code": "19.31",
        "name": "Агломерация антрацита"
    },
    {
        "code": "19.32",
        "name": "Агломерация угля"
    },
    {
        "code": "19.33",
        "name": "Агломерация бурого угля (лигнита)"
    },
    {
        "code": "19.34",
        "name": "Производство термоуглей"
    },
    {
        "code": "19.34.1",
        "name": "Производство термоуглей из антрацита"
    },
    {
        "code": "19.34.2",
        "name": "Производство термоуглей из бурого угля (лигнита)"
    },
    {
        "code": "19.34.3",
        "name": "Производство теромуглей из угля за исключением антрацитов, лигнитов и угля каменного коксующегося"
    },
    {
        "code": "20",
        "name": "Производство химических веществ и химических продуктов"
    },
    {
        "code": "20.1",
        "name": "Производство основных химических веществ, удобрений и азотных соединений, пластмасс и синтетического каучука в первичных формах"
    },
    {
        "code": "20.11",
        "name": "Производство промышленных газов"
    },
    {
        "code": "20.12",
        "name": "Производство красителей и пигментов"
    },
    {
        "code": "20.13",
        "name": "Производство прочих основных неорганических химических веществ"
    },
    {
        "code": "20.14",
        "name": "Производство прочих основных органических химических веществ"
    },
    {
        "code": "20.14.1",
        "name": "Производство углеводородов и их производных"
    },
    {
        "code": "20.14.2",
        "name": "Производство спиртов, фенолов, фенолоспиртов и их галогенированных, сульфированных, нитрованных или нитрозированных производных; производство жирных промышленных спиртов"
    },
    {
        "code": "20.14.3",
        "name": "Производство промышленных монокарбоновых жирных кислот, карбоновых кислот и их производных"
    },
    {
        "code": "20.14.4",
        "name": "Производство органических соединений с азотсодержащими функциональными группами"
    },
    {
        "code": "20.14.5",
        "name": "Производство сераорганических соединений и прочих элементоорганических соединений"
    },
    {
        "code": "20.14.6",
        "name": "Производство простых эфиров, органических пероксидов, эпоксидов, ацеталей и полуацеталей, прочих органических соединений"
    },
    {
        "code": "20.14.7",
        "name": "Производство прочих химических органических основных веществ"
    },
    {
        "code": "20.15",
        "name": "Производство удобрений и азотных соединений"
    },
    {
        "code": "20.15.1",
        "name": "Производство азотных кислот, сульфоазотных кислот, аммиака"
    },
    {
        "code": "20.15.2",
        "name": "Производство хлорида аммония, нитритов"
    },
    {
        "code": "20.15.3",
        "name": "Производство азотных минеральных или химических удобрений"
    },
    {
        "code": "20.15.4",
        "name": "Производство фосфорных минеральных или химических удобрений"
    },
    {
        "code": "20.15.5",
        "name": "Производство калийных минеральных или химических удобрений"
    },
    {
        "code": "20.15.6",
        "name": "Производство нитрата натрия"
    },
    {
        "code": "20.15.7",
        "name": "Производство удобрений, не включенных в другие группировки"
    },
    {
        "code": "20.15.8",
        "name": "Производство удобрений животного или растительного происхождения"
    },
    {
        "code": "20.16",
        "name": "Производство пластмасс и синтетических смол в первичных формах"
    },
    {
        "code": "20.17",
        "name": "Производство синтетического каучука в первичных формах"
    },
    {
        "code": "20.2",
        "name": "Производство пестицидов и прочих агрохимических продуктов"
    },
    {
        "code": "20.20",
        "name": "Производство пестицидов и прочих агрохимических продуктов"
    },
    {
        "code": "20.3",
        "name": "Производство красок, лаков и аналогичных материалов для нанесения покрытий, полиграфических красок и мастик"
    },
    {
        "code": "20.30",
        "name": "Производство красок, лаков и аналогичных материалов для нанесения покрытий, полиграфических красок и мастик"
    },
    {
        "code": "20.30.1",
        "name": "Производство красок и лаков на основе полимеров"
    },
    {
        "code": "20.30.2",
        "name": "Производство прочих красок, лаков, эмалей и аналогичных материалов для нанесения покрытий, художественных и полиграфических красок"
    },
    {
        "code": "20.4",
        "name": "Производство мыла и моющих, чистящих и полирующих средств; парфюмерных и косметических средств"
    },
    {
        "code": "20.41",
        "name": "Производство мыла и моющих, чистящих и полирующих средств"
    },
    {
        "code": "20.41.1",
        "name": "Производство глицерина"
    },
    {
        "code": "20.41.2",
        "name": "Производство органических поверхностно-активных веществ, кроме мыла"
    },
    {
        "code": "20.41.3",
        "name": "Производство мыла и моющих средств, чистящих и полирующих средств"
    },
    {
        "code": "20.41.4",
        "name": "Производство средств для ароматизации и дезодорирования воздуха и восков"
    },
    {
        "code": "20.42",
        "name": "Производство парфюмерных и косметических средств"
    },
    {
        "code": "20.5",
        "name": "Производство прочих химических продуктов"
    },
    {
        "code": "20.51",
        "name": "Производство взрывчатых веществ"
    },
    {
        "code": "20.52",
        "name": "Производство клеев"
    },
    {
        "code": "20.53",
        "name": "Производство эфирных масел"
    },
    {
        "code": "20.59",
        "name": "Производство прочих химических продуктов, не включенных в другие группировки"
    },
    {
        "code": "20.59.1",
        "name": "Производство фотопластинок и фотопленок; фотопленок для моментальных фотоснимков; химических составов и несмешанных продуктов, используемых в фотографии"
    },
    {
        "code": "20.59.2",
        "name": "Производство химически модифицированных животных или растительных жиров и масел (включая олифу), непищевых смесей животных или растительных жиров и масел"
    },
    {
        "code": "20.59.3",
        "name": "Производство чернил для письма и рисования"
    },
    {
        "code": "20.59.4",
        "name": "Производство смазочных материалов, присадок к смазочным материалам и антифризов"
    },
    {
        "code": "20.59.5",
        "name": "Производство прочих химических продуктов, не включенных в другие группировки"
    },
    {
        "code": "20.59.6",
        "name": "Производство желатина и его производных"
    },
    {
        "code": "20.6",
        "name": "Производство химических волокон"
    },
    {
        "code": "20.60",
        "name": "Производство химических волокон"
    },
    {
        "code": "20.60.1",
        "name": "Производство синтетических волокон"
    },
    {
        "code": "20.60.2",
        "name": "Производство искусственных волокон"
    },
    {
        "code": "21",
        "name": "Производство лекарственных средств и материалов, применяемых в медицинских целях"
    },
    {
        "code": "21.1",
        "name": "Производство фармацевтических субстанций"
    },
    {
        "code": "21.10",
        "name": "Производство фармацевтических субстанций"
    },
    {
        "code": "21.2",
        "name": "Производство лекарственных препаратов и материалов, применяемых в медицинских целях"
    },
    {
        "code": "21.20",
        "name": "Производство лекарственных препаратов и материалов, применяемых в медицинских целях"
    },
    {
        "code": "21.20.1",
        "name": "Производство лекарственных препаратов"
    },
    {
        "code": "21.20.2",
        "name": "Производство материалов, применяемых в медицинских целях"
    },
    {
        "code": "22",
        "name": "Производство резиновых и пластмассовых изделий"
    },
    {
        "code": "22.1",
        "name": "Производство резиновых изделий"
    },
    {
        "code": "22.11",
        "name": "Производство резиновых шин, покрышек и камер; восстановление резиновых шин и покрышек"
    },
    {
        "code": "22.19",
        "name": "Производство прочих резиновых изделий"
    },
    {
        "code": "22.19.1",
        "name": "Производство регенерированной резины в первичной форме или в виде пластин, листов или полос (лент)"
    },
    {
        "code": "22.19.2",
        "name": "Производство резиновых смесей и изделий из них; производство вулканизированной резины в виде нити, корда, пластин, листов, полос, прутков и профилей"
    },
    {
        "code": "22.19.3",
        "name": "Производство труб, трубок, рукавов и шлангов из вулканизированной резины"
    },
    {
        "code": "22.19.4",
        "name": "Производство конвейерных лент и приводных ремней, бельтинга из вулканизированной резины"
    },
    {
        "code": "22.19.5",
        "name": "Производство прорезиненных текстильных материалов, кроме кордных тканей"
    },
    {
        "code": "22.19.6",
        "name": "Производство предметов одежды и ее аксессуаров из вулканизированной резины"
    },
    {
        "code": "22.19.7",
        "name": "Производство изделий из вулканизированной резины, не включенных в другие группировки"
    },
    {
        "code": "22.2",
        "name": "Производство изделий из пластмасс"
    },
    {
        "code": "22.21",
        "name": "Производство пластмассовых плит, полос, труб и профилей"
    },
    {
        "code": "22.22",
        "name": "Производство пластмассовых изделий для упаковывания товаров"
    },
    {
        "code": "22.23",
        "name": "Производство пластмассовых изделий, используемых в строительстве"
    },
    {
        "code": "22.29",
        "name": "Производство прочих пластмассовых изделий"
    },
    {
        "code": "22.29.1",
        "name": "Производство предметов одежды и аксессуаров для нее, включая перчатки, из пластмасс"
    },
    {
        "code": "22.29.2",
        "name": "Производство прочих изделий из пластмасс, не включенных в другие группировки, кроме устройств пломбировочных их пластика"
    },
    {
        "code": "22.29.3",
        "name": "Производство устройств пломбировочных из пластика"
    },
    {
        "code": "22.29.9",
        "name": "Предоставление услуг в области производства прочих пластмассовых изделий"
    },
    {
        "code": "23",
        "name": "Производство прочей неметаллической минеральной продукции"
    },
    {
        "code": "23.1",
        "name": "Производство стекла и изделий из стекла"
    },
    {
        "code": "23.11",
        "name": "Производство листового стекла"
    },
    {
        "code": "23.11.1",
        "name": "Производство листового тянутого стекла"
    },
    {
        "code": "23.11.2",
        "name": "Производство листового узорчатого, прокатного (но не обработанного другим способом): неармированного стекла"
    },
    {
        "code": "23.11.3",
        "name": "Производство листового узорчатого, прокатного (но не обработанного другим способом): армированного стекла"
    },
    {
        "code": "23.11.4",
        "name": "Производство бесцветного листового стекла, изготовленного методом флоат"
    },
    {
        "code": "23.11.5",
        "name": "Производство листового матового стекла"
    },
    {
        "code": "23.11.6",
        "name": "Производство полированного стекла в листах (кроме изготовленного методом флоат)"
    },
    {
        "code": "23.11.7",
        "name": "Производство листового стекла с покрытием (кроме лакокрасочного в т.ч. эмалевого)"
    },
    {
        "code": "23.11.9",
        "name": "Производство листового стекла, прочего"
    },
    {
        "code": "23.12",
        "name": "Формирование и обработка листового стекла"
    },
    {
        "code": "23.12.1",
        "name": "Производство упрочненного (в том числе термически упрочненного и закаленного) стекла"
    },
    {
        "code": "23.12.2",
        "name": "Производство стеклянных зеркал"
    },
    {
        "code": "23.12.3",
        "name": "Производство многослойного стекла для строительства"
    },
    {
        "code": "23.12.4",
        "name": "Производство безопасного многослойного стекла для транспорта (в том числе наземного)"
    },
    {
        "code": "23.12.5",
        "name": "Производство стеклопакетов"
    },
    {
        "code": "23.13",
        "name": "Производство полых стеклянных изделий"
    },
    {
        "code": "23.13.1",
        "name": "Производство бутылок и прочих емкостей из стекла или хрусталя"
    },
    {
        "code": "23.13.2",
        "name": "Производство стаканов и прочих сосудов для питья из стекла или хрусталя"
    },
    {
        "code": "23.13.3",
        "name": "Производство столовой и кухонной посуды из стекла или хрусталя"
    },
    {
        "code": "23.13.4",
        "name": "Производство туалетных и канцелярских принадлежностей из стекла или хрусталя"
    },
    {
        "code": "23.13.5",
        "name": "Производство украшений для интерьера и аналогичных изделий из стекла или хрусталя"
    },
    {
        "code": "23.13.6",
        "name": "Производство стеклянных колб для вакуумных сосудов"
    },
    {
        "code": "23.14",
        "name": "Производство стекловолокна"
    },
    {
        "code": "23.19",
        "name": "Производство и обработка прочих стеклянных изделий, включая технические изделия из стекла"
    },
    {
        "code": "23.19.1",
        "name": "Производство необработанного стекла в блоках, в виде шаров, прутков, труб или трубок"
    },
    {
        "code": "23.19.2",
        "name": "Производство блоков для мощения, стеклоблоков, плит и прочих изделий из прессованного или отформованного стекла, используемых в строительстве; производство стекла для витражей; производство многоячеистого стекла или пеностекла в блоках, плитах и анал огичных формах"
    },
    {
        "code": "23.19.3",
        "name": "Производство стеклянных колб для электрических ламп, электронно-лучевых приборов или аналогичных изделий"
    },
    {
        "code": "23.19.4",
        "name": "Производство стекол для часов или очков, не подвергнутых оптической обработке"
    },
    {
        "code": "23.19.5",
        "name": "Производство посуды для лабораторных, фармацевтических и гигиенических целей из стекла; производство ампул и прочих изделий из медицинского стекла"
    },
    {
        "code": "23.19.6",
        "name": "Производство стеклянных деталей электрических ламп и осветительной арматуры, световых указателей, световых табло и аналогичных изделий"
    },
    {
        "code": "23.19.7",
        "name": "Производство электрических изоляторов из стекла"
    },
    {
        "code": "23.19.9",
        "name": "Производство прочих изделий из стекла, не включенных в другие группировки"
    },
    {
        "code": "23.2",
        "name": "Производство огнеупорных изделий"
    },
    {
        "code": "23.20",
        "name": "Производство огнеупорных изделий"
    },
    {
        "code": "23.20.1",
        "name": "Производство огнеупорных кирпичей, блоков, плиток"
    },
    {
        "code": "23.20.2",
        "name": "Производство огнеупорных цементов, растворов, бетонов и аналогичных составов"
    },
    {
        "code": "23.20.3",
        "name": "Производство безобжиговых огнеупорных изделий"
    },
    {
        "code": "23.20.9",
        "name": "Производство прочих огнеупорных керамических изделий"
    },
    {
        "code": "23.3",
        "name": "Производство строительных керамических материалов"
    },
    {
        "code": "23.31",
        "name": "Производство керамических плит и плиток"
    },
    {
        "code": "23.32",
        "name": "Производство кирпича, черепицы и прочих строительных изделий из обожженной глины"
    },
    {
        "code": "23.4",
        "name": "Производство прочих фарфоровых и керамических изделий"
    },
    {
        "code": "23.41",
        "name": "Производство хозяйственных и декоративных керамических изделий"
    },
    {
        "code": "23.41.1",
        "name": "Производство столовой и кухонной керамической посуды"
    },
    {
        "code": "23.41.2",
        "name": "Производство прочих хозяйственных и туалетных керамических принадлежностей"
    },
    {
        "code": "23.41.3",
        "name": "Производство статуэток и прочих декоративных керамических изделий"
    },
    {
        "code": "23.42",
        "name": "Производство керамических санитарно-технических изделий"
    },
    {
        "code": "23.43",
        "name": "Производство керамических изоляторов и изолирующей арматуры"
    },
    {
        "code": "23.44",
        "name": "Производство прочих технических керамических изделий"
    },
    {
        "code": "23.44.1",
        "name": "Производство керамических изделий лабораторного, химического и промышленного назначения"
    },
    {
        "code": "23.44.2",
        "name": "Производство керамических и ферритовых магнитов"
    },
    {
        "code": "23.49",
        "name": "Производство прочих керамических изделий"
    },
    {
        "code": "23.49.1",
        "name": "Производство керамических горшков, банок, кувшинов и подобных изделий, используемых для транспортирования или упаковывания товаров"
    },
    {
        "code": "23.49.9",
        "name": "Производство керамических изделий, не включенных в другие группировки"
    },
    {
        "code": "23.5",
        "name": "Производство цемента, извести и гипса"
    },
    {
        "code": "23.51",
        "name": "Производство цемента"
    },
    {
        "code": "23.52",
        "name": "Производство извести и гипса"
    },
    {
        "code": "23.52.1",
        "name": "Производство негашеной, гашеной и гидравлической извести"
    },
    {
        "code": "23.52.2",
        "name": "Производство гипса"
    },
    {
        "code": "23.52.3",
        "name": "Производство кальцинированного доломита"
    },
    {
        "code": "23.6",
        "name": "Производство изделий из бетона, цемента и гипса"
    },
    {
        "code": "23.61",
        "name": "Производство изделий из бетона для использования в строительстве"
    },
    {
        "code": "23.61.1",
        "name": "Производство готовых строительных изделий из бетона, цемента и искусственного камня"
    },
    {
        "code": "23.61.2",
        "name": "Производство сборных строительных конструкций из бетона, цемента и искусственного камня"
    },
    {
        "code": "23.62",
        "name": "Производство гипсовых изделий для использования в строительстве"
    },
    {
        "code": "23.63",
        "name": "Производство товарного бетона"
    },
    {
        "code": "23.64",
        "name": "Производство сухих бетонных смесей"
    },
    {
        "code": "23.65",
        "name": "Производство изделий из хризотилцемента и волокнистого цемента"
    },
    {
        "code": "23.65.1",
        "name": "Производство строительных материалов из растительного сырья, смешанного с цементом, гипсом или прочими минеральными связующими веществами"
    },
    {
        "code": "23.65.2",
        "name": "Производство изделий из хризотилцемента и волокнистого цемента с волокнами целлюлозы или аналогичных материалов"
    },
    {
        "code": "23.69",
        "name": "Производство прочих изделий из гипса, бетона или цемента"
    },
    {
        "code": "23.7",
        "name": "Резка, обработка и отделка камня"
    },
    {
        "code": "23.70",
        "name": "Резка, обработка и отделка камня"
    },
    {
        "code": "23.70.1",
        "name": "Резка, обработка и отделка камня для использования в строительстве в качестве дорожного покрытия"
    },
    {
        "code": "23.70.2",
        "name": "Резка, обработка и отделка камня для памятников"
    },
    {
        "code": "23.70.3",
        "name": "Производство гранул и порошков из природного камня"
    },
    {
        "code": "23.9",
        "name": "Производство абразивных и неметаллических минеральных изделий, не включенных в другие группировки"
    },
    {
        "code": "23.91",
        "name": "Производство абразивных изделий"
    },
    {
        "code": "23.99",
        "name": "Производство прочей неметаллической минеральной продукции, не включенной в другие группировки"
    },
    {
        "code": "23.99.1",
        "name": "Производство обработанных хризотиловых волокон, смесей на основе хризотила и изделий из них"
    },
    {
        "code": "23.99.2",
        "name": "Производство изделий из асфальта или аналогичных материалов"
    },
    {
        "code": "23.99.3",
        "name": "Производство битуминозных смесей на основе природного асфальта или битума, нефтяного битума, минеральных смол или их пеков"
    },
    {
        "code": "23.99.4",
        "name": "Производство искусственного графита, коллоидного или полуколлоидного графита, продуктов на основе графита или прочих форм углерода в виде полуфабрикатов"
    },
    {
        "code": "23.99.5",
        "name": "Производство искусственного корунда"
    },
    {
        "code": "23.99.6",
        "name": "Производство минеральных тепло- и звукоизоляционных материалов и изделий"
    },
    {
        "code": "23.99.61",
        "name": "Производство минеральных теплоизоляционных материалов и изделий"
    },
    {
        "code": "23.99.62",
        "name": "Производство минеральных звукоизоляционных материалов и изделий"
    },
    {
        "code": "24",
        "name": "Производство металлургическое"
    },
    {
        "code": "24.1",
        "name": "Производство чугуна, стали и ферросплавов"
    },
    {
        "code": "24.10",
        "name": "Производство чугуна, стали и ферросплавов"
    },
    {
        "code": "24.10.1",
        "name": "Производство основных продуктов из железа и стали"
    },
    {
        "code": "24.10.11",
        "name": "Производство чугуна"
    },
    {
        "code": "24.10.12",
        "name": "Производство ферросплавов"
    },
    {
        "code": "24.10.13",
        "name": "Производство продуктов прямого восстановления железной руды и губчатого железа"
    },
    {
        "code": "24.10.14",
        "name": "Производство гранул и порошков из чугуна или стали"
    },
    {
        "code": "24.10.2",
        "name": "Производство стали в слитках"
    },
    {
        "code": "24.10.3",
        "name": "Производство листового горячекатаного стального проката"
    },
    {
        "code": "24.10.4",
        "name": "Производство листового холоднокатаного стального проката"
    },
    {
        "code": "24.10.5",
        "name": "Производство листового холоднокатаного стального проката, плакированного, с гальваническим или иным покрытием"
    },
    {
        "code": "24.10.6",
        "name": "Производство сортового горячекатаного проката и катанки"
    },
    {
        "code": "24.10.7",
        "name": "Производство незамкнутых стальных профилей горячей обработки, листового проката в пакетах и стального рельсового профиля для железных дорог и трамвайных путей"
    },
    {
        "code": "24.10.9",
        "name": "Производство прочего проката из черных металлов, не включенного в другие группировки"
    },
    {
        "code": "24.2",
        "name": "Производство стальных труб, полых профилей и фитингов"
    },
    {
        "code": "24.20",
        "name": "Производство стальных труб, полых профилей и фитингов"
    },
    {
        "code": "24.20.1",
        "name": "Производство бесшовных труб и пустотелых профилей"
    },
    {
        "code": "24.20.2",
        "name": "Производство сварных труб"
    },
    {
        "code": "24.20.3",
        "name": "Производство стальных фитингов для труб, кроме литых"
    },
    {
        "code": "24.3",
        "name": "Производство прочих стальных изделий первичной обработкой"
    },
    {
        "code": "24.31",
        "name": "Производство стальных прутков и сплошных профилей методом холодного волочения"
    },
    {
        "code": "24.32",
        "name": "Производство холоднотянутого штрипса"
    },
    {
        "code": "24.33",
        "name": "Производство профилей с помощью холодной штамповки или гибки"
    },
    {
        "code": "24.34",
        "name": "Производство проволоки методом холодного волочения"
    },
    {
        "code": "24.4",
        "name": "Производство основных драгоценных металлов и прочих цветных металлов, производство ядерного топлива"
    },
    {
        "code": "24.41",
        "name": "Производство драгоценных металлов"
    },
    {
        "code": "24.42",
        "name": "Производство алюминия"
    },
    {
        "code": "24.43",
        "name": "Производство свинца, цинка и олова"
    },
    {
        "code": "24.43.1",
        "name": "Производство свинца"
    },
    {
        "code": "24.43.2",
        "name": "Производство цинка"
    },
    {
        "code": "24.43.3",
        "name": "Производство олова"
    },
    {
        "code": "24.44",
        "name": "Производство меди"
    },
    {
        "code": "24.45",
        "name": "Производство прочих цветных металлов"
    },
    {
        "code": "24.45.1",
        "name": "Производство никеля"
    },
    {
        "code": "24.45.2",
        "name": "Производство титана"
    },
    {
        "code": "24.45.3",
        "name": "Производство магния"
    },
    {
        "code": "24.45.4",
        "name": "Производство вольфрама"
    },
    {
        "code": "24.45.5",
        "name": "Производство молибдена"
    },
    {
        "code": "24.45.6",
        "name": "Производство кобальта"
    },
    {
        "code": "24.45.7",
        "name": "Производство хрома"
    },
    {
        "code": "24.45.8",
        "name": "Производство марганца"
    },
    {
        "code": "24.45.9",
        "name": "Производство редких (тантал, ниобий, галлий, германий, иридий) и редкоземельных металлов"
    },
    {
        "code": "24.46",
        "name": "Производство ядерного топлива"
    },
    {
        "code": "24.5",
        "name": "Литье металлов"
    },
    {
        "code": "24.51",
        "name": "Литье чугуна"
    },
    {
        "code": "24.52",
        "name": "Литье стали"
    },
    {
        "code": "24.53",
        "name": "Литье легких металлов"
    },
    {
        "code": "24.54",
        "name": "Литье прочих цветных металлов"
    },
    {
        "code": "25",
        "name": "Производство готовых металлических изделий, кроме машин и оборудования"
    },
    {
        "code": "25.1",
        "name": "Производство строительных металлических конструкций и изделий"
    },
    {
        "code": "25.11",
        "name": "Производство строительных металлических конструкций, изделий и их частей"
    },
    {
        "code": "25.12",
        "name": "Производство металлических дверей и окон"
    },
    {
        "code": "25.2",
        "name": "Производство металлических цистерн, резервуаров и прочих емкостей"
    },
    {
        "code": "25.21",
        "name": "Производство радиаторов и котлов центрального отопления"
    },
    {
        "code": "25.21.1",
        "name": "Производство радиаторов"
    },
    {
        "code": "25.21.2",
        "name": "Производство котлов центрального отопления"
    },
    {
        "code": "25.29",
        "name": "Производство прочих металлических цистерн, резервуаров и емкостей"
    },
    {
        "code": "25.3",
        "name": "Производство паровых котлов, кроме котлов центрального отопления"
    },
    {
        "code": "25.30",
        "name": "Производство паровых котлов, кроме котлов центрального отопления"
    },
    {
        "code": "25.30.1",
        "name": "Производство паровых котлов и их частей"
    },
    {
        "code": "25.30.2",
        "name": "Производство ядерных установок и их составных частей, в том числе для транспортных средств"
    },
    {
        "code": "25.30.21",
        "name": "Производство ядерных установок, кроме устройств для разделения изотопов"
    },
    {
        "code": "25.30.22",
        "name": "Производство частей ядерных установок, кроме устройств для разделения изотопов"
    },
    {
        "code": "25.4",
        "name": "Производство оружия и боеприпасов"
    },
    {
        "code": "25.40",
        "name": "Производство оружия и боеприпасов"
    },
    {
        "code": "25.5",
        "name": "Ковка, прессование, штамповка и профилирование; изготовление изделий методом порошковой металлургии"
    },
    {
        "code": "25.50",
        "name": "Ковка, прессование, штамповка и профилирование, изготовление изделий методом порошковой металлургии"
    },
    {
        "code": "25.50.1",
        "name": "Предоставление услуг по ковке, прессованию, объемной и листовой штамповке и профилированию листового металла"
    },
    {
        "code": "25.50.2",
        "name": "Предоставление услуг по производству изделий методом порошковой металлургии"
    },
    {
        "code": "25.6",
        "name": "Обработка металлов и нанесение покрытий на металлы; механическая обработка металлов"
    },
    {
        "code": "25.61",
        "name": "Обработка металлов и нанесение покрытий на металлы"
    },
    {
        "code": "25.62",
        "name": "Обработка металлических изделий механическая"
    },
    {
        "code": "25.7",
        "name": "Производство ножевых изделий и столовых приборов, инструментов и универсальных скобяных изделий"
    },
    {
        "code": "25.71",
        "name": "Производство ножевых изделий и столовых приборов"
    },
    {
        "code": "25.72",
        "name": "Производство замков, петель"
    },
    {
        "code": "25.73",
        "name": "Производство инструмента"
    },
    {
        "code": "25.9",
        "name": "Производство прочих готовых металлических изделий"
    },
    {
        "code": "25.91",
        "name": "Производство металлических бочек и аналогичных емкостей"
    },
    {
        "code": "25.92",
        "name": "Производство тары из легких металлов"
    },
    {
        "code": "25.93",
        "name": "Производство изделий из проволоки, цепей и пружин"
    },
    {
        "code": "25.93.1",
        "name": "Производство изделий из проволоки и пружин"
    },
    {
        "code": "25.93.2",
        "name": "Производство цепей, кроме шарнирных, и составных частей к ним"
    },
    {
        "code": "25.94",
        "name": "Производство крепежных изделий"
    },
    {
        "code": "25.99",
        "name": "Производство прочих готовых металлических изделий, не включенных в другие группировки"
    },
    {
        "code": "25.99.1",
        "name": "Производство металлических изделий для ванных комнат и кухни"
    },
    {
        "code": "25.99.11",
        "name": "Производство раковин, моек, ванн и прочих санитарно-технических изделий и их составных частей из черных металлов, меди или алюминия"
    },
    {
        "code": "25.99.12",
        "name": "Производство столовых, кухонных и прочих бытовых изделий, кроме столовых и кухонных приборов, и их составных частей из черных металлов, меди или алюминия"
    },
    {
        "code": "25.99.2",
        "name": "Производство прочих металлических изделий"
    },
    {
        "code": "25.99.21",
        "name": "Производство бронированных или армированных сейфов, несгораемых шкафов и дверей"
    },
    {
        "code": "25.99.22",
        "name": "Производство канцелярского настольного оборудования (ящиков, картотек, лотков и т. п.) из недрагоценных металлов"
    },
    {
        "code": "25.99.23",
        "name": "Производство деталей для скоросшивателей или папок; канцелярских принадлежностей и скоб в виде полос из недрагоценных металлов"
    },
    {
        "code": "25.99.24",
        "name": "Производство статуэток, рам для фотографий, картин, зеркал и прочих декоративных изделий из недрагоценных металлов"
    },
    {
        "code": "25.99.25",
        "name": "Производство фурнитуры из недрагоценных металлов для одежды, обуви, кожгалантереи и прочих изделий, в том числе крючков, пряжек, застежек, петелек, колечек, трубчатых и раздвоенных заклепок и др."
    },
    {
        "code": "25.99.26",
        "name": "Производство судовых гребных винтов и гребных колес"
    },
    {
        "code": "25.99.27",
        "name": "Производство металлических изделий для области использования атомной энергии"
    },
    {
        "code": "25.99.29",
        "name": "Производство прочих изделий из недрагоценных металлов, не включенных в другие группировки"
    },
    {
        "code": "25.99.3",
        "name": "Изготовление готовых металлических изделий хозяйственного назначения по индивидуальному заказу населения"
    },
    {
        "code": "26",
        "name": "Производство компьютеров, электронных и оптических изделий"
    },
    {
        "code": "26.1",
        "name": "Производство элементов электронной аппаратуры и печатных схем (плат)"
    },
    {
        "code": "26.11",
        "name": "Производство элементов электронной аппаратуры"
    },
    {
        "code": "26.11.1",
        "name": "Производство электронных вакуумных ламп и трубок и прочих электронных вакуумных приборов"
    },
    {
        "code": "26.11.2",
        "name": "Производство диодов, транзисторов и прочих полупроводниковых приборов, включая светоизлучающие диоды, пьезоэлектрические приборы и их части"
    },
    {
        "code": "26.11.3",
        "name": "Производство интегральных электронных схем"
    },
    {
        "code": "26.11.9",
        "name": "Производство частей электронных ламп, трубок и прочих электронных компонентов, не включенных в другие группировки"
    },
    {
        "code": "26.12",
        "name": "Производство электронных печатных плат"
    },
    {
        "code": "26.2",
        "name": "Производство компьютеров и периферийного оборудования"
    },
    {
        "code": "26.20",
        "name": "Производство компьютеров и периферийного оборудования"
    },
    {
        "code": "26.20.1",
        "name": "Производство компьютеров"
    },
    {
        "code": "26.20.2",
        "name": "Производство периферийного оборудования"
    },
    {
        "code": "26.20.3",
        "name": "Производство запоминающих устройств и прочих устройств хранения данных"
    },
    {
        "code": "26.20.4",
        "name": "Производство средств защиты информации, а также информационных и телекоммуникационных систем, защищенных с использованием средств защиты информации"
    },
    {
        "code": "26.20.9",
        "name": "Производство прочих устройств автоматической обработки данных"
    },
    {
        "code": "26.3",
        "name": "Производство коммуникационного оборудования"
    },
    {
        "code": "26.30",
        "name": "Производство коммуникационного оборудования"
    },
    {
        "code": "26.30.1",
        "name": "Производство коммуникационной аппаратуры, радио- и телевизионной передающей аппаратуры, телевизионных камер"
    },
    {
        "code": "26.30.11",
        "name": "Производство средств связи, выполняющих функцию систем коммутации"
    },
    {
        "code": "26.30.12",
        "name": "Производство средств связи, выполняющих функцию цифровых транспортных систем"
    },
    {
        "code": "26.30.13",
        "name": "Производство средств связи, выполняющих функцию систем управления и мониторинга"
    },
    {
        "code": "26.30.14",
        "name": "Производство оборудования, используемого для учета объема оказанных услуг связи"
    },
    {
        "code": "26.30.15",
        "name": "Производство радиоэлектронных средств связи"
    },
    {
        "code": "26.30.16",
        "name": "Производство оборудования средств связи, в том числе программное обеспечение, обеспечивающее выполнение установленных действий при проведении оперативно-розыскных мероприятий"
    },
    {
        "code": "26.30.17",
        "name": "Производство радио- и телевизионной передающей аппаратуры"
    },
    {
        "code": "26.30.18",
        "name": "Производство телевизионных камер"
    },
    {
        "code": "26.30.19",
        "name": "Производство прочего коммуникационного оборудования"
    },
    {
        "code": "26.30.2",
        "name": "Производство оконечного (пользовательского) оборудования телефонной или телеграфной связи, аппаратуры видеосвязи"
    },
    {
        "code": "26.30.21",
        "name": "Производство пользовательского (оконечного) оборудования проводной телефонной связи с проводными или беспроводными телефонными трубками"
    },
    {
        "code": "26.30.22",
        "name": "Производство телефонных аппаратов для работы в сотовых или иных беспроводных сетях связи"
    },
    {
        "code": "26.30.29",
        "name": "Производство прочих телефонных аппаратов, устройств и аппаратуры для передачи и приема речи, изображений или других данных, включая оборудование коммуникационное для работы в проводных или беспроводных сетях связи (например, локальных и глобальных се тях)"
    },
    {
        "code": "26.30.3",
        "name": "Производство запасных частей и комплектующих коммуникационного оборудования"
    },
    {
        "code": "26.30.4",
        "name": "Производство антенн, антенных отражателей всех видов и их деталей"
    },
    {
        "code": "26.30.5",
        "name": "Производство запасных частей и комплектующих радио- и телевизионной передающей аппаратуры и телевизионных камер"
    },
    {
        "code": "26.30.6",
        "name": "Производство охранно-пожарной сигнализации и аналогичных приборов"
    },
    {
        "code": "26.4",
        "name": "Производство бытовой электроники"
    },
    {
        "code": "26.40",
        "name": "Производство бытовой электроники"
    },
    {
        "code": "26.40.1",
        "name": "Производство радиоприемников"
    },
    {
        "code": "26.40.2",
        "name": "Производство телевизионных приемников, включая видеомониторы и видеопроекторы"
    },
    {
        "code": "26.40.21",
        "name": "Производство телевизоров с электронно-лучевой трубкой"
    },
    {
        "code": "26.40.22",
        "name": "Производство телевизоров жидкокристаллических и плазменных"
    },
    {
        "code": "26.40.23",
        "name": "Производство видеомониторов и видеопроекторов"
    },
    {
        "code": "26.40.3",
        "name": "Производство аппаратуры для записи и воспроизведения звука и изображения"
    },
    {
        "code": "26.40.4",
        "name": "Производство электроакустической аппаратуры"
    },
    {
        "code": "26.40.5",
        "name": "Производство частей звукозаписывающей и звуковоспроизводящей аппаратуры и видеоаппаратуры"
    },
    {
        "code": "26.5",
        "name": "Производство контрольно-измерительных и навигационных приборов и аппаратов; производство часов"
    },
    {
        "code": "26.51",
        "name": "Производство инструментов и приборов для измерения, тестирования и навигации"
    },
    {
        "code": "26.51.1",
        "name": "Производство навигационных, метеорологических, геодезических, геофизических и аналогичного типа приборов, аппаратуры и инструментов"
    },
    {
        "code": "26.51.2",
        "name": "Производство радиолокационной, радионавигационной аппаратуры и радиоаппаратуры дистанционного управления"
    },
    {
        "code": "26.51.3",
        "name": "Производство точных весов; производство ручных инструментов для черчения, разметки и математических расчетов; производство ручных инструментов для измерения линейных размеров, не включенных в другие группировки"
    },
    {
        "code": "26.51.4",
        "name": "Производство приборов и аппаратуры для измерения электрических величин или ионизирующих излучений"
    },
    {
        "code": "26.51.5",
        "name": "Производство приборов для контроля прочих физических величин"
    },
    {
        "code": "26.51.6",
        "name": "Производство прочих приборов, датчиков, аппаратуры и инструментов для измерения, контроля и испытаний"
    },
    {
        "code": "26.51.7",
        "name": "Производство приборов и аппаратуры для автоматического регулирования или управления"
    },
    {
        "code": "26.51.8",
        "name": "Производство частей приборов и инструментов для навигации, управления, измерения, контроля, испытаний и прочих целей"
    },
    {
        "code": "26.52",
        "name": "Производство часов"
    },
    {
        "code": "26.52.1",
        "name": "Производство часов всех видов и прочих приборов времени"
    },
    {
        "code": "26.52.2",
        "name": "Производство часовых механизмов, деталей и составных частей часов и приборов времени"
    },
    {
        "code": "26.6",
        "name": "Производство облучающего и электротерапевтического оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.60",
        "name": "Производство облучающего и электротерапевтического оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.60.1",
        "name": "Производство аппаратов, применяемых в медицинских целях, основанных на использовании рентгеновского, альфа-, бета- и гамма-излучений"
    },
    {
        "code": "26.60.2",
        "name": "Производство гемодиализного, диатермического, наркозного оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.60.3",
        "name": "Производство оборудования для переливания крови"
    },
    {
        "code": "26.60.4",
        "name": "Производство инструмента, оборудования и приспособлений, применяемых в медицинских целях"
    },
    {
        "code": "26.60.5",
        "name": "Производство диагностического и терапевтического оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.60.6",
        "name": "Производство компьютерных томографов"
    },
    {
        "code": "26.60.7",
        "name": "Производство ультразвукового оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.60.9",
        "name": "Производство прочего оборудования, применяемого в медицинских целях"
    },
    {
        "code": "26.7",
        "name": "Производство оптических приборов, фото- и кинооборудования"
    },
    {
        "code": "26.70",
        "name": "Производство оптических приборов, фото- и кинооборудования"
    },
    {
        "code": "26.70.1",
        "name": "Производство фото- и кинооборудования"
    },
    {
        "code": "26.70.2",
        "name": "Производство микроскопов (кроме электронных и протонных)"
    },
    {
        "code": "26.70.3",
        "name": "Производство оптических систем обнаружения оружия"
    },
    {
        "code": "26.70.4",
        "name": "Производство оборудования оптического позиционирования на местности"
    },
    {
        "code": "26.70.5",
        "name": "Производство линз, оптических микроскопов, биноклей и телескопов"
    },
    {
        "code": "26.70.6",
        "name": "Производство оптических прицелов и приборов определения координат целей"
    },
    {
        "code": "26.70.7",
        "name": "Производство пленочных и цифровых фото- и кинокамер"
    },
    {
        "code": "26.8",
        "name": "Производство незаписанных магнитных и оптических технических носителей информации"
    },
    {
        "code": "26.80",
        "name": "Производство незаписанных магнитных и оптических технических носителей информации"
    },
    {
        "code": "27",
        "name": "Производство электрического оборудования"
    },
    {
        "code": "27.1",
        "name": "Производство электродвигателей, генераторов, трансформаторов и распределительных устройств, а также контрольно-измерительной аппаратуры"
    },
    {
        "code": "27.11",
        "name": "Производство электродвигателей, электрогенераторов и трансформаторов"
    },
    {
        "code": "27.11.1",
        "name": "Производство электродвигателей, генераторов и трансформаторов, кроме ремонта"
    },
    {
        "code": "27.11.11",
        "name": "Производство электродвигателей"
    },
    {
        "code": "27.11.12",
        "name": "Производство генераторов"
    },
    {
        "code": "27.11.13",
        "name": "Производство трансформаторов"
    },
    {
        "code": "27.12",
        "name": "Производство электрической распределительной и регулирующей аппаратуры"
    },
    {
        "code": "27.2",
        "name": "Производство электрических аккумуляторов и аккумуляторных батарей"
    },
    {
        "code": "27.20",
        "name": "Производство электрических аккумуляторов и аккумуляторных батарей"
    },
    {
        "code": "27.20.1",
        "name": "Производство первичных элементов, батарей первичных элементов и их частей"
    },
    {
        "code": "27.20.2",
        "name": "Производство аккумуляторов, в том числе для автомобилей, аккумуляторных батарей и их составных частей"
    },
    {
        "code": "27.20.21",
        "name": "Производство аккумуляторов для автомобилей"
    },
    {
        "code": "27.20.22",
        "name": "Производство аккумуляторных батарей и их частей"
    },
    {
        "code": "27.20.23",
        "name": "Производство прочих аккумуляторов"
    },
    {
        "code": "27.20.3",
        "name": "Производство солнечных батарей для наземного энергообеспечения и их составных частей"
    },
    {
        "code": "27.3",
        "name": "Производство кабелей и кабельной арматуры"
    },
    {
        "code": "27.31",
        "name": "Производство волоконно-оптических кабелей"
    },
    {
        "code": "27.32",
        "name": "Производство прочих проводов и кабелей для электронного и электрического оборудования"
    },
    {
        "code": "27.32.1",
        "name": "Производство кабелей для телефонной связи"
    },
    {
        "code": "27.32.2",
        "name": "Производство силовых кабелей"
    },
    {
        "code": "27.32.3",
        "name": "Производство обмоточных эмалированных кабелей"
    },
    {
        "code": "27.33",
        "name": "Производство электроустановочных изделий"
    },
    {
        "code": "27.4",
        "name": "Производство электрических ламп и осветительного оборудования"
    },
    {
        "code": "27.40",
        "name": "Производство электрических ламп и осветительного оборудования"
    },
    {
        "code": "27.5",
        "name": "Производство бытовых приборов"
    },
    {
        "code": "27.51",
        "name": "Производство бытовых электрических приборов"
    },
    {
        "code": "27.51.1",
        "name": "Производство стиральных машин"
    },
    {
        "code": "27.51.2",
        "name": "Производство холодильников и морозильников"
    },
    {
        "code": "27.51.3",
        "name": "Производство пылесосов"
    },
    {
        "code": "27.51.4",
        "name": "Производство посудомоечных машин"
    },
    {
        "code": "27.51.5",
        "name": "Производство электропечей"
    },
    {
        "code": "27.51.6",
        "name": "Производство микроволновых печей"
    },
    {
        "code": "27.52",
        "name": "Производство бытовых неэлектрических приборов"
    },
    {
        "code": "27.9",
        "name": "Производство прочего электрического оборудования"
    },
    {
        "code": "27.90",
        "name": "Производство прочего электрического оборудования"
    },
    {
        "code": "27.90.1",
        "name": "Производство ускорителей заряженных частиц"
    },
    {
        "code": "27.90.2",
        "name": "Производство радиационных аппаратов и радионуклидных энергетических устройств (РЭУ)"
    },
    {
        "code": "27.90.9",
        "name": "Производство электрического оборудования прочего, не включенного в другие группировки"
    },
    {
        "code": "28",
        "name": "Производство машин и оборудования, не включенных в другие группировки"
    },
    {
        "code": "28.1",
        "name": "Производство машин и оборудования общего назначения"
    },
    {
        "code": "28.11",
        "name": "Производство двигателей и турбин, кроме авиационных, автомобильных и мотоциклетных двигателей"
    },
    {
        "code": "28.11.1",
        "name": "Производство двигателей, кроме авиационных, автомобильных и мотоциклетных"
    },
    {
        "code": "28.11.2",
        "name": "Производство турбин"
    },
    {
        "code": "28.11.21",
        "name": "Производство паровых турбин"
    },
    {
        "code": "28.11.22",
        "name": "Производство гидравлических турбин и водяных колес"
    },
    {
        "code": "28.11.23",
        "name": "Производство газовых турбин, кроме турбореактивных и турбовинтовых"
    },
    {
        "code": "28.12",
        "name": "Производство гидравлического и пневматического силового оборудования"
    },
    {
        "code": "28.12.1",
        "name": "Производство гидравлических и пневматических силовых установок и двигателей"
    },
    {
        "code": "28.12.2",
        "name": "Производство гидравлических насосов"
    },
    {
        "code": "28.13",
        "name": "Производство прочих насосов и компрессоров"
    },
    {
        "code": "28.14",
        "name": "Производство арматуры трубопроводной (арматуры)"
    },
    {
        "code": "28.15",
        "name": "Производство подшипников, зубчатых передач, элементов механических передач и приводов"
    },
    {
        "code": "28.15.1",
        "name": "Производство шариковых и роликовых подшипников"
    },
    {
        "code": "28.15.2",
        "name": "Производство корпусов подшипников и подшипников скольжения, зубчатых колес, зубчатых передач и элементов приводов"
    },
    {
        "code": "28.15.9",
        "name": "Производство прочих подшипников"
    },
    {
        "code": "28.2",
        "name": "Производство прочих машин и оборудования общего назначения"
    },
    {
        "code": "28.21",
        "name": "Производство печей, термокамер и печных горелок"
    },
    {
        "code": "28.21.1",
        "name": "Производство неэлектрических печей, горелок и устройств для них"
    },
    {
        "code": "28.21.2",
        "name": "Производство электрических печей"
    },
    {
        "code": "28.22",
        "name": "Производство подъемно-транспортного оборудования"
    },
    {
        "code": "28.22.1",
        "name": "Производство талей и подъемников"
    },
    {
        "code": "28.22.2",
        "name": "Производство лебедок и кабестанов"
    },
    {
        "code": "28.22.3",
        "name": "Производство домкратов и подъемных механизмов для транспортных средств"
    },
    {
        "code": "28.22.4",
        "name": "Производство подъемных кранов"
    },
    {
        "code": "28.22.41",
        "name": "Производство подъемных кранов для строительства"
    },
    {
        "code": "28.22.42",
        "name": "Производство прочих подъемных кранов"
    },
    {
        "code": "28.22.5",
        "name": "Производство автопогрузчиков и тягачей, используемых на железнодорожных платформах"
    },
    {
        "code": "28.22.6",
        "name": "Производство лифтов, скриповых подъемников, эскалаторов и движущихся пешеходных дорожек"
    },
    {
        "code": "28.22.7",
        "name": "Производство пневматических подъемников и конвейеров и прочего оборудования непрерывного действия для товаров или материалов"
    },
    {
        "code": "28.22.9",
        "name": "Производство прочего грузоподъемного, транспортирующего и погрузочно-разгрузочного оборудования"
    },
    {
        "code": "28.23",
        "name": "Производство офисной техники и оборудования (кроме компьютеров и периферийного оборудования)"
    },
    {
        "code": "28.23.1",
        "name": "Производство пишущих машин, машин для обработки текста, калькуляторов, счетных машин и их частей"
    },
    {
        "code": "28.23.2",
        "name": "Производство фотокопировальных машин, офисных машин для офсетной печати и прочих офисных машин и оборудования и их составных частей"
    },
    {
        "code": "28.24",
        "name": "Производство ручных инструментов с механизированным приводом"
    },
    {
        "code": "28.25",
        "name": "Производство промышленного холодильного и вентиляционного оборудования"
    },
    {
        "code": "28.25.1",
        "name": "Производство теплообменных устройств, оборудования для кондиционирования воздуха промышленного холодильного и морозильного оборудования, производство оборудования для фильтрования и очистки газов"
    },
    {
        "code": "28.25.11",
        "name": "Производство теплообменных устройств и машин для сжижения воздуха или прочих газов"
    },
    {
        "code": "28.25.12",
        "name": "Производство оборудования для кондиционирования воздуха"
    },
    {
        "code": "28.25.13",
        "name": "Производство промышленного холодильного и морозильного оборудования"
    },
    {
        "code": "28.25.14",
        "name": "Производство оборудования для фильтрования и очистки газов"
    },
    {
        "code": "28.25.2",
        "name": "Производство вентиляторов"
    },
    {
        "code": "28.29",
        "name": "Производство прочих машин и оборудования общего назначения, не включенного в другие группировки"
    },
    {
        "code": "28.29.1",
        "name": "Производство газогенераторов, аппаратов для дистилляции и фильтрования"
    },
    {
        "code": "28.29.11",
        "name": "Производство генераторов для получения генераторного или водяного газа, ацетиленовых и аналогичных газогенераторов, установок для дистилляции или очистки"
    },
    {
        "code": "28.29.12",
        "name": "Производство оборудования и установок для фильтрования или очистки жидкостей"
    },
    {
        "code": "28.29.13",
        "name": "Производство масляных, бензиновых и всасывающих воздушных фильтров для двигателей внутреннего сгорания"
    },
    {
        "code": "28.29.2",
        "name": "Производство оборудования для мойки, заполнения, закупоривания или упаковывания бутылок или прочих емкостей, огнетушителей, распылителей, пароструйных или пескоструйных машин, прокладок"
    },
    {
        "code": "28.29.21",
        "name": "Производство оборудования для мойки, заполнения, закупоривания или упаковывания бутылок или прочих емкостей"
    },
    {
        "code": "28.29.22",
        "name": "Производство огнетушителей, распылителей, пароструйных или пескоструйных машин"
    },
    {
        "code": "28.29.3",
        "name": "Производство промышленного, бытового и прочего оборудования для взвешивания и дозировки"
    },
    {
        "code": "28.29.31",
        "name": "Производство промышленного оборудования для взвешивания и дозировки"
    },
    {
        "code": "28.29.32",
        "name": "Производство бытового оборудования для взвешивания"
    },
    {
        "code": "28.29.39",
        "name": "Производство прочего оборудования для взвешивания и дозировки"
    },
    {
        "code": "28.29.4",
        "name": "Производство центрифуг, каландров и торговых автоматов"
    },
    {
        "code": "28.29.41",
        "name": "Производство центрифуг"
    },
    {
        "code": "28.29.42",
        "name": "Производство каландров или прочих валковых машин, кроме машин для обработки металлов или стекла"
    },
    {
        "code": "28.29.43",
        "name": "Производство торговых автоматов"
    },
    {
        "code": "28.29.5",
        "name": "Производство посудомоечных машин промышленного типа"
    },
    {
        "code": "28.29.6",
        "name": "Производство оборудования для обработки материалов с использованием процессов, включающих изменение температуры, не включенного в другие группировки"
    },
    {
        "code": "28.29.7",
        "name": "Производство неэлектрического оборудования и инструментов для пайки мягким и твердым припоем или сварки, машин и аппаратов для газотермического напыления"
    },
    {
        "code": "28.3",
        "name": "Производство машин и оборудования для сельского и лесного хозяйства"
    },
    {
        "code": "28.30",
        "name": "Производство машин и оборудования для сельского и лесного хозяйства"
    },
    {
        "code": "28.30.1",
        "name": "Производство тракторов, управляемых рядом идущим водителем"
    },
    {
        "code": "28.30.2",
        "name": "Производство тракторов для сельского хозяйства"
    },
    {
        "code": "28.30.21",
        "name": "Производство колесных тракторов для сельского хозяйства"
    },
    {
        "code": "28.30.22",
        "name": "Производство гусеничных тракторов для сельского хозяйства"
    },
    {
        "code": "28.30.3",
        "name": "Производство машин и сельскохозяйственного оборудования для обработки почвы"
    },
    {
        "code": "28.30.4",
        "name": "Производство косилок для газонов, парков или спортивных площадок"
    },
    {
        "code": "28.30.5",
        "name": "Производство машин для уборки урожая"
    },
    {
        "code": "28.30.51",
        "name": "Производство зерноуборочных комбайнов"
    },
    {
        "code": "28.30.52",
        "name": "Производство кормозаготовительных комбайнов"
    },
    {
        "code": "28.30.53",
        "name": "Производство корнеуборочных или клубнеуборочных машин"
    },
    {
        "code": "28.30.59",
        "name": "Производство прочих машин для уборки урожая"
    },
    {
        "code": "28.30.6",
        "name": "Производство механических устройств для разбрасывания или распыления жидкостей или порошков, используемых в сельском хозяйстве или садоводстве"
    },
    {
        "code": "28.30.7",
        "name": "Производство самозагружающихся или саморазгружающихся прицепов и полуприцепов для сельского хозяйства"
    },
    {
        "code": "28.30.8",
        "name": "Производство прочих машин и оборудования для сельского и лесного хозяйства"
    },
    {
        "code": "28.30.81",
        "name": "Производство машин для очистки, сортировки или калибровки яиц, фруктов или прочих сельскохозяйственных продуктов, кроме семян, зерна или сухих бобовых культур"
    },
    {
        "code": "28.30.82",
        "name": "Производство доильных аппаратов"
    },
    {
        "code": "28.30.83",
        "name": "Производство оборудования для приготовления кормов для животных"
    },
    {
        "code": "28.30.84",
        "name": "Производство инкубаторов и брудеров для птицеводства"
    },
    {
        "code": "28.30.85",
        "name": "Производство машин и оборудования для содержания птицы"
    },
    {
        "code": "28.30.86",
        "name": "Производство машин и оборудования для лесного хозяйства"
    },
    {
        "code": "28.30.89",
        "name": "Производство прочих машин и оборудования для сельского хозяйства, садоводства, птицеводства или пчеловодства, не включенных в другие группировки"
    },
    {
        "code": "28.4",
        "name": "Производство станков, машин и оборудования для обработки металлов и прочих твердых материалов"
    },
    {
        "code": "28.41",
        "name": "Производство металлообрабатывающего оборудования"
    },
    {
        "code": "28.41.1",
        "name": "Производство металлообрабатывающих станков"
    },
    {
        "code": "28.41.2",
        "name": "Производство кузнечно-прессового оборудования"
    },
    {
        "code": "28.41.3",
        "name": "Производство аддитивных установок, использующих в качестве сырья металл, керамику и композиты"
    },
    {
        "code": "28.49",
        "name": "Производство прочих станков"
    },
    {
        "code": "28.49.1",
        "name": "Производство станков для обработки камня, дерева и аналогичных твердых материалов"
    },
    {
        "code": "28.49.11",
        "name": "Производство станков для обработки камня, керамики, бетона или аналогичных минеральных материалов или для холодной обработки стекла"
    },
    {
        "code": "28.49.12",
        "name": "Производство деревообрабатывающих станков"
    },
    {
        "code": "28.49.13",
        "name": "Производство станков для обработки кости, твердой резины, твердых пластмасс или аналогичных твердых материалов"
    },
    {
        "code": "28.49.2",
        "name": "Производство оборудования для нанесения гальванического покрытия"
    },
    {
        "code": "28.49.3",
        "name": "Производство оправок для крепления инструмента"
    },
    {
        "code": "28.49.4",
        "name": "Производство делительных головок и прочих специальных приспособлений для станков"
    },
    {
        "code": "28.9",
        "name": "Производство прочих машин специального назначения"
    },
    {
        "code": "28.91",
        "name": "Производство машин и оборудования для металлургии"
    },
    {
        "code": "28.91.1",
        "name": "Производство конвертеров, ковшей, изложниц и литейных машин"
    },
    {
        "code": "28.91.2",
        "name": "Производство прокатных станов"
    },
    {
        "code": "28.91.3",
        "name": "Производство валков для прокатных станов"
    },
    {
        "code": "28.92",
        "name": "Производство машин и оборудования для добычи полезных ископаемых и строительства"
    },
    {
        "code": "28.92.1",
        "name": "Производство оборудования для добычи полезных ископаемых подземным способом"
    },
    {
        "code": "28.92.11",
        "name": "Производство подъемников и конвейеров непрерывного действия для подземных работ"
    },
    {
        "code": "28.92.12",
        "name": "Производство врубовых машин и оборудования для проходки тоннелей"
    },
    {
        "code": "28.92.2",
        "name": "Производство прочих машин для перемещения, грейдерных работ, планирования, скреперных работ, выемки, трамбовки, уплотнения или добычи, самоходных, для грунта, минералов или руд"
    },
    {
        "code": "28.92.21",
        "name": "Производство бульдозеров"
    },
    {
        "code": "28.92.22",
        "name": "Производство самоходных грейдеров и планировщиков"
    },
    {
        "code": "28.92.23",
        "name": "Производство самоходных скреперов"
    },
    {
        "code": "28.92.24",
        "name": "Производство трамбовочных машин и дорожных самоходных катков"
    },
    {
        "code": "28.92.25",
        "name": "Производство самоходных фронтальных одноковшовых погрузчиков"
    },
    {
        "code": "28.92.26",
        "name": "Производство одноковшовых полноповоротных экскаваторов и погрузчиков"
    },
    {
        "code": "28.92.27",
        "name": "Производство прочих экскаваторов и самоходных ковшовых погрузчиков"
    },
    {
        "code": "28.92.28",
        "name": "Производство отвалов бульдозеров"
    },
    {
        "code": "28.92.29",
        "name": "Производство автомобилей-самосвалов, предназначенных для использования в условиях бездорожья"
    },
    {
        "code": "28.92.3",
        "name": "Производство прочих машин для выемки грунта"
    },
    {
        "code": "28.92.4",
        "name": "Производство машин для сортировки, дробления, смешивания и аналогичной обработки грунта, камня, руды и прочих минеральных веществ"
    },
    {
        "code": "28.92.5",
        "name": "Производство гусеничных тракторов"
    },
    {
        "code": "28.93",
        "name": "Производство машин и оборудования для производства пищевых продуктов, напитков и табачных изделий"
    },
    {
        "code": "28.94",
        "name": "Производство машин и оборудования для изготовления текстильных, швейных, меховых и кожаных изделий"
    },
    {
        "code": "28.94.1",
        "name": "Производство оборудования для подготовки, прядения текстильных волокон, производства тканых и трикотажных текстильных изделий"
    },
    {
        "code": "28.94.2",
        "name": "Производство прочего оборудования для текстильной и швейной промышленности, в том числе промышленных швейных машин"
    },
    {
        "code": "28.94.3",
        "name": "Производство оборудования для обработки шкур, сырых кож и выделанной кожи и для изготовления или ремонта обуви и прочих изделий"
    },
    {
        "code": "28.94.4",
        "name": "Производство бытовых швейных машин"
    },
    {
        "code": "28.94.5",
        "name": "Производство составных частей и принадлежностей машин для текстильного и швейного производства и для обработки кож"
    },
    {
        "code": "28.95",
        "name": "Производство машин и оборудования для изготовления бумаги и картона"
    },
    {
        "code": "28.96",
        "name": "Производство машин и оборудования для переработки пластмасс и резины"
    },
    {
        "code": "28.96.1",
        "name": "Производство аддитивных установок, использующих в качестве сырья полимеры, включая пластики"
    },
    {
        "code": "28.96.9",
        "name": "Производство аддитивных установок, использующих прочие виды сырья"
    },
    {
        "code": "28.99",
        "name": "Производство прочих машин и оборудования специального назначения, не включенных в другие группировки"
    },
    {
        "code": "28.99.1",
        "name": "Производство переплетного, наборного, включая фотонаборные машины, печатного оборудования и его составных частей"
    },
    {
        "code": "28.99.2",
        "name": "Производство оборудования и аппаратуры для производства полупроводниковых слитков или пластин, полупроводниковых устройств, электронных интегральных микросхем или плоскопанельных дисплеев"
    },
    {
        "code": "28.99.3",
        "name": "Производство пусковых устройств для воздушных судов, катапультирующих устройств для воздушных судов и т. п. оборудования"
    },
    {
        "code": "28.99.4",
        "name": "Производство оборудования технологического специального для объектов использования атомной энергии"
    },
    {
        "code": "28.99.41",
        "name": "Производство оборудования и устройств по разделению изотопов"
    },
    {
        "code": "28.99.42",
        "name": "Производство оборудования и устройств для транспортировки продуктов"
    },
    {
        "code": "28.99.43",
        "name": "Производство оборудования для автоматизированных систем управления технологическим процессом атомных электростанций"
    },
    {
        "code": "28.99.49",
        "name": "Производство оборудования технологического специального прочего для объектов использования атомной энергии, не включенного в другие группировки"
    },
    {
        "code": "28.99.5",
        "name": "Производство промышленных роботов и робототехнических устройств Эта группировка включает: производство промышленных роботов для многоцелевого специализированного использования; промышленных робототехнических комплексов; промышленных роботизированныхячеек; промышленных роботизированных линий"
    },
    {
        "code": "28.99.9",
        "name": "Производство оборудования специального назначения, не включенного в другие группировки"
    },
    {
        "code": "29",
        "name": "Производство автотранспортных средств, прицепов и полуприцепов"
    },
    {
        "code": "29.1",
        "name": "Производство автотранспортных средств"
    },
    {
        "code": "29.10",
        "name": "Производство автотранспортных средств"
    },
    {
        "code": "29.10.1",
        "name": "Производство двигателей внутреннего сгорания автотранспортных средств"
    },
    {
        "code": "29.10.11",
        "name": "Производство двигателей внутреннего сгорания с искровым зажиганием с рабочим объемом цилиндров не более 1000 см3"
    },
    {
        "code": "29.10.12",
        "name": "Производство двигателей внутреннего сгорания с рабочим объемом цилиндров более 1000 см3"
    },
    {
        "code": "29.10.13",
        "name": "Производство двигателей внутреннего сгорания с воспламенением от сжатия"
    },
    {
        "code": "29.10.2",
        "name": "Производство легковых автомобилей"
    },
    {
        "code": "29.10.3",
        "name": "Производство автобусов и троллейбусов"
    },
    {
        "code": "29.10.31",
        "name": "Производство автобусов"
    },
    {
        "code": "29.10.32",
        "name": "Производство троллейбусов"
    },
    {
        "code": "29.10.4",
        "name": "Производство грузовых автомобилей"
    },
    {
        "code": "29.10.5",
        "name": "Производство автомобилей специального назначения"
    },
    {
        "code": "29.2",
        "name": "Производство кузовов для автотранспортных средств; производство прицепов и полуприцепов"
    },
    {
        "code": "29.20",
        "name": "Производство кузовов для автотранспортных средств; производство прицепов и полуприцепов"
    },
    {
        "code": "29.20.1",
        "name": "Производство кузовов для легковых автомобилей"
    },
    {
        "code": "29.20.2",
        "name": "Производство кузовов для грузовых автомобилей"
    },
    {
        "code": "29.20.3",
        "name": "Производство кузовов для автобусов"
    },
    {
        "code": "29.20.4",
        "name": "Производство прицепов и полуприцепов"
    },
    {
        "code": "29.20.5",
        "name": "Производство грузовых контейнеров"
    },
    {
        "code": "29.3",
        "name": "Производство комплектующих и принадлежностей для автотранспортных средств"
    },
    {
        "code": "29.31",
        "name": "Производство электрического и электронного оборудования для автотранспортных средств"
    },
    {
        "code": "29.32",
        "name": "Производство прочих комплектующих и принадлежностей для автотранспортных средств"
    },
    {
        "code": "29.32.1",
        "name": "Производство сидений для автотранспортных средств"
    },
    {
        "code": "29.32.2",
        "name": "Производство ремней безопасности, подушек безопасности, их частей и принадлежностей кузовов"
    },
    {
        "code": "29.32.3",
        "name": "Производство частей и принадлежностей для автотранспортных средств, не включенных в другие группировки"
    },
    {
        "code": "30",
        "name": "Производство прочих транспортных средств и оборудования"
    },
    {
        "code": "30.1",
        "name": "Строительство кораблей, судов и лодок"
    },
    {
        "code": "30.11",
        "name": "Строительство кораблей, судов и плавучих конструкций"
    },
    {
        "code": "30.12",
        "name": "Строительство прогулочных и спортивных судов"
    },
    {
        "code": "30.2",
        "name": "Производство железнодорожных локомотивов и подвижного состава"
    },
    {
        "code": "30.20",
        "name": "Производство железнодорожных локомотивов и подвижного состава"
    },
    {
        "code": "30.20.1",
        "name": "Производство железнодорожных локомотивов"
    },
    {
        "code": "30.20.11",
        "name": "Производство магистральных электровозов"
    },
    {
        "code": "30.20.12",
        "name": "Производство магистральных тепловозов"
    },
    {
        "code": "30.20.13",
        "name": "Производство маневровых тепловозов"
    },
    {
        "code": "30.20.2",
        "name": "Производство моторных железнодорожных, трамвайных вагонов и вагонов метро, автодрезин, кроме транспортных средств для ремонта и технического обслуживания железнодорожных и трамвайных путей"
    },
    {
        "code": "30.20.3",
        "name": "Производство прочего подвижного состава"
    },
    {
        "code": "30.20.31",
        "name": "Производство транспортных средств для ремонта и технического обслуживания железнодорожных, трамвайных и прочих путей"
    },
    {
        "code": "30.20.32",
        "name": "Производство немоторных пассажирских железнодорожных, трамвайных вагонов и вагонов метро, багажных, почтовых и прочих вагонов специального назначения, кроме вагонов, предназначенных для ремонта и технического обслуживания путей"
    },
    {
        "code": "30.20.33",
        "name": "Производство несамоходных железнодорожных, трамвайных и прочих вагонов для перевозки грузов"
    },
    {
        "code": "30.20.4",
        "name": "Производство частей железнодорожных локомотивов, трамвайных и прочих моторных вагонов и подвижного состава; производство путевого оборудования и устройств для железнодорожных, трамвайных и прочих путей, механического и электромеханического оборудован ия для управления движением"
    },
    {
        "code": "30.20.9",
        "name": "Предоставление услуг по восстановлению и оснащению (завершению) железнодорожных локомотивов, трамвайных моторных вагонов и прочего подвижного состава"
    },
    {
        "code": "30.3",
        "name": "Производство летательных аппаратов, включая космические, и соответствующего оборудования"
    },
    {
        "code": "30.30",
        "name": "Производство летательных аппаратов, включая космические, и соответствующего оборудования"
    },
    {
        "code": "30.30.1",
        "name": "Производство силовых установок и двигателей для летательных аппаратов, включая космические; наземных тренажеров для летного состава; их частей"
    },
    {
        "code": "30.30.11",
        "name": "Производство двигателей летательных аппаратов с искровым зажиганием и их частей"
    },
    {
        "code": "30.30.12",
        "name": "Производство турбореактивных и турбовинтовых двигателей и их частей"
    },
    {
        "code": "30.30.13",
        "name": "Производство реактивных двигателей и их частей"
    },
    {
        "code": "30.30.14",
        "name": "Производство наземных тренажеров для летного состава и их частей"
    },
    {
        "code": "30.30.2",
        "name": "Производство аэростатов, дирижаблей, планеров, дельтапланов и прочих безмоторных летательных аппаратов"
    },
    {
        "code": "30.30.3",
        "name": "Производство вертолетов, самолетов и прочих летательных аппаратов"
    },
    {
        "code": "30.30.31",
        "name": "Производство вертолетов"
    },
    {
        "code": "30.30.32",
        "name": "Производство самолетов"
    },
    {
        "code": "30.30.39",
        "name": "Производство прочих летательных аппаратов"
    },
    {
        "code": "30.30.4",
        "name": "Производство космических аппаратов (в том числе спутников), ракет-носителей"
    },
    {
        "code": "30.30.41",
        "name": "Производство автоматических космических аппаратов"
    },
    {
        "code": "30.30.42",
        "name": "Производство пилотируемых и беспилотных космических кораблей и станций, включая орбитальные, межпланетные, многоразового использования"
    },
    {
        "code": "30.30.43",
        "name": "Производство ракет-носителей"
    },
    {
        "code": "30.30.44",
        "name": "Производство межконтинентальных баллистических ракет"
    },
    {
        "code": "30.30.5",
        "name": "Производство частей и принадлежностей летательных и космических аппаратов"
    },
    {
        "code": "30.4",
        "name": "Производство военных боевых машин"
    },
    {
        "code": "30.40",
        "name": "Производство военных боевых машин"
    },
    {
        "code": "30.9",
        "name": "Производство транспортных средств и оборудования, не включенных в другие группировки"
    },
    {
        "code": "30.91",
        "name": "Производство мотоциклов"
    },
    {
        "code": "30.92",
        "name": "Производство велосипедов и инвалидных колясок"
    },
    {
        "code": "30.92.1",
        "name": "Производство велосипедов"
    },
    {
        "code": "30.92.2",
        "name": "Производство инвалидных колясок"
    },
    {
        "code": "30.92.3",
        "name": "Производство частей и принадлежности велосипедов и инвалидных колясок"
    },
    {
        "code": "30.92.4",
        "name": "Производство детских колясок и их частей"
    },
    {
        "code": "30.99",
        "name": "Производство прочих транспортных средств и оборудования, не включенных в другие группировки"
    },
    {
        "code": "31",
        "name": "Производство мебели"
    },
    {
        "code": "31.0",
        "name": "Производство мебели"
    },
    {
        "code": "31.01",
        "name": "Производство мебели для офисов и предприятий торговли"
    },
    {
        "code": "31.02",
        "name": "Производство кухонной мебели"
    },
    {
        "code": "31.02.1",
        "name": "Производство кухонной мебели, кроме изготовленной по индивидуальному заказу населения"
    },
    {
        "code": "31.02.2",
        "name": "Изготовление кухонной мебели по индивидуальному заказу населения"
    },
    {
        "code": "31.03",
        "name": "Производство матрасов"
    },
    {
        "code": "31.09",
        "name": "Производство прочей мебели"
    },
    {
        "code": "31.09.1",
        "name": "Производство прочей мебели, кроме изготовленной по индивидуальному заказу населения"
    },
    {
        "code": "31.09.2",
        "name": "Изготовление прочей мебели и отдельных мебельных деталей, не включенных в другие группировки по индивидуальному заказу населения"
    },
    {
        "code": "32",
        "name": "Производство прочих готовых изделий"
    },
    {
        "code": "32.1",
        "name": "Производство ювелирных изделий, бижутерии и подобных товаров"
    },
    {
        "code": "32.11",
        "name": "Чеканка монет"
    },
    {
        "code": "32.12",
        "name": "Производство ювелирных изделий и аналогичных изделий"
    },
    {
        "code": "32.12.1",
        "name": "Производство изделий технического назначения из драгоценных металлов"
    },
    {
        "code": "32.12.2",
        "name": "Производство изделий технического назначения из драгоценных камней"
    },
    {
        "code": "32.12.3",
        "name": "Обработка алмазов"
    },
    {
        "code": "32.12.4",
        "name": "Обработка драгоценных, полудрагоценных, поделочных и синтетических камней, кроме алмазов и янтаря; производство изделий из полудрагоценных, поделочных и синтетических камней, кроме янтаря"
    },
    {
        "code": "32.12.5",
        "name": "Производство ювелирных изделий, медалей из драгоценных металлов и драгоценных камней"
    },
    {
        "code": "32.12.6",
        "name": "Изготовление ювелирных изделий и аналогичных изделий по индивидуальному заказу населения"
    },
    {
        "code": "32.12.7",
        "name": "Обработка янтаря и производство изделий из янтаря"
    },
    {
        "code": "32.13",
        "name": "Производство бижутерии и подобных товаров"
    },
    {
        "code": "32.13.1",
        "name": "Производство бижутерии и подобных товаров, кроме изготовленных по индивидуальному заказу"
    },
    {
        "code": "32.13.2",
        "name": "Изготовление бижутерии и подобных товаров по индивидуальному заказу населения"
    },
    {
        "code": "32.2",
        "name": "Производство музыкальных инструментов"
    },
    {
        "code": "32.20",
        "name": "Производство музыкальных инструментов"
    },
    {
        "code": "32.3",
        "name": "Производство спортивных товаров"
    },
    {
        "code": "32.30",
        "name": "Производство спортивных товаров"
    },
    {
        "code": "32.4",
        "name": "Производство игр и игрушек"
    },
    {
        "code": "32.40",
        "name": "Производство игр и игрушек"
    },
    {
        "code": "32.5",
        "name": "Производство медицинских инструментов и оборудования"
    },
    {
        "code": "32.50",
        "name": "Производство медицинских инструментов и оборудования"
    },
    {
        "code": "32.9",
        "name": "Производство изделий, не включенных в другие группировки"
    },
    {
        "code": "32.91",
        "name": "Производство метел и щеток"
    },
    {
        "code": "32.99",
        "name": "Производство прочих готовых изделий, не включенных в другие группировки"
    },
    {
        "code": "32.99.1",
        "name": "Производство головных защитных уборов и прочих средств защиты"
    },
    {
        "code": "32.99.2",
        "name": "Производство пишущих принадлежностей"
    },
    {
        "code": "32.99.3",
        "name": "Производство зонтов, тростей, пуговиц, кнопок, застежек-молний"
    },
    {
        "code": "32.99.4",
        "name": "Производство изделий из волоса человека или животных; производство аналогичных изделий из текстильных материалов"
    },
    {
        "code": "32.99.5",
        "name": "Производство зажигалок и прочих курительных принадлежностей"
    },
    {
        "code": "32.99.6",
        "name": "Производство изделий для праздников, карнавалов или прочих изделий для увеселения"
    },
    {
        "code": "32.99.7",
        "name": "Производство приборов, аппаратуры и моделей, предназначенных для демонстрационных целей"
    },
    {
        "code": "32.99.8",
        "name": "Производство изделий народных художественных промыслов"
    },
    {
        "code": "32.99.9",
        "name": "Производство прочих изделий, не включенных в другие группировки"
    },
    {
        "code": "33",
        "name": "Ремонт и монтаж машин и оборудования"
    },
    {
        "code": "33.1",
        "name": "Ремонт и монтаж металлических изделий, машин и оборудования"
    },
    {
        "code": "33.11",
        "name": "Ремонт металлоизделий"
    },
    {
        "code": "33.12",
        "name": "Ремонт машин и оборудования"
    },
    {
        "code": "33.13",
        "name": "Ремонт электронного и оптического оборудования"
    },
    {
        "code": "33.14",
        "name": "Ремонт электрического оборудования"
    },
    {
        "code": "33.15",
        "name": "Ремонт и техническое обслуживание судов и лодок"
    },
    {
        "code": "33.16",
        "name": "Ремонт и техническое обслуживание летательных аппаратов, включая космические"
    },
    {
        "code": "33.17",
        "name": "Ремонт и техническое обслуживание прочих транспортных средств и оборудования"
    },
    {
        "code": "33.19",
        "name": "Ремонт прочего оборудования"
    },
    {
        "code": "33.2",
        "name": "Монтаж промышленных машин и оборудования"
    },
    {
        "code": "33.20",
        "name": "Монтаж промышленных машин и оборудования"
    },
    {
        "code": "35",
        "name": "Обеспечение электрической энергией, газом и паром; кондиционирование воздуха"
    },
    {
        "code": "35.1",
        "name": "Производство, передача и распределение электроэнергии"
    },
    {
        "code": "35.11",
        "name": "Производство электроэнергии"
    },
    {
        "code": "35.11.1",
        "name": "Производство электроэнергии тепловыми электростанциями, в том числе деятельность по обеспечению работоспособности электростанций"
    },
    {
        "code": "35.11.2",
        "name": "Производство электроэнергии гидроэлектростанциями, в том числе деятельность по обеспечению работоспособности электростанций"
    },
    {
        "code": "35.11.3",
        "name": "Производство электроэнергии атомными электростанциями, в том числе деятельность по обеспечению работоспособности электростанций"
    },
    {
        "code": "35.11.4",
        "name": "Производство электроэнергии, получаемой из возобновляемых источников энергии, включая выработанную солнечными, ветровыми, геотермальными электростанциями, в том числе деятельность по обеспечению их работоспособности"
    },
    {
        "code": "35.12",
        "name": "Передача электроэнергии и технологическое присоединение к распределительным электросетям"
    },
    {
        "code": "35.12.1",
        "name": "Передача электроэнергии"
    },
    {
        "code": "35.12.2",
        "name": "Технологическое присоединение к распределительным электросетям"
    },
    {
        "code": "35.13",
        "name": "Распределение электроэнергии"
    },
    {
        "code": "35.14",
        "name": "Торговля электроэнергией"
    },
    {
        "code": "35.2",
        "name": "Производство и распределение газообразного топлива"
    },
    {
        "code": "35.21",
        "name": "Производство газа"
    },
    {
        "code": "35.21.1",
        "name": "Газификация угля"
    },
    {
        "code": "35.21.11",
        "name": "Газификация антрацита"
    },
    {
        "code": "35.21.12",
        "name": "Газификация каменного угля за исключением антрацита"
    },
    {
        "code": "35.21.13",
        "name": "Газификация бурого угля (лигнита)"
    },
    {
        "code": "35.21.2",
        "name": "Сжижение углей"
    },
    {
        "code": "35.21.21",
        "name": "Сжижение антрацита"
    },
    {
        "code": "35.21.22",
        "name": "Сжижение каменного угля за исключением антрацита"
    },
    {
        "code": "35.21.23",
        "name": "Сжижение бурого угля (лигнита)"
    },
    {
        "code": "35.22",
        "name": "Распределение газообразного топлива по газораспределительным сетям"
    },
    {
        "code": "35.22.1",
        "name": "Распределение природного, сухого (отбензиненного) газа по газораспределительным сетям"
    },
    {
        "code": "35.22.11",
        "name": "Распределение природного, сухого (отбензиненного) газа по газораспределительным сетям по тарифам, регулируемым государством"
    },
    {
        "code": "35.22.12",
        "name": "Распределение природного, сухого (отбензиненного) газа по газораспределительным сетям по тарифам, не регулируемым государством"
    },
    {
        "code": "35.22.2",
        "name": "Распределение сжиженных углеводородных газов по газораспределительным сетям"
    },
    {
        "code": "35.22.21",
        "name": "Распределение сжиженных углеводородных газов по газораспределительным сетям по тарифам, регулируемым государством"
    },
    {
        "code": "35.22.22",
        "name": "Распределение сжиженных углеводородных газов по газораспределительным сетям по тарифам, не регулируемым государством"
    },
    {
        "code": "35.23",
        "name": "Торговля газообразным топливом, подаваемым по распределительным сетям"
    },
    {
        "code": "35.23.1",
        "name": "Торговля природным, сухим (отбензиненным) газом, подаваемым по распределительным сетям"
    },
    {
        "code": "35.23.11",
        "name": "Торговля природным, сухим (отбензиненным) газом, подаваемым по распределительным сетям по регулируемым государством ценам (тарифам)"
    },
    {
        "code": "35.23.12",
        "name": "Торговля природным, сухим (отбензиненным) газом, подаваемым по распределительным сетям по не регулируемым государством ценам (тарифам)"
    },
    {
        "code": "35.23.2",
        "name": "Торговля сжиженными углеводородными газами, подаваемыми по распределительным сетям"
    },
    {
        "code": "35.23.21",
        "name": "Торговля сжиженными углеводородными газами, подаваемыми по распределительным сетям по регулируемым государством ценам (тарифам)"
    },
    {
        "code": "35.23.22",
        "name": "Торговля сжиженными углеводородными газами, подаваемыми по распределительным сетям по не регулируемым государством ценам (тарифам)"
    },
    {
        "code": "35.3",
        "name": "Производство, передача и распределение пара и горячей воды; кондиционирование воздуха"
    },
    {
        "code": "35.30",
        "name": "Производство, передача и распределение пара и горячей воды; кондиционирование воздуха"
    },
    {
        "code": "35.30.1",
        "name": "Производство пара и горячей воды (тепловой энергии)"
    },
    {
        "code": "35.30.11",
        "name": "Производство пара и горячей воды (тепловой энергии) тепловыми электростанциями"
    },
    {
        "code": "35.30.12",
        "name": "Производство пара и горячей воды (тепловой энергии) атомными электростанциями"
    },
    {
        "code": "35.30.13",
        "name": "Производство пара и горячей воды (тепловой энергии) прочими электростанциями и промышленными блок-станциями"
    },
    {
        "code": "35.30.14",
        "name": "Производство пара и горячей воды (тепловой энергии) котельными"
    },
    {
        "code": "35.30.15",
        "name": "Производство охлажденной воды или льда (натурального из воды) для целей охлаждения"
    },
    {
        "code": "35.30.2",
        "name": "Передача пара и горячей воды (тепловой энергии)"
    },
    {
        "code": "35.30.3",
        "name": "Распределение пара и горячей воды (тепловой энергии)"
    },
    {
        "code": "35.30.4",
        "name": "Обеспечение работоспособности котельных"
    },
    {
        "code": "35.30.5",
        "name": "Обеспечение работоспособности тепловых сетей"
    },
    {
        "code": "35.30.6",
        "name": "Торговля паром и горячей водой (тепловой энергией)"
    },
    {
        "code": "36",
        "name": "Забор, очистка и распределение воды"
    },
    {
        "code": "36.0",
        "name": "Забор, очистка и распределение воды"
    },
    {
        "code": "36.00",
        "name": "Забор, очистка и распределение воды"
    },
    {
        "code": "36.00.1",
        "name": "Забор и очистка воды для питьевых и промышленных нужд"
    },
    {
        "code": "36.00.2",
        "name": "Распределение воды для питьевых и промышленных нужд"
    },
    {
        "code": "37",
        "name": "Сбор и обработка сточных вод"
    },
    {
        "code": "37.0",
        "name": "Сбор и обработка сточных вод"
    },
    {
        "code": "37.00",
        "name": "Сбор и обработка сточных вод"
    },
    {
        "code": "38",
        "name": "Сбор, обработка и утилизация отходов; обработка вторичного сырья"
    },
    {
        "code": "38.1",
        "name": "Сбор отходов"
    },
    {
        "code": "38.11",
        "name": "Сбор неопасных отходов"
    },
    {
        "code": "38.12",
        "name": "Сбор опасных отходов"
    },
    {
        "code": "38.2",
        "name": "Обработка и утилизация отходов"
    },
    {
        "code": "38.21",
        "name": "Обработка и утилизация неопасных отходов"
    },
    {
        "code": "38.22",
        "name": "Обработка и утилизация опасных отходов"
    },
    {
        "code": "38.22.1",
        "name": "Обработка и утилизация радиоактивных отходов"
    },
    {
        "code": "38.22.11",
        "name": "Деятельность по обращению с отработавшим ядерным топливом"
    },
    {
        "code": "38.22.12",
        "name": "Деятельность по обращению с особыми радиоактивными отходами"
    },
    {
        "code": "38.22.13",
        "name": "Деятельность по обращению с удаляемыми радиоактивными отходами"
    },
    {
        "code": "38.22.9",
        "name": "Обработка и утилизация опасных отходов прочих, не включенных в другие группировки"
    },
    {
        "code": "38.3",
        "name": "Деятельность по обработке вторичного сырья"
    },
    {
        "code": "38.31",
        "name": "Демонтаж техники, не подлежащей восстановлению"
    },
    {
        "code": "38.32",
        "name": "Утилизация отсортированных материалов"
    },
    {
        "code": "38.32.1",
        "name": "Сортировка материалов для дальнейшего использования"
    },
    {
        "code": "38.32.11",
        "name": "Сортировка металлических материалов для дальнейшего использования"
    },
    {
        "code": "38.32.12",
        "name": "Сортировка неметаллических материалов для дальнейшего использования"
    },
    {
        "code": "38.32.2",
        "name": "Обработка отходов и лома драгоценных металлов"
    },
    {
        "code": "38.32.3",
        "name": "Обработка отходов и лома черных металлов"
    },
    {
        "code": "38.32.4",
        "name": "Обработка отходов и лома цветных металлов"
    },
    {
        "code": "38.32.41",
        "name": "Обработка отходов и лома металлов, содержащих медь"
    },
    {
        "code": "38.32.42",
        "name": "Обработка отходов и лома металлов, содержащих никель"
    },
    {
        "code": "38.32.43",
        "name": "Обработка отходов и лома металлов, содержащих алюминий"
    },
    {
        "code": "38.32.49",
        "name": "Обработка вторичного сырья, содержащего прочие цветные металлы"
    },
    {
        "code": "38.32.5",
        "name": "Обработка вторичного неметаллического сырья"
    },
    {
        "code": "38.32.51",
        "name": "Обработка отходов и лома стекла"
    },
    {
        "code": "38.32.52",
        "name": "Обработка отходов бумаги и картона"
    },
    {
        "code": "38.32.53",
        "name": "Обработка отходов и лома пластмасс"
    },
    {
        "code": "38.32.54",
        "name": "Обработка отходов резины"
    },
    {
        "code": "38.32.55",
        "name": "Обработка отходов текстильных материалов"
    },
    {
        "code": "38.32.59",
        "name": "Обработка прочего вторичного неметаллического сырья"
    },
    {
        "code": "39",
        "name": "Предоставление услуг в области ликвидации последствий загрязнений и прочих услуг, связанных с удалением отходов"
    },
    {
        "code": "39.0",
        "name": "Предоставление услуг в области ликвидации последствий загрязнений и прочих услуг, связанных с удалением отходов"
    },
    {
        "code": "39.00",
        "name": "Предоставление услуг в области ликвидации последствий загрязнений и прочих услуг, связанных с удалением отходов"
    },
    {
        "code": "41",
        "name": "Строительство зданий"
    },
    {
        "code": "41.1",
        "name": "Разработка строительных проектов"
    },
    {
        "code": "41.10",
        "name": "Разработка строительных проектов"
    },
    {
        "code": "41.2",
        "name": "Строительство жилых и нежилых зданий"
    },
    {
        "code": "41.20",
        "name": "Строительство жилых и нежилых зданий"
    },
    {
        "code": "42",
        "name": "Строительство инженерных сооружений"
    },
    {
        "code": "42.1",
        "name": "Строительство автомобильных и железных дорог"
    },
    {
        "code": "42.11",
        "name": "Строительство автомобильных дорог и автомагистралей"
    },
    {
        "code": "42.12",
        "name": "Строительство железных дорог и метро"
    },
    {
        "code": "42.13",
        "name": "Строительство мостов и тоннелей"
    },
    {
        "code": "42.2",
        "name": "Строительство инженерных коммуникаций"
    },
    {
        "code": "42.21",
        "name": "Строительство инженерных коммуникаций для водоснабжения и водоотведения, газоснабжения"
    },
    {
        "code": "42.22",
        "name": "Строительство коммунальных объектов для обеспечения электроэнергией и телекоммуникациями"
    },
    {
        "code": "42.22.1",
        "name": "Строительство междугородних линий электропередачи и связи"
    },
    {
        "code": "42.22.2",
        "name": "Строительство местных линий электропередачи и связи"
    },
    {
        "code": "42.22.3",
        "name": "Строительство электростанций"
    },
    {
        "code": "42.9",
        "name": "Строительство прочих инженерных сооружений"
    },
    {
        "code": "42.91",
        "name": "Строительство водных сооружений"
    },
    {
        "code": "42.91.1",
        "name": "Строительство портовых сооружений"
    },
    {
        "code": "42.91.2",
        "name": "Строительство гидротехнических сооружений"
    },
    {
        "code": "42.91.3",
        "name": "Строительство ирригационных систем"
    },
    {
        "code": "42.91.4",
        "name": "Производство дноочистительных, дноуглубительных и берегоукрепительных работ"
    },
    {
        "code": "42.91.5",
        "name": "Производство подводных работ, включая водолазные"
    },
    {
        "code": "42.99",
        "name": "Строительство прочих инженерных сооружений, не включенных в другие группировки"
    },
    {
        "code": "43",
        "name": "Работы строительные специализированные"
    },
    {
        "code": "43.1",
        "name": "Разборка и снос зданий, подготовка строительного участка"
    },
    {
        "code": "43.11",
        "name": "Разборка и снос зданий"
    },
    {
        "code": "43.12",
        "name": "Подготовка строительной площадки"
    },
    {
        "code": "43.12.1",
        "name": "Расчистка территории строительной площадки"
    },
    {
        "code": "43.12.2",
        "name": "Производство дренажных работ на сельскохозяйственных землях, землях лесных территорий, а также на строительных площадках"
    },
    {
        "code": "43.12.3",
        "name": "Производство земляных работ"
    },
    {
        "code": "43.12.4",
        "name": "Подготовка участка к разработке и добыче полезных ископаемых, за исключением нефтяных и газовых участков"
    },
    {
        "code": "43.13",
        "name": "Разведочное бурение"
    },
    {
        "code": "43.2",
        "name": "Производство электромонтажных, санитарно-технических и прочих строительно-монтажных работ"
    },
    {
        "code": "43.21",
        "name": "Производство электромонтажных работ"
    },
    {
        "code": "43.22",
        "name": "Производство санитарно-технических работ, монтаж отопительных систем и систем кондиционирования воздуха"
    },
    {
        "code": "43.29",
        "name": "Производство прочих строительно-монтажных работ"
    },
    {
        "code": "43.3",
        "name": "Работы строительные отделочные"
    },
    {
        "code": "43.31",
        "name": "Производство штукатурных работ"
    },
    {
        "code": "43.32",
        "name": "Работы столярные и плотничные"
    },
    {
        "code": "43.32.1",
        "name": "Установка дверей (кроме автоматических и вращающихся), окон, дверных и оконных рам из дерева или прочих материалов"
    },
    {
        "code": "43.32.2",
        "name": "Работы по установке внутренних лестниц, встроенных шкафов, встроенного кухонного оборудования"
    },
    {
        "code": "43.32.3",
        "name": "Производство работ по внутренней отделке зданий (включая потолки, раздвижные и съемные перегородки и т.д.)"
    },
    {
        "code": "43.33",
        "name": "Работы по устройству покрытий полов и облицовке стен"
    },
    {
        "code": "43.34",
        "name": "Производство малярных и стекольных работ"
    },
    {
        "code": "43.34.1",
        "name": "Производство малярных работ"
    },
    {
        "code": "43.34.2",
        "name": "Производство стекольных работ"
    },
    {
        "code": "43.39",
        "name": "Производство прочих отделочных и завершающих работ"
    },
    {
        "code": "43.9",
        "name": "Работы строительные специализированные прочие"
    },
    {
        "code": "43.91",
        "name": "Производство кровельных работ"
    },
    {
        "code": "43.99",
        "name": "Работы строительные специализированные прочие, не включенные в другие группировки"
    },
    {
        "code": "43.99.1",
        "name": "Работы гидроизоляционные"
    },
    {
        "code": "43.99.2",
        "name": "Работы по установке строительных лесов и подмостей"
    },
    {
        "code": "43.99.3",
        "name": "Работы свайные и работы по строительству фундаментов"
    },
    {
        "code": "43.99.4",
        "name": "Работы бетонные и железобетонные"
    },
    {
        "code": "43.99.5",
        "name": "Работы по монтажу стальных строительных конструкций"
    },
    {
        "code": "43.99.6",
        "name": "Работы каменные и кирпичные"
    },
    {
        "code": "43.99.7",
        "name": "Работы по сборке и монтажу сборных конструкций"
    },
    {
        "code": "43.99.9",
        "name": "Работы строительные специализированные, не включенные в другие группировки"
    },
    {
        "code": "45",
        "name": "Торговля оптовая и розничная автотранспортными средствами и мотоциклами и их ремонт"
    },
    {
        "code": "45.1",
        "name": "Торговля автотранспортными средствами"
    },
    {
        "code": "45.11",
        "name": "Торговля легковыми автомобилями и грузовыми автомобилями малой грузоподъемности"
    },
    {
        "code": "45.11.1",
        "name": "Торговля оптовая легковыми автомобилями и легкими автотранспортными средствами"
    },
    {
        "code": "45.11.2",
        "name": "Торговля розничная легковыми автомобилями и легкими автотранспортными средствами в специализированных магазинах"
    },
    {
        "code": "45.11.3",
        "name": "Торговля розничная легковыми автомобилями и легкими автотранспортными средствами прочая"
    },
    {
        "code": "45.11.31",
        "name": "Торговля розничная легковыми автомобилями и легкими автотранспортными средствами через информационно-коммуникационную сеть Интернет"
    },
    {
        "code": "45.11.39",
        "name": "Торговля розничная легковыми автомобилями и легкими автотранспортными средствами прочая, не включенная в другие группировки"
    },
    {
        "code": "45.11.4",
        "name": "Торговля оптовая легковыми автомобилями и легкими автотранспортными средствами за вознаграждение или на договорной основе"
    },
    {
        "code": "45.11.41",
        "name": "Торговля оптовая легковыми автомобилями и легкими автотранспортными средствами через информационно-коммуникационную сеть Интернет за вознаграждение или на договорной основе"
    },
    {
        "code": "45.11.49",
        "name": "Торговля оптовая легковыми автомобилями и легкими автотранспортными средствами за вознаграждение или на договорной основе прочая"
    },
    {
        "code": "45.19",
        "name": "Торговля прочими автотранспортными средствами"
    },
    {
        "code": "45.19.1",
        "name": "Торговля оптовая прочими автотранспортными средствами, кроме пассажирских"
    },
    {
        "code": "45.19.2",
        "name": "Торговля розничная прочими автотранспортными средствами, кроме пассажирских, в специализированных магазинах"
    },
    {
        "code": "45.19.3",
        "name": "Торговля розничная прочими автотранспортными средствами, кроме пассажирских, прочая"
    },
    {
        "code": "45.19.31",
        "name": "Торговля розничная прочими автотранспортными средствами, кроме пассажирских, через информационно-коммуникационную сеть Интернет"
    },
    {
        "code": "45.19.39",
        "name": "Торговля розничная прочими автотранспортными средствами, кроме пассажирских, прочая, не включенная в другие группировки"
    },
    {
        "code": "45.19.4",
        "name": "Торговля оптовая прочими автотранспортными средствами, кроме пассажирских, за вознаграждение или на договорной основе"
    },
    {
        "code": "45.19.41",
        "name": "Торговля оптовая прочими автотранспортными средствами, кроме пассажирских, через информационно-коммуникационную сеть Интернет за вознаграждение или на договорной основе"
    },
    {
        "code": "45.19.49",
        "name": "Торговля оптовая прочими автотранспортными средствами, кроме пассажирских, за вознаграждение или на договорной основе прочая"
    },
    {
        "code": "45.2",
        "name": "Техническое обслуживание и ремонт автотранспортных средств"
    },
    {
        "code": "45.20",
        "name": "Техническое обслуживание и ремонт автотранспортных средств"
    },
    {
        "code": "45.20.1",
        "name": "Техническое обслуживание и ремонт легковых автомобилей и легких грузовых автотранспортных средств"
    },
    {
        "code": "45.20.2",
        "name": "Техническое обслуживание и ремонт прочих автотранспортных средств"
    },
    {
        "code": "45.20.3",
        "name": "Мойка автотранспортных средств, полирование и предоставление аналогичных услуг"
    },
    {
        "code": "45.20.4",
        "name": "Техническая помощь на дорогах и транспортирование неисправных автотранспортных средств к месту их ремонта или стоянки"
    },
    {
        "code": "45.3",
        "name": "Торговля автомобильными деталями, узлами и принадлежностями"
    },
    {
        "code": "45.31",
        "name": "Торговля оптовая автомобильными деталями, узлами и принадлежностями"
    },
    {
        "code": "45.31.1",
        "name": "Торговля оптовая автомобильными деталями, узлами и принадлежностями, кроме деятельности агентов"
    },
    {
        "code": "45.31.2",
        "name": "Деятельность агентов по оптовой торговле автомобильными деталями, узлами и принадлежностями"
    },
    {
        "code": "45.32",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями"
    },
    {
        "code": "45.32.1",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями в специализированных магазинах"
    },
    {
        "code": "45.32.2",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями прочая"
    },
    {
        "code": "45.32.21",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями через информационно-коммуникационную сеть Интернет"
    },
    {
        "code": "45.32.22",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями по почтовым заказам"
    },
    {
        "code": "45.32.29",
        "name": "Торговля розничная автомобильными деталями, узлами и принадлежностями прочая, не включенная в другие группировки"
    },
    {
        "code": "45.4",
        "name": "Торговля мотоциклами, их деталями, узлами и принадлежностями; техническое обслуживание и ремонт мотоциклов"
    },
    {
        "code": "45.40",
        "name": "Торговля мотоциклами, их деталями, узлами и принадлежностями; техническое обслуживание и ремонт мотоциклов"
    },
    {
        "code": "45.40.1",
        "name": "Торговля оптовая мотоциклами, их деталями, узлами и принадлежностями"
    },
    {
        "code": "45.40.2",
        "name": "Торговля розничная мотоциклами, их деталями, составными частями и принадлежностями в специализированных магазинах"
    },
    {
        "code": "45.40.3",
        "name": "Торговля розничная мотоциклами, их деталями, узлами и принадлежностями прочая"
    },
    {
        "code": "45.40.4",
        "name": "Деятельность агентов по оптовой торговле мотоциклами, их деталями, узлами и принадлежностями"
    },
    {
        "code": "45.40.5",
        "name": "Техническое обслуживание и ремонт мотоциклов и мототранспортных средств"
    },
    {
        "code": "46",
        "name": "Торговля оптовая, кроме оптовой торговли автотранспортными средствами и мотоциклами"
    },
    {
        "code": "46.1",
        "name": "Торговля оптовая за вознаграждение или на договорной основе"
    },
    {
        "code": "46.11",
        "name": "Деятельность агентов по оптовой торговле сельскохозяйственным сырьем, живыми животными, текстильным сырьем и полуфабрикатами"
    },
    {
        "code": "46.11.1",
        "name": "Деятельность агентов по оптовой торговле живыми животными"
    },
    {
        "code": "46.11.2",
        "name": "Деятельность агентов по оптовой торговле цветами и растениями"
    },
    {
        "code": "46.11.3",
        "name": "Деятельность агентов по оптовой торговле прочим сельскохозяйственным сырьем, текстильным сырьем и полуфабрикатами"
    },
    {
        "code": "46.11.31",
        "name": "Деятельность агентов по оптовой торговле зерном"
    },
    {
        "code": "46.11.32",
        "name": "Деятельность агентов по оптовой торговле семенами, кроме семян масличных культур"
    },
    {
        "code": "46.11.33",
        "name": "Деятельность агентов по оптовой торговле семенами масличных культур"
    },
    {
        "code": "46.11.34",
        "name": "Деятельность агентов по оптовой торговле кормами для сельскохозяйственных животных"
    },
    {
        "code": "46.11.35",
        "name": "Деятельность агентов по оптовой торговле текстильным сырьем и полуфабрикатами"
    },
    {
        "code": "46.11.39",
        "name": "Деятельность агентов по оптовой торговле прочими сельскохозяйственным сырьем и сельскохозяйственными полуфабрикатами, не включенными в другие группировки"
    },
    {
        "code": "46.12",
        "name": "Деятельность агентов по оптовой торговле топливом, рудами, металлами и химическими веществами"
    },
    {
        "code": "46.12.1",
        "name": "Деятельность агентов по оптовой торговле твердым, жидким и газообразным топливом и связанными продуктами"
    },
    {
        "code": "46.12.2",
        "name": "Деятельность агентов по оптовой торговле рудами и металлами в первичных формах"
    },
    {
        "code": "46.12.21",
        "name": "Деятельность агентов по оптовой торговле рудами"
    },
    {
        "code": "46.12.22",
        "name": "Деятельность агентов по оптовой торговле металлами в первичных формах"
    },
    {
        "code": "46.12.3",
        "name": "Деятельность агентов по оптовой торговле промышленными и техническими химическими веществами, удобрениями и агрохимикатами"
    },
    {
        "code": "46.12.31",
        "name": "Деятельность агентов по оптовой торговле промышленными и техническими химическими веществами"
    },
    {
        "code": "46.12.32",
        "name": "Деятельность агентов по оптовой торговле удобрениями и агрохимикатами"
    },
    {
        "code": "46.13",
        "name": "Деятельность агентов по оптовой торговле лесоматериалами и строительными материалами"
    },
    {
        "code": "46.13.1",
        "name": "Деятельность агентов по оптовой торговле лесоматериалами"
    },
    {
        "code": "46.13.2",
        "name": "Деятельность агентов по оптовой торговле строительными материалами"
    },
    {
        "code": "46.14",
        "name": "Деятельность агентов по оптовой торговле машинами, промышленным оборудованием, судами и летательными аппаратами"
    },
    {
        "code": "46.14.1",
        "name": "Деятельность агентов по оптовой торговле вычислительной техникой, телекоммуникационным оборудованием и прочим офисным оборудованием"
    },
    {
        "code": "46.14.2",
        "name": "Деятельность агентов по оптовой торговле судами, летательными аппаратами и прочими транспортными средствами, не включенными в другие группировки"
    },
    {
        "code": "46.14.9",
        "name": "Деятельность агентов по оптовой торговле прочими видами машин и промышленным оборудованием"
    },
    {
        "code": "46.15",
        "name": "Деятельность агентов по оптовой торговле мебелью, бытовыми товарами, скобяными, ножевыми и прочими металлическими изделиями"
    },
    {
        "code": "46.15.1",
        "name": "Деятельность агентов по оптовой торговле мебелью"
    },
    {
        "code": "46.15.2",
        "name": "Деятельность агентов по оптовой торговле скобяными, ножевыми и прочими бытовыми металлическими изделиями"
    },
    {
        "code": "46.15.3",
        "name": "Деятельность агентов по оптовой торговле электротоварами и бытовыми электроустановочными изделиями"
    },
    {
        "code": "46.15.4",
        "name": "Деятельность агентов по оптовой торговле радио- и телеаппаратурой, техническими носителями информации"
    },
    {
        "code": "46.15.9",
        "name": "Деятельность агентов по оптовой торговле прочими бытовыми товарами, не включенными в другие группировки"
    },
    {
        "code": "46.16",
        "name": "Деятельность агентов по оптовой торговле текстильными изделиями, одеждой, обувью, изделиями из кожи и меха"
    },
    {
        "code": "46.16.1",
        "name": "Деятельность агентов по оптовой торговле текстильными изделиями"
    },
    {
        "code": "46.16.2",
        "name": "Деятельность агентов по оптовой торговле одеждой, изделиями из меха и обувью"
    },
    {
        "code": "46.16.3",
        "name": "Деятельность агентов по оптовой торговле изделиями из кожи и дорожными принадлежностями"
    },
    {
        "code": "46.17",
        "name": "Деятельность агентов по оптовой торговле пищевыми продуктами, напитками и табачными изделиями"
    },
    {
        "code": "46.17.1",
        "name": "Деятельность агентов по оптовой торговле пищевыми продуктами"
    },
    {
        "code": "46.17.2",
        "name": "Деятельность агентов по оптовой торговле напитками"
    },
    {
        "code": "46.17.21",
        "name": "Деятельность агентов по оптовой торговле безалкогольными напитками"
    },
    {
        "code": "46.17.22",
        "name": "Деятельность агентов по оптовой торговле алкогольными напитками, кроме пива"
    },
    {
        "code": "46.17.23",
        "name": "Деятельность агентов по оптовой торговле пивом"
    },
    {
        "code": "46.17.3",
        "name": "Деятельность агентов по оптовой торговле табачными изделиями"
    },
    {
        "code": "46.18",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле прочими отдельными видами товаров"
    },
    {
        "code": "46.18.1",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле фармацевтической продукцией, изделиями, применяемыми в медицинских целях, парфюмерными и косметическими товарами, включая мыло, и чистящими средствами"
    },
    {
        "code": "46.18.11",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле фармацевтической продукцией"
    },
    {
        "code": "46.18.12",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле изделиями, применяемыми в медицинских целях"
    },
    {
        "code": "46.18.13",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле парфюмерными и косметическими товарами, включая мыло"
    },
    {
        "code": "46.18.14",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле чистящими средствами"
    },
    {
        "code": "46.18.2",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле играми и игрушками, спортивными товарами, велосипедами, книгами, газетами, журналами, писчебумажными и канцелярскими товарами, музыкальными инструментами, часами и ювелирными изделиями, фот отоварами и оптическими товарами"
    },
    {
        "code": "46.18.3",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле техникой, оборудованием и инструментами, применяемыми в медицинских целях"
    },
    {
        "code": "46.18.9",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле товарами, не включенными в другие группировки"
    },
    {
        "code": "46.18.91",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле бумагой и картоном"
    },
    {
        "code": "46.18.92",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле древесным сырьем и необработанными лесоматериалами"
    },
    {
        "code": "46.18.93",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле отходами, ломом и материалами для переработки"
    },
    {
        "code": "46.18.99",
        "name": "Деятельность агентов, специализирующихся на оптовой торговле прочими товарами, не включенными в другие группировки"
    },
    {
        "code": "46.19",
        "name": "Деятельность агентов по оптовой торговле универсальным ассортиментом товаров"
    },
    {
        "code": "46.2",
        "name": "Торговля оптовая сельскохозяйственным сырьем и живыми животными"
    },
    {
        "code": "46.21",
        "name": "Торговля оптовая зерном, необработанным табаком, семенами и кормами для сельскохозяйственных животных"
    },
    {
        "code": "46.21.1",
        "name": "Торговля оптовая зерном, семенами и кормами для животных"
    },
    {
        "code": "46.21.11",
        "name": "Торговля оптовая зерном"
    },
    {
        "code": "46.21.12",
        "name": "Торговля оптовая семенами, кроме семян масличных культур"
    },
    {
        "code": "46.21.13",
        "name": "Торговля оптовая масличными семенами и маслосодержащими плодами"
    },
    {
        "code": "46.21.14",
        "name": "Торговля оптовая кормами для сельскохозяйственных животных"
    },
    {
        "code": "46.21.19",
        "name": "Торговля оптовая сельскохозяйственным сырьем, не включенным в другие группировки"
    },
    {
        "code": "46.21.2",
        "name": "Торговля оптовая необработанным табаком"
    },
    {
        "code": "46.22",
        "name": "Торговля оптовая цветами и растениями"
    },
    {
        "code": "46.23",
        "name": "Торговля оптовая живыми животными"
    },
    {
        "code": "46.24",
        "name": "Торговля оптовая шкурами и кожей"
    },
    {
        "code": "46.3",
        "name": "Торговля оптовая пищевыми продуктами, напитками и табачными изделиями"
    },
    {
        "code": "46.31",
        "name": "Торговля оптовая фруктами и овощами"
    },
    {
        "code": "46.31.1",
        "name": "Торговля оптовая свежими овощами, фруктами и орехами"
    },
    {
        "code": "46.31.11",
        "name": "Торговля оптовая свежим картофелем"
    },
    {
        "code": "46.31.12",
        "name": "Торговля оптовая прочими свежими овощами"
    },
    {
        "code": "46.31.13",
        "name": "Торговля оптовая свежими фруктами и орехами"
    },
    {
        "code": "46.31.2",
        "name": "Торговля оптовая консервированными овощами, фруктами и орехами"
    },
    {
        "code": "46.32",
        "name": "Торговля оптовая мясом и мясными продуктами"
    },
    {
        "code": "46.32.1",
        "name": "Торговля оптовая мясом и мясом птицы, включая субпродукты"
    },
    {
        "code": "46.32.2",
        "name": "Торговля оптовая продуктами из мяса и мяса птицы"
    },
    {
        "code": "46.32.3",
        "name": "Торговля оптовая консервами из мяса и мяса птицы"
    },
    {
        "code": "46.33",
        "name": "Торговля оптовая молочными продуктами, яйцами и пищевыми маслами и жирами"
    },
    {
        "code": "46.33.1",
        "name": "Торговля оптовая молочными продуктами"
    },
    {
        "code": "46.33.2",
        "name": "Торговля оптовая яйцами"
    },
    {
        "code": "46.33.3",
        "name": "Торговля оптовая пищевыми маслами и жирами"
    },
    {
        "code": "46.34",
        "name": "Торговля оптовая напитками"
    },
    {
        "code": "46.34.1",
        "name": "Торговля оптовая соками, минеральной водой и прочими безалкогольными напитками"
    },
    {
        "code": "46.34.2",
        "name": "Торговля оптовая алкогольными напитками, включая пиво и пищевой этиловый спирт"
    },
    {
        "code": "46.34.21",
        "name": "Торговля оптовая алкогольными напитками, кроме пива и пищевого этилового спирта"
    },
    {
        "code": "46.34.22",
        "name": "Торговля оптовая пищевым этиловым спиртом"
    },
    {
        "code": "46.34.23",
        "name": "Торговля оптовая пивом"
    },
    {
        "code": "46.34.3",
        "name": "Закупка вина в больших емкостях с последующим розливом в мелкую тару без переработки"
    },
    {
        "code": "46.35",
        "name": "Торговля оптовая табачными изделиями"
    },
    {
        "code": "46.36",
        "name": "Торговля оптовая сахаром, шоколадом и сахаристыми кондитерскими изделиями"
    },
    {
        "code": "46.36.1",
        "name": "Торговля оптовая сахаром"
    },
    {
        "code": "46.36.2",
        "name": "Торговля оптовая шоколадом и сахаристыми кондитерскими изделиями"
    },
    {
        "code": "46.36.3",
        "name": "Торговля оптовая мучными кондитерскими изделиями"
    },
    {
        "code": "46.36.4",
        "name": "Торговля оптовая хлебобулочными изделиями"
    },
    {
        "code": "46.37",
        "name": "Торговля оптовая кофе, чаем, какао и пряностями"
    },
    {
        "code": "46.38",
        "name": "Торговля оптовая прочими пищевыми продуктами, включая рыбу, ракообразных и моллюсков"
    },
    {
        "code": "46.38.1",
        "name": "Торговля оптовая рыбой, ракообразными и моллюсками, консервами и пресервами из рыбы и морепродуктов"
    },
    {
        "code": "46.38.2",
        "name": "Торговля оптовая прочими пищевыми продуктами"
    },
    {
        "code": "46.38.21",
        "name": "Торговля оптовая гомогенизированными пищевыми продуктами, детским и диетическим питанием"
    },
    {
        "code": "46.38.22",
        "name": "Торговля оптовая кормами для домашних животных"
    },
    {
        "code": "46.38.23",
        "name": "Торговля оптовая мукой и макаронными изделиями"
    },
    {
        "code": "46.38.24",
        "name": "Торговля оптовая крупами"
    },
    {
        "code": "46.38.25",
        "name": "Торговля оптовая солью"
    },
    {
        "code": "46.38.26",
        "name": "Торговля оптовая мороженым и замороженными десертами"
    },
    {
        "code": "46.38.29",
        "name": "Торговля оптовая прочими пищевыми продуктами, не включенными в другие группировки"
    },
    {
        "code": "46.39",
        "name": "Торговля оптовая неспециализированная пищевыми продуктами, напитками и табачными изделиями"
    },
    {
        "code": "46.39.1",
        "name": "Торговля оптовая неспециализированная замороженными пищевыми продуктами"
    },
    {
        "code": "46.39.2",
        "name": "Торговля оптовая неспециализированная незамороженными пищевыми продуктами, напитками и табачными изделиями"
    },
    {
        "code": "46.4",
        "name": "Торговля оптовая непродовольственными потребительскими товарами"
    },
    {
        "code": "46.41",
        "name": "Торговля оптовая текстильными изделиями"
    },
    {
        "code": "46.41.1",
        "name": "Торговля оптовая текстильными изделиями, кроме текстильных галантерейных изделий"
    },
    {
        "code": "46.41.2",
        "name": "Торговля оптовая галантерейными изделиями"
    },
    {
        "code": "46.42",
        "name": "Торговля оптовая одеждой и обувью"
    },
    {
        "code": "46.42.1",
        "name": "Торговля оптовая одеждой"
    },
    {
        "code": "46.42.11",
        "name": "Торговля оптовая одеждой, включая спортивную, кроме нательного белья"
    },
    {
        "code": "46.42.12",
        "name": "Торговля оптовая нательным бельем"
    },
    {
        "code": "46.42.13",
        "name": "Торговля оптовая изделиями из меха"
    },
    {
        "code": "46.42.14",
        "name": "Торговля оптовая аксессуарами одежды и головными уборами, кроме меховых"
    },
    {
        "code": "46.42.2",
        "name": "Торговля оптовая обувью"
    },
    {
        "code": "46.43",
        "name": "Торговля оптовая бытовыми электротоварами"
    },
    {
        "code": "46.43.1",
        "name": "Торговля оптовая электрической бытовой техникой"
    },
    {
        "code": "46.43.2",
        "name": "Торговля оптовая радио-, теле- и видеоаппаратурой и аппаратурой для цифровых видеодисков (DVD)"
    },
    {
        "code": "46.43.3",
        "name": "Торговля оптовая грампластинками, аудио- и видеомагнитными лентами, компакт-дисками (CD) и цифровыми видеодисками (DVD) (кроме носителей без записей)"
    },
    {
        "code": "46.43.4",
        "name": "Торговля оптовая фототоварами и оптическими товарами"
    },
    {
        "code": "46.44",
        "name": "Торговля оптовая изделиями из керамики и стекла и чистящими средствами"
    },
    {
        "code": "46.44.1",
        "name": "Торговля оптовая изделиями из керамики и стекла"
    },
    {
        "code": "46.44.2",
        "name": "Торговля оптовая чистящими средствами"
    },
    {
        "code": "46.45",
        "name": "Торговля оптовая парфюмерными и косметическими товарами"
    },
    {
        "code": "46.45.1",
        "name": "Торговля оптовая парфюмерными и косметическими товарами, кроме мыла"
    },
    {
        "code": "46.45.2",
        "name": "Торговля оптовая туалетным и хозяйственным мылом"
    },
    {
        "code": "46.46",
        "name": "Торговля оптовая фармацевтической продукцией"
    },
    {
        "code": "46.46.1",
        "name": "Торговля оптовая фармацевтической продукцией"
    },
    {
        "code": "46.46.2",
        "name": "Торговля оптовая изделиями, применяемыми в медицинских целях"
    },
    {
        "code": "46.47",
        "name": "Торговля оптовая мебелью, коврами и осветительным оборудованием"
    },
    {
        "code": "46.47.1",
        "name": "Торговля оптовая бытовой мебелью"
    },
    {
        "code": "46.47.2",
        "name": "Торговля оптовая осветительным оборудованием"
    },
    {
        "code": "46.47.3",
        "name": "Торговля оптовая коврами и ковровыми изделиями"
    },
    {
        "code": "46.48",
        "name": "Торговля оптовая часами и ювелирными изделиями"
    },
    {
        "code": "46.48.1",
        "name": "Торговля оптовая часами"
    },
    {
        "code": "46.48.2",
        "name": "Торговля оптовая ювелирными изделиями"
    },
    {
        "code": "46.49",
        "name": "Торговля оптовая прочими бытовыми товарами"
    },
    {
        "code": "46.49.1",
        "name": "Торговля оптовая ножевыми изделиями и бытовой металлической посудой"
    },
    {
        "code": "46.49.2",
        "name": "Торговля оптовая плетеными изделиями, изделиями из пробки, бондарными изделиями и прочими бытовыми деревянными изделиями"
    },
    {
        "code": "46.49.3",
        "name": "Торговля оптовая книгами, газетами и журналами, писчебумажными и канцелярскими товарами"
    },
    {
        "code": "46.49.31",
        "name": "Торговля оптовая книгами"
    },
    {
        "code": "46.49.32",
        "name": "Торговля оптовая газетами и журналами"
    },
    {
        "code": "46.49.33",
        "name": "Торговля оптовая писчебумажными и канцелярскими товарами"
    },
    {
        "code": "46.49.4",
        "name": "Торговля оптовая прочими потребительскими товарами"
    },
    {
        "code": "46.49.41",
        "name": "Торговля оптовая музыкальными инструментами и нотными изданиями"
    },
    {
        "code": "46.49.42",
        "name": "Торговля оптовая играми и игрушками"
    },
    {
        "code": "46.49.43",
        "name": "Торговля оптовая спортивными товарами, включая велосипеды"
    },
    {
        "code": "46.49.44",
        "name": "Торговля оптовая изделиями из кожи и дорожными аксессуарами"
    },
    {
        "code": "46.49.49",
        "name": "Торговля оптовая прочими потребительскими товарами, не включенными в другие группировки"
    },
    {
        "code": "46.49.5",
        "name": "Торговля оптовая неэлектрическими бытовыми приборами"
    },
    {
        "code": "46.5",
        "name": "Торговля оптовая информационным и коммуникационным оборудованием"
    },
    {
        "code": "46.51",
        "name": "Торговля оптовая компьютерами, периферийными устройствами к компьютерам и программным обеспечением"
    },
    {
        "code": "46.51.1",
        "name": "Торговля оптовая компьютерами и периферийными устройствами"
    },
    {
        "code": "46.51.2",
        "name": "Торговля оптовая программным обеспечением"
    },
    {
        "code": "46.52",
        "name": "Торговля оптовая электронным и телекоммуникационным оборудованием и его запасными частями"
    },
    {
        "code": "46.52.1",
        "name": "Торговля оптовая телекоммуникационным оборудованием и его запасными частями"
    },
    {
        "code": "46.52.2",
        "name": "Торговля оптовая электронным оборудованием и его запасными частями"
    },
    {
        "code": "46.52.3",
        "name": "Торговля оптовая аудио- и видеомагнитными лентами и дискетами, магнитными и оптическими дисками, компакт-дисками (CD), цифровыми видеодисками (DVD) и прочими техническими носителями информации без записей"
    },
    {
        "code": "46.6",
        "name": "Торговля оптовая прочими машинами, оборудованием и принадлежностями"
    },
    {
        "code": "46.61",
        "name": "Торговля оптовая машинами, оборудованием и инструментами для сельского хозяйства"
    },
    {
        "code": "46.61.1",
        "name": "Торговля оптовая сельскохозяйственными и лесохозяйственными машинами, оборудованием и инструментами, включая тракторы"
    },
    {
        "code": "46.61.2",
        "name": "Торговля оптовая садово-огородной техникой и инвентарем"
    },
    {
        "code": "46.62",
        "name": "Торговля оптовая станками"
    },
    {
        "code": "46.62.1",
        "name": "Торговля оптовая деревообрабатывающими станками"
    },
    {
        "code": "46.62.2",
        "name": "Торговля оптовая металлообрабатывающими станками"
    },
    {
        "code": "46.62.3",
        "name": "Торговля оптовая станками для обработки прочих материалов"
    },
    {
        "code": "46.63",
        "name": "Торговля оптовая машинами и оборудованием для добычи полезных ископаемых и строительства"
    },
    {
        "code": "46.64",
        "name": "Торговля оптовая машинами и оборудованием для текстильного, швейного и трикотажного производств"
    },
    {
        "code": "46.65",
        "name": "Торговля оптовая офисной мебелью"
    },
    {
        "code": "46.66",
        "name": "Торговля оптовая прочей офисной техникой и оборудованием"
    },
    {
        "code": "46.69",
        "name": "Торговля оптовая прочими машинами и оборудованием"
    },
    {
        "code": "46.69.1",
        "name": "Торговля оптовая транспортными средствами, кроме автомобилей, мотоциклов и велосипедов"
    },
    {
        "code": "46.69.2",
        "name": "Торговля оптовая эксплуатационными материалами и принадлежностями машин"
    },
    {
        "code": "46.69.3",
        "name": "Торговля оптовая подъемно-транспортными машинами и оборудованием"
    },
    {
        "code": "46.69.4",
        "name": "Торговля оптовая машинами и оборудованием для производства пищевых продуктов, напитков и табачных изделий"
    },
    {
        "code": "46.69.5",
        "name": "Торговля оптовая производственным электротехническим оборудованием, машинами, аппаратурой и материалами"
    },
    {
        "code": "46.69.6",
        "name": "Торговля оптовая оружием и боеприпасами"
    },
    {
        "code": "46.69.7",
        "name": "Торговля оптовая измерительными приборами и оборудованием"
    },
    {
        "code": "46.69.8",
        "name": "Торговля оптовая техникой, оборудованием и инструментами, применяемыми в медицинских целях"
    },
    {
        "code": "46.69.9",
        "name": "Торговля оптовая прочими машинами, приборами, аппаратурой и оборудованием общепромышленного и специального назначения"
    },
    {
        "code": "46.7",
        "name": "Торговля оптовая специализированная прочая"
    },
    {
        "code": "46.71",
        "name": "Торговля оптовая твердым, жидким и газообразным топливом и подобными продуктами"
    },
    {
        "code": "46.71.1",
        "name": "Торговля оптовая твердым топливом"
    },
    {
        "code": "46.71.2",
        "name": "Торговля оптовая моторным топливом, включая авиационный бензин"
    },
    {
        "code": "46.71.3",
        "name": "Торговля оптовая нефтью"
    },
    {
        "code": "46.71.4",
        "name": "Торговля оптовая природным (естественным) газом"
    },
    {
        "code": "46.71.5",
        "name": "Торговля оптовая сжиженными углеводородными газами"
    },
    {
        "code": "46.71.51",
        "name": "Торговля оптовая сжиженными углеводородными газами по регулируемым государством ценам (тарифам)"
    },
    {
        "code": "46.71.52",
        "name": "Торговля оптовая сжиженными углеводородными газами по не регулируемым государством ценам (тарифам)"
    },
    {
        "code": "46.71.9",
        "name": "Торговля оптовая прочим топливом и подобными продуктами"
    },
    {
        "code": "46.72",
        "name": "Торговля оптовая металлами и металлическими рудами"
    },
    {
        "code": "46.72.1",
        "name": "Торговля оптовая металлическими рудами"
    },
    {
        "code": "46.72.11",
        "name": "Торговля оптовая железными рудами"
    },
    {
        "code": "46.72.12",
        "name": "Торговля оптовая рудами цветных металлов"
    },
    {
        "code": "46.72.2",
        "name": "Торговля оптовая металлами в первичных формах"
    },
    {
        "code": "46.72.21",
        "name": "Торговля оптовая черными металлами в первичных формах"
    },
    {
        "code": "46.72.22",
        "name": "Торговля оптовая цветными металлами в первичных формах, кроме драгоценных"
    },
    {
        "code": "46.72.23",
        "name": "Торговля оптовая золотом и другими драгоценными металлами"
    },
    {
        "code": "46.73",
        "name": "Торговля оптовая лесоматериалами, строительными материалами и санитарно-техническим оборудованием"
    },
    {
        "code": "46.73.1",
        "name": "Торговля оптовая древесным сырьем и необработанными лесоматериалами"
    },
    {
        "code": "46.73.2",
        "name": "Торговля оптовая пиломатериалами"
    },
    {
        "code": "46.73.3",
        "name": "Торговля оптовая санитарно-техническим оборудованием"
    },
    {
        "code": "46.73.4",
        "name": "Торговля оптовая лакокрасочными материалами"
    },
    {
        "code": "46.73.5",
        "name": "Торговля оптовая листовым стеклом"
    },
    {
        "code": "46.73.6",
        "name": "Торговля оптовая прочими строительными материалами и изделиями"
    },
    {
        "code": "46.73.7",
        "name": "Торговля оптовая обоями"
    },
    {
        "code": "46.73.8",
        "name": "Торговля оптовая напольными покрытиями (кроме ковров)"
    },
    {
        "code": "46.74",
        "name": "Торговля оптовая скобяными изделиями, водопроводным и отопительным оборудованием и принадлежностями"
    },
    {
        "code": "46.74.1",
        "name": "Торговля оптовая скобяными изделиями"
    },
    {
        "code": "46.74.2",
        "name": "Торговля оптовая водопроводным и отопительным оборудованием и санитарно-технической арматурой"
    },
    {
        "code": "46.74.3",
        "name": "Торговля оптовая ручными инструментами"
    },
    {
        "code": "46.75",
        "name": "Торговля оптовая химическими продуктами"
    },
    {
        "code": "46.75.1",
        "name": "Торговля оптовая удобрениями и агрохимическими продуктами"
    },
    {
        "code": "46.75.2",
        "name": "Торговля оптовая промышленными химикатами"
    },
    {
        "code": "46.76",
        "name": "Торговля оптовая прочими промежуточными продуктами"
    },
    {
        "code": "46.76.1",
        "name": "Торговля оптовая бумагой и картоном"
    },
    {
        "code": "46.76.2",
        "name": "Торговля оптовая текстильными волокнами"
    },
    {
        "code": "46.76.3",
        "name": "Торговля оптовая пластмассами и резиной в первичных формах"
    },
    {
        "code": "46.76.4",
        "name": "Торговля оптовая драгоценными камнями"
    },
    {
        "code": "46.77",
        "name": "Торговля оптовая отходами и ломом"
    },
    {
        "code": "46.9",
        "name": "Торговля оптовая неспециализированная"
    },
    {
        "code": "46.90",
        "name": "Торговля оптовая неспециализированная"
    },
    {
        "code": "47",
        "name": "Торговля розничная, кроме торговли автотранспортными средствами и мотоциклами"
    },
    {
        "code": "47.1",
        "name": "Торговля розничная в неспециализированных магазинах"
    },
    {
        "code": "47.11",
        "name": "Торговля розничная преимущественно пищевыми продуктами, включая напитки, и табачными изделиями в неспециализированных магазинах"
    },
    {
        "code": "47.11.1",
        "name": "Торговля розничная замороженными продуктами в неспециализированных магазинах"
    },
    {
        "code": "47.11.2",
        "name": "Торговля розничная незамороженными продуктами, включая напитки и табачные изделия, в неспециализированных магазинах"
    },
    {
        "code": "47.11.3",
        "name": "Деятельность по розничной торговле большим товарным ассортиментом с преобладанием продовольственных товаров в неспециализированных магазинах"
    },
    {
        "code": "47.19",
        "name": "Торговля розничная прочая в неспециализированных магазинах"
    },
    {
        "code": "47.19.1",
        "name": "Торговля розничная большим товарным ассортиментом с преобладанием непродовольственных товаров в неспециализированных магазинах"
    },
    {
        "code": "47.19.2",
        "name": "Деятельность универсальных магазинов, торгующих товарами общего ассортимента"
    },
    {
        "code": "47.2",
        "name": "Торговля розничная пищевыми продуктами, напитками и табачными изделиями в специализированных магазинах"
    },
    {
        "code": "47.21",
        "name": "Торговля розничная фруктами и овощами в специализированных магазинах"
    },
    {
        "code": "47.21.1",
        "name": "Торговля розничная свежими фруктами, овощами, картофелем и орехами в специализированных магазинах"
    },
    {
        "code": "47.21.2",
        "name": "Торговля розничная консервированными фруктами, овощами и орехами в специализированных магазинах"
    },
    {
        "code": "47.22",
        "name": "Торговля розничная мясом и мясными продуктами в специализированных магазинах"
    },
    {
        "code": "47.22.1",
        "name": "Торговля розничная мясом и мясом птицы, включая субпродукты в специализированных магазинах"
    },
    {
        "code": "47.22.2",
        "name": "Торговля розничная продуктами из мяса и мяса птицы в специализированных магазинах"
    },
    {
        "code": "47.22.3",
        "name": "Торговля розничная консервами из мяса и мяса птицы в специализированных магазинах"
    },
    {
        "code": "47.23",
        "name": "Торговля розничная рыбой, ракообразными и моллюсками в специализированных магазинах"
    },
    {
        "code": "47.23.1",
        "name": "Торговля розничная рыбой и морепродуктами в специализированных магазинах"
    },
    {
        "code": "47.23.2",
        "name": "Торговля розничная консервами из рыбы и морепродуктов в специализированных магазинах"
    },
    {
        "code": "47.24",
        "name": "Торговля розничная хлебом и хлебобулочными изделиями и кондитерскими изделиями в специализированных магазинах"
    },
    {
        "code": "47.24.1",
        "name": "Торговля розничная хлебом и хлебобулочными изделиями в специализированных магазинах"
    },
    {
        "code": "47.24.2",
        "name": "Торговля розничная кондитерскими изделиями в специализированных магазинах"
    },
    {
        "code": "47.24.21",
        "name": "Торговля розничная мучными кондитерскими изделиями в специализированных магазинах"
    },
    {
        "code": "47.24.22",
        "name": "Торговля розничная кондитерскими изделиями, включая шоколад, в специализированных магазинах"
    },
    {
        "code": "47.24.3",
        "name": "Торговля розничная мороженым и замороженными десертами в специализированных магазинах"
    },
    {
        "code": "47.25",
        "name": "Торговля розничная напитками в специализированных магазинах"
    },
    {
        "code": "47.25.1",
        "name": "Торговля розничная алкогольными напитками, включая пиво, в специализированных магазинах"
    },
    {
        "code": "47.25.11",
        "name": "Торговля розничная алкогольными напитками, кроме пива, в специализированных магазинах"
    },
    {
        "code": "47.25.12",
        "name": "Торговля розничная пивом в специализированных магазинах"
    },
    {
        "code": "47.25.2",
        "name": "Торговля розничная безалкогольными напитками в специализированных магазинах"
    },
    {
        "code": "47.26",
        "name": "Торговля розничная табачными изделиями в специализированных магазинах"
    },
    {
        "code": "47.29",
        "name": "Торговля розничная прочими пищевыми продуктами в специализированных магазинах"
    },
    {
        "code": "47.29.1",
        "name": "Торговля розничная молочными продуктами и яйцами в специализированных магазинах"
    },
    {
        "code": "47.29.11",
        "name": "Торговля розничная молочными продуктами в специализированных магазинах"
    },
    {
        "code": "47.29.12",
        "name": "Торговля розничная яйцами в специализированных магазинах"
    },
    {
        "code": "47.29.2",
        "name": "Торговля розничная пищевыми маслами и жирами в специализированных магазинах"
    },
    {
        "code": "47.29.21",
        "name": "Торговля розничная животными маслами и жирами в специализированных магазинах"
    },
    {
        "code": "47.29.22",
        "name": "Торговля розничная растительными маслами в специализированных магазинах"
    },
    {
        "code": "47.29.3",
        "name": "Торговля розничная прочими пищевыми продуктами в специализированных магазинах"
    },
    {
        "code": "47.29.31",
        "name": "Торговля розничная мукой и макаронными изделиями в специализированных магазинах"
    },
    {
        "code": "47.29.32",
        "name": "Торговля розничная крупами в специализированных магазинах"
    },
    {
        "code": "47.29.33",
        "name": "Торговля розничная сахаром в специализированных магазинах"
    },
    {
        "code": "47.29.34",
        "name": "Торговля розничная солью в специализированных магазинах"
    },
    {
        "code": "47.29.35",
        "name": "Торговля розничная чаем, кофе, какао в специализированных магазинах"
    },
    {
        "code": "47.29.36",
        "name": "Торговля розничная гомогенизированными пищевыми продуктами, детским и диетическим питанием в специализированных магазинах"
    },
    {
        "code": "47.29.39",
        "name": "Торговля розничная прочими пищевыми продуктами в специализированных магазинах, не включенными в другие группировки"
    },
    {
        "code": "47.3",
        "name": "Торговля розничная моторным топливом в специализированных магазинах"
    },
    {
        "code": "47.30",
        "name": "Торговля розничная моторным топливом в специализированных магазинах"
    },
    {
        "code": "47.30.1",
        "name": "Торговля розничная моторным топливом в специализированных магазинах"
    },
    {
        "code": "47.30.11",
        "name": "Торговля розничная бензином и дизельным топливом в специализированных магазинах"
    },
    {
        "code": "47.30.12",
        "name": "Торговля розничная газом для заправки автомобилей в специализированных магазинах"
    },
    {
        "code": "47.30.2",
        "name": "Торговля розничная смазочными материалами и охлаждающими жидкостями для автотранспортных средств"
    },
    {
        "code": "47.4",
        "name": "Торговля розничная информационным и коммуникационным оборудованием в специализированных магазинах"
    },
    {
        "code": "47.41",
        "name": "Торговля розничная компьютерами, периферийными устройствами к ним и программным обеспечением в специализированных магазинах"
    },
    {
        "code": "47.41.1",
        "name": "Торговля розничная компьютерами в специализированных магазинах"
    },
    {
        "code": "47.41.2",
        "name": "Торговля розничная программным обеспечением в специализированных магазинах"
    },
    {
        "code": "47.41.3",
        "name": "Торговля розничная периферийными устройствами в специализированных магазинах"
    },
    {
        "code": "47.41.4",
        "name": "Торговля розничная офисными машинами и оборудованием в специализированных магазинах"
    },
    {
        "code": "47.42",
        "name": "Торговля розничная телекоммуникационным оборудованием, включая розничную торговлю мобильными телефонами, в специализированных магазинах"
    },
    {
        "code": "47.43",
        "name": "Торговля розничная аудио- и видеотехникой в специализированных магазинах"
    },
    {
        "code": "47.5",
        "name": "Торговля розничная прочими бытовыми изделиями в специализированных магазинах"
    },
    {
        "code": "47.51",
        "name": "Торговля розничная текстильными изделиями в специализированных магазинах"
    },
    {
        "code": "47.51.1",
        "name": "Торговля розничная текстильными изделиями в специализированных магазинах"
    },
    {
        "code": "47.51.2",
        "name": "Торговля розничная галантерейными изделиями в специализированных магазинах"
    },
    {
        "code": "47.52",
        "name": "Торговля розничная скобяными изделиями, лакокрасочными материалами и стеклом в специализированных магазинах"
    },
    {
        "code": "47.52.1",
        "name": "Торговля розничная скобяными изделиями в специализированных магазинах"
    },
    {
        "code": "47.52.2",
        "name": "Торговля розничная лакокрасочными материалами в специализированных магазинах"
    },
    {
        "code": "47.52.3",
        "name": "Торговля розничная стеклом в специализированных магазинах"
    },
    {
        "code": "47.52.4",
        "name": "Торговля розничная материалами и оборудованием для изготовления поделок в специализированных магазинах"
    },
    {
        "code": "47.52.5",
        "name": "Торговля розничная санитарно-техническим оборудованием в специализированных магазинах"
    },
    {
        "code": "47.52.6",
        "name": "Торговля розничная садово-огородной техникой и инвентарем в специализированных магазинах"
    },
    {
        "code": "47.52.7",
        "name": "Торговля розничная строительными материалами, не включенными в другие группировки, в специализированных магазинах"
    },
    {
        "code": "47.52.71",
        "name": "Торговля розничная пиломатериалами в специализированных магазинах"
    },
    {
        "code": "47.52.72",
        "name": "Торговля розничная кирпичом в специализированных магазинах"
    },
    {
        "code": "47.52.73",
        "name": "Торговля розничная металлическими и неметаллическими конструкциями в специализированных магазинах"
    },
    {
        "code": "47.52.74",
        "name": "Торговля розничная сборными деревянными строениями в специализированных магазинах"
    },
    {
        "code": "47.52.79",
        "name": "Торговля розничная прочими строительными материалами, не включенными в другие группировки, в специализированных магазинах"
    },
    {
        "code": "47.53",
        "name": "Торговля розничная коврами, ковровыми изделиями, покрытиями для пола и стен в специализированных магазинах"
    },
    {
        "code": "47.53.1",
        "name": "Торговля розничная коврами и ковровыми изделиями в специализированных магазинах"
    },
    {
        "code": "47.53.2",
        "name": "Торговля розничная портьерами, тюлевыми занавесями в специализированных магазинах"
    },
    {
        "code": "47.53.3",
        "name": "Торговля розничная обоями и напольными покрытиями в специализированных магазинах"
    },
    {
        "code": "47.54",
        "name": "Торговля розничная бытовыми электротоварами в специализированных магазинах"
    },
    {
        "code": "47.59",
        "name": "Торговля розничная мебелью, осветительными приборами и прочими бытовыми изделиями в специализированных магазинах"
    },
    {
        "code": "47.59.1",
        "name": "Торговля розничная мебелью в специализированных магазинах"
    },
    {
        "code": "47.59.2",
        "name": "Торговля розничная различной домашней утварью, ножевыми изделиями, посудой, изделиями из стекла и керамики, в том числе фарфора и фаянса в специализированных магазинах"
    },
    {
        "code": "47.59.3",
        "name": "Торговля розничная осветительными приборами в специализированных магазинах"
    },
    {
        "code": "47.59.4",
        "name": "Торговля розничная изделиями из дерева, пробки и плетеными изделиями в специализированных магазинах"
    },
    {
        "code": "47.59.5",
        "name": "Торговля розничная музыкальными инструментами и нотными изданиями в специализированных магазинах"
    },
    {
        "code": "47.59.6",
        "name": "Торговля розничная неэлектрическими бытовыми приборами в специализированных магазинах"
    },
    {
        "code": "47.59.7",
        "name": "Торговля розничная электрическими системами охранной сигнализации, такими как запорные устройства, сейфы и хранилища"
    },
    {
        "code": "47.59.9",
        "name": "Торговля розничная бытовыми изделиями и приборами, не включенными в другие группировки, в специализированных магазинах"
    },
    {
        "code": "47.6",
        "name": "Торговля розничная товарами культурно-развлекательного назначения в специализированных магазинах"
    },
    {
        "code": "47.61",
        "name": "Торговля розничная книгами в специализированных магазинах"
    },
    {
        "code": "47.62",
        "name": "Торговля розничная газетами и канцелярскими товарами в специализированных магазинах"
    },
    {
        "code": "47.62.1",
        "name": "Торговля розничная газетами и журналами в специализированных магазинах"
    },
    {
        "code": "47.62.2",
        "name": "Торговля розничная писчебумажными и канцелярскими товарами в специализированных магазинах"
    },
    {
        "code": "47.63",
        "name": "Торговля розничная музыкальными и видеозаписями в специализированных магазинах"
    },
    {
        "code": "47.63.1",
        "name": "Торговля розничная музыкальными записями, аудиолентами, компакт-дисками и кассетами в специализированных магазинах"
    },
    {
        "code": "47.63.2",
        "name": "Торговля розничная лентами и дисками без записей в специализированных магазинах"
    },
    {
        "code": "47.64",
        "name": "Торговля розничная спортивным оборудованием и спортивными товарами в специализированных магазинах"
    },
    {
        "code": "47.64.1",
        "name": "Торговля розничная спортивным оборудованием и спортивными товарами в специализированных магазинах"
    },
    {
        "code": "47.64.2",
        "name": "Торговля розничная рыболовными принадлежностями в специализированных магазинах"
    },
    {
        "code": "47.64.3",
        "name": "Торговля розничная туристическим снаряжением в специализированных магазинах"
    },
    {
        "code": "47.64.4",
        "name": "Торговля розничная лодками в специализированных магазинах"
    },
    {
        "code": "47.64.5",
        "name": "Торговля розничная велосипедами в специализированных магазинах"
    },
    {
        "code": "47.65",
        "name": "Торговля розничная играми и игрушками в специализированных магазинах"
    },
    {
        "code": "47.7",
        "name": "Торговля розничная прочими товарами в специализированных магазинах"
    },
    {
        "code": "47.71",
        "name": "Торговля розничная одеждой в специализированных магазинах"
    },
    {
        "code": "47.71.1",
        "name": "Торговля розничная мужской, женской и детской одеждой в специализированных магазинах"
    },
    {
        "code": "47.71.2",
        "name": "Торговля розничная нательным бельем в специализированных магазинах"
    },
    {
        "code": "47.71.3",
        "name": "Торговля розничная изделиями из меха в специализированных магазинах"
    },
    {
        "code": "47.71.4",
        "name": "Торговля розничная одеждой из кожи в специализированных магазинах"
    },
    {
        "code": "47.71.5",
        "name": "Торговля розничная спортивной одеждой в специализированных магазинах"
    },
    {
        "code": "47.71.6",
        "name": "Торговля розничная чулочно-носочными изделиями в специализированных магазинах"
    },
    {
        "code": "47.71.7",
        "name": "Торговля розничная головными уборами в специализированных магазинах"
    },
    {
        "code": "47.71.8",
        "name": "Торговля розничная аксессуарами одежды (перчатками, галстуками, шарфами, ремнями, подтяжками и т. п.) в специализированных магазинах"
    },
    {
        "code": "47.72",
        "name": "Торговля розничная обувью и изделиями из кожи в специализированных магазинах"
    },
    {
        "code": "47.72.1",
        "name": "Торговля розничная обувью в специализированных магазинах"
    },
    {
        "code": "47.72.2",
        "name": "Торговля розничная изделиями из кожи и дорожными принадлежностями в специализированных магазинах"
    },
    {
        "code": "47.73",
        "name": "Торговля розничная лекарственными средствами в специализированных магазинах (аптеках)"
    },
    {
        "code": "47.74",
        "name": "Торговля розничная изделиями, применяемыми в медицинских целях, ортопедическими изделиями в специализированных магазинах"
    },
    {
        "code": "47.74.1",
        "name": "Торговля розничная изделиями, применяемыми в медицинских целях, в специализированных магазинах"
    },
    {
        "code": "47.74.2",
        "name": "Торговля розничная ортопедическими изделиями в специализированных магазинах"
    },
    {
        "code": "47.75",
        "name": "Торговля розничная косметическими и товарами личной гигиены в специализированных магазинах"
    },
    {
        "code": "47.75.1",
        "name": "Торговля розничная косметическими и парфюмерными товарами, кроме мыла в специализированных магазинах"
    },
    {
        "code": "47.75.2",
        "name": "Торговля розничная туалетным и хозяйственным мылом в специализированных магазинах"
    },
    {
        "code": "47.75.3",
        "name": "Торговля розничная предметами личной гигиены в специализированных магазинах"
    },
    {
        "code": "47.76",
        "name": "Торговля розничная цветами и другими растениями, семенами, удобрениями, домашними животными и кормами для домашних животных в специализированных магазинах"
    },
    {
        "code": "47.76.1",
        "name": "Торговля розничная цветами и другими растениями, семенами и удобрениями в специализированных магазинах"
    },
    {
        "code": "47.76.2",
        "name": "Торговля розничная домашними животными и кормами для домашних животных в специализированных магазинах"
    },
    {
        "code": "47.77",
        "name": "Торговля розничная часами и ювелирными изделиями в специализированных магазинах"
    },
    {
        "code": "47.77.1",
        "name": "Торговля розничная часами в специализированных магазинах"
    },
    {
        "code": "47.77.2",
        "name": "Торговля розничная ювелирными изделиями в специализированных магазинах"
    },
    {
        "code": "47.78",
        "name": "Торговля розничная прочая в специализированных магазинах"
    },
    {
        "code": "47.78.1",
        "name": "Торговля розничная фотоаппаратурой, оптическими приборами и средствами измерений, кроме очков, в специализированных магазинах"
    },
    {
        "code": "47.78.2",
        "name": "Торговля розничная очками, включая сборку и ремонт очков в специализированных магазинах"
    },
    {
        "code": "47.78.21",
        "name": "Торговля розничная очками в специализированных магазинах"
    },
    {
        "code": "47.78.22",
        "name": "Сборка и ремонт очков в специализированных магазинах"
    },
    {
        "code": "47.78.3",
        "name": "Торговля розничная сувенирами, изделиями народных художественных промыслов"
    },
    {
        "code": "47.78.4",
        "name": "Торговля розничная предметами культового и религиозного назначения, похоронными принадлежностями в специализированных магазинах"
    },
    {
        "code": "47.78.5",
        "name": "Деятельность коммерческих художественных галерей, торговля розничная произведениями искусства в коммерческих художественных галереях"
    },
    {
        "code": "47.78.6",
        "name": "Торговля розничная бытовым жидким котельным топливом, газом в баллонах, углем, древесным топливом, топливным торфом в специализированных магазинах"
    },
    {
        "code": "47.78.61",
        "name": "Торговля розничная бытовым жидким котельным топливом, углем, древесным топливом, топливным торфом в специализированных магазинах"
    },
    {
        "code": "47.78.62",
        "name": "Торговля розничная газом в баллонах в специализированных магазинах по регулируемым государствам ценам (тарифам)"
    },
    {
        "code": "47.78.63",
        "name": "Торговля розничная газом в баллонах в специализированных магазинах по нерегулируемым государством ценам (тарифам)"
    },
    {
        "code": "47.78.7",
        "name": "Торговля розничная оружием и боеприпасами в специализированных магазинах"
    },
    {
        "code": "47.78.8",
        "name": "Торговля розничная филателистическими и нумизматическими товарами в специализированных магазинах"
    },
    {
        "code": "47.78.9",
        "name": "Торговля розничная непродовольственными товарами, не включенными в другие группировки, в специализированных магазинах"
    },
    {
        "code": "47.79",
        "name": "Торговля розничная бывшими в употреблении товарами в магазинах"
    },
    {
        "code": "47.79.1",
        "name": "Торговля розничная предметами антиквариата"
    },
    {
        "code": "47.79.2",
        "name": "Торговля розничная букинистическими книгами"
    },
    {
        "code": "47.79.3",
        "name": "Торговля розничная прочими бывшими в употреблении товарами"
    },
    {
        "code": "47.79.4",
        "name": "Деятельность аукционных домов по розничной торговле"
    },
    {
        "code": "47.8",
        "name": "Торговля розничная в нестационарных торговых объектах и на рынках"
    },
    {
        "code": "47.81",
        "name": "Торговля розничная в нестационарных торговых объектах и на рынках пищевыми продуктами, напитками и табачной продукцией"
    },
    {
        "code": "47.81.1",
        "name": "Торговля розничная в нестационарных торговых объектах напитками и табачной продукцией"
    },
    {
        "code": "47.81.2",
        "name": "Торговля розничная на рынках пищевыми продуктами, напитками и табачной продукцией"
    },
    {
        "code": "47.82",
        "name": "Торговля розничная в нестационарных торговых объектах и на рынках текстилем, одеждой и обувью"
    },
    {
        "code": "47.82.1",
        "name": "Торговля розничная в нестационарных торговых объектах текстилем, одеждой и обувью"
    },
    {
        "code": "47.82.2",
        "name": "Торговля розничная на рынках текстилем, одеждой и обувью"
    },
    {
        "code": "47.89",
        "name": "Торговля розничная в нестационарных торговых объектах и на рынках прочими товарами"
    },
    {
        "code": "47.89.1",
        "name": "Торговля розничная в нестационарных торговых объектах прочими товарами"
    },
    {
        "code": "47.89.2",
        "name": "Торговля розничная на рынках прочими товарами"
    },
    {
        "code": "47.9",
        "name": "Торговля розничная вне магазинов, палаток, рынков"
    },
    {
        "code": "47.91",
        "name": "Торговля розничная по почте или по информационно-коммуникационной сети Интернет"
    },
    {
        "code": "47.91.1",
        "name": "Торговля розничная по почте"
    },
    {
        "code": "47.91.2",
        "name": "Торговля розничная, осуществляемая непосредственно при помощи информационно-коммуникационной сети Интернет"
    },
    {
        "code": "47.91.3",
        "name": "Торговля розничная через Интернет-аукционы"
    },
    {
        "code": "47.91.4",
        "name": "Торговля розничная, осуществляемая непосредственно при помощи телевидения, радио, телефона"
    },
    {
        "code": "47.99",
        "name": "Торговля розничная прочая вне магазинов, палаток, рынков"
    },
    {
        "code": "47.99.1",
        "name": "Деятельность по осуществлению прямых продаж или продаж торговыми агентами с доставкой"
    },
    {
        "code": "47.99.2",
        "name": "Деятельность по осуществлению торговли через автоматы"
    },
    {
        "code": "47.99.3",
        "name": "Деятельность по осуществлению прямых продаж топлива с доставкой по адресу клиента"
    },
    {
        "code": "47.99.4",
        "name": "Деятельность аукционов по розничной торговле вне магазинов, за исключением продаж через Интернет-аукционы"
    },
    {
        "code": "47.99.5",
        "name": "Деятельность по осуществлению розничных продаж комиссионными агентами вне магазинов"
    },
    {
        "code": "49",
        "name": "Деятельность сухопутного и трубопроводного транспорта"
    },
    {
        "code": "49.1",
        "name": "Деятельность железнодорожного транспорта: междугородные и международные пассажирские перевозки"
    },
    {
        "code": "49.10",
        "name": "Деятельность железнодорожного транспорта: междугородные и международные пассажирские перевозки"
    },
    {
        "code": "49.10.1",
        "name": "Перевозка пассажиров железнодорожным транспортом в междугородном сообщении"
    },
    {
        "code": "49.10.11",
        "name": "Перевозка пассажиров железнодорожным транспортом в междугородном сообщении в регулируемом секторе"
    },
    {
        "code": "49.10.12",
        "name": "Перевозка пассажиров железнодорожным транспортом в междугородном сообщении в нерегулируемом секторе"
    },
    {
        "code": "49.10.2",
        "name": "Перевозка пассажиров железнодорожным транспортом в международном сообщении"
    },
    {
        "code": "49.2",
        "name": "Деятельность железнодорожного транспорта: грузовые перевозки"
    },
    {
        "code": "49.20",
        "name": "Деятельность железнодорожного транспорта: грузовые перевозки"
    },
    {
        "code": "49.20.1",
        "name": "Перевозка опасных грузов"
    },
    {
        "code": "49.20.9",
        "name": "Перевозка прочих грузов"
    },
    {
        "code": "49.3",
        "name": "Деятельность прочего сухопутного пассажирского транспорта"
    },
    {
        "code": "49.31",
        "name": "Деятельность сухопутного пассажирского транспорта: перевозки пассажиров в городском и пригородном сообщении"
    },
    {
        "code": "49.31.1",
        "name": "Перевозка пассажиров железнодорожным транспортом в пригородном сообщении"
    },
    {
        "code": "49.31.11",
        "name": "Перевозка пассажиров железнодорожным транспортом в пригородном сообщении в регулируемом секторе"
    },
    {
        "code": "49.31.12",
        "name": "Перевозка пассажиров железнодорожным транспортом в пригородном сообщении в нерегулируемом секторе"
    },
    {
        "code": "49.31.2",
        "name": "Регулярные перевозки пассажиров прочим сухопутным транспортом в городском и пригородном сообщении"
    },
    {
        "code": "49.31.21",
        "name": "Регулярные перевозки пассажиров автобусами в городском и пригородном сообщении"
    },
    {
        "code": "49.31.22",
        "name": "Регулярные перевозки пассажиров троллейбусами в городском и пригородном сообщении"
    },
    {
        "code": "49.31.23",
        "name": "Регулярные перевозки пассажиров трамваями в городском и пригородном сообщении"
    },
    {
        "code": "49.31.24",
        "name": "Перевозка пассажиров метрополитеном"
    },
    {
        "code": "49.31.25",
        "name": "Перевозка пассажиров фуникулерами, подвесными канатными дорогами и подъемниками, являющимися частью городской или пригородной транспортной системы"
    },
    {
        "code": "49.32",
        "name": "Деятельность легкового такси и арендованных легковых автомобилей с водителем"
    },
    {
        "code": "49.39",
        "name": "Деятельность прочего сухопутного пассажирского транспорта, не включенная в другие группировки"
    },
    {
        "code": "49.39.1",
        "name": "Регулярные перевозки пассажиров сухопутным транспортом, кроме железнодорожного транспорта, в междугородном и международном сообщении, а также специальные перевозки (для собственных нужд)"
    },
    {
        "code": "49.39.11",
        "name": "Регулярные перевозки пассажиров автобусами в междугородном сообщении"
    },
    {
        "code": "49.39.12",
        "name": "Регулярные перевозки пассажиров автобусами в международном сообщении"
    },
    {
        "code": "49.39.13",
        "name": "Специальные перевозки (для собственных нужд) автобусами"
    },
    {
        "code": "49.39.2",
        "name": "Перевозка пассажиров фуникулерами, подвесными канатными дорогами и лыжными подъемниками, не являющимися частью внутригородской, пригородной или городской и пригородной транспортных систем"
    },
    {
        "code": "49.39.3",
        "name": "Перевозки пассажиров сухопутным транспортом по заказам"
    },
    {
        "code": "49.39.31",
        "name": "Перевозки пассажиров арендованными автобусами с водителем"
    },
    {
        "code": "49.39.32",
        "name": "Перевозка пассажиров автобусами по туристическим или экскурсионным маршрутам"
    },
    {
        "code": "49.39.33",
        "name": "Перевозка пассажиров автобусами в городском и пригородном сообщении по заказам, за исключением перевозки арендованными автобусами с водителем и по туристическим или экскурсионным маршрутам"
    },
    {
        "code": "49.39.34",
        "name": "Перевозка пассажиров автобусами в междугородном и международном сообщении по заказам, за исключением перевозки арендованными автобусами с водителем и по туристическим или экскурсионным маршрутам"
    },
    {
        "code": "49.39.35",
        "name": "Перевозка пассажиров транспортными средствами, приводимыми в движение человеком или животными"
    },
    {
        "code": "49.39.39",
        "name": "Перевозки пассажиров сухопутным транспортом прочие, не включенные в другие группировки"
    },
    {
        "code": "49.4",
        "name": "Деятельность автомобильного грузового транспорта и услуги по перевозкам"
    },
    {
        "code": "49.41",
        "name": "Деятельность автомобильного грузового транспорта"
    },
    {
        "code": "49.41.1",
        "name": "Перевозка грузов специализированными автотранспортными средствами"
    },
    {
        "code": "49.41.2",
        "name": "Перевозка грузов неспециализированными автотранспортными средствами"
    },
    {
        "code": "49.41.3",
        "name": "Аренда грузового автомобильного транспорта с водителем"
    },
    {
        "code": "49.42",
        "name": "Предоставление услуг по перевозкам"
    },
    {
        "code": "49.5",
        "name": "Деятельность трубопроводного транспорта"
    },
    {
        "code": "49.50",
        "name": "Деятельность трубопроводного транспорта"
    },
    {
        "code": "49.50.1",
        "name": "Транспортирование по трубопроводам нефти и нефтепродуктов"
    },
    {
        "code": "49.50.11",
        "name": "Транспортирование по трубопроводам нефти"
    },
    {
        "code": "49.50.12",
        "name": "Транспортирование по трубопроводам нефтепродуктов"
    },
    {
        "code": "49.50.2",
        "name": "Транспортирование по трубопроводам газа и продуктов его переработки"
    },
    {
        "code": "49.50.21",
        "name": "Транспортирование по трубопроводам газа"
    },
    {
        "code": "49.50.22",
        "name": "Транспортирование по трубопроводам продуктов переработки газа"
    },
    {
        "code": "49.50.3",
        "name": "Сухопутная конвейерная транспортировка угля"
    },
    {
        "code": "49.50.9",
        "name": "Деятельность трубопроводного транспорта прочая"
    },
    {
        "code": "50",
        "name": "Деятельность водного транспорта"
    },
    {
        "code": "50.1",
        "name": "Деятельность морского пассажирского транспорта"
    },
    {
        "code": "50.10",
        "name": "Деятельность морского пассажирского транспорта"
    },
    {
        "code": "50.10.1",
        "name": "Перевозка пассажиров морскими судами заграничного плавания"
    },
    {
        "code": "50.10.11",
        "name": "Перевозка пассажиров морскими судами заграничного плавания, подчиняющимися расписанию"
    },
    {
        "code": "50.10.12",
        "name": "Перевозка пассажиров морскими судами заграничного плавания, не подчиняющимися расписанию"
    },
    {
        "code": "50.10.2",
        "name": "Перевозка пассажиров морскими судами каботажного плавания"
    },
    {
        "code": "50.10.21",
        "name": "Перевозка пассажиров морскими судами каботажного плавания, подчиняющимися расписанию"
    },
    {
        "code": "50.10.22",
        "name": "Перевозка пассажиров морскими судами каботажного плавания, не подчиняющимися расписанию"
    },
    {
        "code": "50.10.3",
        "name": "Аренда морских судов заграничного и каботажного плавания для перевозки пассажиров с экипажем"
    },
    {
        "code": "50.10.31",
        "name": "Аренда морских судов заграничного плавания для перевозки пассажиров с экипажем"
    },
    {
        "code": "50.10.32",
        "name": "Аренда морских судов каботажного плавания для перевозки пассажиров с экипажем"
    },
    {
        "code": "50.10.39",
        "name": "Аренда прочих морских судов для перевозки пассажиров с экипажем"
    },
    {
        "code": "50.2",
        "name": "Деятельность морского грузового транспорта"
    },
    {
        "code": "50.20",
        "name": "Деятельность морского грузового транспорта"
    },
    {
        "code": "50.20.1",
        "name": "Перевозка грузов морскими судами заграничного плавания"
    },
    {
        "code": "50.20.11",
        "name": "Перевозка замороженных или охлажденных грузов судами-рефрижераторами заграничного плавания"
    },
    {
        "code": "50.20.12",
        "name": "Перевозка нефти морскими судами-танкерами заграничного плавания"
    },
    {
        "code": "50.20.13",
        "name": "Перевозка прочих жидкостей или газов морскими судами-танкерами заграничного плавания"
    },
    {
        "code": "50.20.14",
        "name": "Перевозка контейнерных грузов судами-контейнеровозами заграничного плавания"
    },
    {
        "code": "50.20.15",
        "name": "Перевозка сухих сыпучих грузов морскими судами заграничного плавания"
    },
    {
        "code": "50.20.16",
        "name": "Транспортирование (перевозка) ядерных материалов, радиоактивных веществ, радиационных источников, частей ядерных установок, радиоактивных отходов морскими судами заграничного плавания"
    },
    {
        "code": "50.20.19",
        "name": "Перевозка прочих грузов морскими судами заграничного плавания"
    },
    {
        "code": "50.20.2",
        "name": "Перевозка грузов морскими судами каботажного плавания"
    },
    {
        "code": "50.20.21",
        "name": "Перевозка замороженных или охлажденных грузов судами-рефрижераторами каботажного плавания"
    },
    {
        "code": "50.20.22",
        "name": "Перевозка нефти морскими судами-танкерами каботажного плавания"
    },
    {
        "code": "50.20.23",
        "name": "Перевозка прочих жидкостей или газов морскими судами-танкерами каботажного плавания"
    },
    {
        "code": "50.20.24",
        "name": "Перевозка контейнерных грузов судами-контейнеровозами каботажного плавания"
    },
    {
        "code": "50.20.25",
        "name": "Перевозка сухих сыпучих грузов морскими судами каботажного плавания"
    },
    {
        "code": "50.20.26",
        "name": "Транспортирование (перевозка) ядерных материалов, радиоактивных веществ, радиационных источников, частей ядерных установок, радиоактивных отходов морскими судами каботажного плавания"
    },
    {
        "code": "50.20.29",
        "name": "Перевозка прочих грузов морскими судами каботажного плавания"
    },
    {
        "code": "50.20.3",
        "name": "Аренда морских судов заграничного и каботажного плавания для перевозки грузов с экипажем"
    },
    {
        "code": "50.20.31",
        "name": "Аренда морских судов заграничного плавания для перевозки грузов с экипажем"
    },
    {
        "code": "50.20.32",
        "name": "Аренда морских судов каботажного плавания для перевозки грузов с экипажем"
    },
    {
        "code": "50.20.4",
        "name": "Буксировка и маневровые услуги, оказываемые судами заграничного и каботажного плавания"
    },
    {
        "code": "50.20.41",
        "name": "Буксировка судами заграничного и каботажного плавания"
    },
    {
        "code": "50.20.42",
        "name": "Деятельность по оказанию маневровых услуг судами заграничного и каботажного плавания"
    },
    {
        "code": "50.3",
        "name": "Деятельность внутреннего водного пассажирского транспорта"
    },
    {
        "code": "50.30",
        "name": "Деятельность внутреннего водного пассажирского транспорта"
    },
    {
        "code": "50.30.1",
        "name": "Перевозка пассажиров по внутренним водным путям"
    },
    {
        "code": "50.30.2",
        "name": "Аренда судов внутреннего водного транспорта для перевозки пассажиров с экипажем"
    },
    {
        "code": "50.4",
        "name": "Деятельность внутреннего водного грузового транспорта"
    },
    {
        "code": "50.40",
        "name": "Деятельность внутреннего водного грузового транспорта"
    },
    {
        "code": "50.40.1",
        "name": "Перевозка грузов по внутренним водным путям"
    },
    {
        "code": "50.40.2",
        "name": "Буксировка и маневровые услуги на внутренних водных путях"
    },
    {
        "code": "50.40.3",
        "name": "Аренда судов внутреннего водного транспорта для перевозки грузов с экипажем"
    },
    {
        "code": "51",
        "name": "Деятельность воздушного и космического транспорта"
    },
    {
        "code": "51.1",
        "name": "Деятельность пассажирского воздушного транспорта"
    },
    {
        "code": "51.10",
        "name": "Деятельность пассажирского воздушного транспорта"
    },
    {
        "code": "51.10.1",
        "name": "Перевозка воздушным пассажирским транспортом, подчиняющимся расписанию"
    },
    {
        "code": "51.10.2",
        "name": "Перевозка воздушным пассажирским транспортом, не подчиняющимся расписанию"
    },
    {
        "code": "51.10.3",
        "name": "Аренда воздушного судна с экипажем для перевозки пассажиров"
    },
    {
        "code": "51.2",
        "name": "Деятельность грузового воздушного транспорта и космического транспорта"
    },
    {
        "code": "51.21",
        "name": "Деятельность грузового воздушного транспорта"
    },
    {
        "code": "51.21.1",
        "name": "Перевозка воздушным грузовым транспортом, подчиняющимся расписанию"
    },
    {
        "code": "51.21.2",
        "name": "Перевозка воздушным грузовым транспортом, не подчиняющимся расписанию"
    },
    {
        "code": "51.21.3",
        "name": "Аренда грузовых воздушных судов с экипажем"
    },
    {
        "code": "51.22",
        "name": "Деятельность космического транспорта"
    },
    {
        "code": "51.22.1",
        "name": "Перевозка пассажиров космическим транспортом"
    },
    {
        "code": "51.22.2",
        "name": "Перевозка грузов космическим транспортом"
    },
    {
        "code": "51.22.3",
        "name": "Запуск ракет космического назначения и выведение космических объектов на орбиту"
    },
    {
        "code": "51.22.4",
        "name": "Деятельность космических лабораторий"
    },
    {
        "code": "52",
        "name": "Складское хозяйство и вспомогательная транспортная деятельность"
    },
    {
        "code": "52.1",
        "name": "Деятельность по складированию и хранению"
    },
    {
        "code": "52.10",
        "name": "Деятельность по складированию и хранению"
    },
    {
        "code": "52.10.1",
        "name": "Хранение и складирование замороженных или охлажденных грузов"
    },
    {
        "code": "52.10.2",
        "name": "Хранение и складирование жидких или газообразных грузов"
    },
    {
        "code": "52.10.21",
        "name": "Хранение и складирование нефти и продуктов ее переработки"
    },
    {
        "code": "52.10.22",
        "name": "Хранение и складирование газа и продуктов его переработки"
    },
    {
        "code": "52.10.23",
        "name": "Хранение и складирование прочих жидких или газообразных грузов"
    },
    {
        "code": "52.10.3",
        "name": "Хранение и складирование зерна"
    },
    {
        "code": "52.10.4",
        "name": "Хранение ядерных материалов и радиоактивных веществ"
    },
    {
        "code": "52.10.9",
        "name": "Хранение и складирование прочих грузов"
    },
    {
        "code": "52.2",
        "name": "Деятельность транспортная вспомогательная"
    },
    {
        "code": "52.21",
        "name": "Деятельность вспомогательная, связанная с сухопутным транспортом"
    },
    {
        "code": "52.21.1",
        "name": "Деятельность вспомогательная, связанная с железнодорожным транспортом"
    },
    {
        "code": "52.21.11",
        "name": "Предоставление железнодорожных маневровых или буксировочных услуг"
    },
    {
        "code": "52.21.12",
        "name": "Деятельность железнодорожных пассажирских вокзалов и грузовых терминалов"
    },
    {
        "code": "52.21.13",
        "name": "Деятельность железнодорожной инфраструктуры"
    },
    {
        "code": "52.21.19",
        "name": "Деятельность вспомогательная прочая, связанная с железнодорожным транспортом"
    },
    {
        "code": "52.21.2",
        "name": "Деятельность вспомогательная, связанная с автомобильным транспортом"
    },
    {
        "code": "52.21.21",
        "name": "Деятельность автовокзалов и автостанций"
    },
    {
        "code": "52.21.22",
        "name": "Деятельность по эксплуатации автомобильных дорог и автомагистралей"
    },
    {
        "code": "52.21.23",
        "name": "Деятельность по эксплуатации мостов и тоннелей"
    },
    {
        "code": "52.21.24",
        "name": "Деятельность стоянок для транспортных средств"
    },
    {
        "code": "52.21.25",
        "name": "Деятельность по буксировке автотранспортных средств"
    },
    {
        "code": "52.21.29",
        "name": "Деятельность вспомогательная прочая, связанная с автомобильным транспортом"
    },
    {
        "code": "52.21.3",
        "name": "Деятельность вспомогательная, связанная с трубопроводным транспортом"
    },
    {
        "code": "52.22",
        "name": "Деятельность вспомогательная, связанная с водным транспортом"
    },
    {
        "code": "52.22.1",
        "name": "Деятельность вспомогательная, связанная с морским транспортом"
    },
    {
        "code": "52.22.11",
        "name": "Деятельность инфраструктуры морских портов, включая портовые гидротехнические сооружения (причалы, морские терминалы, доки и др.)"
    },
    {
        "code": "52.22.12",
        "name": "Обеспечение судоходства в морских и прибрежных водах, включая лоцманскую проводку судов"
    },
    {
        "code": "52.22.13",
        "name": "Деятельность по постановке судов к причалу, осуществление швартовых операций с судами в морских портах"
    },
    {
        "code": "52.22.14",
        "name": "Деятельность по навигационному обеспечению судоходства на морском транспорте"
    },
    {
        "code": "52.22.15",
        "name": "Деятельность аварийно-спасательная и судоподъемная на морском транспорте"
    },
    {
        "code": "52.22.16",
        "name": "Снабженческое (шипчандлерское) обслуживание судов, включая бункеровку судов топливом, обслуживание судов в период стоянки в портах: агентирование судов, обследовательское (сюрвейерское) обслуживание судов на морском транспорте"
    },
    {
        "code": "52.22.17",
        "name": "Производство водолазных работ по обслуживанию морских судов"
    },
    {
        "code": "52.22.18",
        "name": "Деятельность ледокольного флота на морском транспорте"
    },
    {
        "code": "52.22.19",
        "name": "Деятельность вспомогательная, связанная морским транспортом, прочая, не включенная в другие группировки"
    },
    {
        "code": "52.22.2",
        "name": "Деятельность вспомогательная, связанная с внутренним водным транспортом"
    },
    {
        "code": "52.22.21",
        "name": "Деятельность инфраструктуры речных портов и гидротехнических сооружений"
    },
    {
        "code": "52.22.22",
        "name": "Обеспечение судоходства по внутренним водным путям, в том числе лоцманская проводка судов"
    },
    {
        "code": "52.22.23",
        "name": "Деятельность по постановке судов к причалу, осуществление швартовых операций в речных портах на внутреннем водном транспорте"
    },
    {
        "code": "52.22.24",
        "name": "Деятельность по навигационному обеспечению судоходства на внутреннем водном транспорте"
    },
    {
        "code": "52.22.25",
        "name": "Деятельность аварийно-спасательная и судоподъемная на внутреннем водном транспорте"
    },
    {
        "code": "52.22.26",
        "name": "Снабженческое (шипчандлерское) обслуживание судов, включая бункеровку судов топливом, обслуживание судов в период стоянки в портах: агентирование судов, обследовательское (сюрвейерское) обслуживание судов на внутреннем водном транспорте"
    },
    {
        "code": "52.22.27",
        "name": "Производство водолазных работ по обслуживанию судов на внутреннем водном транспорте"
    },
    {
        "code": "52.22.28",
        "name": "Деятельность ледокольного флота на внутреннем водном транспорте"
    },
    {
        "code": "52.22.29",
        "name": "Деятельность вспомогательная, связанная с внутренним водным транспортом, прочая, не включенная в другие группировки"
    },
    {
        "code": "52.23",
        "name": "Деятельность вспомогательная, связанная с воздушным и космическим транспортом"
    },
    {
        "code": "52.23.1",
        "name": "Деятельность вспомогательная, связанная с воздушным транспортом"
    },
    {
        "code": "52.23.11",
        "name": "Деятельность аэропортовая"
    },
    {
        "code": "52.23.12",
        "name": "Обеспечение обслуживания (управления) воздушного движения"
    },
    {
        "code": "52.23.13",
        "name": "Выполнение авиационных работ"
    },
    {
        "code": "52.23.19",
        "name": "Деятельность вспомогательная прочая, связанная с воздушным транспортом"
    },
    {
        "code": "52.23.2",
        "name": "Деятельность вспомогательная, связанная с космическим транспортом"
    },
    {
        "code": "52.23.21",
        "name": "Деятельность наземных центров управления полетами космических объектов в космическом пространстве и центров (пунктов) космической связи"
    },
    {
        "code": "52.23.22",
        "name": "Деятельность поисковых и аварийно-спасательных служб, в том числе по эвакуации спускаемых аппаратов (капсул), составных частей ракет космического назначения"
    },
    {
        "code": "52.23.23",
        "name": "Деятельность, связанная с подготовкой космонавтов для работы непосредственно в космическом пространстве"
    },
    {
        "code": "52.23.29",
        "name": "Деятельность вспомогательная прочая, связанная с космическим транспортом"
    },
    {
        "code": "52.24",
        "name": "Транспортная обработка грузов"
    },
    {
        "code": "52.24.1",
        "name": "Транспортная обработка контейнеров"
    },
    {
        "code": "52.24.2",
        "name": "Транспортная обработка прочих грузов"
    },
    {
        "code": "52.29",
        "name": "Деятельность вспомогательная прочая, связанная с перевозками"
    },
    {
        "code": "53",
        "name": "Деятельность почтовой связи и курьерская деятельность"
    },
    {
        "code": "53.1",
        "name": "Деятельность почтовой связи общего пользования"
    },
    {
        "code": "53.10",
        "name": "Деятельность почтовой связи общего пользования"
    },
    {
        "code": "53.10.1",
        "name": "Деятельность почтовой связи, связанная с пересылкой газет и других периодических изданий"
    },
    {
        "code": "53.10.2",
        "name": "Деятельность почтовой связи, связанная с пересылкой письменной корреспонденции"
    },
    {
        "code": "53.10.3",
        "name": "Деятельность почтовой связи, связанная с пересылкой посылочной почты"
    },
    {
        "code": "53.10.4",
        "name": "Деятельность почтовой связи дополнительная"
    },
    {
        "code": "53.10.9",
        "name": "Деятельность почтовой связи общего пользования прочая"
    },
    {
        "code": "53.2",
        "name": "Деятельность почтовой связи прочая и курьерская деятельность"
    },
    {
        "code": "53.20",
        "name": "Деятельность почтовой связи прочая и курьерская деятельность"
    },
    {
        "code": "53.20.1",
        "name": "Деятельность специальной почтовой связи"
    },
    {
        "code": "53.20.2",
        "name": "Деятельность фельдъегерской связи"
    },
    {
        "code": "53.20.21",
        "name": "Деятельность федеральной фельдъегерской связи"
    },
    {
        "code": "53.20.22",
        "name": "Деятельность фельдъегерско-почтовой связи"
    },
    {
        "code": "53.20.29",
        "name": "Деятельность почтовой связи прочая, не включенная в другие группировки"
    },
    {
        "code": "53.20.3",
        "name": "Деятельность курьерская"
    },
    {
        "code": "53.20.31",
        "name": "Деятельность по курьерской доставке различными видами транспорта"
    },
    {
        "code": "53.20.32",
        "name": "Деятельность по доставке еды на дом"
    },
    {
        "code": "53.20.39",
        "name": "Деятельность курьерская прочая"
    },
    {
        "code": "55",
        "name": "Деятельность по предоставлению мест для временного проживания"
    },
    {
        "code": "55.1",
        "name": "Деятельность гостиниц и прочих мест для временного проживания"
    },
    {
        "code": "55.10",
        "name": "Деятельность гостиниц и прочих мест для временного проживания"
    },
    {
        "code": "55.2",
        "name": "Деятельность по предоставлению мест для краткосрочного проживания"
    },
    {
        "code": "55.20",
        "name": "Деятельность по предоставлению мест для краткосрочного проживания"
    },
    {
        "code": "55.3",
        "name": "Деятельность по предоставлению мест для временного проживания в кемпингах, жилых автофургонах и туристических автоприцепах"
    },
    {
        "code": "55.30",
        "name": "Деятельность по предоставлению мест для временного проживания в кемпингах, жилых автофургонах и туристических автоприцепах"
    },
    {
        "code": "55.9",
        "name": "Деятельность по предоставлению прочих мест для временного проживания"
    },
    {
        "code": "55.90",
        "name": "Деятельность по предоставлению прочих мест для временного проживания"
    },
    {
        "code": "56",
        "name": "Деятельность по предоставлению продуктов питания и напитков"
    },
    {
        "code": "56.1",
        "name": "Деятельность ресторанов и услуги по доставке продуктов питания"
    },
    {
        "code": "56.10",
        "name": "Деятельность ресторанов и услуги по доставке продуктов питания"
    },
    {
        "code": "56.10.1",
        "name": "Деятельность ресторанов и кафе с полным ресторанным обслуживанием, кафетериев, ресторанов быстрого питания и самообслуживания"
    },
    {
        "code": "56.10.2",
        "name": "Деятельность по приготовлению и/или продаже пищи, готовой к непосредственному употреблению на месте, с транспортных средств или передвижных лавок"
    },
    {
        "code": "56.10.21",
        "name": "Деятельность предприятий общественного питания с обслуживанием на вынос"
    },
    {
        "code": "56.10.22",
        "name": "Деятельность передвижных продовольственных лавок по приготовлению и/или продаже пищи, готовой к употреблению"
    },
    {
        "code": "56.10.23",
        "name": "Деятельность вагончиков, палаток по приготовлению и продаже мороженого"
    },
    {
        "code": "56.10.24",
        "name": "Деятельность рыночных киосков и торговых палаток по приготовлению пищи"
    },
    {
        "code": "56.10.3",
        "name": "Деятельность ресторанов и баров по обеспечению питанием в железнодорожных вагонахресторанах и на судах"
    },
    {
        "code": "56.2",
        "name": "Деятельность предприятий общественного питания по обслуживанию торжественных мероприятий и прочим видам организации питания"
    },
    {
        "code": "56.21",
        "name": "Деятельность предприятий общественного питания по обслуживанию торжественных мероприятий"
    },
    {
        "code": "56.29",
        "name": "Деятельность предприятий общественного питания по прочим видам организации питания"
    },
    {
        "code": "56.29.1",
        "name": "Деятельность организаций общественного питания, поставляющих готовую пищу (для транспортных и строительных компаний, туристическим группам, личному составу вооруженных сил, предприятиям розничной торговли и другим группам потребителей) по договору"
    },
    {
        "code": "56.29.2",
        "name": "Деятельность столовых и буфетов при предприятиях и учреждениях"
    },
    {
        "code": "56.29.3",
        "name": "Деятельность по доставке продуктов питания учебным, спортивным и прочим учреждениям (по льготным ценам)"
    },
    {
        "code": "56.29.4",
        "name": "Деятельность социальных столовых, буфетов или кафетериев (в офисах, больницах, школах, институтах и пр.) на основе льготных цен на питание"
    },
    {
        "code": "56.3",
        "name": "Подача напитков"
    },
    {
        "code": "56.30",
        "name": "Подача напитков"
    },
    {
        "code": "58",
        "name": "Деятельность издательская"
    },
    {
        "code": "58.1",
        "name": "Издание книг, периодических публикаций и другие виды издательской деятельности"
    },
    {
        "code": "58.11",
        "name": "Издание книг"
    },
    {
        "code": "58.11.1",
        "name": "Издание книг, брошюр, рекламных буклетов и аналогичных изданий, включая издание словарей и энциклопедий, в том числе для слепых, в печатном виде"
    },
    {
        "code": "58.11.2",
        "name": "Издание книг, брошюр, рекламных буклетов и аналогичных изданий, включая издание словарей и энциклопедий на электронных носителях"
    },
    {
        "code": "58.11.3",
        "name": "Издание атласов, карт и таблиц, в том числе для слепых, в печатном виде"
    },
    {
        "code": "58.11.4",
        "name": "Издание атласов, карт и таблиц на электронных носителях"
    },
    {
        "code": "58.12",
        "name": "Издание адресных справочников и списков адресатов"
    },
    {
        "code": "58.12.1",
        "name": "Издание справочников в печатном виде"
    },
    {
        "code": "58.12.2",
        "name": "Издание справочников на электронных носителях"
    },
    {
        "code": "58.13",
        "name": "Издание газет"
    },
    {
        "code": "58.13.1",
        "name": "Издание газет в печатном виде"
    },
    {
        "code": "58.13.2",
        "name": "Издание газет на электронных носителях"
    },
    {
        "code": "58.14",
        "name": "Издание журналов и периодических изданий"
    },
    {
        "code": "58.14.1",
        "name": "Издание журналов и периодических публикаций в печатном виде"
    },
    {
        "code": "58.14.2",
        "name": "Издание журналов и периодических публикаций на электронных носителях"
    },
    {
        "code": "58.19",
        "name": "Виды издательской деятельности прочие"
    },
    {
        "code": "58.2",
        "name": "Издание программного обеспечения"
    },
    {
        "code": "58.21",
        "name": "Издание компьютерных игр"
    },
    {
        "code": "58.29",
        "name": "Издание прочих программных продуктов"
    },
    {
        "code": "59",
        "name": "Производство кинофильмов, видеофильмов и телевизионных программ, издание звукозаписей и нот"
    },
    {
        "code": "59.1",
        "name": "Производство кинофильмов, видеофильмов и телевизионных программ"
    },
    {
        "code": "59.11",
        "name": "Производство кинофильмов, видеофильмов и телевизионных программ"
    },
    {
        "code": "59.11.1",
        "name": "Производство анимационных фильмов"
    },
    {
        "code": "59.11.9",
        "name": "Производство прочих кинофильмов, видеофильмов и телевизионных программ"
    },
    {
        "code": "59.12",
        "name": "Деятельность монтажно-компоновочная в области производства кинофильмов, видеофильмов и телевизионных программ"
    },
    {
        "code": "59.13",
        "name": "Деятельность по распространению кинофильмов, видеофильмов и телевизионных программ"
    },
    {
        "code": "59.14",
        "name": "Деятельность в области демонстрации кинофильмов"
    },
    {
        "code": "59.2",
        "name": "Деятельность в области звукозаписи и издания музыкальных произведений"
    },
    {
        "code": "59.20",
        "name": "Деятельность в области звукозаписи и издания музыкальных произведений"
    },
    {
        "code": "59.20.1",
        "name": "Издание аудиовизуальных произведений на магнитных, электронных и цифровых носителях"
    },
    {
        "code": "59.20.2",
        "name": "Деятельность студий звукозаписи"
    },
    {
        "code": "59.20.3",
        "name": "Издание музыкальных и нотных тетрадей, в том числе для слепых"
    },
    {
        "code": "60",
        "name": "Деятельность в области телевизионного и радиовещания"
    },
    {
        "code": "60.1",
        "name": "Деятельность в области радиовещания"
    },
    {
        "code": "60.10",
        "name": "Деятельность в области радиовещания"
    },
    {
        "code": "60.2",
        "name": "Деятельность в области телевизионного вещания"
    },
    {
        "code": "60.20",
        "name": "Деятельность в области телевизионного вещания"
    },
    {
        "code": "61",
        "name": "Деятельность в сфере телекоммуникаций"
    },
    {
        "code": "61.1",
        "name": "Деятельность в области связи на базе проводных технологий"
    },
    {
        "code": "61.10",
        "name": "Деятельность в области связи на базе проводных технологий"
    },
    {
        "code": "61.10.1",
        "name": "Деятельность по предоставлению услуг телефонной связи"
    },
    {
        "code": "61.10.2",
        "name": "Деятельность по предоставлению услуг по передаче данных для целей передачи голосовой информации (IР-телефония)"
    },
    {
        "code": "61.10.3",
        "name": "Деятельность по предоставлению услуг по передаче данных и услуг доступа к информационно-коммуникационной сети Интернет"
    },
    {
        "code": "61.10.4",
        "name": "Деятельность в области документальной электросвязи"
    },
    {
        "code": "61.10.5",
        "name": "Деятельность по трансляции телерадиоканалов по сетям кабельного телерадиовещания"
    },
    {
        "code": "61.10.6",
        "name": "Деятельность операторов связи по присоединению и пропуску трафика"
    },
    {
        "code": "61.10.8",
        "name": "Деятельность операторов связи по присоединению и пропуску международного трафика"
    },
    {
        "code": "61.10.9",
        "name": "Деятельность в области связи на базе проводных технологий прочая"
    },
    {
        "code": "61.2",
        "name": "Деятельность в области связи на базе беспроводных технологий"
    },
    {
        "code": "61.20",
        "name": "Деятельность в области связи на базе беспроводных технологий"
    },
    {
        "code": "61.20.1",
        "name": "Деятельность по предоставлению услуг подвижной связи для целей передачи голоса"
    },
    {
        "code": "61.20.2",
        "name": "Деятельность по предоставлению услуг подвижной связи для целей передачи данных"
    },
    {
        "code": "61.20.3",
        "name": "Деятельность по предоставлению услуг подвижной связи для доступа к информационно-коммуникационной сети Интернет"
    },
    {
        "code": "61.20.4",
        "name": "Деятельность по предоставлению услуг связи для целей открытого эфирного вещания"
    },
    {
        "code": "61.20.5",
        "name": "Деятельность по предоставлению услуг цифрового телерадиовещания на базе беспроводных технологий"
    },
    {
        "code": "61.3",
        "name": "Деятельность в области спутниковой связи"
    },
    {
        "code": "61.30",
        "name": "Деятельность в области спутниковой связи"
    },
    {
        "code": "61.30.1",
        "name": "Деятельность по предоставлению услуг доступа к информационно-коммуникационной сети Интернет оператором спутниковой связи"
    },
    {
        "code": "61.30.2",
        "name": "Деятельность по предоставлению услуг трансляции телерадиоканалов по сетям спутникового телерадиовещания"
    },
    {
        "code": "61.9",
        "name": "Деятельность в области телекоммуникаций прочая"
    },
    {
        "code": "61.90",
        "name": "Деятельность в области телекоммуникаций прочая"
    },
    {
        "code": "62",
        "name": "Разработка компьютерного программного обеспечения, консультационные услуги в данной области и другие сопутствующие услуги"
    },
    {
        "code": "62.0",
        "name": "Разработка компьютерного программного обеспечения, консультационные услуги в данной области и другие сопутствующие услуги"
    },
    {
        "code": "62.01",
        "name": "Разработка компьютерного программного обеспечения"
    },
    {
        "code": "62.02",
        "name": "Деятельность консультативная и работы в области компьютерных технологий"
    },
    {
        "code": "62.02.1",
        "name": "Деятельность по планированию, проектированию компьютерных систем"
    },
    {
        "code": "62.02.2",
        "name": "Деятельность по обследованию и экспертизе компьютерных систем"
    },
    {
        "code": "62.02.3",
        "name": "Деятельность по обучению пользователей"
    },
    {
        "code": "62.02.4",
        "name": "Деятельность по подготовке компьютерных систем к эксплуатации"
    },
    {
        "code": "62.02.9",
        "name": "Деятельность консультативная в области компьютерных технологий прочая"
    },
    {
        "code": "62.03",
        "name": "Деятельность по управлению компьютерным оборудованием"
    },
    {
        "code": "62.03.1",
        "name": "Деятельность по управлению компьютерными системами"
    },
    {
        "code": "62.03.11",
        "name": "Деятельность по управлению компьютерными системами непосредственно"
    },
    {
        "code": "62.03.12",
        "name": "Деятельность по управлению компьютерными системами дистанционно"
    },
    {
        "code": "62.03.13",
        "name": "Деятельность по сопровождению компьютерных систем"
    },
    {
        "code": "62.03.19",
        "name": "Деятельность по управлению компьютерным оборудованием прочая, не включенная в другие группировки"
    },
    {
        "code": "62.09",
        "name": "Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая"
    },
    {
        "code": "63",
        "name": "Деятельность в области информационных технологий"
    },
    {
        "code": "63.1",
        "name": "Деятельность по обработке данных, предоставление услуг по размещению информации, деятельность порталов в информационно-коммуникационной сети Интернет"
    },
    {
        "code": "63.11",
        "name": "Деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность"
    },
    {
        "code": "63.11.1",
        "name": "Деятельность по созданию и использованию баз данных и информационных ресурсов"
    },
    {
        "code": "63.11.9",
        "name": "Деятельность по предоставлению услуг по размещению информации прочая"
    },
    {
        "code": "63.12",
        "name": "Деятельность web-порталов"
    },
    {
        "code": "63.12.1",
        "name": "Деятельность сетевых изданий"
    },
    {
        "code": "63.9",
        "name": "Деятельность в области информационных услуг прочая"
    },
    {
        "code": "63.91",
        "name": "Деятельность информационных агентств"
    },
    {
        "code": "63.99",
        "name": "Деятельность информационных служб прочая, не включенная в другие группировки"
    },
    {
        "code": "63.99.1",
        "name": "Деятельность по оказанию консультационных и информационных услуг"
    },
    {
        "code": "63.99.11",
        "name": "Деятельность по оказанию компьютерных информационных услуг телефонной связи"
    },
    {
        "code": "63.99.12",
        "name": "Деятельность по оказанию услуг службами информационного поиска по договору или на платной основе"
    },
    {
        "code": "63.99.2",
        "name": "Деятельность по оказанию услуг по составлению обзоров новостей, услуг по подборке печатных изданий и подобной информации"
    },
    {
        "code": "64",
        "name": "Деятельность по предоставлению финансовых услуг, кроме услуг по страхованию и пенсионному обеспечению"
    },
    {
        "code": "64.1",
        "name": "Денежное посредничество"
    },
    {
        "code": "64.11",
        "name": "Деятельность Центрального банка Российской Федерации (Банка России)"
    },
    {
        "code": "64.19",
        "name": "Денежное посредничество прочее"
    },
    {
        "code": "64.2",
        "name": "Деятельность холдинговых компаний"
    },
    {
        "code": "64.20",
        "name": "Деятельность холдинговых компаний"
    },
    {
        "code": "64.3",
        "name": "Деятельность инвестиционных фондов и аналогичных финансовых организаций"
    },
    {
        "code": "64.30",
        "name": "Деятельность инвестиционных фондов и аналогичных финансовых организаций"
    },
    {
        "code": "64.9",
        "name": "Деятельность по предоставлению прочих финансовых услуг, кроме услуг по страхованию и пенсионному обеспечению"
    },
    {
        "code": "64.91",
        "name": "Деятельность по финансовой аренде (лизингу/сублизингу)"
    },
    {
        "code": "64.91.1",
        "name": "Деятельность по финансовой аренде (лизингу/сублизингу) племенных животных"
    },
    {
        "code": "64.91.2",
        "name": "Деятельность по финансовой аренде (лизингу/сублизингу) в прочих областях, кроме племенных животных"
    },
    {
        "code": "64.92",
        "name": "Предоставление займов и прочих видов кредита"
    },
    {
        "code": "64.92.1",
        "name": "Деятельность по предоставлению потребительского кредита"
    },
    {
        "code": "64.92.2",
        "name": "Деятельность по предоставлению займов промышленности"
    },
    {
        "code": "64.92.3",
        "name": "Деятельность по предоставлению денежных ссуд под залог недвижимого имущества"
    },
    {
        "code": "64.92.4",
        "name": "Деятельность по предоставлению кредитов на покупку домов специализированными учреждениями, не принимающими депозиты"
    },
    {
        "code": "64.92.6",
        "name": "Деятельность по предоставлению ломбардами краткосрочных займов под залог движимого имущества"
    },
    {
        "code": "64.92.7",
        "name": "Деятельность микрофинансовая"
    },
    {
        "code": "64.99",
        "name": "Предоставление прочих финансовых услуг, кроме услуг по страхованию и пенсионному обеспечению, не включенных в другие группировки"
    },
    {
        "code": "64.99.1",
        "name": "Вложения в ценные бумаги"
    },
    {
        "code": "64.99.2",
        "name": "Деятельность дилерская"
    },
    {
        "code": "64.99.3",
        "name": "Капиталовложения в уставные капиталы, венчурное инвестирование, в том числе посредством инвестиционных компаний"
    },
    {
        "code": "64.99.4",
        "name": "Заключение свопов, опционов и других срочных сделок"
    },
    {
        "code": "64.99.5",
        "name": "Предоставление факторинговых услуг"
    },
    {
        "code": "64.99.6",
        "name": "Деятельность по финансовой взаимопомощи"
    },
    {
        "code": "64.99.7",
        "name": "Деятельность специализированного депозитария инвестиционных фондов, паевых инвестиционных фондов, негосударственных пенсионных фондов"
    },
    {
        "code": "64.99.8",
        "name": "Деятельность ипотечных агентов, управляющих ипотечным покрытием; деятельность специализированных депозитариев ипотечного покрытия"
    },
    {
        "code": "64.99.9",
        "name": "Деятельность жилищных накопительных кооперативов"
    },
    {
        "code": "65",
        "name": "Страхование, перестрахование, деятельность негосударственных пенсионных фондов, кроме обязательного социального обеспечения"
    },
    {
        "code": "65.1",
        "name": "Страхование"
    },
    {
        "code": "65.11",
        "name": "Страхование жизни"
    },
    {
        "code": "65.12",
        "name": "Страхование, кроме страхования жизни"
    },
    {
        "code": "65.12.1",
        "name": "Страхование медицинское"
    },
    {
        "code": "65.12.2",
        "name": "Страхование имущества"
    },
    {
        "code": "65.12.3",
        "name": "Страхование гражданской ответственности"
    },
    {
        "code": "65.12.4",
        "name": "Страхование от несчастных случаев и болезней"
    },
    {
        "code": "65.12.5",
        "name": "Страхование рисков"
    },
    {
        "code": "65.12.6",
        "name": "Страхование для путешественника, выезжающего за пределы постоянного проживания"
    },
    {
        "code": "65.12.9",
        "name": "Прочие виды страхования, не включенные в другие группировки"
    },
    {
        "code": "65.2",
        "name": "Перестрахование"
    },
    {
        "code": "65.20",
        "name": "Перестрахование"
    },
    {
        "code": "65.3",
        "name": "Деятельность негосударственных пенсионных фондов"
    },
    {
        "code": "65.30",
        "name": "Деятельность негосударственных пенсионных фондов"
    },
    {
        "code": "66",
        "name": "Деятельность вспомогательная в сфере финансовых услуг и страхования"
    },
    {
        "code": "66.1",
        "name": "Деятельность вспомогательная в сфере финансовых услуг, кроме страхования и пенсионного обеспечения"
    },
    {
        "code": "66.11",
        "name": "Управление финансовыми рынками"
    },
    {
        "code": "66.11.1",
        "name": "Деятельность по организации торговли на финансовых рынках"
    },
    {
        "code": "66.11.2",
        "name": "Управление и контроль за деятельностью фондовых, товарных, валютных и валютнофондовых бирж"
    },
    {
        "code": "66.11.3",
        "name": "Деятельность регистраторов по ведению реестра владельцев ценных бумаг"
    },
    {
        "code": "66.11.4",
        "name": "Деятельность по обеспечению эффективности функционирования финансовых рынков"
    },
    {
        "code": "66.11.5",
        "name": "Деятельность по определению взаимных обязательств (клиринг)"
    },
    {
        "code": "66.12",
        "name": "Деятельность брокерская по сделкам с ценными бумагами и товарами"
    },
    {
        "code": "66.12.1",
        "name": "Деятельность биржевых посредников и биржевых брокеров, совершающих товарные фьючерсные и опционные сделки в биржевой торговле"
    },
    {
        "code": "66.12.2",
        "name": "Деятельность по управлению ценными бумагами"
    },
    {
        "code": "66.12.3",
        "name": "Деятельность эмиссионная"
    },
    {
        "code": "66.19",
        "name": "Деятельность вспомогательная прочая в сфере финансовых услуг, кроме страхования и пенсионного обеспечения"
    },
    {
        "code": "66.19.1",
        "name": "Деятельность по предоставлению брокерских услуг по ипотечным операциям"
    },
    {
        "code": "66.19.3",
        "name": "Деятельность по предоставлению услуг по обработке наличных денег"
    },
    {
        "code": "66.19.4",
        "name": "Деятельность по предоставлению консультационных услуг по вопросам финансового посредничества"
    },
    {
        "code": "66.19.5",
        "name": "Предоставление услуг по хранению ценностей, депозитарная деятельность"
    },
    {
        "code": "66.19.6",
        "name": "Деятельность по приему платежей физических лиц платежными агентами"
    },
    {
        "code": "66.19.61",
        "name": "Деятельность операторов по приему платежей физических лиц"
    },
    {
        "code": "66.19.62",
        "name": "Деятельность платежных субагентов по приему платежей физических лиц"
    },
    {
        "code": "66.19.7",
        "name": "Рейтинговая деятельность"
    },
    {
        "code": "66.2",
        "name": "Деятельность вспомогательная в сфере страхования и пенсионного обеспечения"
    },
    {
        "code": "66.21",
        "name": "Оценка рисков и ущерба"
    },
    {
        "code": "66.22",
        "name": "Деятельность страховых агентов и брокеров"
    },
    {
        "code": "66.29",
        "name": "Деятельность вспомогательная прочая в сфере страхования и пенсионного обеспечения"
    },
    {
        "code": "66.29.1",
        "name": "Деятельность страховых актуариев"
    },
    {
        "code": "66.29.2",
        "name": "Деятельность распорядителей спасательными работами"
    },
    {
        "code": "66.29.9",
        "name": "Деятельность вспомогательная прочая в сфере страхования, кроме обязательного социального страхования"
    },
    {
        "code": "66.3",
        "name": "Деятельность по управлению фондами"
    },
    {
        "code": "66.30",
        "name": "Деятельность по управлению фондами"
    },
    {
        "code": "66.30.1",
        "name": "Управление инвестиционными фондами"
    },
    {
        "code": "66.30.2",
        "name": "Управление фондами денежного рынка"
    },
    {
        "code": "66.30.3",
        "name": "Управление пенсионными накоплениями негосударственных пенсионных фондов"
    },
    {
        "code": "66.30.4",
        "name": "Управление пенсионными резервами негосударственных пенсионных фондов"
    },
    {
        "code": "66.30.5",
        "name": "Управление страховыми резервами субъектов страхового дела"
    },
    {
        "code": "66.30.6",
        "name": "Управление на основе индивидуальных договоров доверительного управления активами"
    },
    {
        "code": "66.30.9",
        "name": "Другие виды деятельности по управлению активами"
    },
    {
        "code": "68",
        "name": "Операции с недвижимым имуществом"
    },
    {
        "code": "68.1",
        "name": "Покупка и продажа собственного недвижимого имущества"
    },
    {
        "code": "68.10",
        "name": "Покупка и продажа собственного недвижимого имущества"
    },
    {
        "code": "68.10.1",
        "name": "Подготовка к продаже собственного недвижимого имущества"
    },
    {
        "code": "68.10.11",
        "name": "Подготовка к продаже собственного жилого недвижимого имущества"
    },
    {
        "code": "68.10.12",
        "name": "Подготовка к продаже собственного нежилого недвижимого имущества"
    },
    {
        "code": "68.10.2",
        "name": "Покупка и продажа собственного недвижимого имущества"
    },
    {
        "code": "68.10.21",
        "name": "Покупка и продажа собственного жилого недвижимого имущества"
    },
    {
        "code": "68.10.22",
        "name": "Покупка и продажа собственных нежилых зданий и помещений"
    },
    {
        "code": "68.10.23",
        "name": "Покупка и продажа земельных участков"
    },
    {
        "code": "68.2",
        "name": "Аренда и управление собственным или арендованным недвижимым имуществом"
    },
    {
        "code": "68.20",
        "name": "Аренда и управление собственным или арендованным недвижимым имуществом"
    },
    {
        "code": "68.20.1",
        "name": "Аренда и управление собственным или арендованным жилым недвижимым имуществом"
    },
    {
        "code": "68.20.2",
        "name": "Аренда и управление собственным или арендованным нежилым недвижимым имуществом"
    },
    {
        "code": "68.3",
        "name": "Операции с недвижимым имуществом за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31",
        "name": "Деятельность агентств недвижимости за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.1",
        "name": "Предоставление посреднических услуг при купле-продаже недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.11",
        "name": "Предоставление посреднических услуг при купле-продаже жилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.12",
        "name": "Предоставление посреднических услуг при купле-продаже нежилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.2",
        "name": "Предоставление посреднических услуг по аренде недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.21",
        "name": "Предоставление посреднических услуг по аренде жилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.22",
        "name": "Предоставление посреднических услуг по аренде нежилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.3",
        "name": "Предоставление консультационных услуг при купле-продаже недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.31",
        "name": "Предоставление консультационных услуг при купле-продаже жилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.32",
        "name": "Предоставление консультационных услуг при купле-продаже нежилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.4",
        "name": "Предоставление консультационных услуг по аренде недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.41",
        "name": "Предоставление консультационных услуг по аренде жилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.42",
        "name": "Предоставление консультационных услуг по аренде нежилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.5",
        "name": "Предоставление посреднических услуг при оценке недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.51",
        "name": "Предоставление посреднических услуг при оценке жилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.31.52",
        "name": "Предоставление посреднических услуг при оценке нежилого недвижимого имущества за вознаграждение или на договорной основе"
    },
    {
        "code": "68.32",
        "name": "Управление недвижимым имуществом за вознаграждение или на договорной основе"
    },
    {
        "code": "68.32.1",
        "name": "Управление эксплуатацией жилого фонда за вознаграждение или на договорной основе"
    },
    {
        "code": "68.32.2",
        "name": "Управление эксплуатацией нежилого фонда за вознаграждение или на договорной основе"
    },
    {
        "code": "68.32.3",
        "name": "Деятельность по технической инвентаризации недвижимого имущества"
    },
    {
        "code": "69",
        "name": "Деятельность в области права и бухгалтерского учета"
    },
    {
        "code": "69.1",
        "name": "Деятельность в области права"
    },
    {
        "code": "69.10",
        "name": "Деятельность в области права"
    },
    {
        "code": "69.2",
        "name": "Деятельность по оказанию услуг в области бухгалтерского учета, по проведению финансового аудита, по налоговому консультированию"
    },
    {
        "code": "69.20",
        "name": "Деятельность по оказанию услуг в области бухгалтерского учета, по проведению финансового аудита, по налоговому консультированию"
    },
    {
        "code": "69.20.1",
        "name": "Деятельность по проведению финансового аудита"
    },
    {
        "code": "69.20.2",
        "name": "Деятельность по оказанию услуг в области бухгалтерского учета"
    },
    {
        "code": "69.20.3",
        "name": "Деятельность области налогового консультирования"
    },
    {
        "code": "70",
        "name": "Деятельность головных офисов; консультирование по вопросам управления"
    },
    {
        "code": "70.1",
        "name": "Деятельность головных офисов"
    },
    {
        "code": "70.10",
        "name": "Деятельность головных офисов"
    },
    {
        "code": "70.10.1",
        "name": "Деятельность по управлению финансово-промышленными группами"
    },
    {
        "code": "70.10.2",
        "name": "Деятельность по управлению холдинг-компаниями"
    },
    {
        "code": "70.2",
        "name": "Консультирование по вопросам управления"
    },
    {
        "code": "70.21",
        "name": "Деятельность в сфере связей с общественностью"
    },
    {
        "code": "70.22",
        "name": "Консультирование по вопросам коммерческой деятельности и управления"
    },
    {
        "code": "71",
        "name": "Деятельность в области архитектуры и инженерно-технического проектирования; технических испытаний, исследований и анализа"
    },
    {
        "code": "71.1",
        "name": "Деятельность в области архитектуры, инженерных изысканий и предоставление технических консультаций в этих областях"
    },
    {
        "code": "71.11",
        "name": "Деятельность в области архитектуры"
    },
    {
        "code": "71.11.1",
        "name": "Деятельность в области архитектуры, связанная с созданием архитектурного объекта"
    },
    {
        "code": "71.11.2",
        "name": "Деятельность по территориальному планированию и планировке территории"
    },
    {
        "code": "71.11.3",
        "name": "Деятельность в области ландшафтной архитектуры и консультативные услуги в области архитектуры"
    },
    {
        "code": "71.12",
        "name": "Деятельность в области инженерных изысканий, инженерно-технического проектирования, управления проектами строительства, выполнения строительного контроля и авторского надзора, предоставление технических консультаций в этих областях"
    },
    {
        "code": "71.12.1",
        "name": "Деятельность, связанная с инженерно-техническим проектированием, управлением проектами строительства, выполнением строительного контроля и авторского надзора"
    },
    {
        "code": "71.12.11",
        "name": "Разработка проектов тепло-, водо-, газоснабжения"
    },
    {
        "code": "71.12.12",
        "name": "Разработка проектов промышленных процессов и производств, относящихся к электротехнике, электронной технике, горному делу, химической технологии, машиностроению, а также в области промышленного строительства, системотехники и техники безопасности"
    },
    {
        "code": "71.12.13",
        "name": "Разработка проектов по кондиционированию воздуха, холодильной технике, санитарной технике и мониторингу загрязнения окружающей среды, строительной акустике"
    },
    {
        "code": "71.12.14",
        "name": "Разработка инженерно-технических проектов и контроль при строительстве и модернизации объектов использования атомной энергии"
    },
    {
        "code": "71.12.15",
        "name": "Инженерные изыскания в строительстве"
    },
    {
        "code": "71.12.2",
        "name": "Деятельность заказчика-застройщика, генерального подрядчика"
    },
    {
        "code": "71.12.3",
        "name": "Работы геолого-разведочные, геофизические и геохимические в области изучения недр и воспроизводства минерально-сырьевой базы"
    },
    {
        "code": "71.12.4",
        "name": "Деятельность геодезическая и картографическая"
    },
    {
        "code": "71.12.41",
        "name": "Деятельность геодезическая, кроме создания геодезической, нивелирной и гравиметрической сетей"
    },
    {
        "code": "71.12.42",
        "name": "Деятельность картографическая, кроме создания топографических карт и планов"
    },
    {
        "code": "71.12.43",
        "name": "Деятельность гидрографическая изыскательская"
    },
    {
        "code": "71.12.44",
        "name": "Деятельность по созданию топографических карт и планов"
    },
    {
        "code": "71.12.45",
        "name": "Создание геодезической, нивелирной, гравиметрической сетей"
    },
    {
        "code": "71.12.5",
        "name": "Деятельность в области гидрометеорологии и смежных с ней областях, мониторинга состояния окружающей среды, ее загрязнения"
    },
    {
        "code": "71.12.51",
        "name": "Деятельность наблюдательной гидрометеорологической сети"
    },
    {
        "code": "71.12.52",
        "name": "Проведение гелиофизических и геофизических работ"
    },
    {
        "code": "71.12.53",
        "name": "Деятельность по мониторингу загрязнения окружающей среды для физических и юридических лиц"
    },
    {
        "code": "71.12.54",
        "name": "Работы полевые и изыскания в области гидрометеорологии и смежных с ней областях, экспедиционные обследования объектов окружающей среды с целью оценки уровней загрязнения"
    },
    {
        "code": "71.12.55",
        "name": "Деятельность по обработке и предоставлению гидрометеорологической информации органам государственной власти и населению"
    },
    {
        "code": "71.12.56",
        "name": "Обеспечение гидрометеорологическое деятельности физических и юридических лиц"
    },
    {
        "code": "71.12.57",
        "name": "Деятельность, связанная с активными воздействиями на метеорологические и геофизические процессы и явления"
    },
    {
        "code": "71.12.6",
        "name": "Деятельность в области технического регулирования, стандартизации, метрологии, аккредитации, каталогизации продукции"
    },
    {
        "code": "71.12.61",
        "name": "Деятельность в области технического регулирования и стандартизации"
    },
    {
        "code": "71.12.62",
        "name": "Деятельность в области метрологии"
    },
    {
        "code": "71.12.63",
        "name": "Деятельность в области аккредитации"
    },
    {
        "code": "71.12.64",
        "name": "Государственный контроль (надзор) за соблюдением требований технических регламентов"
    },
    {
        "code": "71.12.65",
        "name": "Федеральный государственный метрологический надзор"
    },
    {
        "code": "71.12.66",
        "name": "Деятельность в области каталогизации продукции"
    },
    {
        "code": "71.12.7",
        "name": "Кадастровая деятельность"
    },
    {
        "code": "71.12.8",
        "name": "Деятельность по предоставлению инженерно-технических консультаций в сфере энергосбережения и повышения энергетической эффективности использования энергетических ресурсов"
    },
    {
        "code": "71.12.9",
        "name": "Землеустройство"
    },
    {
        "code": "71.2",
        "name": "Технические испытания, исследования, анализ и сертификация"
    },
    {
        "code": "71.20",
        "name": "Технические испытания, исследования, анализ и сертификация"
    },
    {
        "code": "71.20.1",
        "name": "Испытания и анализ состава и чистоты материалов и веществ: анализ химических и биологических свойств материалов и веществ; испытания и анализ в области гигиены питания, включая ветеринарный контроль и контроль за производством продуктов питания"
    },
    {
        "code": "71.20.2",
        "name": "Судебно-экспертная деятельность"
    },
    {
        "code": "71.20.3",
        "name": "Испытания и анализ физико-механических свойств материалов и веществ"
    },
    {
        "code": "71.20.4",
        "name": "Испытания, исследования и анализ целостных механических и электрических систем, энергетическое обследование"
    },
    {
        "code": "71.20.5",
        "name": "Технический осмотр автотранспортных средств"
    },
    {
        "code": "71.20.6",
        "name": "Экспертиза проектной документации, запасов полезных ископаемых и подземных вод, геологической информации о предоставляемых в пользование участках недр, результатов инженерных изысканий"
    },
    {
        "code": "71.20.61",
        "name": "Экспертиза проектной документации, запасов полезных ископаемых и подземных вод, геологической информации о предоставляемых в пользование участках недр, результатов инженерных изысканий государственная"
    },
    {
        "code": "71.20.62",
        "name": "Экспертиза проектной документации и результатов инженерных изысканий негосударственная"
    },
    {
        "code": "71.20.7",
        "name": "Деятельность по оценке условий труда"
    },
    {
        "code": "71.20.8",
        "name": "Сертификация продукции, услуг и организаций"
    },
    {
        "code": "71.20.9",
        "name": "Деятельность по техническому контролю, испытаниям и анализу прочая"
    },
    {
        "code": "72",
        "name": "Научные исследования и разработки"
    },
    {
        "code": "72.1",
        "name": "Научные исследования и разработки в области естественных и технических наук"
    },
    {
        "code": "72.10.11",
        "name": "Проведение исследований в области социально-экономической географии"
    },
    {
        "code": "72.11",
        "name": "Научные исследования и разработки в области биотехнологии"
    },
    {
        "code": "72.19",
        "name": "Научные исследования и разработки в области естественных и технических наук прочие"
    },
    {
        "code": "72.19.1",
        "name": "Проведение фундаментальных исследований и научно-исследовательских и опытно-конструкторских работ в области использования атомной энергии и в области ядерной оружейной продукции"
    },
    {
        "code": "72.19.11",
        "name": "Проведение фундаментальных исследований, научно-исследовательских и опытно-конструкторских работ в области использования атомной энергии"
    },
    {
        "code": "72.19.12",
        "name": "Научные исследования и разработки в области ядерной оружейной продукции"
    },
    {
        "code": "72.19.3",
        "name": "Научные исследования и разработки в области нанотехнологий"
    },
    {
        "code": "72.19.4",
        "name": "Научные исследования и разработки в области защиты информации"
    },
    {
        "code": "72.19.9",
        "name": "Научные исследования и разработки в области естественных и технических наук прочие, не включенные в другие группировки"
    },
    {
        "code": "72.2",
        "name": "Научные исследования и разработки в области общественных и гуманитарных наук"
    },
    {
        "code": "72.20",
        "name": "Научные исследования и разработки в области общественных и гуманитарных наук"
    },
    {
        "code": "72.20.1",
        "name": "Научные исследования и разработки в области общественных наук"
    },
    {
        "code": "72.20.19",
        "name": "Научные исследования и разработки в области общественных наук прочие"
    },
    {
        "code": "72.20.2",
        "name": "Научные исследования и разработки в области гуманитарных наук"
    },
    {
        "code": "73",
        "name": "Деятельность рекламная и исследование конъюнктуры рынка"
    },
    {
        "code": "73.1",
        "name": "Деятельность рекламная"
    },
    {
        "code": "73.11",
        "name": "Деятельность рекламных агентств"
    },
    {
        "code": "73.12",
        "name": "Представление в средствах массовой информации"
    },
    {
        "code": "73.2",
        "name": "Исследование конъюнктуры рынка и изучение общественного мнения"
    },
    {
        "code": "73.20",
        "name": "Исследование конъюнктуры рынка и изучение общественного мнения"
    },
    {
        "code": "73.20.1",
        "name": "Исследование конъюнктуры рынка"
    },
    {
        "code": "73.20.2",
        "name": "Деятельность по изучению общественного мнения"
    },
    {
        "code": "74",
        "name": "Деятельность профессиональная научная и техническая прочая"
    },
    {
        "code": "74.1",
        "name": "Деятельность специализированная в области дизайна"
    },
    {
        "code": "74.10",
        "name": "Деятельность специализированная в области дизайна"
    },
    {
        "code": "74.2",
        "name": "Деятельность в области фотографии"
    },
    {
        "code": "74.20",
        "name": "Деятельность в области фотографии"
    },
    {
        "code": "74.3",
        "name": "Деятельность по письменному и устному переводу"
    },
    {
        "code": "74.30",
        "name": "Деятельность по письменному и устному переводу"
    },
    {
        "code": "74.9",
        "name": "Деятельность профессиональная, научная и техническая прочая, не включенная в другие группировки"
    },
    {
        "code": "74.90",
        "name": "Деятельность профессиональная, научная и техническая прочая, не включенная в другие группировки"
    },
    {
        "code": "74.90.1",
        "name": "Предоставление посреднических услуг по организации покупки и продажи мелких или средних коммерческих предприятий, включая профессиональную практику"
    },
    {
        "code": "74.90.2",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости (оценочная деятельность), кроме оценки, связанной с недвижимым имуществом или страхованием"
    },
    {
        "code": "74.90.21",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости отдельных материальных объектов (вещей)"
    },
    {
        "code": "74.90.22",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости совокупности вещей, составляющих имущество лица, в том числе имущество определенного вида (движимое или недвижимое, в том числе предприятия)"
    },
    {
        "code": "74.90.23",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости права собственности или иных вещных прав на имущество или отдельные вещи из состава имущества"
    },
    {
        "code": "74.90.24",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости прав требования, обязательств (долгов)"
    },
    {
        "code": "74.90.25",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости работ, услуг, информации"
    },
    {
        "code": "74.90.26",
        "name": "Деятельность, направленная на установление рыночной или иной стоимости иных объектов гражданских прав, в отношении которых законодательством Российской Федерации установлена возможность их участия в гражданском обороте"
    },
    {
        "code": "74.90.3",
        "name": "Предоставление консультационных услуг по вопросам безопасности"
    },
    {
        "code": "74.90.31",
        "name": "Предоставление услуг по проведению оценки уязвимости объектов транспортной инфраструктуры и транспортных средств"
    },
    {
        "code": "74.90.32",
        "name": "Предоставление услуг по проведению оценки уязвимости объектов промышленного назначения, связи, здравоохранения и т. д."
    },
    {
        "code": "74.90.4",
        "name": "Предоставление консультационных услуг в области сельского хозяйства"
    },
    {
        "code": "74.90.5",
        "name": "Предоставление консультационных услуг в области экологии"
    },
    {
        "code": "74.90.6",
        "name": "Предоставление прочих технических консультаций, деятельность консультантов, кроме архитекторов, проектировщиков и консультантов по управлению"
    },
    {
        "code": "74.90.7",
        "name": "Деятельность по подготовке метеорологических прогнозов"
    },
    {
        "code": "74.90.8",
        "name": "Деятельность агентств и агентов, действующих от имени физических лиц, и обычно связанную с заключением контрактов (договоров) на участие в кинофильмах, театральных постановках и других развлекательных или спортивных мероприятиях, а также с предложени ем книг, пьес, предметов изобразительного искусства, фотографий и аналогичных предметов издателям, продюсерам"
    },
    {
        "code": "74.90.9",
        "name": "Деятельность в области защиты информации"
    },
    {
        "code": "74.90.91",
        "name": "Деятельность по разработке средств защиты информации"
    },
    {
        "code": "74.90.92",
        "name": "Деятельность по разработке информационных и телекоммуникационных систем, защищенных с использованием средств защиты информации"
    },
    {
        "code": "74.90.99",
        "name": "Деятельность в области защиты информации прочая"
    },
    {
        "code": "75",
        "name": "Деятельность ветеринарная"
    },
    {
        "code": "75.0",
        "name": "Деятельность ветеринарная"
    },
    {
        "code": "75.00",
        "name": "Деятельность ветеринарная"
    },
    {
        "code": "75.00.1",
        "name": "Деятельность ветеринарная для сельскохозяйственных животных"
    },
    {
        "code": "75.00.2",
        "name": "Деятельность ветеринарная для домашних животных"
    },
    {
        "code": "77",
        "name": "Аренда и лизинг"
    },
    {
        "code": "77.1",
        "name": "Аренда и лизинг автотранспортных средств"
    },
    {
        "code": "77.11",
        "name": "Аренда и лизинг легковых автомобилей и легких автотранспортных средств"
    },
    {
        "code": "77.12",
        "name": "Аренда и лизинг грузовых транспортных средств"
    },
    {
        "code": "77.2",
        "name": "Прокат и аренда предметов личного пользования и хозяйственно-бытового назначения"
    },
    {
        "code": "77.21",
        "name": "Прокат и аренда товаров для отдыха и спортивных товаров"
    },
    {
        "code": "77.22",
        "name": "Прокат видеокассет и аудиокассет, грампластинок, компакт-дисков (CD), цифровых видеодисков (DVD)"
    },
    {
        "code": "77.29",
        "name": "Прокат и аренда прочих предметов личного пользования и хозяйственно-бытового назначения"
    },
    {
        "code": "77.29.1",
        "name": "Прокат телевизоров, радиоприемников, устройств видеозаписи, аудиозаписи и подобного оборудования"
    },
    {
        "code": "77.29.2",
        "name": "Прокат мебели, электрических и неэлектрических бытовых приборов"
    },
    {
        "code": "77.29.3",
        "name": "Прокат музыкальных инструментов"
    },
    {
        "code": "77.29.4",
        "name": "Прокат одежды, даже если чистка таких изделий является составной частью этого вида деятельности"
    },
    {
        "code": "77.29.9",
        "name": "Прокат прочих бытовых изделий и предметов личного пользования для домашних хозяйств, предприятий и организаций, не включенных в другие группировки"
    },
    {
        "code": "77.3",
        "name": "Аренда и лизинг прочих машин и оборудования и материальных средств"
    },
    {
        "code": "77.31",
        "name": "Аренда и лизинг сельскохозяйственных машин и оборудования"
    },
    {
        "code": "77.32",
        "name": "Аренда и лизинг строительных машин и оборудования"
    },
    {
        "code": "77.33",
        "name": "Аренда и лизинг офисных машин и оборудования, включая вычислительную технику"
    },
    {
        "code": "77.33.1",
        "name": "Аренда и лизинг офисных машин и оборудования"
    },
    {
        "code": "77.33.2",
        "name": "Аренда и лизинг вычислительных машин и оборудования"
    },
    {
        "code": "77.34",
        "name": "Аренда и лизинг водных транспортных средств и оборудования"
    },
    {
        "code": "77.35",
        "name": "Аренда и лизинг воздушных судов и авиационного оборудования"
    },
    {
        "code": "77.39",
        "name": "Аренда и лизинг прочих видов транспорта, оборудования и материальных средств, не включенных в другие группировки"
    },
    {
        "code": "77.39.1",
        "name": "Аренда и лизинг прочих сухопутных транспортных средств и оборудования"
    },
    {
        "code": "77.39.11",
        "name": "Аренда и лизинг прочего автомобильного транспорта и оборудования"
    },
    {
        "code": "77.39.12",
        "name": "Аренда и лизинг железнодорожного транспорта и оборудования"
    },
    {
        "code": "77.39.2",
        "name": "Аренда и лизинг прочих машин и оборудования, не включенных в другие группировки"
    },
    {
        "code": "77.39.21",
        "name": "Аренда и лизинг двигателей, турбин и станков"
    },
    {
        "code": "77.39.22",
        "name": "Аренда и лизинг горного и нефтепромыслового оборудования"
    },
    {
        "code": "77.39.23",
        "name": "Аренда и лизинг подъемно-транспортного оборудования"
    },
    {
        "code": "77.39.24",
        "name": "Аренда и лизинг профессиональной радио- и телевизионной аппаратуры и аппаратуры связи"
    },
    {
        "code": "77.39.25",
        "name": "Аренда и лизинг контрольно-измерительной аппаратуры"
    },
    {
        "code": "77.39.26",
        "name": "Аренда и лизинг приборов, аппаратов и прочего оборудования, применяемого в медицинских целях"
    },
    {
        "code": "77.39.27",
        "name": "Аренда и лизинг торгового оборудования"
    },
    {
        "code": "77.39.29",
        "name": "Аренда и лизинг прочих машин и оборудования научного и промышленного назначения"
    },
    {
        "code": "77.39.3",
        "name": "Аренда и лизинг племенных сельскохозяйственных животных"
    },
    {
        "code": "77.4",
        "name": "Аренда интеллектуальной собственности и подобной продукции, кроме авторских прав"
    },
    {
        "code": "77.40",
        "name": "Аренда интеллектуальной собственности и подобной продукции, кроме авторских прав"
    },
    {
        "code": "78",
        "name": "Деятельность по трудоустройству и подбору персонала"
    },
    {
        "code": "78.1",
        "name": "Деятельность агентств по подбору персонала"
    },
    {
        "code": "78.10",
        "name": "Деятельность агентств по подбору персонала"
    },
    {
        "code": "78.2",
        "name": "Деятельность агентств по временному трудоустройству"
    },
    {
        "code": "78.20",
        "name": "Деятельность агентств по временному трудоустройству"
    },
    {
        "code": "78.3",
        "name": "Деятельность по подбору персонала прочая"
    },
    {
        "code": "78.30",
        "name": "Деятельность по подбору персонала прочая"
    },
    {
        "code": "79",
        "name": "Деятельность туристических агентств и прочих организаций, предоставляющих услуги в сфере туризма"
    },
    {
        "code": "79.1",
        "name": "Деятельность туристических агентств и туроператоров"
    },
    {
        "code": "79.11",
        "name": "Деятельность туристических агентств"
    },
    {
        "code": "79.12",
        "name": "Деятельность туроператоров"
    },
    {
        "code": "79.9",
        "name": "Услуги по бронированию прочие и сопутствующая деятельность"
    },
    {
        "code": "79.90",
        "name": "Услуги по бронированию прочие и сопутствующая деятельность"
    },
    {
        "code": "79.90.1",
        "name": "Деятельность по предоставлению туристических информационных услуг"
    },
    {
        "code": "79.90.2",
        "name": "Деятельность по предоставлению экскурсионных туристических услуг"
    },
    {
        "code": "79.90.21",
        "name": "Деятельность туристических агентств по предоставлению экскурсионных туристических услуг"
    },
    {
        "code": "79.90.22",
        "name": "Деятельность самостоятельных экскурсоводов и гидов по предоставлению экскурсионных туристических услуг"
    },
    {
        "code": "79.90.3",
        "name": "Деятельность по предоставлению туристических услуг, связанных с бронированием"
    },
    {
        "code": "79.90.31",
        "name": "Деятельность по бронированию билетов на культурно-развлекательные мероприятия"
    },
    {
        "code": "79.90.32",
        "name": "Деятельность по оказанию прочих услуг, связанных со службой предварительных заказов"
    },
    {
        "code": "80",
        "name": "Деятельность по обеспечению безопасности и проведению расследований"
    },
    {
        "code": "80.1",
        "name": "Деятельность частных охранных служб"
    },
    {
        "code": "80.10",
        "name": "Деятельность частных охранных служб"
    },
    {
        "code": "80.2",
        "name": "Деятельность систем обеспечения безопасности"
    },
    {
        "code": "80.20",
        "name": "Деятельность систем обеспечения безопасности"
    },
    {
        "code": "80.3",
        "name": "Деятельность по расследованию"
    },
    {
        "code": "80.30",
        "name": "Деятельность по расследованию"
    },
    {
        "code": "81",
        "name": "Деятельность по обслуживанию зданий и территорий"
    },
    {
        "code": "81.1",
        "name": "Деятельность по комплексному обслуживанию помещений"
    },
    {
        "code": "81.10",
        "name": "Деятельность по комплексному обслуживанию помещений"
    },
    {
        "code": "81.2",
        "name": "Деятельность по чистке и уборке"
    },
    {
        "code": "81.21",
        "name": "Деятельность по общей уборке зданий"
    },
    {
        "code": "81.21.1",
        "name": "Деятельность по уборке квартир и частных домов"
    },
    {
        "code": "81.21.9",
        "name": "Деятельность по уборке прочих типов зданий и помещений"
    },
    {
        "code": "81.22",
        "name": "Деятельность по чистке и уборке жилых зданий и нежилых помещений прочая"
    },
    {
        "code": "81.29",
        "name": "Деятельность по чистке и уборке прочая"
    },
    {
        "code": "81.29.1",
        "name": "Дезинфекция, дезинсекция, дератизация зданий, промышленного оборудования"
    },
    {
        "code": "81.29.2",
        "name": "Подметание улиц и уборка снега"
    },
    {
        "code": "81.29.9",
        "name": "Деятельность по чистке и уборке прочая, не включенная в другие группировки"
    },
    {
        "code": "81.3",
        "name": "Предоставление услуг по благоустройству ландшафта"
    },
    {
        "code": "81.30",
        "name": "Деятельность по благоустройству ландшафта"
    },
    {
        "code": "82",
        "name": "Деятельность административно-хозяйственная, вспомогательная деятельность по обеспечению функционирования организации, деятельность по предоставлению прочих вспомогательных услуг для бизнеса"
    },
    {
        "code": "82.1",
        "name": "Деятельность административно-хозяйственная и вспомогательная деятельность по обеспечению функционирования организации"
    },
    {
        "code": "82.11",
        "name": "Деятельность административно-хозяйственная комплексная по обеспечению работы организации"
    },
    {
        "code": "82.19",
        "name": "Деятельность по фотокопированию и подготовке документов и прочая специализированная вспомогательная деятельность по обеспечению деятельности офиса"
    },
    {
        "code": "82.2",
        "name": "Деятельность центров обработки телефонных вызовов"
    },
    {
        "code": "82.20",
        "name": "Деятельность центров обработки телефонных вызовов"
    },
    {
        "code": "82.3",
        "name": "Деятельность по организации конференций и выставок"
    },
    {
        "code": "82.30",
        "name": "Деятельность по организации конференций и выставок"
    },
    {
        "code": "82.9",
        "name": "Деятельность по предоставлению вспомогательных услуг для бизнеса, не включенная в другие группировки"
    },
    {
        "code": "82.91",
        "name": "Деятельность агентств по сбору платежей и бюро кредитной информации"
    },
    {
        "code": "82.92",
        "name": "Деятельность по упаковыванию товаров"
    },
    {
        "code": "82.99",
        "name": "Деятельность по предоставлению прочих вспомогательных услуг для бизнеса, не включенная в другие группировки"
    },
    {
        "code": "84",
        "name": "Деятельность органов государственного управления по обеспечению военной безопасности, обязательному социальному обеспечению"
    },
    {
        "code": "84.1",
        "name": "Деятельность органов государственного управления и местного самоуправления по вопросам общего и социально-экономического характера"
    },
    {
        "code": "84.11",
        "name": "Деятельность органов государственного управления и местного самоуправления по вопросам общего характера"
    },
    {
        "code": "84.11.1",
        "name": "Деятельность федеральных органов государственной власти по управлению вопросами общего характера, кроме судебной власти"
    },
    {
        "code": "84.11.11",
        "name": "Деятельность федеральных органов государственной власти, кроме полномочных представителей Президента Российской Федерации и территориальных органов федеральных органов исполнительной власти"
    },
    {
        "code": "84.11.12",
        "name": "Деятельность полномочных представителей Президента Российской Федерации в регионах Российской Федерации и территориальных органов федеральных органов исполнительной власти в субъектах Российской Федерации (республиках, краях, областях)"
    },
    {
        "code": "84.11.13",
        "name": "Деятельность территориальных органов федеральных органов исполнительной власти в городах и районах субъектов Российской Федерации"
    },
    {
        "code": "84.11.2",
        "name": "Деятельность органов государственной власти по управлению вопросами общего характера, кроме судебной власти, субъектов Российской Федерации"
    },
    {
        "code": "84.11.21",
        "name": "Деятельность органов государственной власти субъектов Российской Федерации (республик, краев, областей), кроме судебной власти, представительств исполнительных органов государственной власти субъектов Российской Федерации при Президенте Российской Фе дерации"
    },
    {
        "code": "84.11.22",
        "name": "Деятельность органов государственной власти субъектов Российской Федерации по осуществлению своих полномочий в городах и районах"
    },
    {
        "code": "84.11.23",
        "name": "Деятельность органов государственной власти субъектов Российской Федерации по осуществлению своих полномочий в сельских населенных пунктах"
    },
    {
        "code": "84.11.3",
        "name": "Деятельность органов местного самоуправления по управлению вопросами общего характера"
    },
    {
        "code": "84.11.31",
        "name": "Деятельность органов местного самоуправления сельских поселений"
    },
    {
        "code": "84.11.32",
        "name": "Деятельность органов местного самоуправления городских поселений"
    },
    {
        "code": "84.11.33",
        "name": "Деятельность органов местного самоуправления муниципальных районов"
    },
    {
        "code": "84.11.34",
        "name": "Деятельность органов местного самоуправления муниципальных округов"
    },
    {
        "code": "84.11.35",
        "name": "Деятельность органов местного самоуправления городских округов"
    },
    {
        "code": "84.11.36",
        "name": "Деятельность органов местного самоуправления городских округов с внутригородским делением"
    },
    {
        "code": "84.11.37",
        "name": "Деятельность органов местного самоуправления внутригородских районов"
    },
    {
        "code": "84.11.38",
        "name": "Деятельность органов местного самоуправления внутригородских территорий городов федерального значения"
    },
    {
        "code": "84.11.4",
        "name": "Управление финансовой деятельностью и деятельностью в сфере налогообложения"
    },
    {
        "code": "84.11.5",
        "name": "Управление деятельностью в области прогнозирования и планирования"
    },
    {
        "code": "84.11.6",
        "name": "Управление деятельностью в области фундаментальных исследований"
    },
    {
        "code": "84.11.7",
        "name": "Управление деятельностью в области статистики"
    },
    {
        "code": "84.11.8",
        "name": "Управление имуществом, находящимся в государственной собственности"
    },
    {
        "code": "84.11.9",
        "name": "Управление деятельностью в области антимонопольного контроля"
    },
    {
        "code": "84.12",
        "name": "Государственное регулирование деятельности в области здравоохранения, образования, социально-культурного развития и других социальных услуг, кроме социального обеспечения"
    },
    {
        "code": "84.13",
        "name": "Регулирование и содействие эффективному ведению экономической деятельности предприятий"
    },
    {
        "code": "84.2",
        "name": "Предоставление государственных услуг обществу"
    },
    {
        "code": "84.21",
        "name": "Деятельность международная"
    },
    {
        "code": "84.22",
        "name": "Деятельность, связанная с обеспечением военной безопасности"
    },
    {
        "code": "84.23",
        "name": "Деятельность в области юстиции и правосудия"
    },
    {
        "code": "84.23.1",
        "name": "Деятельность федеральных судов"
    },
    {
        "code": "84.23.11",
        "name": "Деятельность Конституционного суда Российской Федерации"
    },
    {
        "code": "84.23.12",
        "name": "Деятельность Верховного суда Российской Федерации"
    },
    {
        "code": "84.23.13",
        "name": "Деятельность кассационных судов общей юрисдикции, апелляционных судов общей юрисдикции, верховных судов республик, краевых, областных судов, судов городов федерального значения, суда автономной области, судов автономных округов"
    },
    {
        "code": "84.23.14",
        "name": "Деятельность районных судов"
    },
    {
        "code": "84.23.15",
        "name": "Деятельность военных судов"
    },
    {
        "code": "84.23.17",
        "name": "Деятельность федеральных арбитражных судов округов"
    },
    {
        "code": "84.23.18",
        "name": "Деятельность арбитражных апелляционных судов, арбитражных судов субъектов Российской Федерации"
    },
    {
        "code": "84.23.19",
        "name": "Деятельность специализированных судов"
    },
    {
        "code": "84.23.2",
        "name": "Деятельность судов субъектов Российской Федерации"
    },
    {
        "code": "84.23.21",
        "name": "Деятельность конституционных (уставных) судов"
    },
    {
        "code": "84.23.22",
        "name": "Деятельность мировых судей"
    },
    {
        "code": "84.23.3",
        "name": "Деятельность органов прокуратуры Российской Федерации"
    },
    {
        "code": "84.23.31",
        "name": "Деятельность Генеральной прокуратуры Российской Федерации"
    },
    {
        "code": "84.23.32",
        "name": "Деятельность прокуратур субъектов Российской Федерации"
    },
    {
        "code": "84.23.33",
        "name": "Деятельность прокуратур городов и районов"
    },
    {
        "code": "84.23.4",
        "name": "Деятельность по управлению и эксплуатации тюрем, исправительных колоний и других мест лишения свободы, а также по оказанию реабилитационной помощи бывшим заключенным"
    },
    {
        "code": "84.23.5",
        "name": "Деятельность Следственного комитета Российской Федерации"
    },
    {
        "code": "84.23.51",
        "name": "Деятельность центрального аппарата Следственного комитета Российской Федерации"
    },
    {
        "code": "84.23.52",
        "name": "Деятельность Главного военного следственного управления, главных следственных управлений и следственных управлений Следственного комитета Российской Федерации по субъектам Российской Федерации (в том числе подразделений указанных управлений по админи стративным округам) и приравненных к ним специализированных (в том числе военных) следственных управлений и следственных отделов Следственного комитета Российской Федерации"
    },
    {
        "code": "84.24",
        "name": "Деятельность по обеспечению общественного порядка и безопасности"
    },
    {
        "code": "84.25",
        "name": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях; деятельность по обеспечению безопасности в области использования атомной энергии"
    },
    {
        "code": "84.25.1",
        "name": "Деятельность по обеспечению пожарной безопасности"
    },
    {
        "code": "84.25.2",
        "name": "Деятельность по обеспечению безопасности на водных объектах"
    },
    {
        "code": "84.25.3",
        "name": "Деятельность по обеспечению безопасности в области использования атомной энергии"
    },
    {
        "code": "84.25.9",
        "name": "Деятельность по обеспечению безопасности в чрезвычайных ситуациях прочая"
    },
    {
        "code": "84.3",
        "name": "Деятельность в области обязательного социального обеспечения"
    },
    {
        "code": "84.30",
        "name": "Деятельность в области обязательного социального обеспечения"
    },
    {
        "code": "85",
        "name": "Образование"
    },
    {
        "code": "85.1",
        "name": "Образование общее"
    },
    {
        "code": "85.11",
        "name": "Образование дошкольное"
    },
    {
        "code": "85.12",
        "name": "Образование начальное общее"
    },
    {
        "code": "85.13",
        "name": "Образование основное общее"
    },
    {
        "code": "85.14",
        "name": "Образование среднее общее"
    },
    {
        "code": "85.2",
        "name": "Образование профессиональное"
    },
    {
        "code": "85.21",
        "name": "Образование профессиональное среднее"
    },
    {
        "code": "85.22",
        "name": "Образование высшее"
    },
    {
        "code": "85.22.1",
        "name": "Образование высшее - бакалавриат"
    },
    {
        "code": "85.22.2",
        "name": "Образование высшее - специалитет"
    },
    {
        "code": "85.22.3",
        "name": "Образование высшее - магистратура"
    },
    {
        "code": "85.23",
        "name": "Подготовка кадров высшей квалификации"
    },
    {
        "code": "85.3",
        "name": "Обучение профессиональное"
    },
    {
        "code": "85.30",
        "name": "Обучение профессиональное"
    },
    {
        "code": "85.4",
        "name": "Образование дополнительное"
    },
    {
        "code": "85.41",
        "name": "Образование дополнительное детей и взрослых"
    },
    {
        "code": "85.41.1",
        "name": "Образование в области спорта и отдыха"
    },
    {
        "code": "85.41.2",
        "name": "Образование в области культуры"
    },
    {
        "code": "85.41.9",
        "name": "Образование дополнительное детей и взрослых прочее, не включенное в другие группировки"
    },
    {
        "code": "85.41.91",
        "name": "Деятельность по организации отдыха детей и их оздоровления"
    },
    {
        "code": "85.42",
        "name": "Образование профессиональное дополнительное"
    },
    {
        "code": "85.42.1",
        "name": "Деятельность школ подготовки водителей автотранспортных средств"
    },
    {
        "code": "85.42.2",
        "name": "Деятельность школ обучения вождению воздушных и плавательных судов, без выдачи коммерческих сертификатов и лицензий"
    },
    {
        "code": "85.42.9",
        "name": "Деятельность по дополнительному профессиональному образованию прочая, не включенная в другие группировки"
    },
    {
        "code": "86",
        "name": "Деятельность в области здравоохранения"
    },
    {
        "code": "86.1",
        "name": "Деятельность больничных организаций"
    },
    {
        "code": "86.10",
        "name": "Деятельность больничных организаций"
    },
    {
        "code": "86.2",
        "name": "Медицинская и стоматологическая практика"
    },
    {
        "code": "86.21",
        "name": "Общая врачебная практика"
    },
    {
        "code": "86.22",
        "name": "Специальная врачебная практика"
    },
    {
        "code": "86.23",
        "name": "Стоматологическая практика"
    },
    {
        "code": "86.9",
        "name": "Деятельность в области медицины прочая"
    },
    {
        "code": "86.90",
        "name": "Деятельность в области медицины прочая"
    },
    {
        "code": "86.90.1",
        "name": "Деятельность организаций санитарно-эпидемиологической службы"
    },
    {
        "code": "86.90.2",
        "name": "Деятельность организаций судебно-медицинской экспертизы"
    },
    {
        "code": "86.90.3",
        "name": "Деятельность массажных салонов"
    },
    {
        "code": "86.90.4",
        "name": "Деятельность санаторно-курортных организаций"
    },
    {
        "code": "86.90.9",
        "name": "Деятельность в области медицины прочая, не включенная в другие группировки"
    },
    {
        "code": "87",
        "name": "Деятельность по уходу с обеспечением проживания"
    },
    {
        "code": "87.1",
        "name": "Деятельность по медицинскому уходу с обеспечением проживания"
    },
    {
        "code": "87.10",
        "name": "Деятельность по медицинскому уходу с обеспечением проживания"
    },
    {
        "code": "87.2",
        "name": "Деятельность по оказанию помощи на дому для лиц с ограниченными возможностями развития, душевнобольным и наркозависимым"
    },
    {
        "code": "87.20",
        "name": "Деятельность по оказанию помощи на дому для лиц с ограниченными возможностями развития, душевнобольным и наркозависимым"
    },
    {
        "code": "87.3",
        "name": "Деятельность по уходу за престарелыми и инвалидами с обеспечением проживания"
    },
    {
        "code": "87.30",
        "name": "Деятельность по уходу за престарелыми и инвалидами с обеспечением проживания"
    },
    {
        "code": "87.9",
        "name": "Деятельность по уходу с обеспечением проживания прочая"
    },
    {
        "code": "87.90",
        "name": "Деятельность по уходу с обеспечением проживания прочая"
    },
    {
        "code": "88",
        "name": "Предоставление социальных услуг без обеспечения проживания"
    },
    {
        "code": "88.1",
        "name": "Предоставление социальных услуг без обеспечения проживания престарелым и инвалидам"
    },
    {
        "code": "88.10",
        "name": "Предоставление социальных услуг без обеспечения проживания престарелым и инвалидам"
    },
    {
        "code": "88.9",
        "name": "Предоставление прочих социальных услуг без обеспечения проживания"
    },
    {
        "code": "88.91",
        "name": "Предоставление услуг по дневному уходу за детьми"
    },
    {
        "code": "88.99",
        "name": "Предоставление прочих социальных услуг без обеспечения проживания, не включенных в другие группировки"
    },
    {
        "code": "90",
        "name": "Деятельность творческая, деятельность в области искусства и организации развлечений"
    },
    {
        "code": "90.0",
        "name": "Деятельность творческая, деятельность в области искусства и организации развлечений"
    },
    {
        "code": "90.01",
        "name": "Деятельность в области исполнительских искусств"
    },
    {
        "code": "90.02",
        "name": "Деятельность вспомогательная, связанная с исполнительскими искусствами"
    },
    {
        "code": "90.03",
        "name": "Деятельность в области художественного творчества"
    },
    {
        "code": "90.04",
        "name": "Деятельность учреждений культуры и искусства"
    },
    {
        "code": "90.04.1",
        "name": "Деятельность концертных залов, театров, оперных зданий, мюзик-холлов, включая услуги билетных касс"
    },
    {
        "code": "90.04.2",
        "name": "Деятельность многоцелевых центров и подобных заведений с преобладанием культурного обслуживания"
    },
    {
        "code": "90.04.3",
        "name": "Деятельность учреждений клубного типа: клубов, дворцов и домов культуры, домов народного творчества"
    },
    {
        "code": "91",
        "name": "Деятельность библиотек, архивов, музеев и прочих объектов культуры"
    },
    {
        "code": "91.0",
        "name": "Деятельность библиотек, архивов, музеев и прочих объектов культуры"
    },
    {
        "code": "91.01",
        "name": "Деятельность библиотек и архивов"
    },
    {
        "code": "91.02",
        "name": "Деятельность музеев"
    },
    {
        "code": "91.03",
        "name": "Деятельность по охране исторических мест и зданий, памятников культуры"
    },
    {
        "code": "91.04",
        "name": "Деятельность ботанических садов, зоопарков, государственных природных заповедников и национальных парков"
    },
    {
        "code": "91.04.1",
        "name": "Деятельность зоопарков"
    },
    {
        "code": "91.04.2",
        "name": "Деятельность государственных природных заповедников (в том числе биосферных)"
    },
    {
        "code": "91.04.3",
        "name": "Деятельность национальных парков"
    },
    {
        "code": "91.04.4",
        "name": "Деятельность природных парков"
    },
    {
        "code": "91.04.5",
        "name": "Деятельность природных заказников"
    },
    {
        "code": "91.04.6",
        "name": "Деятельность дендрологических парков и ботанических садов"
    },
    {
        "code": "92",
        "name": "Деятельность по организации и проведению азартных игр и заключению пари, по организации и проведению лотерей"
    },
    {
        "code": "92.1",
        "name": "Деятельность по организации и проведению азартных игр и заключения пари"
    },
    {
        "code": "92.11",
        "name": "Деятельность казино"
    },
    {
        "code": "92.12",
        "name": "Деятельность залов игровых автоматов"
    },
    {
        "code": "92.13",
        "name": "Деятельность по организации заключения пари"
    },
    {
        "code": "92.2",
        "name": "Деятельность по организации и проведению лотерей"
    },
    {
        "code": "92.21",
        "name": "Деятельность организаторов лотерей"
    },
    {
        "code": "92.22",
        "name": "Деятельность операторов лотерей"
    },
    {
        "code": "92.23",
        "name": "Деятельность распространителей лотерейных билетов"
    },
    {
        "code": "93",
        "name": "Деятельность в области спорта, отдыха и развлечений"
    },
    {
        "code": "93.1",
        "name": "Деятельность в области спорта"
    },
    {
        "code": "93.11",
        "name": "Деятельность спортивных объектов"
    },
    {
        "code": "93.12",
        "name": "Деятельность спортивных клубов"
    },
    {
        "code": "93.13",
        "name": "Деятельность фитнес-центров"
    },
    {
        "code": "93.19",
        "name": "Деятельность в области спорта прочая"
    },
    {
        "code": "93.2",
        "name": "Деятельность в области отдыха и развлечений"
    },
    {
        "code": "93.21",
        "name": "Деятельность парков культуры и отдыха и тематических парков"
    },
    {
        "code": "93.29",
        "name": "Деятельность зрелищно-развлекательная прочая"
    },
    {
        "code": "93.29.1",
        "name": "Деятельность парков отдыха и пляжей"
    },
    {
        "code": "93.29.2",
        "name": "Деятельность танцплощадок, дискотек, школ танцев"
    },
    {
        "code": "93.29.3",
        "name": "Организация обрядов (свадеб, юбилеев), в т.ч. музыкальное сопровождение"
    },
    {
        "code": "93.29.9",
        "name": "Деятельность зрелищно-развлекательная прочая, не включенная в другие группировки"
    },
    {
        "code": "94",
        "name": "Деятельность общественных организаций"
    },
    {
        "code": "94.1",
        "name": "Деятельность коммерческих, предпринимательских и профессиональных организаций"
    },
    {
        "code": "94.11",
        "name": "Деятельность коммерческих и предпринимательских членских организаций"
    },
    {
        "code": "94.12",
        "name": "Деятельность профессиональных членских организаций"
    },
    {
        "code": "94.2",
        "name": "Деятельность профессиональных союзов"
    },
    {
        "code": "94.20",
        "name": "Деятельность профессиональных союзов"
    },
    {
        "code": "94.9",
        "name": "Деятельность прочих общественных организаций"
    },
    {
        "code": "94.91",
        "name": "Деятельность религиозных организаций"
    },
    {
        "code": "94.92",
        "name": "Деятельность политических организаций"
    },
    {
        "code": "94.99",
        "name": "Деятельность прочих общественных организаций, не включенных в другие группировки"
    },
    {
        "code": "95",
        "name": "Ремонт компьютеров, предметов личного потребления и хозяйственно-бытового назначения"
    },
    {
        "code": "95.1",
        "name": "Ремонт компьютеров и коммуникационного оборудования"
    },
    {
        "code": "95.11",
        "name": "Ремонт компьютеров и периферийного компьютерного оборудования"
    },
    {
        "code": "95.12",
        "name": "Ремонт коммуникационного оборудования"
    },
    {
        "code": "95.2",
        "name": "Ремонт предметов личного потребления и хозяйственно-бытового назначения"
    },
    {
        "code": "95.21",
        "name": "Ремонт электронной бытовой техники"
    },
    {
        "code": "95.22",
        "name": "Ремонт бытовых приборов, домашнего и садового инвентаря"
    },
    {
        "code": "95.22.1",
        "name": "Ремонт бытовой техники"
    },
    {
        "code": "95.22.2",
        "name": "Ремонт домашнего и садового оборудования"
    },
    {
        "code": "95.23",
        "name": "Ремонт обуви и прочих изделий из кожи"
    },
    {
        "code": "95.24",
        "name": "Ремонт мебели и предметов домашнего обихода"
    },
    {
        "code": "95.24.1",
        "name": "Ремонт мебели"
    },
    {
        "code": "95.24.2",
        "name": "Ремонт предметов домашнего обихода"
    },
    {
        "code": "95.25",
        "name": "Ремонт часов и ювелирных изделий"
    },
    {
        "code": "95.25.1",
        "name": "Ремонт часов"
    },
    {
        "code": "95.25.2",
        "name": "Ремонт ювелирных изделий"
    },
    {
        "code": "95.29",
        "name": "Ремонт прочих предметов личного потребления и бытовых товаров"
    },
    {
        "code": "95.29.1",
        "name": "Ремонт одежды и текстильных изделий"
    },
    {
        "code": "95.29.11",
        "name": "Ремонт одежды"
    },
    {
        "code": "95.29.12",
        "name": "Ремонт текстильных изделий"
    },
    {
        "code": "95.29.13",
        "name": "Ремонт трикотажных изделий"
    },
    {
        "code": "95.29.2",
        "name": "Ремонт спортивного и туристского оборудования"
    },
    {
        "code": "95.29.3",
        "name": "Ремонт игрушек и подобных им изделий"
    },
    {
        "code": "95.29.4",
        "name": "Ремонт металлоизделий бытового и хозяйственного назначения"
    },
    {
        "code": "95.29.41",
        "name": "Ремонт предметов и изделий из металла"
    },
    {
        "code": "95.29.42",
        "name": "Ремонт металлической галантереи, ключей, номерных знаков, указателей улиц"
    },
    {
        "code": "95.29.43",
        "name": "Заточка пил, чертежных и других инструментов, ножей, ножниц, бритв, коньков и т.п."
    },
    {
        "code": "95.29.5",
        "name": "Ремонт бытовых осветительных приборов"
    },
    {
        "code": "95.29.6",
        "name": "Ремонт велосипедов"
    },
    {
        "code": "95.29.7",
        "name": "Ремонт и настройка музыкальных инструментов (кроме органов и исторических музыкальных инструментов)"
    },
    {
        "code": "95.29.9",
        "name": "Ремонт прочих бытовых изделий и предметов личного пользования, не вошедших в другие группировки"
    },
    {
        "code": "96",
        "name": "Деятельность по предоставлению прочих персональных услуг"
    },
    {
        "code": "96.0",
        "name": "Деятельность по предоставлению прочих персональных услуг"
    },
    {
        "code": "96.01",
        "name": "Стирка и химическая чистка текстильных и меховых изделий"
    },
    {
        "code": "96.02",
        "name": "Предоставление услуг парикмахерскими и салонами красоты"
    },
    {
        "code": "96.02.1",
        "name": "Предоставление парикмахерских услуг"
    },
    {
        "code": "96.02.2",
        "name": "Предоставление косметических услуг парикмахерскими и салонами красоты"
    },
    {
        "code": "96.03",
        "name": "Организация похорон и представление связанных с ними услуг"
    },
    {
        "code": "96.04",
        "name": "Деятельность физкультурно- оздоровительная"
    },
    {
        "code": "96.09",
        "name": "Предоставление прочих персональных услуг, не включенных в другие группировки"
    },
    {
        "code": "97",
        "name": "Деятельность домашних хозяйств с наемными работниками"
    },
    {
        "code": "97.0",
        "name": "Деятельность домашних хозяйств с наемными работниками"
    },
    {
        "code": "97.00",
        "name": "Деятельность домашних хозяйств с наемными работниками"
    },
    {
        "code": "98",
        "name": "Деятельность недифференцированная частных домашних хозяйств по производству товаров и предоставлению услуг для собственного потребления"
    },
    {
        "code": "98.1",
        "name": "Деятельность недифференцированная частных домашних хозяйств по производству товаров для собственного потребления"
    },
    {
        "code": "98.10",
        "name": "Деятельность недифференцированная частных домашних хозяйств по производству товаров для собственного потребления"
    },
    {
        "code": "98.2",
        "name": "Деятельность недифференцированная частных домашних хозяйств по предоставлению услуг для собственного потребления"
    },
    {
        "code": "98.20",
        "name": "Деятельность недифференцированная частных домашних хозяйств по предоставлению услуг для собственного потребления"
    },
    {
        "code": "99",
        "name": "Деятельность экстерриториальных организаций и органов"
    },
    {
        "code": "99.0",
        "name": "Деятельность экстерриториальных организаций и органов"
    },
    {
        "code": "99.00",
        "name": "Деятельность экстерриториальных организаций и органов"
    },
];

export { okved };